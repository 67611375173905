import { IMultiChart, IChart } from "@/shared/components/charts/types/chartTypes";

export function base64ToUtf(key: string): string {
  return decodeURIComponent(window.atob(key));
}

export function utf8ToB64(str: string) {
  return window.btoa(decodeURI(encodeURIComponent(str)));
}

export function transformDataToMultiChart(usersChartData: IMultiChart[]): IChart[] {
  const updatedUsersChartDataWithInvitesId: IMultiChart[] = usersChartData.map((data) => {
    const updChData = data.chartData.map((chartData) => {
      if (chartData.value != null) {
        chartData[data.inviteId] = chartData.value;
      }
      return { ...chartData };
    });
    return { ...data, chartData: updChData };
  });

  const allCharts = updatedUsersChartDataWithInvitesId.map((data) => data.chartData).flat();

  if (updatedUsersChartDataWithInvitesId.length !== 0) {
    const competencyMap = new Map<string, [string, number]>();
    updatedUsersChartDataWithInvitesId.forEach((usersChartData) => {
      usersChartData.chartData.forEach((item) => {
        if (typeof item.value === "number") {
          competencyMap.set(`${item.competency}__${item.testId}`, [item.competency, item.testId]);
        }
      });
    });

    const competencyList = Array.from(competencyMap.values()).map(([competencyName, testId]) => {
      return { competencyName, testId };
    });

    return competencyList.map((competency) => {
      const competencyVal = allCharts.filter(
        (a) => a.competency === competency.competencyName && a.testId === competency.testId,
      );
      return Object.assign({}, ...competencyVal);
    });
  } else return [];
}

export function getUniqueElements<T>(array: T[]): T[] {
  let result: T[] = [];

  for (let item of array) {
    if (!result.includes(item)) {
      result.push(item);
    }
  }

  return result;
}

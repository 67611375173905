import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

import { ReportContentView } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { Loading } from "@/shared/components/Loading/Loading";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { NOT_FOUND_ROUTE, REPORT_MENU_ITEM } from "@/routing/AppRouter/routes.constants";

import { generateMenu } from "../utils/reportMenuUtils";
import "./sidebar-menu.css";

export interface IMenuItem {
  label: string;
  key: ReportContentView;
}

export interface IReportSidebarMenu {
  isScored: boolean;
  isDone: boolean;
  showOverall: boolean;
  onMenuClick: (e: any) => void;
  hasNarrative: boolean;
  isViewerAdmin: boolean;
  showRadar: boolean;
  isCompareView: boolean;
}

export function ReportSidebarMenu({
  isScored,
  isDone,
  showOverall,
  onMenuClick,
  hasNarrative,
  isViewerAdmin,
  showRadar,
  isCompareView,
}: IReportSidebarMenu) {
  const navigate = useNavigate();

  const defaultSelectedMenuItem = useGetQueryCode(REPORT_MENU_ITEM);
  const contentViewFromURL = ReportContentView[defaultSelectedMenuItem as keyof typeof ReportContentView];

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);

  useEffect(() => {
    setIsLoading(true);
    const items = generateMenu({
      isDone,
      isScored,
      isViewerAdmin,
      isCompareView,
      hasNarrative,
      showOverall,
      showRadar,
      abilities: { canManageAssessment },
    });
    setMenuItems([...items]);
    setIsLoading(false);
  }, [isDone, isScored, isViewerAdmin, isCompareView, hasNarrative, showOverall, canManageAssessment, showRadar]);

  useEffect(() => {
    if (menuItems.length) {
      if (defaultSelectedMenuItem && menuItems.find((item) => item.key === contentViewFromURL)) {
        onMenuClick({ label: defaultSelectedMenuItem, key: contentViewFromURL });
      } else {
        onMenuClick(menuItems[0]);
      }
    } else {
      if (!isLoading) navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [menuItems, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="sidebarMenu">
      <h3 className="menuTitle">Menu</h3>
      <Menu
        items={menuItems}
        defaultSelectedKeys={
          defaultSelectedMenuItem ? [String(contentViewFromURL)] : menuItems.length ? [String(menuItems[0].key)] : []
        }
        onClick={onMenuClick}
        className="menuItemsContainer"
      />
    </div>
  );
}

import { useEffect, useState } from "react";

import { ITestAnswer, ITestAnswerTimes } from "@/shared/components/charts/types/chartTypes";

import { minToSec, secToMls } from "@/shared/utils/common.utils";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

import { ITest, ITestElement } from "@/modules/assessments/types/test.interface";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";

import { getTestElementSection, getTestTimes } from "../utils/section.utils";

export type SectionErrorState = { concurrentTimerError: boolean; genericTimerError: boolean };

export const DELTA_TIME_SEC = 15;

export function useSectionsHook({
  test,
  invite,
  answers,
  currentPage,
  questionsList,
  isOpenedInstructions,
}: {
  currentPage: number;
  answers: ITestAnswer[];
  questionsList: ITestElement[][];
  isOpenedInstructions: boolean;
  test?: ITest;
  invite?: IAssessmentInvite;
}) {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isTimedOutSection, setIsTimedOutSection] = useState(false);
  const [sectionsTime, setSectionsTime] = useState<ITestAnswerTimes>({});
  const [currentSectionElement, setCurrentSectionElement] = useState<ITestElement | undefined>();
  const [errorState, setErrorState] = useState<SectionErrorState>({
    genericTimerError: false,
    concurrentTimerError: false,
  });
  const [isOpenedTimeOutModal, setIsOpenedTimeOutModal] = useState<boolean>(false);

  const sectionName = currentSectionElement?.config.startsSection?.name;
  const testAnswer = answers.find((answer) => answer.testId === test?.testId);
  const isViewingInstructionsForFirstTime = isOpenedInstructions && (!testAnswer || !testAnswer.answer.length);

  // 1. Define section
  useEffect(() => {
    if (!currentPage || !test) {
      return;
    }

    setCurrentSectionElement(getTestElementSection(questionsList, test.testId, currentPage) || undefined);
  }, [currentPage, test?.testId]);

  // 2. Set default times
  useEffect(() => {
    if (!answers.length || !test?.testId) {
      return;
    }

    setSectionsTime(getTestTimes(answers, test.testId) || {});
  }, [answers, test?.testId, sectionName]);

  // 3. Set timer for the section
  useEffect(() => {
    if (!currentSectionElement || !currentSectionElement.config.startsSection?.timeLimit || invite?.timerDisabled) {
      setSectionsTime({});
    }

    const startsSection = currentSectionElement?.config?.startsSection;

    if (
      !startsSection ||
      !invite?.inviteId ||
      invite?.timerDisabled ||
      !test?.testId ||
      isViewingInstructionsForFirstTime
    ) {
      return;
    }

    const sectionKey = currentSectionElement?.config.key;

    const interval = setInterval(async () => {
      try {
        const sectionTimer = await InvitesHttpService.increaseSectionTime(invite.inviteId, test.testId, {
          elementKey: sectionKey,
          deltaTime: DELTA_TIME_SEC,
        });

        setSectionsTime((section) => ({ ...section, [sectionKey]: sectionTimer }));
        setErrorState({ concurrentTimerError: false, genericTimerError: false });
      } catch (e: any) {
        if (e && e.response && e.response.status === 403) {
          setErrorState((prevState) => ({ ...prevState, concurrentTimerError: true }));
        } else {
          setErrorState((prevState) => ({ ...prevState, genericTimerError: true }));
        }
      }
    }, secToMls(DELTA_TIME_SEC));

    if (isTimedOutSection) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    currentSectionElement,
    isTimedOutSection,
    test?.testId,
    invite?.inviteId,
    invite?.timerDisabled,
    isViewingInstructionsForFirstTime,
  ]);

  // 4. Check if the section is timed-out
  useEffect(() => {
    if (!currentSectionElement || !currentSectionElement.config.startsSection?.timeLimit || invite?.timerDisabled) {
      setIsTimedOutSection(false);
    }

    const startsSection = currentSectionElement?.config?.startsSection;

    if (!startsSection || !invite?.inviteId || !test?.testId || typeof startsSection.timeLimit !== "number") {
      return;
    }

    const sectionKey = currentSectionElement?.config.key;

    if (
      startsSection.timeLimit &&
      sectionsTime[sectionKey] &&
      sectionsTime[sectionKey].time >= minToSec(startsSection.timeLimit)
    ) {
      setIsTimedOutSection(true);
    } else {
      setIsTimedOutSection(false);
    }
  }, [sectionName, sectionsTime, invite?.timerDisabled]);

  // 5. How much time left for the section
  useEffect(() => {
    if (currentSectionElement?.config.startsSection?.timeLimit && !invite?.timerDisabled) {
      let subtractor = 0;

      if (sectionsTime[currentSectionElement?.config.key]?.time) {
        subtractor = sectionsTime[currentSectionElement?.config.key]?.time;
      }

      const timeLeft = secToMls(minToSec(currentSectionElement?.config?.startsSection?.timeLimit) - subtractor);

      if (
        isViewingInstructionsForFirstTime &&
        timeLeft === secToMls(minToSec(currentSectionElement?.config?.startsSection?.timeLimit))
      ) {
        return setTimeLeft(null);
      }
      setTimeLeft(timeLeft);
    } else {
      setTimeLeft(null);
    }
  }, [currentSectionElement, sectionsTime, isViewingInstructionsForFirstTime, invite?.timerDisabled]);

  // 6. Check if the section is timed out
  useEffect(() => {
    if (!isTimedOutSection) {
      return;
    }

    setIsOpenedTimeOutModal(true);
  }, [isTimedOutSection]);

  return {
    timeLeft,
    errorState,
    sectionsTime,
    isTimedOutSection,
    currentSectionElement,
    isOpenedTimeOutModal,
    setIsOpenedTimeOutModal,
  };
}

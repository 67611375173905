import { httpClient } from "@/shared/services/http";

import { IUser } from "@/modules/client/clients.interface";

/**
 * @description
 * * ASSESSMENT_INVITER - can only view assessment and invite candidates.
 * * USER - only has view permissions on this assessment - can see all tabs, report but can’t edit anything and can’t invite users.
 * */
export enum AssessmentRoleEnum {
  ASSESSMENT_INVITER = "assessment_inviter",
  USER = "user",
}

export const AssessmentRolesDescription = {
  [AssessmentRoleEnum.ASSESSMENT_INVITER]: "Can only view assessment and invite candidates.",
  [AssessmentRoleEnum.USER]: "Can see candidate reports but not make changes to the assessment or invite candidates.",
};

export type IAssessmentRole = {
  roleId: number;
  name: AssessmentRoleEnum;
};

export type IAssessmentPermissions = {
  assessmentId: number;
  userId: number;
  roleId: number;

  user?: IUser;
  role?: IAssessmentRole;
};

export class AssessmentPermissionsHttpService {
  static async getAssessmentUsers(assessmentId: number): Promise<IAssessmentPermissions[]> {
    const { data: assessmentPermissions } = await httpClient.get(`/api/assessments/${assessmentId}/permissions`);
    return assessmentPermissions;
  }

  static async manageUserAssessmentPermissions(
    assessmentId: number,
    { userId, role }: { userId: number; role: AssessmentRoleEnum },
  ): Promise<IAssessmentPermissions> {
    const { data: assessmentUser } = await httpClient.patch(`/api/assessments/${assessmentId}/permissions`, {
      userId,
      role,
    });
    return assessmentUser;
  }

  static async removeUserAssessmentPermissions(assessmentId: number, userId: number): Promise<IAssessmentPermissions> {
    const { data: removeResult } = await httpClient.delete(
      `/api/assessments/${assessmentId}/permissions/users/${userId}`,
    );
    return removeResult;
  }
}

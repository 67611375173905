import { Dispatch, SetStateAction } from "react";

import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";

import { UnansweredQuestionsFooter } from "./UnansweredQuestionsFooter";

export function UnansweredQuestionsModal({
  isOpen,
  setIsOpenModal,
  handleBack,
  handleNextClick,
}: {
  isOpen: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  handleBack: (isConfirmed?: boolean) => void;
  handleNextClick: (isConfirmed?: boolean, isBack?: boolean) => void;
}) {
  return (
    <BasicModal
      autoHeight
      handleCancel={() => setIsOpenModal(false)}
      isModalOpen={isOpen}
      footer={
        <UnansweredQuestionsFooter
          handleOk={async () => {
            await handleNextClick(true, false);
            setIsOpenModal(false);
          }}
          handleCancel={async () => {
            await handleNextClick(true, true);
            handleBack();
            setIsOpenModal(false);
          }}
        />
      }
    >
      <p>You have unanswered question(s). Would you like to review your answers?</p>
    </BasicModal>
  );
}

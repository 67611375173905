import { IClientConfig } from "@/modules/client/clients.interface";
import { ICompetency } from "@/shared/components/competencies/types";
import { sortElements } from "@/shared/utils/array/sort.utils";

export interface IGetCompetencyName {
  base64Key: string;
  testId: number;
  competencies?: ICompetency[];
}

export interface IGetCustomCompetencyOptions extends IGetCompetencyName {
  clientConfig: IClientConfig | undefined | null;
}

export interface ICompareCompetencies {
  firstKey: string;
  secondKey: string;
  testId: number;
  competencies?: ICompetency[];
}

export class ClientCompetenciesService {
  static getCompetencyName({ base64Key, testId, competencies }: IGetCompetencyName): string {
    if (!competencies) {
      return "";
    }

    const competency = competencies.find((competency) => competency.testId === testId && competency.key === base64Key);
    return competency ? competency.name : "";
  }

  static getCustomCompetencyName({ base64Key, testId, clientConfig, competencies }: IGetCustomCompetencyOptions) {
    return (
      clientConfig?.customCompetencyNames?.[testId]?.[base64Key] ||
      this.getCompetencyName({ base64Key, testId, competencies })
    );
  }

  static compareCompetenciesByName({ firstKey, secondKey, testId, competencies }: ICompareCompetencies) {
    const firstCompetencyName = this.getCompetencyName({ base64Key: firstKey, testId, competencies });
    const secondCompetencyName = this.getCompetencyName({ base64Key: secondKey, testId, competencies });
    return sortElements(firstCompetencyName, secondCompetencyName);
  }
}

import { message } from "antd";
import { RcFile } from "antd/lib/upload";

export class AssetsUtils {
  static imageValidation(file: RcFile): boolean {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/apng" ||
      file.type === "image/avif" ||
      file.type === "image/svg+xml" ||
      file.type === "image/webp" ||
      file.type === "image/bmp" ||
      file.type === "image/tiff";

    if (!isJpgOrPng) {
      message.error("You can only upload image files!");
    }

    const isLt100M = file.size / 1024 / 1024 < 100;

    if (!isLt100M) {
      message.error("Image must be smaller than 100MB!");
    }

    return isJpgOrPng && isLt100M;
  }
}

import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

import { IAssessmentTest, ITestElement } from "@/modules/assessments/types/test.interface";

export function getElementKeyIndex(questionsList: ITestElement[][], elementKey: string) {
  return questionsList.findIndex((questions) => questions.find(({ config }) => config.key === elementKey));
}

export const getLastAnsweredTestElement = (assessmentTest?: IAssessmentTest, testAnswer?: ITestAnswer): string => {
  if (!assessmentTest || !testAnswer) {
    return "";
  }

  const updatedTest = assessmentTest.test.testElements.filter((testElement) => {
    return (
      testElement.config.type !== QuestionTypes.Text &&
      testAnswer?.answer.find((answer) => answer.elementKey === testElement.config.key)
    );
  });

  return updatedTest.at(-1)?.config.key || "";
};

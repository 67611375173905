import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Menu } from "antd";

import { selectClientData, selectPickedClient } from "@/shared/services/store/clientStore";
import { removeElementByKeyElement } from "@/shared/utils/array/array.utils";

import { ADMIN_ROUTE, ASSESSMENT_ROUTE, CLIENT_ROUTE } from "@/routing/AppRouter/routes.constants";

import { useMenuItems } from "@/modules/layout/Menu/use-menu-items.hook";

import styles from "./style.module.css";

export function CustomMenu() {
  const { pathname } = useLocation();
  const clients = useSelector(selectClientData);
  const selectClientId = useSelector(selectPickedClient);
  const [clientName, setClientName] = useState<string>();

  useEffect(() => {
    if (clients.length !== 0 && selectClientId) {
      const foundClient = clients.find((client) => client.clientId === selectClientId);
      if (foundClient) {
        const name = foundClient.name;
        setClientName(name);
      }
    }
  }, [clients, selectClientId]);

  const menuItems = useMenuItems(selectClientId, clientName);

  const path: string[] = pathname
    .split("/")
    .map((item) => item)
    .filter((item) => item);

  // if 'assessments' in path, remove 'clients' from selected keys
  let selectedKeys = removeElementByKeyElement(path, ASSESSMENT_ROUTE, 1);
  if (selectedKeys.includes(ADMIN_ROUTE) && selectedKeys.includes(CLIENT_ROUTE)) {
    selectedKeys.splice(1, 1);
  }

  return <Menu mode="horizontal" className={styles.mainMenu} selectedKeys={selectedKeys} items={menuItems} />;
}

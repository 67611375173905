import { useCallback, useState } from "react";
import { notification } from "antd";

import { UserHttpService } from "@/shared/services/user/user-http.service";

export function useDeleteUserHook() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const deleteUser = useCallback(async (userId: number) => {
    try {
      setLoading(true);
      await UserHttpService.deleteUser(userId);
    } catch (e: any) {
      setError(e?.message || "Error while removing user");

      notification.error({
        message: e.message || "Error while removing user",
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, error, deleteUser };
}

import { useState } from "react";
import { debounce } from "react-ace/lib/editorOptions";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { splitPattern } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import {
  initialPlaceholderText,
  MIN_SEARCH_LENGTH,
} from "@/shared/components/assessment/report/ReportCompareHeader/ReportCompareHeader";
import { isValidNumericParam } from "@/shared/utils/common.utils";

export function useCompareReportHook(
  selectedUsers: string[],
  setSelectedUsers: (value: string[]) => void,
  fetchAnswers?: (invitedId: number) => void,
  setIsHiddenSameScore?: (value: boolean) => void,
) {
  const [placeholder, setPlaceholder] = useState<string>(initialPlaceholderText);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [candidateSearch, setCandidateSearch] = useState<string>("");

  const handleChangeUsers = async (values: string[]) => {
    const newCandidateToCompareNameId = values.filter((name) => !selectedUsers.includes(name)).at(0);
    const newInviteId: string | undefined = newCandidateToCompareNameId?.split(splitPattern).at(-1);
    const candidateToRemoveName = selectedUsers.filter((name) => !values.includes(name)).at(0);
    if (newInviteId && isValidNumericParam(newInviteId)) {
      if (fetchAnswers) {
        await fetchAnswers(Number(newInviteId));
        setSelectedUsers(values);
      }
    } else {
      setSelectedUsers(values.filter((name) => name !== candidateToRemoveName));
    }
    if (setIsHiddenSameScore) {
      setIsHiddenSameScore(false);
    }
  };
  const handleInputUsers = debounce(async (searchValue: string) => {
    setCurrentPage(1);
    if (searchValue.length >= MIN_SEARCH_LENGTH) {
      setCandidateSearch(searchValue);
    }
  }, 300);
  const handleOpenDropdown = async (open: boolean) => {
    if (open) {
      setPlaceholder("");
    } else {
      setPlaceholder(initialPlaceholderText);
    }
  };

  const handlePaginationSelect = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };
  const handleHideSameScore = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    if (setIsHiddenSameScore) {
      setIsHiddenSameScore(checked);
    }
  };

  return {
    handleChangeUsers,
    handleHideSameScore,
    handleInputUsers,
    handleOpenDropdown,
    handlePaginationSelect,
    placeholder,
    currentPage,
    candidateSearch,
  };
}

import { convertToFuzzyTimeFormat } from "@/shared/utils/common.utils";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ITestElement } from "@/modules/assessments/types/test.interface";

import { ITextLocation } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import { TextLocations } from "@/modules/assessments/scenes/CreateAssessment/createAssessment.constants";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

export function getQuestionNumberByCurrentPage(currentPage: number, questionsList: ITestElement[][]): number {
  let counter: number = 0;
  const questionFirstIndexOnPageList: number[] = questionsList.map((pageQuestionsList) => {
    const firstIndex = counter;
    counter = pageQuestionsList.length + counter;
    return firstIndex;
  });
  return questionFirstIndexOnPageList[currentPage - 1] + 1;
}

export function getCurrentTextNumber(maxTestIndexInModule: number[], currentQuestion: number) {
  const i = maxTestIndexInModule.findIndex((element) => element >= currentQuestion);
  return i === -1 ? maxTestIndexInModule.length : i + 1;
}

// TODO: Refactor this approach, many components depend on this function; however, they only use some of the values
export function getTestsProps(assessment: IAssessment | undefined, textLocations: ITextLocation[]) {
  if (!assessment) {
    return {
      imageUrl: "",
      title: "",
      testsQuantity: 0,
      questionsQuantity: 0,
      leftTime: "",
      introText: "",
      outroText: "",
      questionsList: [[]],
    };
  }

  const introTextId: number | undefined = textLocations.find(
    (textLocation) => textLocation.name === TextLocations.introText,
  )?.locationId;
  const outroTextId: number | undefined = textLocations.find(
    (textLocation) => textLocation.name === TextLocations.outroText,
  )?.locationId;

  const { assessmentTests, assessmentTexts, name: title, titleImageUrl: imageUrl } = assessment;

  const questionsList: ITestElement[][] = getAllQuestionsAsArray(getAllTests(assessment));

  const maxTestIndexInModule: number[] = getMaxTestIndexInModule(getAllTests(assessment));
  const testsQuantity: number = assessmentTests?.length || 0;

  let questionsQuantity: number = 0;
  let leftTime: string = convertToFuzzyTimeFormat(0);

  if (testsQuantity !== 0 && assessmentTests) {
    questionsQuantity = getAllTests(assessment)
      .flat()
      .filter((element) => element.config.type !== QuestionTypes.Text).length;

    leftTime = convertToFuzzyTimeFormat(
      assessmentTests.map((assessmentTest) => assessmentTest.test.config.suggestedTime || 0).reduce((a, b) => a + b),
    );
  }

  const introText: string | undefined = assessmentTexts
    ? assessmentTexts.find((text) => text.locationId === introTextId)?.body?.["en-US"]
    : "";
  const outroText: string | undefined = assessmentTexts
    ? assessmentTexts.find((text) => text.locationId === outroTextId)?.body?.["en-US"]
    : "";

  return {
    imageUrl,
    title,
    testsQuantity,
    leftTime,
    introText,
    outroText,
    questionsList,
    maxTestIndexInModule,
    questionsQuantity,
  };
}

function getAllTests(assessment: IAssessment): ITestElement[][] {
  if (assessment && assessment.assessmentTests) {
    return assessment.assessmentTests
      .sort((a, b) => a.rank - b.rank)
      .map(({ test: { testElements } }) => {
        return testElements.sort((a, b) => a.rank - b.rank);
      });
  }
  return [];
}

function getAllQuestionsAsArray(allTests: ITestElement[][]): ITestElement[][] {
  const allQuestionsList: ITestElement[] = allTests.flat();
  let outerList: ITestElement[][] = [];
  let innerList: ITestElement[] = [];
  let firstElementTestID = allQuestionsList[0].testId;
  let previousElementTestId: number;
  allQuestionsList.forEach((testElement: ITestElement, index) => {
    if (index === 0) {
      previousElementTestId = firstElementTestID;
    }
    const hasBreakAfter = testElement.config.breakAfter;
    if (testElement.testId === previousElementTestId) {
      if (!hasBreakAfter) {
        innerList.push(testElement);
        if (index === allQuestionsList.length - 1) {
          outerList.push(innerList);
          innerList = [];
        }
      } else {
        innerList.push(testElement);
        outerList.push(innerList);
        innerList = [];
      }
    } else {
      if (!hasBreakAfter) {
        if (innerList.length !== 0) {
          outerList.push(innerList);
          innerList = [];
        } else {
          innerList.push(testElement);
        }
      } else {
        if (innerList.length !== 0) {
          outerList.push(innerList);
          innerList = [];
        }
        innerList.push(testElement);
        outerList.push(innerList);
        innerList = [];
      }
    }
    previousElementTestId = testElement.testId;
  });
  return outerList;
}

function getMaxTestIndexInModule(allTests: ITestElement[][]): number[] {
  let quantity: number = 0;
  return allTests.map((item) => {
    quantity += item.length;
    return quantity;
  });
}

type ButtonText = "next" | "finish test" | "start section" | "next section";

export const getNextButtonText = (currentPage: number, questionsList: ITestElement[][]): ButtonText => {
  if (currentPage < 1 || currentPage > questionsList.length) return "next";

  const currentPageQuestions = questionsList[currentPage - 1];
  const hasNextPage = currentPage < questionsList.length;
  const nextPageQuestions = hasNextPage ? questionsList[currentPage] : [];

  const isLastPage = !hasNextPage;
  const isIntroPageOfSection =
    currentPageQuestions.length === 1 && currentPageQuestions[0]?.config.type === QuestionTypes.Text;
  const isFinalPageOfSection =
    hasNextPage && nextPageQuestions.length === 1 && nextPageQuestions[0]?.config.type === QuestionTypes.Text;

  if (isLastPage) {
    return "finish test";
  } else if (isIntroPageOfSection) {
    return "start section";
  } else if (isFinalPageOfSection) {
    return "next section";
  } else {
    return "next";
  }
};

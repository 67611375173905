import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ITest } from "@/modules/assessments/types/test.interface";

import { TestHttpService } from "@/shared/services/tests/test-http.service";
import { isValidNumericParam } from "@/shared/utils/common.utils";

import getErrorRoute from "@/routing/services/getErrorRoute";
import { HttpStatus } from "@/routing/services/statusCodes";

export function useGetTestHook(testId: number, isAllowedToLoad = true) {
  const navigate = useNavigate();

  const [test, setTest] = useState<ITest>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isAllowedToLoad) {
      return;
    }

    const fetchTest = async () => {
      try {
        setIsLoading(true);

        const testFromAPI: ITest = await TestHttpService.getTestById(testId);

        setTest(testFromAPI);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);

        console.error(`fetchingTest: ${e}`);
        const route: string = getErrorRoute(HttpStatus.NOT_FOUND);
        navigate(route);
      }
    };

    if (isValidNumericParam(testId)) {
      fetchTest();
    }
  }, [testId]);

  return { test, isLoading };
}

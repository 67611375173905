import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import { Tooltip } from "antd";
import { AlignLeftOutlined, ClockCircleOutlined } from "@ant-design/icons";

import { getRoute } from "@/shared/utils/route/route.utils";
import { Loading } from "@/shared/components/Loading/Loading";
import { TTestAnswer } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

import { getTestsProps } from "@/modules/candidate/utils/candidateAssessment.utils";

import { ITestElement } from "@/modules/assessments/types/test.interface";
import { useClientAssessmentHook } from "@/modules/candidate/hooks/useClientAssessment.hook";
import { ITextLocation } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";

import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import ParagraphWithIcon from "@/ui-components/commonComponents/ParagraphWithIcon/ParagraphWithIcon";

import { ReactComponent as DotSVG } from "@/assets/icons/feather-icons/circle.svg";

import style from "./style.module.css";

interface IInviteCard {
  assessmentTitle: string;
  assessmentId: number;
  inviteCode: string;
  modulesQuantity?: number;
  leftTime?: number;
  questionsNumber?: number;
  state: AssessmentInviteStatus;
  testAnswers: TTestAnswer[];
  textLocations: ITextLocation[];
  timerDisabled: boolean;
}

export const statusText: { [text: string]: string } = {
  [AssessmentInviteStatus.Accepted]: "Accepted",
  [AssessmentInviteStatus.Invited]: "Invited",
  [AssessmentInviteStatus.InProgress]: "In Progress",
  [AssessmentInviteStatus.Done]: "Finished",
  [AssessmentInviteStatus.Started]: "Started",
  [AssessmentInviteStatus.WithAnError]: "With an error",
  [AssessmentInviteStatus.Rescinded]: "Rescinded",
};

const buttonTitle: { [text: string]: string } = {
  [AssessmentInviteStatus.Accepted]: "Start",
  [AssessmentInviteStatus.Invited]: "Start",
  [AssessmentInviteStatus.Started]: "Continue",
  [AssessmentInviteStatus.InProgress]: "Continue",
  [AssessmentInviteStatus.Done]: "View results",
};

function getQuestionProgressText(
  questionsList: ITestElement[][],
  testAnswers: TTestAnswer[],
  state: AssessmentInviteStatus,
  questionsQuantity?: number,
) {
  if (!questionsQuantity) {
    return "no questions";
  }

  if (state === AssessmentInviteStatus.InProgress) {
    const answeredCount = testAnswers.reduce((answeredCount, testAnswer) => {
      if (testAnswer.answer.length) {
        answeredCount += testAnswer.answer.filter(({ answer }) => answer !== undefined).length;
      }
      return answeredCount;
    }, 0);

    return `${answeredCount} / ${questionsQuantity} questions`;
  }

  if (questionsQuantity === 1) {
    return `${questionsQuantity} question`;
  }

  return `${questionsQuantity} questions`;
}

export function InviteCard({
  assessmentTitle,
  assessmentId,
  inviteCode,
  state,
  textLocations,
  testAnswers,
  timerDisabled,
}: IInviteCard) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { assessment, isLoading } = useClientAssessmentHook(inviteCode, assessmentId);

  const { questionsQuantity, leftTime, introText, outroText, questionsList } = useMemo(() => {
    return getTestsProps(assessment, textLocations);
  }, [assessment, textLocations]);

  const handleInviteCardButtonClick = (): void => {
    navigate(getRoute(pathname, inviteCode));
  };

  const questionProgressText = useMemo(() => {
    return getQuestionProgressText(questionsList, testAnswers, state, questionsQuantity);
  }, [questionsList, testAnswers, state, questionsQuantity]);

  if (isLoading) {
    return (
      <div className={style.cardContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={cn(style.cardContainer, state === AssessmentInviteStatus.Rescinded && style.cardContainerRescinded)}
    >
      <div className={style.cardContent}>
        <div className={style.headerContainer}>
          <div className={cn(style.badge, style[state])}>
            <DotSVG aria-hidden="true" />
            {statusText[state]}
          </div>
          <Tooltip title={assessmentTitle} color="#747474" placement="topLeft">
            <p className={style.assessmentTitle}>{assessmentTitle}</p>
          </Tooltip>
        </div>

        <div className={style.statisticsContainer}>
          <ParagraphWithIcon text={timerDisabled ? "Untimed" : `${leftTime ? leftTime : 0}`}>
            <ClockCircleOutlined aria-label="Estimated time" />
          </ParagraphWithIcon>
          <ParagraphWithIcon text={questionProgressText}>
            <AlignLeftOutlined aria-label={state === AssessmentInviteStatus.InProgress ? "Progress" : "Total"} />
          </ParagraphWithIcon>
        </div>

        <div className={style.descriptionContainer}>
          <MarkdownPreviewCustom
            source={state === AssessmentInviteStatus.Done ? outroText : introText}
            style={{ fontWeight: 400 }}
          />
        </div>
      </div>

      {state !== AssessmentInviteStatus.Done &&
        state !== AssessmentInviteStatus.WithAnError &&
        state !== AssessmentInviteStatus.Rescinded && (
          <div className={style.buttonContainer}>
            <CustomButton
              title={buttonTitle[state]}
              buttonSize={ButtonSize.small}
              fullWidth={true}
              onClick={handleInviteCardButtonClick}
            />
          </div>
        )}
    </div>
  );
}

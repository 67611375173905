import React, { useState } from "react";
import { notification } from "antd";
import MDEditor from "@uiw/react-md-editor";
import { longFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import mdStyle from "@/shared/components/assessment/AboutAssessment/style.module.css";
import style from "./style.module.css";

interface INarrativeBlockProps {
  inviteId?: number;
  content?: string | null;
  allowEdit?: boolean;
  updatedAt?: string;
  updatedByUser?: {
    fullName: string;
  } | null;
}

export function NarrativeBlock({
  inviteId,
  content,
  allowEdit = false,
  updatedAt,
  updatedByUser,
}: INarrativeBlockProps) {
  const [localContent, setLocalContent] = useState(content || "");
  const [isLoading, setLoading] = useState(false);
  const updateContent = async () => {
    if (!inviteId) return;

    setLoading(true);

    try {
      await InvitesHttpService.updateInviteNarrative(inviteId, localContent);

      notification.success({ message: "Narrative content was successfully updated" });
    } catch (err) {
      notification.error({ message: `Narrative content update failed. ${err}` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.container}>
      {allowEdit ? (
        <div className={mdStyle.mdEditor}>
          <MDEditor
            highlightEnable={false}
            height={500}
            placeholder="Type content..."
            value={localContent}
            onChange={(value) => setLocalContent(value || "")}
          />
          <div className={style.submitContainer}>
            {updatedByUser && updatedAt && (
              <div className={style.aboutUpdateContainer}>
                Last updated at <b>{longFormatDate(updatedAt)}</b> by <b>{updatedByUser.fullName}</b>
              </div>
            )}
            <CustomButton title="Update" isLoading={isLoading} onClick={updateContent} />
          </div>
        </div>
      ) : (
        <div>
          <MarkdownPreviewCustom source={localContent} />
        </div>
      )}
    </div>
  );
}

import * as React from "react";
import { ChartTypes } from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";

import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { BarChartComponent, IBarResponsiveProps } from "@/shared/components/charts/BarChart/BarChartComponent";
import { IRadarResponsiveProps, RadarChartComponent } from "@/shared/components/charts/RadarChart/RadarChartComponent";
import { ChartCategories, IChart, IMultiChart } from "@/shared/components/charts/types/chartTypes";
import {
  chartMaxValues,
  ChartDataType,
  divideChartDataByCategories,
  sortByTestOrderColorAndName,
} from "@/shared/components/charts/utils/chartUtils";
import { addSpaces } from "@/shared/utils/common.utils";

import style from "./style.module.css";

interface IBarStyles extends IBarResponsiveProps {
  yAxisWidth?: number;
}

interface IRadarStyles extends IRadarResponsiveProps {
  radarHeight?: number;
  tickLabelLimit?: number;
}

interface ICategorizedChartsBlock {
  chartMultiData: IMultiChart[];
  chartType: ChartTypes;
  scene?: ReportScene;
  magnifier?: number;
  hoveredTestId?: number;
  checkedHideSameScore?: boolean;
  barStyles?: IBarStyles;
  radarStyles?: IRadarStyles;
}

const barResponsiveProps: { sm: IBarResponsiveProps; lg: IBarResponsiveProps } = {
  sm: {
    labelWidth: 100,
    containerWidth: "100%",
    barHeight: 36,
  },
  lg: {
    labelWidth: 250,
    containerWidth: "100%",
    barHeight: 24,
  },
};

export default function CategorizedCharts({
  scene,
  chartMultiData,
  chartType,
  checkedHideSameScore,
  hoveredTestId,
  magnifier,
  barStyles,
  radarStyles,
}: ICategorizedChartsBlock) {
  const divided: Map<ChartCategories, IMultiChart[]> = divideChartDataByCategories(chartMultiData);
  const chartCategories = Array.from(divided.keys()).sort((a) => (a === ChartCategories.BehaviorsAndSkills ? -1 : 1));

  const separateBarChart = (chartValues: IChart[]): { min_max?: [number, number]; separatedValues: IChart[][] } => {
    const BAR_CHART_THRESHOLD = 1;
    const sortedCompetencies = sortByTestOrderColorAndName(chartValues);
    if (chartValues.length > BAR_CHART_THRESHOLD) {
      let subArray: IChart[][] = [];
      for (let i = 0; i < Math.ceil(sortedCompetencies.length / BAR_CHART_THRESHOLD); i++) {
        subArray[i] = sortedCompetencies.slice(i * BAR_CHART_THRESHOLD, i * BAR_CHART_THRESHOLD + BAR_CHART_THRESHOLD);
      }
      const min = sortedCompetencies.at(-1)?.value;
      return {
        separatedValues: subArray,
        min_max: [min && min > 0 ? 0 : min || 0, chartMaxValues[ChartDataType.ptr5] || 0],
      };
    }
    return { separatedValues: [chartValues] };
  };

  return (
    <div>
      {chartCategories.map((category) => {
        const chartData: IMultiChart[] | undefined = divided.get(category);
        if (chartData) {
          return category && chartData.length === 0 ? (
            <p>Select from 2 to 5 candidates to compare</p>
          ) : chartData.length === 0 ? (
            <p>Select a test to see a chart</p>
          ) : chartType === ChartTypes.radar ? (
            <div className={style.chartCategoryContainer} key={category}>
              <h2 className={style.chartCategoryTitle}>{addSpaces(String(category))}</h2>
              <RadarChartComponent
                usersChartData={chartData}
                magnifier={magnifier}
                hoveredTestId={hoveredTestId}
                isHiddenSameScore={Boolean(checkedHideSameScore)}
                radarHeight={scene === ReportScene.print ? 400 : radarStyles?.radarHeight}
                tickLabelLimit={radarStyles?.tickLabelLimit}
              />
            </div>
          ) : scene === ReportScene.print ? (
            <div className={style.chartCategoryContainer} key={category}>
              <h2 className={style.chartCategoryTitle}>{addSpaces(String(category))}</h2>
              {separateBarChart(chartData[0].chartData).separatedValues.map((subArray, index) => {
                const multyChart: IMultiChart[] = [
                  {
                    name: chartData[0].name,
                    chartData: subArray,
                    color: chartData[0].color,
                    inviteId: chartData[0].inviteId,
                  },
                ];
                return (
                  <BarChartComponent
                    key={index}
                    scene={scene}
                    usersChartData={[...multyChart]}
                    hideXAxis={index !== 0}
                    isHiddenSameScore={false}
                    responsiveProps={chartData[0].chartData.length > 8 ? barResponsiveProps.lg : barResponsiveProps.sm}
                    min_max={separateBarChart(chartData[0].chartData).min_max}
                  />
                );
              })}
            </div>
          ) : (
            <div className={style.chartCategoryContainer} key={category}>
              <h2 className={style.chartCategoryTitle}>{addSpaces(String(category))}</h2>
              <BarChartComponent
                scene={scene}
                isHiddenSameScore={Boolean(checkedHideSameScore)}
                usersChartData={[...chartData]}
                yAxisWidth={barStyles?.yAxisWidth}
              />
            </div>
          );
        } else return null;
      })}
    </div>
  );
}

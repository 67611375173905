import { httpClient } from "@/shared/services/http";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";

export class PermissionsHttpService {
  static async getClientUserRoles(clientId: number): Promise<ClientRoleEnum[]> {
    const { data: roles } = await httpClient.get(`/api/clients/${clientId}/permissions/users`);
    return roles;
  }

  static async getAssessmentUserRoles(assessmentId: number): Promise<AssessmentRoleEnum[]> {
    const { data: roles } = await httpClient.get(`/api/assessments/${assessmentId}/permissions/users`);
    return roles;
  }
}

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "../style.module.css";

export function NextModalFooter({ handleOk, handleCancel }: { handleOk?: () => void; handleCancel?: () => void }) {
  return (
    <div className={style.actionButtons}>
      <CustomButton key="ok" title="continue" onClick={handleOk} />
      <CustomButton key="cancel" title="cancel" onClick={handleCancel} />
    </div>
  );
}

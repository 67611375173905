import * as React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetAssessmentInviteHook } from "@/modules/assessments/hooks/use-get-assessment-invite.hook";
import { useGetCandidateAnswersHook } from "@/modules/assessments/hooks/use-get-candidate-answers.hook";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";

import { Loading } from "@/shared/components/Loading/Loading";
import { ReportComponent, ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { useGetUserHook } from "@/shared/hooks/users/use-get-user.hook";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";
import { shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

export default function AssessmentReport() {
  const { invite_id, client_id } = useParams();

  const { assessment } = useContext(AssessmentContext);

  const { client } = useClientByIdHook(Number(client_id));
  const { assessmentInvite, isLoading: inviteLoading } = useGetAssessmentInviteHook(Number(invite_id));
  const { user, isLoading: isLoadingUser } = useGetUserHook(assessmentInvite?.claimedBy);
  const { answers, isLoading: answersLoading } = useGetCandidateAnswersHook(Number(invite_id));

  if (inviteLoading || answersLoading || !client || !assessment || !assessmentInvite || isLoadingUser) {
    return <Loading />;
  }

  return (
    <>
      <SEO titleParams={[user?.fullName, assessment?.name, maybe(assessmentInvite?.finishedOn, shortFormatDate, "")]} />
      <ReportComponent
        user={user}
        client={client}
        assessment={assessment}
        invite={assessmentInvite}
        inviteId={Number(invite_id)}
        usersAnswers={[{ inviteId: Number(invite_id), answers }]}
        scene={ReportScene.single}
        selectedUsers={[String(user?.fullName)]}
      />
    </>
  );
}

import React, { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import cn from "classnames";

import { ReactComponent as PlusSVG } from "@/assets/icons/feather-icons/plus-16-white.svg";

import { IClient } from "@/modules/client/clients.interface";

import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";

import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { CLIENT_ROUTE, SETTINGS_ROUTE } from "@/routing/AppRouter/routes.constants";

import { ReactComponent as Tool } from "@/assets/icons/feather-icons/tool.svg";
import { ReactComponent as LogIn } from "@/assets/icons/feather-icons/log-in.svg";

import style from "./style.module.css";

export default function TableClients({
  isAdmin,
  dataList,
  onAddClient,
}: {
  isAdmin: boolean;
  dataList: IClient[];
  onAddClient: () => void;
}) {
  const navigate = useNavigate();

  const clientsTableColumns: ColumnsType<IClient> = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      className: style.tableColumnName,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => sortElements(a.name, b.name),
      render: (value, record) => (
        <Tooltip title={record.name} placement={"topLeft"} overlayStyle={{ maxWidth: "60%", minWidth: "50px" }}>
          <Link to={`/${CLIENT_ROUTE}/${record.clientId}`} className={style.tableLink}>
            {value}
          </Link>
        </Tooltip>
      ),
    },
    {
      key: "actions",
      title: "",
      dataIndex: "actions",
      className: cn(style.tableColumn, style.actionsColumnWidth),
      render: (value, record) => {
        return (
          <div className={style.actionsContainer}>
            {isAdmin && (
              <CustomButton
                onClick={() => {
                  navigate(`/${CLIENT_ROUTE}/${record.clientId}/${SETTINGS_ROUTE}`);
                }}
                title="Settings"
                colorType={ButtonColorType.transparentDark}
                buttonSize={ButtonSize.small}
              >
                <Tool />
              </CustomButton>
            )}
            <CustomButton
              disabled={record.selected}
              onClick={() => {
                navigate(`/${CLIENT_ROUTE}/${record.clientId}`);
              }}
              title="Switch to"
              colorType={ButtonColorType.dark}
              buttonSize={ButtonSize.small}
            >
              <LogIn />
            </CustomButton>
          </div>
        );
      },
    },
  ];

  const DEFAULT_PAGE_SIZE = isAdmin ? 8 : 10;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [tableData, setTableData] = useState<IClient[]>(dataList);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTableData(dataList.filter((item: IClient) => isSubstring(item.name, event.target.value)));
  };

  return (
    <div className={style.tableBlock}>
      {isAdmin ? (
        <TableHeader tableName="Clients" handleSearchChange={handleSearchChange}>
          <CustomButton title="Add Client" onClick={onAddClient} buttonSize={ButtonSize.small}>
            <PlusSVG aria-hidden="true" />
          </CustomButton>
        </TableHeader>
      ) : (
        <TableHeader tableName="Clients" handleSearchChange={handleSearchChange} />
      )}
      <Table
        columns={clientsTableColumns}
        dataSource={tableData}
        scroll={{ x: "1000px" }}
        style={{ width: "100%" }}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          onShowSizeChange: (current, size) => setPageSize(size),
        }}
      />
    </div>
  );
}

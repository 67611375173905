import { useEffect, useState } from "react";
import { IClientUser } from "@/modules/client/clients.interface";
import { Firebase } from "@/shared/services/firebase";
import { UserHttpService } from "@/shared/services/user/user-http.service";

export const DB_USER_KEY = "DB_USER";

export type TDbUser = {
  clientUsers: IClientUser[];
  createdAt: string;
  email: string;
  firebaseId: string;
  fullName: string;
  isAdmin: boolean;
  updatedAt: string;
  userId: number;
};

export interface IUserHook {
  user: TDbUser | undefined;
  loading: boolean;
  error: any;
}

export function useCurrentUserHook(
  firebase: Firebase,
  isLoadingFirebase: boolean,
  uid?: string,
  email?: string,
  displayName?: string,
): IUserHook {
  const [user, setUser] = useState<TDbUser>();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoadingFirebase) return;

    const asyncFunction = async () => {
      try {
        if (!uid) {
          setIsLoading(false);
          setUser(undefined);
          return;
        }

        let dbUser: TDbUser | undefined = undefined;

        try {
          dbUser = await UserHttpService.getCurrentUser(uid);
        } catch (e: any) {
          if (displayName && email) {
            dbUser = await UserHttpService.postUser(uid, email, displayName);
          }
        }

        if (!dbUser) {
          console.log("Cannot find user");
          return;
        }

        localStorage.setItem(DB_USER_KEY, JSON.stringify(dbUser));

        setUser(dbUser);
        setIsLoading(false);
      } catch (e: any) {
        setError(e);

        if (e?.response && e.response.status === 403) {
          await firebase.signOut();
        }

        setIsLoading(false);
      }
    };

    asyncFunction();
  }, [isLoadingFirebase, uid, email]);

  return { user, loading: isLoading, error };
}

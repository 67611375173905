import React from "react";
import { Link } from "react-router-dom";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { CLIENT_ROUTE, TESTS_ROUTE, ADMIN_USERS_ROUTE } from "@/routing/AppRouter/routes.constants";

import style from "./style.module.css";

const adminDashboardLinks: { name: string; link: string }[] = [
  { name: "Tests", link: TESTS_ROUTE },
  { name: "Clients", link: CLIENT_ROUTE },
  { name: "Admin Users", link: ADMIN_USERS_ROUTE },
];

export function AdminDashboard() {
  return (
    <div className="main-container">
      <SEO titleParams={["Dashboard"]} />
      <div className="inner-container">
        <Banner title={"Admin Page"} />
        <div className={style.linksContainer}>
          {adminDashboardLinks.map(({ name, link }) => (
            <Link to={link} className={style.link} key={name}>
              {name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

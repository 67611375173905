import { useEffect, useState } from "react";

import { NormHttpService } from "@/modules/assessments/scenes/AddTests/services/norm-http.service";

import { INorm } from "@/shared/components/assessment/new/TestItem/TestItem";
import { isValidNumericParam } from "@/shared/utils/common.utils";

export function useTestNormsHook(testId: number) {
  const [norms, setNorms] = useState<INorm[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchNorms = async () => {
      try {
        setIsLoading(true);
        const norms: INorm[] = await NormHttpService.getNormsByTestId(testId);
        setNorms(norms);
      } catch (e) {
        console.error(`Error fetching norms: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(testId)) {
      fetchNorms();
    }
  }, []);

  return { norms, isLoading };
}

export function useAssessmentNormsHook(testIds: number[] | undefined) {
  const [norms, setNorms] = useState<INorm[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchNorms = async () => {
      try {
        if (testIds) {
          setIsLoading(true);
          const norms: INorm[][] = await Promise.all(testIds.map(NormHttpService.getNormsByTestId));
          setNorms(norms);
        }
      } catch (e) {
        console.error(`Error fetching norms: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNorms();
  }, []);

  return { norms, isLoading };
}

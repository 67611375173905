import React, { useState } from "react";

import { Loading } from "@/shared/components/Loading/Loading";

import { UserContext } from "@/shared/contexts/user.context";
import { ClientAbilityContext, ClientUserRolesContext } from "@/shared/contexts/ability.context";

import { CaslAbilityFactory } from "@/shared/services/permissions/casl-ability.factory";

import { TDbUser } from "@/shared/hooks/auth/currentUser.hook";
import { TFirebaseUser } from "@/shared/hooks/auth/useFirebaseAuth.hook";
import { useGetClientUserRolesHook } from "@/shared/hooks/permissions/use-get-client-user-roles.hook";
import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";

export function GlobalProvider({
  user,
  clientId,
  isLoadingAuth,
  firebaseUser,
  children,
}: {
  user?: TDbUser;
  clientId: number;
  isLoadingAuth: boolean;
  firebaseUser: TFirebaseUser;
  children: React.ReactElement<any, any>;
}) {
  const { selectedClient } = useReduxClientsHook();
  const { clientRoles, isLoading: isLoadingRoles } = useGetClientUserRolesHook(
    user?.userId,
    selectedClient,
    Boolean(user?.userId),
  );

  const [selectedClientId, setSelectedClientId] = useState<number | undefined>(clientId);

  if (isLoadingAuth || isLoadingRoles || (selectedClient && !clientRoles)) {
    return <Loading />;
  }

  const caslAbilityFactory = new CaslAbilityFactory();
  caslAbilityFactory.createForClientUser(clientRoles ?? [], user);

  return (
    <UserContext.Provider
      value={{
        user,
        selectedClientId,
        isLoading: isLoadingAuth,
        firebaseUser: firebaseUser,
        changeSelectedClientId: setSelectedClientId,
      }}
    >
      <ClientUserRolesContext.Provider value={clientRoles}>
        <ClientAbilityContext.Provider value={caslAbilityFactory.buildForUser()}>
          {children}
        </ClientAbilityContext.Provider>
      </ClientUserRolesContext.Provider>
    </UserContext.Provider>
  );
}

import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ICompetency } from "@/shared/components/competencies/types";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";
import { addCompetencies, selectAllCompetencies } from "@/shared/services/store/competenciesStore";

import { CompetencyHttpService } from "@/modules/assessments/scenes/AddTests/services/competency-http.service";

export function useGetFilteredCompetenciesHook(testId: number, refreshData: {}) {
  const reduxCompetencies = useSelector(selectAllCompetencies).filter((competency) => competency.testId === testId);
  const dispatch = useDispatch();

  const isReduxCompetenciesExist = !!reduxCompetencies.length;

  const [competencies, setCompetencies] = useState<ICompetency[]>([]);
  const [filteredCompetencies, setFilteredCompetencies] = useState<ICompetency[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(!isReduxCompetenciesExist);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const filtered: ICompetency[] = competencies.filter((competency) =>
      isSubstring(competency.name, event.target.value),
    );
    setFilteredCompetencies(filtered);
  };

  useEffect(() => {
    if (reduxCompetencies.length !== 0) {
      setCompetencies(reduxCompetencies);
      setFilteredCompetencies(reduxCompetencies);
    }
  }, []);

  useEffect(() => {
    const fetchCompetencies = async () => {
      try {
        setIsLoading(true);
        const competencies: ICompetency[] = await CompetencyHttpService.getCompetenciesByTestId(testId);
        if (competencies.length !== 0) {
          setCompetencies((prevState) => [...prevState, ...competencies]);
          setFilteredCompetencies((prevState) => [...prevState, ...competencies]);
          dispatch(addCompetencies(competencies));
        }
      } catch (e: any) {
        console.error(`Get test competencies error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isReduxCompetenciesExist) {
      if (isValidNumericParam(testId)) {
        fetchCompetencies();
      }
    }
  }, [testId, refreshData]);

  return { competencies, filteredCompetencies, isLoading, handleSearch };
}

import { useEffect, useState } from "react";

import { isValidNumericParam } from "@/shared/utils/common.utils";
import { TestHttpService } from "@/shared/services/tests/test-http.service";

import { ITest } from "../types/test.interface";

export function useAllClientTestsHook(clientId: number) {
  const [tests, setAllTests] = useState<ITest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        setIsLoading(true);

        const tests: ITest[] = await TestHttpService.getAllClientTests(clientId);
        if (tests.length !== 0) {
          const sortedTests: ITest[] = tests.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setAllTests(sortedTests);
        }

        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(clientId)) {
      fetchTests();
    }
  }, [clientId]);

  return { tests, isLoading };
}

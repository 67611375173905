import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useAssessmentHook(assessmentId?: number, forPublic?: boolean) {
  const { assessment, setAssessment } = useContext(AssessmentContext);
  if (!setAssessment) {
    throw new Error("Cannot use useAssessmentHook without AssessmentContext");
  }
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAssessment = async () => {
      try {
        setIsLoading(true);

        const inviteAssessment = await AssessmentHttpService.getAssessmentByID(Number(assessmentId));

        if (inviteAssessment) {
          setAssessment(inviteAssessment);
        }
      } catch (e) {
        console.error(`get assessment error, ${e}`);
        navigate(`/${NOT_FOUND_ROUTE}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (!assessment) {
      if (isValidNumericParam(assessmentId)) {
        fetchAssessment();
      } else {
        if (!forPublic) {
          navigate(`/${NOT_FOUND_ROUTE}`);
        } else return;
      }
    } else {
      setIsLoading(false);
    }
  }, [assessmentId, assessment, setAssessment, forPublic, navigate]);

  return { assessment, isLoading, setAssessment };
}

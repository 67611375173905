import { ICompetencyFormula } from "./types";

export class CompetencyFormulasUtils {
  public static sortDescending(competencyFormulas: ICompetencyFormula[]): ICompetencyFormula[] {
    return competencyFormulas.sort((formulaA, formulaAB) => {
      if (formulaA.createdAt < formulaAB.createdAt) {
        return 1;
      }

      if (formulaA.createdAt > formulaAB.createdAt) {
        return -1;
      }

      return 0;
    });
  }

  public static getLatestFormulaVersion(competencyFormulas: ICompetencyFormula[]): ICompetencyFormula | undefined {
    const sortedFormulas = competencyFormulas.sort((formulaA, formulaAB) => {
      if (formulaA.version < formulaAB.version) {
        return -1;
      }

      if (formulaA.version > formulaAB.version) {
        return 1;
      }

      return 0;
    });

    return sortedFormulas.at(-1);
  }
}

import { httpClient } from "@/shared/services/http";

export class CompetencyHttpService {
  static async getAllCompetencies(testIds?: number[]) {
    if (!testIds) {
      const { data: competencies } = await httpClient.get("/api/competencies");
      return competencies;
    }
    const parameter = "testId";
    let query = "?";
    for (const testId of testIds) {
      query = `${query}${parameter}=${testId}&`;
    }
    const { data: competencies } = await httpClient.get(`/api/competencies${query}`);
    return competencies;
  }

  static async getCompetenciesByTestId(testId: number) {
    const { data: competencies } = await httpClient.get(`/api/tests/${testId}/competencies`);
    return competencies;
  }

  static async updateCompetencyById(
    competencyId: number,
    payload: {
      name?: string;
      formula?: string;
      explanation?: string;
      interviewQuestions?: string;
      isDefaultEnabled: boolean;
    },
  ) {
    return httpClient.patch(`/api/competencies/${competencyId}`, payload);
  }

  static updateCompetencyRangeInAssessment(
    clientId: number,
    assessmentId: number,
    testId: number,
    config: { competencies: { [competency: string]: boolean | number } },
  ) {
    return httpClient.patch(`/api/clients/${clientId}/assessments/${assessmentId}/tests/${testId}/configs`, config);
  }
}

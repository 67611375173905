import { IScale, IScaleReportStatement } from "@/modules/assessments/types/scale.interface";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";

export type ScaleByTestIdMap = Record<string, Record<string, number>>;

export interface StatementsMap {
  [testId: string]: {
    [paragraph: string]: string[];
  };
}

export class ScalesService {
  static calculateScales(answers: ITestAnswer[], testScales: Record<string, IScale[]>): StatementsMap {
    const scalesByTestIdMap: ScaleByTestIdMap = {};
    const statementsMap: StatementsMap = {};

    answers.forEach(({ testId, score }) => {
      scalesByTestIdMap[testId] = {};

      Object.entries(score.scales).forEach(([scale, { percentile }]) => {
        scalesByTestIdMap[testId][scale] = percentile;
      });
    });

    Object.entries(testScales).forEach(([testId, scales]) => {
      const scalesByParagraph: Record<string, Pick<IScaleReportStatement, "order" | "statement">[]> = {};

      scales.forEach(({ abbr, reportStatements }) => {
        if (!reportStatements?.length) return;

        const resultTestScales = scalesByTestIdMap[testId];

        if (!resultTestScales || typeof resultTestScales[abbr] === "undefined") return;

        const matchedScale: IScaleReportStatement | undefined = reportStatements
          .sort((first, second) => first.lowerThan - second.lowerThan)
          .find(({ lowerThan }) => resultTestScales[abbr] < lowerThan);

        if (!matchedScale) return;

        if (!scalesByParagraph[matchedScale.paragraph]?.length) {
          scalesByParagraph[matchedScale.paragraph] = [];
        }

        scalesByParagraph[matchedScale.paragraph].push({
          order: matchedScale.order,
          statement: matchedScale.statement,
        });
      });

      Object.entries(scalesByParagraph).forEach(([paragraph, scalesStatements]) => {
        if (!statementsMap[testId]) {
          statementsMap[testId] = {};
        }

        statementsMap[testId][paragraph] = scalesStatements
          .sort((first, second) => first.order - second.order)
          .map(({ statement }) => statement);
      });
    });

    return statementsMap;
  }
}

import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUser } from "@/modules/client/clients.interface";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import TableClientUsers from "@/modules/tables/TableClientUsers";
import { Loading } from "@/shared/components/Loading/Loading";
import { useGetClientUsersHook } from "@/shared/hooks/users/use-get-client-users.hook";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";
import { NEW_ROUTE, USER_ROUTE } from "@/routing/AppRouter/routes.constants";

export interface IClientUserTable {
  key: number;
  name: string;
  email: string;
  roleId: number;
  lastLogin: string;
  joinDate: string;
}

export default function ClientTeam() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { client_id: clientId } = useParams<{ client_id: string }>();
  const { users, isLoading: isLoadingUsers } = useGetClientUsersHook(Number(clientId));

  // TODO: Move to global state
  const { client, isLoading } = useClientByIdHook(Number(clientId));

  // TODO: Move to utils
  const transformUserData = (clientUsers: IUser[]) => {
    return clientUsers.map((user) => {
      const clientUser = user.clientUsers && user.clientUsers.find((cUser) => cUser.clientId === Number(clientId));

      return {
        name: user.fullName,
        key: user.userId,
        email: user.email,
        roleId: user.clientUsers ? user.clientUsers[0].roleId : 0,
        registerDate: user.createdAt,
        joinDate: clientUser?.createdAt,
        lastLogin: user.lastLogin,
      };
    }) as IClientUserTable[];
  };

  const handleInviteUser = () => {
    navigate(`${pathname}/${NEW_ROUTE}`);
  };

  const clientAbility = useContext(ClientAbilityContext);
  const canViewClientTeam = clientAbility.can(Action.Read, Subject.CLIENT);

  useRouteAccessController({
    slug: USER_ROUTE,
    hasPermission: canViewClientTeam,
  });

  if (isLoading || isLoadingUsers) {
    return <Loading />;
  }

  return <TableClientUsers dataList={transformUserData(users)} client={client} onInviteUser={handleInviteUser} />;
}

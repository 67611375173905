import { IChoice } from "@/modules/assessments/types/test.interface";

import { TestElementMode } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { Likert5Choices } from "@/shared/constants/test-elements.constants";

import RadioGroup from "@/ui-components/testElementComponents/RadioGroup/RadioGroup";

export interface ILikert5Group {
  id: number;
  mode?: TestElementMode;
  disable?: boolean;
  choices: IChoice[];
  currentAnswer: string | boolean | undefined;
  onChange?: (e: any) => void;
}

export default function Likert5Group({ id, mode, disable = false, currentAnswer, choices, onChange }: ILikert5Group) {
  const isAdminView: boolean = mode === TestElementMode.Admin;
  return (
    <RadioGroup
      id={id}
      mode={mode}
      disable={disable || isAdminView}
      onChange={onChange}
      currentAnswer={currentAnswer}
      choices={choices.map(({ choice, ...rest }) => ({ ...rest, choice, customName: Likert5Choices[Number(choice)] }))}
    />
  );
}

import React from "react";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";
import { NextModalFooter } from "@/modules/candidate/assessment/CandidateAssessment/components/modals/ConfirmNextModal/NextModalFooter";
import { IAssessmentTable } from "@/modules/tables/TableAssessments";

export function ConfirmCloneModal({
  isOpen,
  onAssessmentClone,
  closeModal,
  assessment,
}: {
  isOpen: boolean;
  onAssessmentClone: (assessmentId: number) => void;
  closeModal: () => void;
  assessment: IAssessmentTable | null;
}) {
  return (
    <BasicModal
      autoHeight
      handleCancel={() => closeModal()}
      isModalOpen={isOpen}
      footer={
        <NextModalFooter
          handleOk={async () => {
            if (!assessment) return;

            await onAssessmentClone(assessment.assessmentId);
          }}
          handleCancel={() => closeModal()}
        />
      }
    >
      <>
        <p>
          {assessment && (
            <span>
              Are you sure you want to clone <b>{assessment.name}</b> assessment?
            </span>
          )}
        </p>
      </>
    </BasicModal>
  );
}

export function getShortest<T>(array1: T[], array2: T[]): T[] {
  return array1.length < array2.length ? array1 : array2;
}

export function removeElementByKeyElement(arr: string[], keyWord: string, elementPosition: number): string[] {
  const hasWord = arr.includes(keyWord);
  return hasWord ? arr.slice(elementPosition) : arr;
}

export function getUniqueElements<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input, Radio, notification } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { selectClientRoles } from "@/shared/services/store/rolesStore";
import { useAssessmentHook } from "@/shared/hooks/assessments/useAssessment.hook";
import { useGetClientUsersHook } from "@/shared/hooks/users/use-get-client-users.hook";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CustomInput, InputType } from "@/ui-components/commonComponents/Input/Input";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { Loading } from "@/shared/components/Loading/Loading";
import { ASSESSMENT_ROUTE, CLIENT_ROUTE, USER_ROUTE } from "@/routing/AppRouter/routes.constants";
import { IUser } from "@/modules/client/clients.interface";
import { IClientUserRole } from "@/shared/services/roles/roles-http.service";
import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import {
  AssessmentRoleEnum,
  IAssessmentPermissions,
} from "@/modules/assessments/services/assessment-permissions-http.service";
import { getAssessmentRolesList } from "@/modules/assessments/scenes/AssessmentTeam/assessment-roles.utils";
import { useManageAssessmentUserHook } from "@/modules/assessments/hooks/use-manage-assessment-user.hook";
import { useGetAssessmentUsersPermissionsHook } from "@/modules/assessments/hooks/use-get-assessment-users-permissions.hook";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { getFriendlyErrorMessage } from "@/shared/services/http";
import style from "@/shared/components/AddNew/style.module.css";
import styles from "./add-user-to-assessment.styles.module.css";

export function getAddUserToAssessmentPageLink(clientId: number, assessmentId: number) {
  return `/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${assessmentId}/${USER_ROUTE}`;
}

interface IFormInputProps {
  name: string;
  label: string;
  inputType: InputType;
  selectOptions?: any[];
  rules: Rule[];
}

interface IFormSubmission {
  assessmentRole: AssessmentRoleEnum;
  email: string;
  fullName: string;
  isExistingUser: boolean;
  user: string;
}

const ASSESSMENT_ROLES = getAssessmentRolesList();

const getExistingUserInputProps = (
  clientUsers: IUser[],
  assessmentPermissions: IAssessmentPermissions[],
  clientRoles: IClientUserRole[],
): IFormInputProps[] => {
  // check if not global admin & not client admin & have not existed assessment role
  const users = clientUsers
    .filter(({ userId, isAdmin, clientUsers }) => {
      const existedAssessmentPermissions = assessmentPermissions.find(
        (assessmentPermission) => assessmentPermission.userId === userId,
      );
      const clientUserRole = clientRoles.find(({ roleId }) => clientUsers && clientUsers[0].roleId === roleId);
      const isClientUserAdmin = clientUserRole && clientUserRole.name === ClientRoleEnum.ADMIN;

      return !existedAssessmentPermissions && !isClientUserAdmin && !isAdmin;
    })
    .map((user) => ({
      value: String(user.userId),
      name: user.fullName,
    }));

  return [
    {
      name: "user",
      label: "User",
      inputType: InputType.select,
      selectOptions: users,
      rules: [
        {
          type: "string",
          required: true,
          message: "Please select a valid user",
        },
      ],
    },
    {
      name: "assessmentRole",
      label: "Assessment Role",
      inputType: InputType.select,
      selectOptions: ASSESSMENT_ROLES,
      rules: [
        {
          type: "string",
          required: true,
          message: "Please select a valid assessment role",
        },
      ],
    },
  ];
};

const newUserInputProps: IFormInputProps[] = [
  {
    name: "fullName",
    label: "Full Name",
    inputType: InputType.input,
    rules: [
      {
        type: "string",
        required: true,
        message: "Please enter a valid name",
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    inputType: InputType.input,
    rules: [
      {
        type: "email",
        required: true,
        message: "Please enter a valid email",
      },
    ],
  },
];

export function AddUserToAssessmentComponent() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { assessment_id } = useParams<{ assessment_id: string }>();
  const assessmentId = Number(assessment_id);
  const clientRoles = useSelector(selectClientRoles);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  // TODO: Move to global state
  const { assessment, isLoading: isLoadingAssessment } = useAssessmentHook(assessmentId);

  const { users: clientUsers, isLoading: isLoadingClientUsers } = useGetClientUsersHook(assessment?.clientId);
  const { assessmentPermissions, isLoading: isLoadingAssessmentPermissions } =
    useGetAssessmentUsersPermissionsHook(assessmentId);
  const { manageAssessmentUser, isLoading: isLoadingUserToAssessment } = useManageAssessmentUserHook();

  const navigateBack = () => {
    navigate(-1);
  };

  const addUserAndNavigateBack = async ({ isExistingUser, user, assessmentRole, fullName, email }: IFormSubmission) => {
    try {
      setIsSubmitting(true);
      if (isExistingUser) {
        await manageAssessmentUser(assessmentId, Number(user), assessmentRole);
      } else {
        await ClientHttpService.addUserToClient(String(assessment?.clientId), {
          email: email,
          fullName: fullName,
          assessmentId: assessmentId,
          assessmentRole: assessmentRole,
        });
      }
      setIsSubmitting(false);
      notification.success({ message: "User added successfully." });

      setTimeout(() => {
        navigateBack();
      }, 3000);
    } catch (error) {
      setIsSubmitting(false);
      notification.error({
        message: getFriendlyErrorMessage(error) ?? "Oops, something went wrong. Please try again.",
      });
    }
  };

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canCreateAssessmentUser = assessmentAbility.can(Action.Create, Subject.ASSESSMENT_USER_PERMISSION);
  const existingUserInputProps = getExistingUserInputProps(clientUsers, assessmentPermissions, clientRoles);

  if (isLoadingAssessment || isLoadingUserToAssessment || isLoadingClientUsers || isLoadingAssessmentPermissions) {
    return <Loading />;
  }

  return (
    <div className={styles.background}>
      <SEO titleParams={[assessment?.name, "Add User"]} />
      <div className={styles.mainContainer}>
        <div className={styles.formContainer}>
          <h1>Add an Assessment Team Member</h1>
          <Form
            form={form}
            name="AssessmentUser"
            requiredMark={false}
            onFinish={addUserAndNavigateBack}
            initialValues={{
              assessmentRole: AssessmentRoleEnum.USER,
              email: null,
              fullName: null,
              isExistingUser: true,
              user: null,
            }}
          >
            <Form.Item name="isExistingUser">
              <Radio.Group>
                <Radio value={true}>Add an Existing User</Radio>
                <Radio value={false}>Add a New User</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isExistingUser !== currentValues.isExistingUser}
            >
              {({ getFieldValue }) => {
                const isExistingUser = getFieldValue("isExistingUser");
                return (
                  <>
                    {isExistingUser ? (
                      existingUserInputProps.map(({ name, label, rules, selectOptions, ...input }) => (
                        <Form.Item
                          className={classNames(style.inputTitle, styles.userAssessmentInput)}
                          key={name}
                          name={name}
                          label={label}
                          rules={rules}
                        >
                          <CustomInput
                            placeholder="Select..."
                            className={styles.selectInput}
                            selectOptions={selectOptions}
                            inputType={input.inputType}
                          />
                        </Form.Item>
                      ))
                    ) : (
                      <>
                        {newUserInputProps.map(({ name, label, rules }) => (
                          <Form.Item
                            className={classNames(style.inputTitle, styles.userAssessmentInput)}
                            key={name}
                            name={name}
                            label={label}
                            rules={rules}
                          >
                            <Input placeholder="Type..." />
                          </Form.Item>
                        ))}
                        {
                          <Form.Item
                            className={classNames(style.inputTitle, styles.userAssessmentInput)}
                            name="assessmentRole"
                            label="Assessment Role"
                            rules={[
                              {
                                type: "string",
                                required: true,
                                message: "Please select a valid Assessment Role",
                              },
                            ]}
                          >
                            <CustomInput
                              placeholder="Select..."
                              className={styles.selectInput}
                              selectOptions={ASSESSMENT_ROLES}
                              inputType={InputType.select}
                            />
                          </Form.Item>
                        }
                      </>
                    )}

                    <div className={classNames(style.buttonContainer, styles.buttonsContainer)}>
                      <CustomButton
                        title={isExistingUser ? "Add" : "Send Invite"}
                        isLoading={isSubmitting}
                        disabled={!canCreateAssessmentUser}
                      />

                      <CustomButton title="Cancel" submitted={false} onClick={navigateBack} />
                    </div>
                  </>
                );
              }}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

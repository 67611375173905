import { Firebase } from "@/shared/services/firebase";
import { TUseFirebaseAuthHook, useFirebaseAuthHook } from "@/shared/hooks/auth/useFirebaseAuth.hook";
import { useCurrentUserHook } from "@/shared/hooks/auth/currentUser.hook";
import { isSignedInState } from "@/shared/utils/auth/auth.utils";

// TODO: Add Clients call
export function useInitializer() {
  const firebase = new Firebase();

  const firebaseAuth = useFirebaseAuthHook(firebase) as TUseFirebaseAuthHook;

  const { user, loading, error } = useCurrentUserHook(
    firebase,
    firebaseAuth.isLoading,
    firebaseAuth.firebaseUser?.uid,
    firebaseAuth.firebaseUser?.email,
    firebaseAuth.firebaseUser?.displayName,
  );

  const isSignedIn = isSignedInState({ ...firebaseAuth, user, error });

  const isLoading = firebaseAuth.isLoading || loading;

  const isErroredState = Boolean(error);

  return {
    user,
    isLoading,
    firebaseAuth,
    isSignedIn,
    isErroredState,
  };
}

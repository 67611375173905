import { createContext, Dispatch, SetStateAction } from "react";

import { TFirebaseUser } from "@/shared/hooks/auth/useFirebaseAuth.hook";
import { TDbUser } from "@/shared/hooks/auth/currentUser.hook";

export interface IUserContext {
  isLoading: boolean;
  user: TDbUser | undefined;
  selectedClientId: number | undefined;
  changeSelectedClientId?: (id: number) => void;
  firebaseUser: TFirebaseUser;
  setUser?: Dispatch<SetStateAction<IUserContext>>;
}

export const UserContext = createContext<IUserContext>({
  user: undefined,
  isLoading: true,
  firebaseUser: {},
  selectedClientId: 0,
} as any);

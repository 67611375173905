import * as React from "react";

import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";

import { SectionErrorState } from "@/modules/candidate/assessment/CandidateAssessment/hooks/useSections.hook";
import { TimerErrorModalFooter } from "./TimerErrorModalFooter";

export function TimerErrorModal({ errorState }: { errorState: SectionErrorState }) {
  const content = errorState.concurrentTimerError
    ? `You cannot have more than one tab open for this test. Please close this tab or click 'Refresh Page' below to reload the page.`
    : `Can't connect to the server. Please click to try again.`;

  return (
    <BasicModal
      autoHeight
      isModalOpen={errorState.concurrentTimerError || errorState.genericTimerError}
      isClosable={false}
      footer={
        <TimerErrorModalFooter
          handleOk={async () => {
            window.location.reload();
          }}
        />
      }
    >
      <p>{content}</p>
    </BasicModal>
  );
}

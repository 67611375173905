import { useEffect, useState } from "react";

import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";

import { InviteHttpService } from "@/modules/assessments/services/invite-http.service";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { useNavigate } from "react-router-dom";

export function useGetCandidateAnswersHook(inviteId?: number, isAllowedToLoad = true) {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState<ITestAnswer[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isAllowedToLoad) {
      return;
    }

    const getAnswers = async (): Promise<ITestAnswer[]> => {
      if (!inviteId) {
        return [];
      }

      try {
        setIsLoading(true);

        const inviteAnswers = await InviteHttpService.getCandidateAnswersByInviteId(inviteId);

        setAnswers(inviteAnswers);

        return inviteAnswers;
      } finally {
        setIsLoading(false);
      }
    };

    getAnswers();
  }, [isAllowedToLoad, inviteId]);

  const setAnswer = (testAnswer: ITestAnswer) =>
    setAnswers((prevState) => {
      const filteredAnswers = prevState.filter(({ testId }) => testAnswer.testId !== testId);

      return [...filteredAnswers, testAnswer];
    });

  const saveTestElementAnswer = async (
    currentAnswers: ITestChoice[],
    { inviteId, testId }: { inviteId: number; testId: number },
    options: { isLast?: boolean; isBack?: boolean } = { isBack: false, isLast: false },
  ) => {
    const addedAnswer = await InvitesHttpService.addTestElementAnswer(
      inviteId,
      testId,
      {
        isLast: options.isLast,
        isBack: options.isBack,
        answers: currentAnswers,
      },
      navigate,
    );

    setAnswer(addedAnswer);

    return addedAnswer;
  };

  return {
    answers,
    isLoading,
    setAnswer,
    saveTestElementAnswer,
  };
}

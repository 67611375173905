import { ReactElement } from "react";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";
import { ButtonColorType, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "./style.module.css";

export interface IConfirmDisableTimerModal {
  isOpen: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  children: ReactElement<any, any>;
}

export default function ConfirmDisableTimerModal({
  isOpen,
  isLoading = false,
  onCancel,
  onConfirm,
  children,
}: IConfirmDisableTimerModal) {
  return (
    <BasicModal
      autoHeight={true}
      isModalOpen={isOpen}
      handleCancel={onCancel}
      footer={
        <div className={style.actionButtons}>
          <CustomButton title="cancel" onClick={onCancel} colorType={ButtonColorType.light} />
          <CustomButton title="disable timer" isLoading={isLoading} onClick={onConfirm} />
        </div>
      }
    >
      <div className={style.modalContent}>{children}</div>
    </BasicModal>
  );
}

import { useGetAllAdminUsersHook } from "@/modules/admin/scenes/hooks/useGetAllAdminUsers.hook";
import TableAdminUsers from "@/modules/tables/TableAdminUsers";

import { Loading } from "@/shared/components/Loading/Loading";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

export default function AdminUsers() {
  const { adminUsers, isLoading } = useGetAllAdminUsersHook();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="main-container">
      <SEO titleParams={["Admin Users"]} />
      <div className="inner-container">
        <TableAdminUsers dataList={adminUsers} />
      </div>
    </div>
  );
}

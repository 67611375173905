export enum QuickSearchKeys {
  assessments = "assessments",
  invites = "invites",
  clients = "clients",
  tests = "tests",
  candidates = "candidates",
}

export interface ISearchOption {
  assessments: IQuickSearchAssessment[];
  invites: IQuickSearchInvite[];
  clients: IQuickSearchClient[];
  tests: IQuickSearchTest[];
}

export interface IQuickSearchAssessment {
  assessmentId: number;
  name: string;
  clientId: number;
}

export interface IQuickSearchInvite {
  inviteId: number;
  name: string;
  assessmentId: number;
  clientId: number;
  userId: number;
  status: string;
}

export interface IQuickSearchClient {
  clientId: number;
  name: string;
}

export interface IQuickSearchTest {
  testId: number;
  name: string;
}

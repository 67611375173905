import React, { ReactElement } from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

import { statusText } from "@/shared/components/candidate/scenes/InviteCard/InviteCard";

import { CANDIDATE_ASSESSMENTS_ROUTE, NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";

import { ReactComponent as CheckedSVG } from "@/assets/icons/feather-icons/check.svg";
import { ReactComponent as InProgressSVG } from "@/assets/icons/feather-icons/more-horizontal.svg";

import { QuickSearchKeys } from "../../types";

import style from "../../style.module.css";

export const statusIcon: { [text: string]: ReactElement } = {
  [AssessmentInviteStatus.Accepted]: <InProgressSVG aria-hidden="true" />,
  [AssessmentInviteStatus.Invited]: <InProgressSVG aria-hidden="true" />,
  [AssessmentInviteStatus.InProgress]: <InProgressSVG aria-hidden="true" />,
  [AssessmentInviteStatus.Done]: <CheckedSVG aria-hidden="true" />,
};

const getItemRoute = (
  category: QuickSearchKeys,
  parameters: { categoryId: number; clientId?: number; assessmentId?: number; userId?: number },
): string => {
  const { categoryId, clientId, assessmentId, userId } = parameters;
  switch (category) {
    case QuickSearchKeys.assessments:
      return clientId ? `/clients/${clientId}/assessments/${categoryId}/tests` : `/${NOT_FOUND_ROUTE}`;
    case QuickSearchKeys.clients:
      return `/clients/${categoryId}`;
    case QuickSearchKeys.tests:
      return `/admin/tests/${categoryId}`;
    case QuickSearchKeys.invites:
      return assessmentId && clientId && userId
        ? `/clients/${clientId}/assessments/${assessmentId}/invites/${categoryId}`
        : `/${NOT_FOUND_ROUTE}`;
    case QuickSearchKeys.candidates:
      return userId ? `/${CANDIDATE_ASSESSMENTS_ROUTE}/${userId}` : `/${NOT_FOUND_ROUTE}`;
    default:
      return `/${NOT_FOUND_ROUTE}`;
  }
};

export function SearchItemComponent(
  category: QuickSearchKeys,
  name: string,
  id: number,
  clientId?: number,
  assessmentId?: number,
  userId?: number,
  status?: string,
) {
  const itemNameLimit: number = 50;
  const displayName: string = name.length < itemNameLimit ? name : `${name.substring(0, itemNameLimit)}...`;

  return {
    key: `${category}-${id}-${name}`,
    value: name,
    label:
      Boolean(status) && status !== "done" ? (
        <div>
          <Typography.Text disabled={Boolean(status) && status !== "done"} className={style.invite}>
            {displayName}
            {status && (
              <div className={style.inviteIconGroup}>
                <div>{statusText[status]}</div>
                {statusIcon[status]}
              </div>
            )}
          </Typography.Text>
        </div>
      ) : (
        <Link
          className={style.link}
          to={getItemRoute(category, {
            userId,
            clientId,
            assessmentId,
            categoryId: id,
          })}
        >
          <Typography.Text disabled={Boolean(status) && status !== "done"} className={style.invite}>
            {displayName}
            {status && (
              <div className={style.inviteIconGroup}>
                <div>{statusText[status]}</div>
                {statusIcon[status]}
              </div>
            )}
          </Typography.Text>
        </Link>
      ),
  };
}

import React, { ChangeEvent, useEffect, useState } from "react";
import { Popover, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table/Table";

import { IUser } from "@/modules/client/clients.interface";

import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";
import { DB_USER_KEY } from "@/shared/hooks/auth/currentUser.hook";
import { useDeleteUserHook } from "@/shared/hooks/users/use-delete-user.hook";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";

import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "./style.module.css";

const adminUsersColumns: ColumnsType<IUser> = [
  {
    key: "fullName",
    title: "Name",
    dataIndex: "fullName",
    className: style.tableColumnName,
    defaultSortOrder: "ascend",
    sorter: (a, b) => {
      return sortElements(a.fullName, b.fullName);
    },
  },
  {
    key: "email",
    title: "Email",
    dataIndex: "email",
    className: style.tableColumn,
    render: (value) => <a href={`mailto:${value}`}>{value}</a>,
    sorter: (a, b) => {
      return sortElements(a.email, b.email);
    },
  },
  {
    key: "lastLogin",
    title: "Last Login",
    dataIndex: "lastLogin",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    sorter: (a, b) => {
      return sortElements(a.lastLogin, b.lastLogin);
    },
  },
];

export default function TableAdminUsers({ dataList }: { dataList: IUser[] }) {
  const DEFAULT_PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [tableData, setTableData] = useState<IUser[]>(dataList);

  const { loading: loadingDelete, deleteUser } = useDeleteUserHook();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTableData(dataList.filter((item: IUser) => isSubstring(item.fullName, event.target.value) && !item.deletedOn));
  };

  useEffect(() => {
    if (dataList.length) {
      setTableData(dataList);
    }
  }, [dataList]);

  const currentUser: IUser = JSON.parse(String(localStorage.getItem(DB_USER_KEY)));

  const columns: ColumnsType<IUser> = [
    ...adminUsersColumns,
    {
      key: "userId",
      dataIndex: "userId",
      className: style.tableColumn,
      render: (userId: number) =>
        currentUser.userId !== userId && (
          <Popover
            key={userId}
            trigger="click"
            content={
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div style={{ marginBottom: 12 }}>
                  <p className={style.text}>Are you sure you want to remove this person?</p>
                  <p className={style.text}>This action is NOT undoable.</p>
                </div>

                <CustomButton
                  title="Remove"
                  buttonSize={ButtonSize.small}
                  isLoading={loadingDelete}
                  onClick={async () => {
                    await deleteUser(userId);
                    setTableData(dataList.filter((user) => user.userId !== userId));
                  }}
                />
              </div>
            }
          >
            <DeleteOutlined />
          </Popover>
        ),
    },
  ];

  return (
    <div className={style.tableBlock}>
      <TableHeader tableName="Admin Users" handleSearchChange={(e) => handleSearchChange(e)} />
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
}

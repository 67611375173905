import React, { useEffect } from "react";
import { Loading } from "@/shared/components/Loading/Loading";
import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";
import { LOGIN_ROUTE, CANDIDATE_ROUTE } from "@/routing/AppRouter/routes.constants";

/**
 * The purpose of this component is to force a page-reload, which will remove all
 * of the state that is cached client-side.
 */
export default function Logout() {
  const loginUrl = useGetQueryCode("url");

  useEffect(() => {
    window.location.href = loginUrl ?? `/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`;
  }, [loginUrl]);

  return <Loading />;
}

import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import { mlsToMin } from "@/shared/utils/common.utils";
import { sortElements, sortNumbers } from "@/shared/utils/array/sort.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";
import TableHeader from "@/shared/components/TableHeader/TableHeader";

import { ISectionConfig } from "@/modules/assessments/types/test.interface";
import { sectionsTimeString, sectionsTimeSum } from "@/modules/tables/tables.utils";

import style from "./style.module.css";

export interface ITestTable {
  key: number;
  id: number;
  name: string;
  questionsCount: number;
  joinedAssessments: number;
  client?: string;
  finishedTestQuantity?: number;
  countOfNorms?: number;
  suggestedTime?: number;
  startsSections?: ISectionConfig[];
}

export const testsColumns: ColumnsType<ITestTable> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    className: style.tableColumnName,
    defaultSortOrder: "ascend",
    render: (value, record) => {
      return (
        <Link to={`${record.id}/`} className={style.tableLink}>
          {value}
        </Link>
      );
    },
    sorter: (a, b) => {
      return sortElements(a.name, b.name);
    },
    onCell: () => ({
      style: {
        cursor: "pointer",
      },
    }),
  },
  {
    key: "questionsCount",
    title: "Elements",
    dataIndex: "questionsCount",
    className: style.tableColumn,
    defaultSortOrder: "ascend",
    sorter: (a, b) => sortElements(a.questionsCount, b.questionsCount),
  },
  {
    key: "client",
    title: "Client",
    dataIndex: "client",
    className: style.tableColumn,
    defaultSortOrder: "ascend",
  },
  {
    key: "joinedAssessments",
    title: "Used In Assessments",
    dataIndex: "joinedAssessments",
    className: style.tableColumn,
    sorter: (a, b) => sortNumbers(a.joinedAssessments, b.joinedAssessments),
  },
  {
    key: "finishedTestQuantity",
    title: "Answered By",
    dataIndex: "finishedTestQuantity",
    className: style.tableColumn,
    sorter: (a, b) => sortElements(a.finishedTestQuantity, b.finishedTestQuantity),
  },
  {
    key: "countOfNorms",
    title: "Norms",
    dataIndex: "countOfNorms",
    className: style.tableColumn,
    sorter: (a, b) => sortElements(a.countOfNorms, b.countOfNorms),
  },
  {
    key: "suggestedTime",
    title: "Estimated Time",
    dataIndex: "suggestedTime",
    className: style.tableColumn,
    render: (suggestedTime: number) => `${mlsToMin(suggestedTime)}m`,
    sorter: (a, b) => sortElements(a.suggestedTime, b.suggestedTime),
  },
  {
    key: "startsSections",
    title: "Limits",
    dataIndex: "startsSections",
    className: style.tableColumn,
    sorter: (a, b) => sortNumbers(sectionsTimeSum(a.startsSections), sectionsTimeSum(b.startsSections)),
    render: (startsSections: ISectionConfig[]) => {
      if (!startsSections || !startsSections.length) {
        return `Not Timed`;
      }

      return <Popover content={sectionsTimeString(startsSections)}>{sectionsTimeSum(startsSections)}m</Popover>;
    },
  },
];

export default function TableTests({ dataList }: { dataList: ITestTable[] }) {
  const DEFAULT_PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  // TODO [PER]: It looks redundant
  const [tableData, setTableData] = useState<ITestTable[]>(dataList);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTableData(dataList.filter((item: ITestTable) => isSubstring(item.name, event.target.value)));
  };

  return (
    <div className={style.tableBlock}>
      <TableHeader tableName="Tests" handleSearchChange={(e) => handleSearchChange(e)} />
      <Table
        columns={testsColumns}
        dataSource={tableData}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { debounce } from "react-ace/lib/editorOptions";

import { QuickSearchKeys } from "@/ui-components/commonComponents/GlobalSearch/types";

import { useGlobalSearchHook } from "./hooks/useGlobalSearch.hook";
import { SearchItemTitle } from "./components/SearchItemTitle/SearchItemTitle";
import { SearchItemComponent } from "./components/SearchItemComponent/SearchItem.component";

const MIN_SEARCH_LENGTH = 2;

const getOptions = (optionsList: any, key: string) => {
  switch (key) {
    case QuickSearchKeys.candidates:
      return optionsList.candidates.map((item: any) =>
        SearchItemComponent(
          key,
          `${item.fullName} - ${item.email}`,
          item.userId,
          item.clientId,
          undefined,
          item.userId,
        ),
      );
    case QuickSearchKeys.invites:
      return optionsList.invites.map((item: any) => {
        return SearchItemComponent(
          key,
          item.name,
          item.inviteId,
          item.clientId,
          item.assessmentId,
          item.userId,
          item.status,
        );
      });
    case QuickSearchKeys.assessments:
      return optionsList.assessments.map((item: any) =>
        SearchItemComponent(key, item.name, item.assessmentId, item.clientId),
      );
    case QuickSearchKeys.clients:
      return optionsList.clients.map((item: any) => SearchItemComponent(key, item.name, item.clientId));
    case QuickSearchKeys.tests:
      return optionsList.tests.map((item: any) => SearchItemComponent(key, item.name, item.testId));
    default:
      return [];
  }
};

const getCategorizedOptions = (optionList: any) => {
  return Object.keys(optionList).map((key) => {
    if (optionList[key].length !== 0) {
      return {
        label: SearchItemTitle(key),
        options: getOptions(optionList, key),
      };
    } else {
      return {};
    }
  });
};

export default function GlobalSearch() {
  const { isLoading, optionsList, clearOptions, getSearchResponse } = useGlobalSearchHook();

  const [value, setValue] = useState<string>("");

  const handleSearchInput = (keyWord: string) => {
    setValue(keyWord);
  };
  const handleSelectOption = () => {
    setValue("");
    clearOptions();
  };

  useEffect(() => {
    const getElements = debounce(async () => {
      if (value.length >= MIN_SEARCH_LENGTH) {
        await getSearchResponse(value);
      }
    }, 300);
    getElements();
  }, [value]);

  return (
    <div>
      <AutoComplete
        allowClear
        onClear={clearOptions}
        value={value}
        dropdownMatchSelectWidth={500}
        dropdownStyle={{ position: "fixed" }}
        options={getCategorizedOptions(optionsList)}
        onSearch={handleSearchInput}
        onSelect={handleSelectOption}
        style={{ borderColor: "transparent" }}
      >
        <Input.Search placeholder={"Type to search..."} loading={isLoading} />
      </AutoComplete>
    </div>
  );
}

/**
 * @description
 * * ADMIN - can see all assessments, reports, etc. can create new assessments.
 * * USER - can’t create assessments, can only see assessments they’re part of.
 * */
export enum ClientRoleEnum {
  ADMIN = "admin",
  USER = "user",
}

export const ClientRoleDescriptions = {
  [ClientRoleEnum.ADMIN]: "Can manage the team, invite new users, create admins, and see all assessment reports.",
  [ClientRoleEnum.USER]: "Only has access to assessments and reports they’re explicitly given access to.",
};

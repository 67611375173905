import { useState } from "react";
import { AssetsHttpService } from "@/shared/services/assets/assets-http.service";

export function useUploadAssessmentImageHook(
  defaultImageUrl: string,
  onUpload: (data: string) => void,
  clientId: string,
) {
  const [imageUrl, setImageUrl] = useState<string>(defaultImageUrl ? defaultImageUrl : "");
  const [isLoading, setIsLoading] = useState(false);
  const [photoError, setPhotoError] = useState(false);

  const uploadFile = async (options: any) => {
    const { onSuccess, onError, file } = options;

    const formData = new FormData();

    formData.append("file", file);

    try {
      setIsLoading(true);

      const res = await AssetsHttpService.uploadAssessmentPhoto(formData, {
        clientId: Number(clientId),
      });

      onSuccess("Ok");

      if (res.status === 201) {
        setImageUrl(res.data.secure_url);
        onUpload(res.data.secure_url);
        setPhotoError(false);
        setIsLoading(false);
      } else {
        setPhotoError(true);
        setIsLoading(false);
      }
    } catch (err) {
      onError({ err });
    }
  };
  return { imageUrl, photoError, isLoading, uploadFile };
}

import { httpClient } from "../http";

export enum AssessmentLogEventsEnum {
  SEND_ASSESSMENT = "SendAssessment",
  ASSESSMENT_STARTED = "AssessmentStarted",
  ASSESSMENT_ACCEPTED = "AssessmentAccepted",
  ASSESSMENT_COMPLETED = "AssessmentCompleted",
  ASSESSMENT_RECALCULATED = "AssessmentRecalculated",

  ASSESSMENT_OPENED = "AssessmentOpened",
  ASSESSMENT_CLOSED = "AssessmentClosed",
  ASSESSMENT_FOCUS = "AssessmentFocus",
  ASSESSMENT_LOST_FOCUS = "AssessmentLostFocus",
}

export class AssessmentLogsHttpService {
  public static async saveInviteLogs(inviteId: number, testId: number, event: string | AssessmentLogEventsEnum) {
    const { data } = await httpClient.post(`/api/invites/${inviteId}/tests/${testId}/assessment-logs`, { event });

    return data;
  }
}

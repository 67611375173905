import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "@/shared/services/store";
import { SentryService } from "@/shared/services/sentry/sentry.service";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

SentryService.init();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState } from "react";

import { SearchHttpService } from "@/shared/services/search/search-http.service";

import { QuickSearchKeys } from "../types";

export function useGlobalSearchHook() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState({});

  const getSearchResponse = async (keyWord: string) => {
    try {
      setIsLoading(true);
      const response = await SearchHttpService.getSearchResponse(keyWord);
      const keys = Object.keys(response);

      // TODO: get rid of any
      let cleanedResponse: any = {};

      keys.forEach((key) => {
        if (response[key] !== null) {
          if (response[key].length !== 0) {
            if (
              key === QuickSearchKeys.tests ||
              key === QuickSearchKeys.assessments ||
              key === QuickSearchKeys.invites ||
              key === QuickSearchKeys.clients ||
              key === QuickSearchKeys.candidates
            )
              cleanedResponse[key] = response[key];
          }
        }
      });
      setOptionsList(cleanedResponse);
    } catch (e: any) {
      console.error(`global search error ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, optionsList, getSearchResponse, clearOptions: () => setOptionsList({}) };
}

import { useEffect, useState } from "react";

import {
  AssessmentPermissionsHttpService,
  IAssessmentPermissions,
} from "../services/assessment-permissions-http.service";

export function useGetAssessmentUsersPermissionsHook(assessmentId?: number) {
  const [assessmentPermissions, setAssessmentPermissions] = useState<IAssessmentPermissions[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAssessmentUsersPermissions = async () => {
    if (!assessmentId) return;

    setIsLoading(true);

    try {
      const assessmentPermissions = await AssessmentPermissionsHttpService.getAssessmentUsers(assessmentId);
      setAssessmentPermissions(assessmentPermissions);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAssessmentUsersPermissions();
  }, [assessmentId]);

  return { assessmentPermissions, isLoading, refreshAssessmentPermissions: getAssessmentUsersPermissions };
}

import { useState } from "react";

import { AssessmentPermissionsHttpService } from "../services/assessment-permissions-http.service";

export function useRemoveAssessmentUserHook() {
  const [isLoading, setIsLoading] = useState(false);

  const removeAssessmentUser = async (assessmentId: number, userId: number) => {
    try {
      setIsLoading(true);

      return AssessmentPermissionsHttpService.removeUserAssessmentPermissions(assessmentId, Number(userId));
    } finally {
      setIsLoading(false);
    }
  };

  return { removeAssessmentUser, isLoading };
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isValidNumericParam } from "@/shared/utils/common.utils";
import {
  AssessmentHttpService,
  GetAssessmentsParameters,
} from "@/modules/assessments/services/assessment-http.service";

import getErrorRoute from "@/routing/services/getErrorRoute";
import { HttpStatus } from "@/routing/services/statusCodes";

import { IAssessment } from "../assessments.types";

export function useAllAssessmentsHook(clientId: number, options: GetAssessmentsParameters = {}, refresh: boolean) {
  const navigate = useNavigate();
  const [assessments, setAssessments] = useState<IAssessment[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        setIsLoading(true);

        const { data: assessmentsFromAPI, totalCount }: { data: IAssessment[]; totalCount: number } =
          await AssessmentHttpService.getAllAssessments(clientId, options);
        const updateAssessmentsWithKey: IAssessment[] = assessmentsFromAPI.map((item: IAssessment) => {
          return { ...item, key: item.assessmentId };
        });

        setAssessments(updateAssessmentsWithKey);
        setTotalCount(totalCount);
      } catch (e: any) {
        console.error(`getting all assessment error: ${e}`);
        const route: string = getErrorRoute(HttpStatus.NOT_FOUND);
        navigate(route);
      } finally {
        setIsLoading(false);
      }
    };

    if (isValidNumericParam(clientId)) {
      fetchAssessments();
    }
  }, [
    clientId,
    options?.take,
    options?.skip,
    options?.sortOptions?.direction,
    options?.sortOptions?.column,
    options?.search,
    options?.testIdsFilter,
    refresh,
  ]);

  return { assessments, isLoading, totalCount };
}

import { DEFAULT_CANDIDATE_INVITES_PAGE_SIZE } from "@/shared/constants/candidate-invites";
import { GetInvitesParameters } from "@/modules/candidate/services/InvitesHttpService";

export class AssessmentService {
  private static OVERALL_SCORE = {
    Unfavorable: { lowerThan: 0.34 },
    Mixed: { lowerThan: 0.68 },
    Favorable: { lowerThan: 0.75 },
    Exceptional: { lowerThan: 1.1 },
  };

  static getOverallScoreLabel(overallScore: number): string {
    const [label] =
      Object.entries(AssessmentService.OVERALL_SCORE).find(([, { lowerThan }]) => overallScore < lowerThan) || [];

    return label || "Exceptional";
  }

  static setInitialQueryForCandidateInvites(
    params: URLSearchParams,
    { status, candidateFilter, take, sortOptions, currentPage }: GetInvitesParameters & { currentPage?: number } = {},
  ) {
    if (!params.has("page")) {
      params.append("page", currentPage ? String(currentPage) : "1");
    }

    if (!params.has("pageSize")) {
      params.append("pageSize", String(take || DEFAULT_CANDIDATE_INVITES_PAGE_SIZE));
    }

    if (!params.has("status") && status) {
      const statusStringValue = Array.isArray(status) ? status.join(",") : status;
      if (statusStringValue) {
        params.append("status", statusStringValue);
      }
    }

    if (!params.has("candidate") && candidateFilter) {
      params.append("candidate", candidateFilter);
    }

    if (!params.has("sort") && sortOptions?.direction) {
      params.append("sort", sortOptions?.direction);
    }

    if (!params.has("sortBy") && sortOptions?.column) {
      params.append("sortBy", sortOptions?.column);
    }

    return params;
  }
}

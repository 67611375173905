import { useEffect, useState, useCallback } from "react";
import { Firebase } from "@/shared/services/firebase";

import { DB_USER_KEY } from "./currentUser.hook";
import { CLIENT_ID } from "@/shared/hooks/use-get-clients.hook";

export const FIREBASE_ACCESS_TOKEN = "FIREBASE_ACCESS_TOKEN";

type TFirebaseAuth = {
  isLoading: boolean;
  firebaseUser: any;
};

// TODO: Add firebaseUser types
export type TFirebaseUser = {
  uid: string;
  photoURL: string;
  displayName: string;
  email: string;
};

export type TUseFirebaseAuthHook = {
  signOut: () => Promise<void>;
  isSignedIn: boolean;
  firebase: Firebase;
  firebaseUser: TFirebaseUser;
  isLoading: boolean;
};

export function useFirebaseAuthHook(firebase: Firebase): TUseFirebaseAuthHook {
  const [firebaseAuth, setFirebaseAuth] = useState<TFirebaseAuth>({
    isLoading: true,
    firebaseUser: null,
  });

  useEffect(() => {
    firebase.auth.onAuthStateChanged(
      async (user) => {
        setFirebaseAuth({ firebaseUser: user, isLoading: false });

        const token = await firebase.getToken(false);

        if (token) {
          localStorage.setItem(FIREBASE_ACCESS_TOKEN, token);
        }
      },
      (err) => console.error("ERR", err),
      () => console.log("COMPLETED"),
    );
  }, [firebase.auth]);

  const signOut = useCallback(async () => {
    setFirebaseAuth({ isLoading: true, firebaseUser: null });

    await firebase.signOut();

    setFirebaseAuth({ isLoading: false, firebaseUser: null });
    localStorage.removeItem(DB_USER_KEY);
    localStorage.removeItem(FIREBASE_ACCESS_TOKEN);
    localStorage.removeItem(CLIENT_ID);
  }, []);

  const isSignedIn = Boolean(firebaseAuth.firebaseUser?.displayName);

  return {
    signOut,
    isSignedIn,
    firebase,
    firebaseUser: firebaseAuth.firebaseUser,
    isLoading: firebaseAuth.isLoading,
  };
}

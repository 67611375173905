import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectPickedClient } from "@/shared/services/store/clientStore";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";

import ErrorPage from "@/modules/main/ErrorPage/ErrorPage";
import { ICommonErrorPage } from "@/modules/main/Forbidden/Forbidden";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { Loading } from "@/shared/components/Loading/Loading";

const buttonTitle: { default: string; notAuthorized: string } = {
  default: "open dashboard",
  notAuthorized: "log in",
};

export default function NotFound({ isSignedIn, isAdmin, isClient, isLoading = false }: ICommonErrorPage) {
  const clientId = useSelector(selectPickedClient);

  const clientAbility = useContext(ClientAbilityContext);
  const canViewAdminDashboard = clientAbility.can(Action.Manage, Subject.ALL);
  const canViewClientDashboard = clientAbility.can(Action.Read, Subject.CLIENT);
  const canViewAssessmentDashboard = clientAbility.can(Action.Read, Subject.ASSESSMENT);

  const navigate = useNavigate();
  const route = isSignedIn
    ? canViewAdminDashboard
      ? "/admin"
      : canViewClientDashboard
      ? "/clients"
      : canViewAssessmentDashboard
      ? `/clients/${clientId}/assessments`
      : "/candidate/invites"
    : "/";

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ErrorPage
      isSignedIn={isSignedIn}
      isAdmin={isAdmin}
      isClient={isClient}
      errorReason={"Sorry, the page you visited does not exist."}
    >
      <CustomButton
        title={!isSignedIn ? buttonTitle.notAuthorized : buttonTitle.default}
        onClick={() => navigate(route)}
      />
    </ErrorPage>
  );
}

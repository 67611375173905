import { ITestAnswerScore } from "@/shared/components/assessment/report/AnalysisBlock/AnalysisBlock";
import { ComputeHttpService } from "@/shared/components/assessment/service/compute-http.service";

export interface IComputeAssessmentScorePayload {
  knockoutFormula: string;
  testAnswers: Omit<ITestAnswerScore, "testId">[];
}

export async function computeAssessmentScore(payload: IComputeAssessmentScorePayload) {
  try {
    return await ComputeHttpService.computeAssessmentScore(payload);
  } catch (e: any) {
    console.error(`Compute assessment score error: ${e}`);
  }
}

import { useState } from "react";

import { AssessmentPermissionsHttpService, AssessmentRoleEnum } from "../services/assessment-permissions-http.service";

export function useManageAssessmentUserHook() {
  const [isLoading, setIsLoading] = useState(false);

  const manageAssessmentUser = async (assessmentId: number, userId: number, roleName: AssessmentRoleEnum) => {
    try {
      setIsLoading(true);
      return AssessmentPermissionsHttpService.manageUserAssessmentPermissions(assessmentId, {
        userId: Number(userId),
        role: roleName,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { manageAssessmentUser, isLoading };
}

import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import { useSearchParams } from "react-router-dom";
import { notification, Typography } from "antd";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { usePaginatedAssessmentInvitesHook } from "@/modules/assessments/hooks/usePaginatedAssessmentInvites.hook";
import { AssessmentService } from "@/modules/assessments/services/assessment.service";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";
import { ASSESSMENT_ROUTE, NEW_ROUTE, SELF_REGISTER_ROUTE } from "@/routing/AppRouter/routes.constants";
import logo from "@/assets/image/logo.png";
import { ReactComponent as CopySVG } from "@/assets/icons/feather-icons/copy.svg";
import { DesktopInvitesList } from "./invites-list/DesktopInvitesList";
import "../../styles/assessmentCommon.css";
import { InviteHttpService } from "@/modules/assessments/services/invite-http.service";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { getFriendlyErrorMessage } from "@/shared/services/http";
import ConfirmDisableTimerModal from "./components/ConfirmDisableTimerModal/ConfirmDisableTimerModal";

const { Paragraph } = Typography;
interface ITimersToDisable {
  status: "idle" | "pending" | "loading";
  invites: Array<IAssessmentInvite>;
}
const INITIAL_DISABLE_TIMER_STATE: ITimersToDisable = { status: "idle", invites: [] };

export function AssessmentInvites({
  isSendable,
  stats,
}: {
  isSendable?: boolean;
  stats: Record<AssessmentInviteStatus, number>;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { assessment_id: assessmentId } = useParams();
  const [statuses, setStatuses] = useState<string[]>([]);
  const [filteredStatuses, setFilteredStatuses] = useState<string[]>(searchParams.get("status")?.split(",") || []);
  const [sortOptions, setSortOptions] = useState<{ column: string | undefined; direction: string | undefined }>({
    column: searchParams.get("sortBy") || undefined,
    direction: searchParams.get("sort") || undefined,
  });
  const [candidateSearch, setCandidateSearch] = useState<string>(searchParams.get("candidate") || "");
  const [pageSize, setPageSize] = useState<number>(Number(searchParams.get("pageSize") || 10));
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get("page") || 1));
  const [isQROpened, setQROpened] = useState(false);
  const [invitesToDisableTimer, setInvitesToDisableTimer] = useState<ITimersToDisable>(INITIAL_DISABLE_TIMER_STATE);
  const options = {
    sortOptions,
    status: filteredStatuses,
    take: pageSize,
    skip: currentPage * pageSize - pageSize,
    candidateFilter: candidateSearch,
  };
  const {
    assessmentInvites: paginatedAssessmentInvites,
    totalCount,
    isLoading,
  } = usePaginatedAssessmentInvitesHook(Number(assessmentId), options);
  const QRUrl = `${window.location.origin}/${ASSESSMENT_ROUTE}/${assessmentId}/${SELF_REGISTER_ROUTE}`;

  AssessmentService.setInitialQueryForCandidateInvites(searchParams, { ...options, currentPage });

  const onCandidateSearch = (search: string) => {
    if (search) {
      searchParams.set("candidate", search);
    } else {
      searchParams.delete("candidate");
    }
    searchParams.set("page", "1");
    setCandidateSearch(search);
    setCurrentPage(1);
    setSearchParams(searchParams);
  };
  const openQR = () => setQROpened(true);
  const setTablePageSize = (size: number) => {
    searchParams.set("pageSize", String(size));
    searchParams.set("page", "1");
    setCurrentPage(1);
    setPageSize(size);
    setSearchParams(searchParams);
  };
  const setTableCurrentPage = (page: number) => {
    searchParams.set("page", String(page));
    setCurrentPage(page);
    setSearchParams(searchParams);
  };
  const onSortChange = ({ column, direction }: { column: string; direction: string }) => {
    setSortOptions({ column, direction });

    if (column && direction) {
      searchParams.set("sort", direction);
      searchParams.set("sortBy", column);
      setSearchParams(searchParams);
    }

    if (column && !direction) {
      searchParams.delete("sort");
      searchParams.delete("sortBy");
      setSearchParams(searchParams);
    }
  };
  const onStatusFilter = (statuses: string[]) => {
    const statusStringVal = statuses.join(",");
    if (statuses.length) {
      searchParams.set("status", statusStringVal);
    } else {
      searchParams.delete("status");
    }
    setFilteredStatuses(statuses);
    setSearchParams(searchParams);
  };
  const refreshInvites = () => {
    setSortOptions({ ...sortOptions });
  };
  const rescindInvite = async (inviteIds: number[]) => {
    try {
      await InviteHttpService.rescindInvites(assessmentId, inviteIds);
      refreshInvites();
      notification.success({ message: "Assessment Invite(s) successfully rescinded." });
    } catch (err) {
      notification.error({ message: "Sorry - this assessment is no longer available." });
    }
  };
  const rescoreInvites = (inviteIds: Array<number>) => {
    navigate(`${pathname}/rescore?${inviteIds.map((id) => `invites=${id}`).join("&")}`);
  };
  const disableInviteTimer = async (inviteIds: Array<number>) => {
    try {
      setInvitesToDisableTimer((val) => ({ ...val, status: "loading" }));
      await InviteHttpService.disableInviteTimer(assessmentId!, inviteIds);
      refreshInvites();
      setInvitesToDisableTimer(INITIAL_DISABLE_TIMER_STATE);
      notification.success({ message: `Timer${inviteIds.length !== 1 ? "s" : ""} disabled.` });
    } catch (error) {
      setInvitesToDisableTimer((val) => ({ ...val, status: "pending" }));
      notification.error({
        message: getFriendlyErrorMessage(error) || "Oops, something went wrong. Please try again.",
      });
    }
  };

  // TODO: rebuild filtering
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    // setTableData(
    //   invitesList.filter((candidate) => {
    //     return candidate.name
    //       .toUpperCase()
    //       .includes(event.target.value.toUpperCase());
    //   })
    // );
  };
  useEffect(() => {
    const statuses = new Set<string>();
    const statusKeys = Object.keys(stats);
    statusKeys.forEach((statusKey) => {
      if (stats[statusKey as AssessmentInviteStatus]) {
        if (statusKey === AssessmentInviteStatus.Invited) {
          const alreadyAccepted =
            stats[AssessmentInviteStatus.Accepted] +
            stats[AssessmentInviteStatus.Started] +
            stats[AssessmentInviteStatus.InProgress] +
            stats[AssessmentInviteStatus.Done];
          if (stats[statusKey as AssessmentInviteStatus] - alreadyAccepted <= 0) {
            return;
          }
        }
        statuses.add(statusKey);
      } else return;
    });
    setStatuses(Array.from(statuses));
  }, [stats]);

  const handleAddCandidates = () => {
    navigate(`${pathname}/${NEW_ROUTE}`);
  };

  useEffect(() => {
    pathname.split("/").pop() === "invites" && setSearchParams(searchParams);
  }, [pathname]);

  return (
    <div className="container">
      <BasicModal autoHeight isModalOpen={isQROpened} handleCancel={() => setQROpened(false)}>
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <QRCode removeQrCodeBehindLogo size={250} value={QRUrl} logoImage={logo} logoHeight={30} logoWidth={30} />
          </div>

          <Paragraph
            copyable={{ tooltips: false, icon: <CopySVG /> }}
            className="linkContainer"
            style={{ marginBottom: 0, marginTop: 20 }}
          >
            {QRUrl}
          </Paragraph>
        </>
      </BasicModal>

      <ConfirmDisableTimerModal
        isOpen={invitesToDisableTimer.invites.length > 0}
        isLoading={invitesToDisableTimer.status === "loading"}
        onCancel={() => setInvitesToDisableTimer(INITIAL_DISABLE_TIMER_STATE)}
        onConfirm={() => {
          return disableInviteTimer(invitesToDisableTimer.invites.map(({ inviteId }) => inviteId));
        }}
      >
        {invitesToDisableTimer.invites.length === 1 ? (
          <>
            <p>
              Are you sure you want to disable the timer for{" "}
              {invitesToDisableTimer.invites[0].candidateFullName || invitesToDisableTimer.invites[0].email}?
            </p>
            <p>Once disabled, it cannot be re-enabled for this Assessment Invite.</p>
          </>
        ) : (
          <>
            <p>Are you sure you want to disable the timer for {invitesToDisableTimer.invites.length} candidates?</p>
            <p>Once disabled, they cannot be re-enabled for these Assessment Invites.</p>
          </>
        )}
      </ConfirmDisableTimerModal>

      <DesktopInvitesList
        dataList={paginatedAssessmentInvites}
        onSearchChange={handleSearchChange}
        onAddCandidates={handleAddCandidates}
        isSendable={isSendable}
        openQRModal={openQR}
        pageSize={pageSize}
        setPageSize={setTablePageSize}
        currentPage={currentPage}
        setCurrentPage={setTableCurrentPage}
        totalCount={totalCount}
        loading={isLoading}
        onCandidateSearch={onCandidateSearch}
        onSortChange={onSortChange}
        statuses={statuses}
        onFilterStatus={onStatusFilter}
        filteredStatuses={filteredStatuses}
        sortOptions={sortOptions}
        onInvitesRescind={rescindInvite}
        onInvitesRescore={rescoreInvites}
        onInvitesDisableTimer={(invites: Array<IAssessmentInvite>) =>
          setInvitesToDisableTimer({ status: "pending", invites })
        }
      />
    </div>
  );
}

import { ChartDataType } from "@/shared/components/charts/utils/chartUtils";

export enum ChartCategories {
  Motivators = "Motivators",
  BehaviorsAndSkills = "BehaviorsAndSkills",
}

export interface ICommonChart {
  usersChartData: IMultiChart[];
  isHiddenSameScore: boolean;
  chartDataType?: ChartDataType;
}

export interface IChart {
  competency: string;
  competencyName: string;
  reportCompetencyColor?: string;
  value: number | undefined;
  testRank: number;
  testId: number;
  chartCategory: ChartCategories;
  testColor: string;
  explanation?: string | null;
  [inviteId: number]: number;
}

export interface IMultiChart {
  name: string;
  inviteId: number;
  color: string;
  chartData: IChart[];
}

export interface ICategorizedMultiChart {
  [chartCategory: string]: IMultiChart[];
}

export interface IAnsweredCompetency {
  [key: string]: {
    score: number;
    adjusted: number;
    formulaVersion: number;
    explanation?: string | null;
  };
}
export interface IAnsweredScoreOverall {
  maxAchievable: number;
  ratio: number;
  score: number;
}
export interface IAnsweredScoreScales {
  [key: string]: { raw: number; percentile: number; percentage?: number };
}

export type ITestAnswerTimes = {
  // element key
  [sectionStartElementKey: string]: {
    // Seconds - how much time the user spent in the section
    time: number;
    // timestamp
    lastUpdated: number;
  };
};

export interface ITestAnswer {
  answerId: number;
  createdAt: string;
  answer: IAnswer[];
  inviteId: number;
  score: {
    scales: IAnsweredScoreScales;
    overall: IAnsweredScoreOverall;
    competencies: IAnsweredCompetency;
  };
  status: string;
  testId: number;
  updatedAt: string;
  userId: number;
  times: ITestAnswerTimes | null;
}

export interface IAnswer {
  elementId: number;
  elementKey: string;
  answer: number | string | boolean;
  answerKey: string;
  scales: string[];
}

import { useEffect } from "react";

import { useBeforeunload } from "@/shared/hooks/use-before-unload";
import { useWindowFocus } from "@/shared/hooks/use-window-focus.hook";
import {
  AssessmentLogEventsEnum,
  AssessmentLogsHttpService,
} from "@/shared/services/assessments/assessment-logs-http.service";

export function useTrackCandidateEventsHook(inviteId?: number, testId?: number) {
  // open
  useEffect(() => {
    if (!inviteId || !testId) {
      return;
    }

    (async () => {
      await AssessmentLogsHttpService.saveInviteLogs(inviteId, testId, AssessmentLogEventsEnum.ASSESSMENT_OPENED);
    })();
  }, [inviteId, testId]);

  // close
  useBeforeunload(async () => {
    if (!inviteId || !testId) {
      return;
    }

    await AssessmentLogsHttpService.saveInviteLogs(inviteId, testId, AssessmentLogEventsEnum.ASSESSMENT_CLOSED);
  });

  // (un)focus
  const isFocused = useWindowFocus();

  useEffect(() => {
    if (!inviteId || !testId) {
      return;
    }

    (async () => {
      const event = isFocused
        ? AssessmentLogEventsEnum.ASSESSMENT_FOCUS
        : AssessmentLogEventsEnum.ASSESSMENT_LOST_FOCUS;
      await AssessmentLogsHttpService.saveInviteLogs(inviteId, testId, event);
    })();
  }, [isFocused]);
}

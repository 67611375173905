import { IAssessmentTest } from "@/modules/assessments/types/test.interface";

import { ITestSelect, splitPattern } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";

export const MIN_AXIS_QUANTITY_FOR_RADAR = 3;

export const isScoredAssessment = ({
  netScore,
  grossScore,
  assessmentTests,
}: {
  netScore?: number;
  grossScore?: number;
  assessmentTests?: IAssessmentTest[];
}) => {
  if (netScore || grossScore) {
    return true;
  }

  return assessmentTests
    ? Boolean(assessmentTests.find(({ test }) => (test ? test.includeInOverallScore : false)))
    : false;
};

export const canRadarBeShown = (selectedKeys: ITestSelect[]): boolean => {
  const totalAvailableCompetenciesToSelect = selectedKeys.reduce((accumulator, selectedKey) => {
    return accumulator + selectedKey.allCompetencies.length;
  }, 0);

  return totalAvailableCompetenciesToSelect >= MIN_AXIS_QUANTITY_FOR_RADAR;
};

export const concatLink = (origin?: string, guid?: string): string => {
  if (origin && guid) {
    return `${origin}/report/${guid}`;
  }
  return "";
};

export const isDateExpired = (date: string | null | undefined): boolean => {
  if (date) {
    const now = new Date();
    const dateToCompare = new Date(date);
    return dateToCompare < now;
  } else return true;
};

const isCompareOptionDisabled = (
  selectedUsers: string[],
  maxCandidatesQuantity: number,
  fullCandidateInfo: string,
): boolean => {
  return selectedUsers.length >= maxCandidatesQuantity ? !selectedUsers.includes(fullCandidateInfo) : false;
};

export const convertInviteDataToSelectOptions = (
  invites: IAssessmentInvite[],
  selectedUsers: string[],
  maxCandidatesQuantity: number,
  isLoading: boolean,
): { label: string; value: string; disabled: boolean }[] => {
  return invites.map((invite) => {
    const candidateFullData = `${invite.candidateFullName} - ${invite.email} - ${maybe(
      invite?.finishedOn,
      shortFormatDate,
      "",
    )}`;
    const value = `${candidateFullData}${splitPattern}${invite.email}${splitPattern}${invite.inviteId}`;
    return {
      value: value,
      label: candidateFullData || "",
      disabled: isCompareOptionDisabled(selectedUsers, maxCandidatesQuantity, value) || isLoading,
    };
  });
};

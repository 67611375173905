import React from "react";
import cn from "classnames";

import styles from "./style.module.css";

export function TestInfo({ testTimeCount, questionsCount }: { questionsCount: number; testTimeCount?: number }) {
  return (
    <div className={styles.testInfoContainer}>
      <p className={styles.text}>
        {testTimeCount && <span className={cn(styles.bold, styles.upper)}>Section: </span>}
        <span className={cn({ [styles.hideInfo]: questionsCount <= 0 })}>{questionsCount} questions</span>
      </p>
      {testTimeCount && (
        <p className={styles.text}>
          <span>, {testTimeCount} minutes</span>
        </p>
      )}
    </div>
  );
}

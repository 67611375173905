import { UserContext } from "@/shared/contexts/user.context";
import { useContext } from "react";
import PublicCandidateRegistration from "./PublicCandidateRegistration";
import { Outlet } from "react-router-dom";

export default function RegistrationLayout() {
  const { user } = useContext(UserContext);

  if (!user) return <PublicCandidateRegistration />;

  return <Outlet />;
}

import { useEffect, useState } from "react";
import ace, { Ace } from "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-min-noconflict/ext-language_tools";

import { editorCustomSnippetsList } from "@/ui-components/commonComponents/CodeEditor/autocompleteWordsList";

import "./aceEditor.css";

export enum AceSupportedLanguages {
  JS = "javascript",
  JSON = "json",
  TS = "typescript",
  CSS = "css",
}

const languages = [
  AceSupportedLanguages.JS,
  AceSupportedLanguages.JSON,
  AceSupportedLanguages.TS,
  AceSupportedLanguages.CSS,
];
languages.forEach((lang) => {
  require(`ace-builds/src-noconflict/mode-${lang}`);
  require(`ace-builds/src-noconflict/snippets/${lang}`);
});

const themes = [
  "monokai",
  "github",
  "tomorrow",
  "kuroir",
  "twilight",
  "xcode",
  "textmate",
  "solarized_dark",
  "solarized_light",
  "terminal",
];

themes.forEach((theme) => require(`ace-builds/src-noconflict/theme-${theme}`));

interface ICodeEditor {
  editorMode: string;
  uniqueName: string;
  defaultContent: string;
  onEditorChange: (e: string) => void;
}

export function CodeEditor({ uniqueName, defaultContent, onEditorChange, editorMode }: ICodeEditor) {
  const [value, setValue] = useState<string>(
    editorMode === AceSupportedLanguages.JSON
      ? JSON.stringify(JSON.parse(defaultContent), null, 2)
      : defaultContent.replace(/; /g, ";\n"),
  );

  useEffect(() => {
    const langTools = ace.require("ace/ext/language_tools");
    const scalesCompleter = {
      getCompletions: (
        editor: Ace.Editor,
        session: Ace.EditSession,
        pos: Ace.Point,
        prefix: string,
        callback: Ace.CompleterCallback,
      ): void => {
        callback(
          null,
          editorCustomSnippetsList.map(
            (scale) =>
              ({
                caption: scale.caption,
                value: scale.value,
                meta: scale.meta,
              } as Ace.Completion),
          ),
        );
      },
    };
    langTools.addCompleter(scalesCompleter);
  }, []);

  const handleEditorChange = (e: string) => {
    setValue(e);
    onEditorChange(e);
  };

  return (
    <AceEditor
      width="100%"
      height="120px"
      mode={editorMode}
      theme="xcode"
      name={uniqueName}
      onChange={handleEditorChange}
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      className="aceEditor"
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}

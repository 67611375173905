import React from "react";
import { IChoice } from "@/modules/assessments/types/test.interface";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import style from "./style.module.css";

interface ITwoWayMatch {
  choices: IChoice[];
  currentAnswer: string | boolean | undefined;
  disabled: boolean;
  id: number;
  onChoiceSelect: (answerData: any) => void;
  pairToMatch: [string, string];
  questionNumber?: number;
}

export function TwoWayMatch({
  id,
  currentAnswer,
  choices,
  disabled,
  pairToMatch,
  onChoiceSelect,
  questionNumber,
}: ITwoWayMatch) {
  const checkboxId = `checkbox-pair-${id}`;
  const [firstItem, secondItem] = pairToMatch;

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const choice = choices.find((choice) => choice.choice === checked);

    if (choice) {
      const selectedChoice = {
        elementId: id,
        choiceKey: choice.key,
      };

      onChoiceSelect(selectedChoice);
    }
  };

  return (
    <div className={style.testElementContainer}>
      {questionNumber && <p className={style.questionNumber}>{questionNumber}</p>}
      <label htmlFor={checkboxId}>{firstItem}</label>
      <Checkbox
        id={checkboxId}
        checked={typeof currentAnswer === "boolean" ? currentAnswer : false}
        disabled={disabled}
        onChange={onCheckboxChange}
      />
      <label htmlFor={checkboxId}>{secondItem}</label>
    </div>
  );
}

import { httpClient } from "../http";

export class UserHttpService {
  static async getAllAdminUsers() {
    const { data: users } = await httpClient.get(`/api/users/admins`);

    return users;
  }

  static async getCurrentUser(uid: string) {
    const { data: user } = await httpClient.get(`/api/users/firebase/${uid}`);

    return user;
  }

  static async getUserById(userId: number) {
    const { data: user } = await httpClient.get(`/api/users/${userId}`);
    return user;
  }

  static async getClientUsers(clientId: number) {
    const { data: users } = await httpClient.get(`/api/clients/${clientId}/users`);

    return users;
  }

  static async postUser(uid: string, email: string, fullName: string) {
    const { data: user } = await httpClient.post(`/api/users`, {
      fullName,
      email: email,
      firebaseId: uid,
    });

    return user;
  }

  static async deleteUser(userId: number) {
    return httpClient.delete(`/api/users/${userId}`);
  }
}

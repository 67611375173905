import * as React from "react";
import { useContext } from "react";
import { Checkbox, Select } from "antd";

import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { ReportCompareContext } from "@/shared/contexts/reportCompare.context";

import { Loading } from "@/shared/components/Loading/Loading";
import TagsContainer from "@/shared/components/assessment/report/ReportCompareHeader/component/TagsContainer/TagsContainer";

import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { usePaginatedAssessmentInvitesHook } from "@/modules/assessments/hooks/usePaginatedAssessmentInvites.hook";
import { useCompareReportHook } from "@/modules/assessments/hooks/use-compare-report.hook";

import { ReactComponent as CheckedSVG } from "@/assets/icons/checked.svg";

import { convertInviteDataToSelectOptions } from "../utils/reportUtils";
import PaginatedDropdown from "./component/PaginatedDropdown/PaginatedDropdown";

import style from "@/modules/assessments/scenes/AssessmentReport/style.module.css";

interface IReportCompareHeader {
  selectedUsers: string[];
  showHideSameScore: boolean;
  setSelectedUsers: (value: string[]) => void;
}

export const initialPlaceholderText = "Select candidates to compare";
export const MIN_SEARCH_LENGTH = 2;

const MAX_COMPARE_CANDIDATES = 5;
const MAX_OPTIONS_LENGTH = 10;

export default function ReportCompareHeader({
  selectedUsers,
  setSelectedUsers,
  showHideSameScore,
}: IReportCompareHeader) {
  const { assessment } = useContext(AssessmentContext);
  const { isHiddenSameScore, setIsHiddenSameScore, fetchAnswers } = useContext(ReportCompareContext);
  const { answersLoading: compareAnswersLoading } = useContext(ReportCompareContext);

  const {
    placeholder,
    currentPage,
    candidateSearch,
    handlePaginationSelect,
    handleOpenDropdown,
    handleInputUsers,
    handleHideSameScore,
    handleChangeUsers,
  } = useCompareReportHook(selectedUsers, setSelectedUsers, fetchAnswers, setIsHiddenSameScore);

  const options = {
    status: AssessmentInviteStatus.Done,
    take: MAX_OPTIONS_LENGTH,
    skip: currentPage * MAX_OPTIONS_LENGTH - MAX_OPTIONS_LENGTH,
    candidateFilter: candidateSearch,
  };

  const {
    assessmentInvites: doneInvites,
    totalCount,
    isLoading: loadingDoneInvites,
  } = usePaginatedAssessmentInvitesHook(Number(assessment?.assessmentId), options);

  if (!assessment) {
    return <Loading />;
  }

  return (
    <div className={style.chartHeader}>
      <Select
        showArrow
        mode="multiple"
        options={convertInviteDataToSelectOptions(
          doneInvites,
          selectedUsers,
          MAX_COMPARE_CANDIDATES,
          compareAnswersLoading,
        )}
        value={selectedUsers}
        placeholder={initialPlaceholderText}
        maxTagPlaceholder={placeholder}
        maxTagCount={0}
        menuItemSelectedIcon={<CheckedSVG />}
        placement="bottomLeft"
        onChange={handleChangeUsers}
        onSearch={handleInputUsers}
        onDropdownVisibleChange={handleOpenDropdown}
        dropdownRender={(menu) => (
          <PaginatedDropdown
            menu={menu}
            loading={loadingDoneInvites}
            currentPage={currentPage}
            totalInvitesQuantity={totalCount}
            handlePagination={handlePaginationSelect}
          />
        )}
      />
      <div className={style.selectedUsers}>
        {!selectedUsers.length && <p>Select from 2 to 5 candidates to compare</p>}
        <TagsContainer
          selectedUsers={selectedUsers}
          onChangeSelected={handleChangeUsers}
          setSelectedUsers={setSelectedUsers}
        />
        <div className={style.checkboxSize}>
          {showHideSameScore && selectedUsers.length !== 0 && (
            <Checkbox checked={isHiddenSameScore} onChange={handleHideSameScore}>
              Hide Same Scores
            </Checkbox>
          )}
        </div>
      </div>
    </div>
  );
}

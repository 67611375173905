import { useContext } from "react";

import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { ReportContext } from "@/shared/contexts/report.context";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";

import style from "./style.module.css";

interface ICustomTooltip {
  active?: boolean;
  payload?: { name: string; dataKey: string; color?: string; value: string; payload: { explanation: string } }[];
  label?: string;
}

export default function CustomTooltip({ active, payload, label }: ICustomTooltip) {
  const { scene } = useContext(ReportContext);
  if (!active || !payload || !payload.length) return null;

  const isCompareReport = scene === ReportScene.compare;

  const explanation = payload[0].payload.explanation;
  const viewData = payload.map(({ name, dataKey, value, color }) => {
    return { name, dataKey, value, color };
  });

  return (
    <div className={style.tooltipContainer}>
      <span>{label}</span>
      {!isCompareReport && (
        <span>
          : <b>{viewData[0].value}</b>
        </span>
      )}

      {isCompareReport &&
        viewData.map(({ name, dataKey, value, color }) => (
          <p key={`${name}_${dataKey}`} style={{ color: color ? color : "#FFFFFF" }}>
            {name.split(" - ")[0]}: <b>{value}</b>
          </p>
        ))}

      {explanation && (
        <div>
          <hr />
          <MarkdownPreviewCustom source={explanation} className={style.explanation} />
        </div>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";

import { INormScale } from "@/modules/assessments/types/scale.interface";
import { NormScalesHttpService } from "@/shared/services/norms/norm-scales-http.service";

export function useGetNormScalesHook(normId: number) {
  const [normScales, setNormScales] = useState<INormScale[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const normScales = await NormScalesHttpService.getNormScales(normId);
        setNormScales(normScales);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [normId]);

  return { normScales, isLoading };
}

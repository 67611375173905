import { createContext } from "react";

export type TReportCompareContext = {
  isHiddenSameScore: boolean;
  answersLoading: boolean;
  setIsHiddenSameScore?: (value: boolean) => void;
  fetchAnswers?: (inviteId: number) => void;
};

export const ReportCompareContext = createContext<TReportCompareContext>({
  isHiddenSameScore: true,
  answersLoading: false,
});

import { useCallback, useContext } from "react";

import { sortNumbers } from "@/shared/utils/array/sort.utils";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ITestElement } from "@/modules/assessments/types/test.interface";

import { InviteContext } from "../../contexts/invite.context";
import { CurrentPageContext } from "../../contexts/current-page.context";

const DEFAULT_REPORT_COLOR = "#122646";

export type IAssessmentProgress = {
  testId: number;
  percentage: number;
  testColor: string;
};

export function calculateAssessmentProgress(
  currentPage: number,
  questionsList: ITestElement[][],
  assessment?: IAssessment,
) {
  if (!assessment || !assessment?.assessmentTests) {
    return [];
  }

  const elements = questionsList.flat();

  return assessment?.assessmentTests
    ?.sort((a, b) => sortNumbers(a.rank, b.rank))
    .map(({ testId, test }) => {
      let pagesCount = 0;

      const [firstPageN, lastPageN] = elements.reduce(
        (pages, element) => {
          if (element.config.breakAfter) {
            pagesCount++;
          }

          if (element.testId !== testId) {
            return pages;
          }

          if (!pages[0]) {
            pages[0] = pagesCount;
          }

          pages[1] = pagesCount;

          return pages;
        },
        [0, 0],
      );

      const currentPageOfTheTest = firstPageN === 1 ? currentPage - firstPageN : currentPage - firstPageN - 1;
      const lastPageOfTheTest = firstPageN === 1 ? lastPageN - firstPageN + 1 : lastPageN - firstPageN;

      const percentage = (100 * currentPageOfTheTest) / lastPageOfTheTest;

      return {
        testId,
        percentage,
        testColor: test.reportColor || DEFAULT_REPORT_COLOR,
      };
    });
}

export function useProgressHook(): IAssessmentProgress[] {
  const { assessment } = useContext(InviteContext);
  const { currentPage, questionsList } = useContext(CurrentPageContext);

  return useCallback(() => calculateAssessmentProgress(currentPage, questionsList, assessment), [currentPage])();
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DB_USER_KEY, TDbUser } from "@/shared/hooks/auth/currentUser.hook";
import { useGetClientsHook } from "@/shared/hooks/use-get-clients.hook";

import { HttpStatus } from "@/routing/services/statusCodes";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useValidateClientAccessHook(clientId: string) {
  const navigate = useNavigate();
  const user = localStorage.getItem(DB_USER_KEY);
  const userObject: TDbUser = user ? JSON.parse(user) : {};
  const isUserAdmin = userObject.isAdmin;
  const isUserClient = userObject.clientUsers?.length !== 0;
  const { clients } = useGetClientsHook(isUserAdmin || isUserClient, false);
  const userIndex: number | undefined = clients?.findIndex((item) => item.clientId === Number(clientId));
  const [accessibleClient, setAccessibleClient] = useState<{
    state: boolean;
    reason?: number;
  }>({
    state: (isUserAdmin && clients && userIndex !== -1) || (!isUserAdmin && Boolean(userIndex) && userIndex !== -1),
  });

  useEffect(() => {
    if (isNaN(Number(clientId))) {
      setAccessibleClient({ state: false, reason: 404 });
    }
  }, [clientId]);

  useEffect(() => {
    if (!clients) {
      return;
    }
    if (!isUserAdmin && userIndex === -1) {
      setAccessibleClient({ state: false, reason: HttpStatus.FORBIDDEN });
    } else if (isUserAdmin && userIndex === -1) {
      setAccessibleClient({ state: false, reason: HttpStatus.NOT_FOUND });
    } else {
      setAccessibleClient({ state: true });
    }
  }, [userIndex]);

  useEffect(() => {
    if (!accessibleClient.state && accessibleClient.reason) {
      navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [accessibleClient]);

  return { isAccessibleClient: accessibleClient };
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { notification } from "antd";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { IAssessmentTable } from "@/modules/tables/TableAssessments";
import { TestsUtils } from "@/shared/services/tests/tests.utils";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";
import { ASSESSMENT_ROUTE, CLIENT_ROUTE, NEW_ROUTE, NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { AssessmentInviteStatus } from "../AssessmentInvites/invites.constants";
import { useAllClientTestsHook } from "../../hooks/useAllClientTestsHook";
import { useAllAssessmentsHook } from "../../hooks/useAllAssessments.hook";
import { MobileAssessmentsListComponent } from "./assessments-list/mobile-assessments-list.component";
import { DesktopAssessmentsListComponent } from "./assessments-list/desktop-assessments-list.component";
import "./Assessments.css";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { ConfirmCloneModal } from "@/modules/assessments/components/confirm-clone/ConfirmCloneModal";

const DEFAULT_PAGE_SIZE = 10;

export function Assessments() {
  const navigate = useNavigate();
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;
  const { client_id: clientId } = useParams<{
    client_id: string | undefined;
  }>();

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [assessmentSearch, setAssessmentSearch] = useState<string>("");
  const [testIdsFilter, setTestIdsFilter] = useState<number[]>([]);
  const [cloneAssessment, setCloneAssessment] = useState<IAssessmentTable | null>(null);
  const [sortOptions, setSortOptions] = useState<{ column: string | undefined; direction: string | undefined }>({
    column: "createdAt",
    direction: "DESC",
  });
  const [refresh, setRefresh] = useState(false);
  const [tableData, setTableData] = useState<IAssessmentTable[]>([]);
  const { client } = useClientByIdHook(Number(clientId));

  const { assessments, isLoading, totalCount } = useAllAssessmentsHook(
    Number(clientId),
    {
      sortOptions,
      take: pageSize,
      skip: currentPage * pageSize - pageSize,
      search: assessmentSearch,
      testIdsFilter,
    },
    refresh,
  );

  const { tests, isLoading: isLoadingTests } = useAllClientTestsHook(Number(clientId));

  const handleClickNewAssessmentButton = () => {
    navigate(`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${NEW_ROUTE}`);
  };
  const refreshAssessments = () => {
    setRefresh(!refresh);
  };
  const onAssessmentClone = async () => {
    if (!cloneAssessment) return;

    try {
      await AssessmentHttpService.cloneAssessment(Number(clientId), cloneAssessment.assessmentId);
      setCloneAssessment(null);
      notification.success({ message: `${cloneAssessment.name} assessment successfully cloned.` });
      refreshAssessments();
    } catch (err) {
      notification.error({ message: JSON.stringify(err) });
    }
  };
  const onAssessmentSearch = (search: string) => {
    setCurrentPage(1);
    setAssessmentSearch(search);
  };

  const onSortChange = ({ column, direction }: { column: string; direction: string }) => {
    setSortOptions({ column, direction });
  };

  useEffect(() => {
    if (!isValidNumericParam(clientId)) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, [clientId, navigate]);

  useEffect(() => {
    setTableData(
      assessments.map((assessment) => {
        const testStat = TestsUtils.getAssessmentTestsStats(tests, assessment.assessmentTests);
        const assessmentTestIds =
          assessment.assessmentTests?.sort((a, b) => a.rank - b.rank).map((test) => test.testId) || [];
        const clientAssessmentInviteStats = assessment.stats;

        return {
          assessmentId: assessment.assessmentId,
          key: assessment.assessmentId,
          name: assessment.name,
          invited: clientAssessmentInviteStats ? clientAssessmentInviteStats[AssessmentInviteStatus.Invited] : 0,
          inProgress: clientAssessmentInviteStats ? clientAssessmentInviteStats[AssessmentInviteStatus.InProgress] : 0,
          finished: clientAssessmentInviteStats ? clientAssessmentInviteStats[AssessmentInviteStatus.Done] : 0,
          tests: assessment.assessmentTests?.length || 0,
          testNames: assessmentTestIds.length
            ? assessmentTestIds.map((id) => {
                const test = tests.find(({ testId }) => id === testId);
                return maybe(test?.name);
              })
            : [],
          questions: testStat.questions,
          timing: (testStat.timingMs / 1000 / 60).toFixed(0),
          createdAt: assessment.createdAt ? assessment.createdAt : "",
          archivedOn: assessment.archivedOn,
        };
      }),
    );
  }, [assessments, tests]);

  return (
    <div className="main-container">
      <SEO titleParams={[client?.name, "Assessments"]} />

      <div className="inner-container">
        {isDesktopView && (
          <DesktopAssessmentsListComponent
            tableData={tableData}
            handleClickNewAssessmentButton={handleClickNewAssessmentButton}
            pageSize={pageSize}
            currentPage={currentPage}
            setPageSize={setPageSize}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            onSortChange={onSortChange}
            sortOptions={sortOptions}
            loading={Boolean(isLoading || isLoadingTests)}
            onAssessmentSearch={onAssessmentSearch}
            setTestIdsFilter={setTestIdsFilter}
            tests={tests}
            onAssessmentClone={setCloneAssessment}
          />
        )}

        {!isDesktopView && (
          <MobileAssessmentsListComponent
            tableData={tableData}
            handleClickNewAssessmentButton={handleClickNewAssessmentButton}
          />
        )}
      </div>

      <ConfirmCloneModal
        onAssessmentClone={onAssessmentClone}
        isOpen={Boolean(cloneAssessment)}
        assessment={cloneAssessment}
        closeModal={() => setCloneAssessment(null)}
      />
    </div>
  );
}

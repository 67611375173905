import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";

import { UserContext } from "@/shared/contexts/user.context";
import { Loading } from "@/shared/components/Loading/Loading";
import { InviteCard } from "@/shared/components/candidate/scenes/InviteCard/InviteCard";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import useAllTextLocations from "@/shared/components/assessment/AboutAssessment/hooks/useAllTextLocations";

import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import pspLogo from "@/assets/image/psp-logo.svg";

import style from "./style.module.css";

export default function CandidateDashboard() {
  const { user } = useContext(UserContext);

  const [invites, setAllInvites] = useState<IAssessmentInvite[]>();
  const [isLoading, setIsLoading] = useState(true);

  const { textLocations, isLoading: isLoadingTextLocations } = useAllTextLocations();

  useEffect(() => {
    if (!user) {
      return;
    }

    const asyncFunction = async () => {
      try {
        const invites: IAssessmentInvite[] = await InvitesHttpService.getAllUserAssessmentInvites();
        const sortedInvites = invites.sort((a: IAssessmentInvite, b: IAssessmentInvite) =>
          a.updatedAt < b.updatedAt ? 1 : -1,
        );
        setAllInvites(sortedInvites);
      } catch (e: any) {
        console.error("allInvitesError: ", e.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    asyncFunction();
  }, [user?.userId]);

  if (isLoading || isLoadingTextLocations) {
    return <Loading />;
  }

  return (
    <div className="main-container">
      <SEO titleParams={["Invites"]} />
      <div className="inner-container">
        <div className={style.logoContainer}>
          <img src={pspLogo} alt="PSP Metrics" className={cn(style.mobilePspLogo, style.testMobilePspLogo)} />
        </div>
        <Banner title="Assessment Invites" />
        {invites && invites.length === 0 && (
          <div className={style.noInvites}>
            <p>You don't have any accepted invites. </p>
            <br />
            <p>Please, check your mailbox</p>
          </div>
        )}
        <div className={style.invitesList}>
          {invites &&
            invites.length > 0 &&
            invites.map(({ code, status, assessment: { name, assessmentId }, testAnswers, timerDisabled }) => (
              <InviteCard
                key={code}
                state={status}
                inviteCode={code}
                assessmentTitle={name}
                assessmentId={assessmentId}
                textLocations={textLocations}
                testAnswers={testAnswers}
                timerDisabled={timerDisabled}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

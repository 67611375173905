import { useEffect, useState } from "react";

import { IUser } from "@/modules/client/clients.interface";

import { UserHttpService } from "@/shared/services/user/user-http.service";
import { isValidNumericParam } from "@/shared/utils/common.utils";

export function useGetUserHook(userId?: number | null) {
  const [user, setUser] = useState<IUser>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const user: IUser = await UserHttpService.getUserById(Number(userId));
        setUser(user);
      } catch (e) {
        console.error(`Fetching user error, ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(userId)) {
      fetchUser();
    }
  }, [userId]);

  return { user, isLoading };
}

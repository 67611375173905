import { IAssessmentTest, ITestElement } from "@/modules/assessments/types/test.interface";

import { minToSec } from "@/shared/utils/common.utils";
import { sortNumbers } from "@/shared/utils/array/sort.utils";

import { TestAnswerStatusEnum } from "@/shared/services/tests/tests.types";
import { IAnswer, ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { TTestAnswer } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { ITestChoice, QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";

import { getTestElementSection, getTestTimes } from "./section.utils";
import { CandidateAssessmentViewTypes } from "../CandidateAssessment";

export function getMainButtonTitle(isAuthenticated: boolean, inviteStatus?: AssessmentInviteStatus) {
  if (!isAuthenticated) {
    return "register to start";
  }

  return inviteStatus === AssessmentInviteStatus.InProgress || inviteStatus === AssessmentInviteStatus.Started
    ? "continue"
    : "start assessment";
}

export function getViewType(questionsList: ITestElement[][], currentPage: number): CandidateAssessmentViewTypes {
  if (questionsList && questionsList[currentPage - 1]) {
    return CandidateAssessmentViewTypes.test;
  }

  return currentPage === 0 ? CandidateAssessmentViewTypes.start : CandidateAssessmentViewTypes.finish;
}

export function getQuestionElements(questionsList: ITestElement[]) {
  if (!questionsList || !questionsList.length) {
    return [];
  }

  return questionsList?.filter(({ config }) => config.type !== QuestionTypes.Text);
}

export function isAllRequiredQuestionsAnswered(
  currentAnswers: ITestChoice[],
  questionsList: ITestElement[][],
  currentPage: number,
) {
  if (!questionsList[currentPage - 1]?.length) {
    return false;
  }

  return !questionsList[currentPage - 1].some((question) => {
    if (question.config.required) {
      return !currentAnswers.find((answer) => answer.elementId === question.elementId)?.choiceKey;
    }

    return false;
  });
}

export function isAllQuestionsAnswered(
  currentAnswers: ITestChoice[],
  questionsList: ITestElement[][],
  currentPage: number,
) {
  return (
    currentAnswers.filter((answer) => answer.choiceKey).length ===
    questionsList[currentPage - 1].filter((question) => question.config.type !== QuestionTypes.Text).length
  );
}

export function getIfPrevQuestionNewTest(questionsList: ITestElement[][], currentPage: number) {
  if (!currentPage) {
    return false;
  }

  const prevQuestion = questionsList[currentPage - 2];
  const currentQuestion = questionsList[currentPage - 1];

  if (!prevQuestion) {
    return true;
  }

  if (!currentQuestion) {
    return false;
  }

  return currentQuestion[0].testId !== prevQuestion[0].testId;
}

export function getIfNextQuestionNewTest(questionsList: ITestElement[][], currentPage: number) {
  if (!currentPage) {
    return false;
  }

  const currentQuestion = questionsList[currentPage - 1];
  const nextQuestion = questionsList[currentPage];

  if (!currentQuestion) {
    return false;
  }

  if (!nextQuestion) {
    return true;
  }

  return currentQuestion[0].testId !== nextQuestion[0].testId;
}

export function getMissedAnswerQuestionsKeys(questionsList: ITestElement[][], answers: ITestAnswer[], testId?: number, isRedirect = true) {
  const testAnswer: IAnswer[] = answers.reduce((testAnswers: IAnswer[], { answer }) => [...testAnswers, ...answer], []);

  if (!testAnswer) {
    return [];
  }

  const missedQuestionIndexes: number[] = [];

  questionsList.forEach((elements, questionIndex) => {
    if (testId && elements[0].testId !== testId) {
      return;
    }

    const questions = elements.filter(({ config }) => config.type !== QuestionTypes.Text);
    const answeredQuestions = questions.filter(({ config }) =>
      testAnswer.find(({ elementKey, answerKey }) => elementKey === config.key && answerKey),
    );

    const ifNotExistQuestionAnswer = answeredQuestions.length !== questions.length || !answeredQuestions.length;

    if (ifNotExistQuestionAnswer) {
      missedQuestionIndexes.push(questionIndex);
    }
  });

  const sectionsTime = getTestTimes(answers, testId);

  if (!missedQuestionIndexes.length && isRedirect) {
    const testQuestionIndex = questionsList.findIndex((elements) => elements[0].testId === testId);
    return [testQuestionIndex];
  }

  const filteredMissedQuestionIndexes = missedQuestionIndexes.filter((indexPage) => {
    // if page has only Text elements -> ignore it
    if (!questionsList[indexPage].some((element) => element.config.type !== QuestionTypes.Text)) {
      return false;
    }

    if (!testId || !sectionsTime) {
      return true;
    }

    const section = getTestElementSection(questionsList, testId, indexPage + 1);

    if (!section || !section.config.startsSection?.timeLimit) {
      return true;
    }

    const sectionTime = sectionsTime[section.config.key];

    if (!sectionTime) {
      return true;
    }

    return sectionTime.time < minToSec(section.config.startsSection?.timeLimit);
  });

  return filteredMissedQuestionIndexes;
}

export function getIfTheEndOfTheAssessment(questionsList: ITestElement[][], currentPage: number, testId?: number) {
  if (!questionsList[currentPage]) {
    return true;
  }

  return !Boolean(
    questionsList
      .slice(currentPage)
      ?.find(
        (elements) =>
          (elements && elements.find((element) => element.config.startsSection) && elements[0].testId === testId) ||
          (elements[0] && elements[0].testId !== testId),
      ),
  );
}

export function findQuestionAnswers(answers: ITestAnswer[], questions: ITestElement[]) {
  const questionAnswers: ITestChoice[] = [];

  const filteredAnswers = answers.filter(({ testId }) => questions && questions[0]?.testId === testId);

  if (!filteredAnswers) {
    return questionAnswers;
  }

  const questionAnswer = filteredAnswers[0];

  questionAnswer?.answer.forEach(({ elementKey, elementId, answerKey }) => {
    const isQuestionAnswer = questions.find((question) => question.config.key === elementKey);

    if (isQuestionAnswer) {
      questionAnswers.push({ elementId, choiceKey: answerKey });
    }
  });

  return questionAnswers;
}

export function findNextTestQuestions(questionsList: ITestElement[][], testAnswers: TTestAnswer[]) {
  return questionsList.find((questions) => testAnswers.find((testAnswer) => testAnswer.testId !== questions[0].testId));
}

export function findCurrentTestId(
  questionsList: ITestElement[][],
  testAnswers: TTestAnswer[],
  questionsIndex: number,
  assessmentTests?: IAssessmentTest[],
) {
  let testId = 0;

  if (questionsList[questionsIndex]) {
    testId = questionsList[questionsIndex][0].testId;
  }

  if (testAnswers) {
    const testAnswer = testAnswers.find((testAnswer) => testAnswer.testId === testId);

    if (testAnswer && testAnswer.status === TestAnswerStatusEnum.Done && assessmentTests?.length) {
      if (testAnswers.find((testAnswer) => testAnswer.testId === questionsList[questionsIndex][0].testId)) {
        const nextTestQuestions = findNextTestQuestions(questionsList.slice(questionsIndex), testAnswers);

        if (nextTestQuestions && nextTestQuestions.length) {
          testId = nextTestQuestions[0].testId;
        }
      }

      const sortedAssessmentTests = assessmentTests.sort((a, b) => sortNumbers(a.rank, b.rank));

      const allowedAssessmentTest = sortedAssessmentTests.find((assessmentTest) =>
        testAnswers.find(
          (tAnswer) => tAnswer.testId === assessmentTest.testId && tAnswer.status !== TestAnswerStatusEnum.Done,
        ),
      );

      if (allowedAssessmentTest) {
        testId = allowedAssessmentTest.testId;
      } else {
        const notFinishedTests = sortedAssessmentTests.filter(
          (assessmentTest) =>
            !testAnswers.find(
              (tAnswer) => tAnswer.testId === assessmentTest.testId && tAnswer.status === TestAnswerStatusEnum.Done,
            ),
        );

        if (notFinishedTests.length && notFinishedTests[0]) {
          testId = notFinishedTests[0].testId;
        }
      }
    }
  }

  return testId;
}

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "@/shared/contexts/user.context";
import { useNavigate, useParams } from "react-router-dom";
import { ASSESSMENT_ROUTE, CANDIDATE_ROUTE, SELF_REGISTER_ROUTE } from "@/routing/AppRouter/routes.constants";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";

export function AssessmentSelfRegister() {
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);
  const { assessmentId } = useParams();
  const isAuthenticated: boolean = Boolean(user && user.userId);
  const [inviteCode, setInviteCode] = useState<string | null>(null);
  const createInvite = async () => {
    const data = await AssessmentHttpService.createInvite(Number(assessmentId));

    return data.data.code && setInviteCode(data.data.code);
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(`/${CANDIDATE_ROUTE}/${ASSESSMENT_ROUTE}/${assessmentId}/${SELF_REGISTER_ROUTE}`);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      createInvite();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (inviteCode) {
      navigate(`/${CANDIDATE_ROUTE}/invites/${inviteCode}`);
    }
  }, [inviteCode]);

  return null;
}

import classNames from "classnames";
import MarkdownPreview, { MarkdownPreviewProps } from "@uiw/react-markdown-preview";

import styles from "./style.module.css";

export default function MarkdownPreviewCustom({ source, style, className, ...props }: MarkdownPreviewProps) {
  return (
    <div className={styles.wrapper}>
      <MarkdownPreview
        {...props}
        source={source}
        style={style}
        className={classNames(styles.markdownBaseView, className)}
      />
    </div>
  );
}

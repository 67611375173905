import * as React from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { IAssessmentInviteLog, useGetAssessmentInviteLogs } from "@/shared/hooks/use-get-invite-logs";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";

import { AssessmentLogsService } from "@/modules/assessments/services/assessment-logs.service";

import style from "./style.module.css";

const columns: ColumnsType<IAssessmentInviteLog> = [
  {
    key: "eventId",
    title: "Event",
    dataIndex: "eventId",
    className: style.tableColumn,
    render: (value, record) => AssessmentLogsService.getAuditLogCaption(value, record.test?.name),
  },
  {
    key: "createdAt",
    title: "On",
    dataIndex: "createdAt",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
  },
  {
    key: "after",
    title: "After",
    dataIndex: "after",
    className: style.tableColumn,
  },
];

export default function TableAuditAssessmentLog() {
  const { invite_id: inviteId } = useParams();
  const { assessmentInviteLogs, isLoading } = useGetAssessmentInviteLogs(Number(inviteId));

  return (
    <div className={cn(style.auditTable, style.firstTable)}>
      <TableHeader tableName="Assessment Logs" hasSearchField={false} />
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={assessmentInviteLogs}
        style={{ width: "100%" }}
        pagination={{
          position: ["bottomLeft"],
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

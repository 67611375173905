import { ReactElement, useState } from "react";
import cn from "classnames";

import style from "./style.module.css";

export interface IBannerProps {
  title: string;
  description?: string;
  children?: ReactElement<any, any>;
}

export function Banner({ title, description, children }: IBannerProps) {
  const MAX_DESCRIPTION_WORDS_QUANTITY = 50;

  const [isExpanded, setIsExpanded] = useState(false);

  const descriptionWordsArray = description?.split(" ");

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className={style.container}>
      <p className={cn(style.title, style.toLeftSide)}>{title}</p>
      {children}
      {description ? (
        <p className={cn(style.description, style.toLeftSide)}>
          {isExpanded
            ? description
            : descriptionWordsArray && descriptionWordsArray.slice(0, MAX_DESCRIPTION_WORDS_QUANTITY).join(" ")}
          {descriptionWordsArray && descriptionWordsArray.length > MAX_DESCRIPTION_WORDS_QUANTITY && (
            <span className={style.expandButton} onClick={toggle} role={"button"}>
              {isExpanded ? " See less" : "... See more"}
            </span>
          )}
        </p>
      ) : null}
    </div>
  );
}

import { PermissionsHttpService } from "@/shared/services/permissions/permissions-http.service";
import { useCacheResult } from "@/shared/hooks/use-fetch.hook";

export function useGetClientUserRolesHook(userId?: number, clientId?: number, canExecute = true) {
  const { data, isLoading } = useCacheResult(
    `CLIENT${clientId ?? ""}_USER_${userId ?? ""}_ROLES`,
    PermissionsHttpService.getClientUserRoles,
    [clientId],
    [userId, clientId],
    canExecute,
  );

  return {
    isLoading,
    clientRoles: data,
  };
}

import { IMenuItem, IReportSidebarMenu } from "../ReportSidebarMenu/ReportSidebarMenu";
import { ReportContentView } from "../ReportComponent/ReportComponent";

interface IReportMenuConditions extends Omit<IReportSidebarMenu, "onMenuClick"> {
  abilities: {
    canManageAssessment: boolean;
  };
}

function getItem(label: string, key: ReportContentView): IMenuItem {
  return {
    key,
    label,
  };
}

export function generateMenu({
  isDone,
  isScored,
  isViewerAdmin,
  isCompareView,
  hasNarrative,
  showOverall,
  showRadar,
  abilities: { canManageAssessment },
}: IReportMenuConditions): IMenuItem[] {
  const items: IMenuItem[] = [];

  if (isDone && !isCompareView && isScored && showOverall) {
    items.push(getItem("Overall", ReportContentView.overall));
  }
  if (isDone && showRadar) {
    items.push(getItem("Radar", ReportContentView.radar));
  }
  if (isDone) {
    items.push(getItem("Bar", ReportContentView.bar));
  }
  if (isDone && !isCompareView && isViewerAdmin) {
    items.push(getItem("Analysis", ReportContentView.analysis));
  }
  if (!isCompareView && (isViewerAdmin || canManageAssessment)) {
    items.push(getItem("Audit", ReportContentView.audit));
  }
  if (isDone && !isCompareView && (isViewerAdmin || hasNarrative)) {
    items.push(getItem("Narrative", ReportContentView.narrative));
  }

  return items;
}

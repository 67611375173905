import React from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ITextLocation } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import CandidateTestElement from "@/modules/candidate/assessment/CandidateAssessment/components/CandidateTestElement/CandidateTestElement";
import { CandidateAssessmentViewTypes } from "@/modules/candidate/assessment/CandidateAssessment/CandidateAssessment";
import { getTestsProps } from "@/modules/candidate/utils/candidateAssessment.utils";

import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import WelcomeElement from "@/shared/components/candidate/scenes/WelcomeElement/WelcomeElement";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

interface IContentView {
  assessment: IAssessment | undefined;
  textLocations: ITextLocation[];
  viewType: CandidateAssessmentViewTypes;
  currentPage: number;
  isLoadingTestInstructions: boolean;
  currentAnswers: ITestChoice[];
  buttonTitle: string;
  setIsOpenedInstructions: (value: boolean) => void;
  handleChoiceSelect: (value: ITestChoice) => void;
  handleSubmitClick: () => Promise<void>;
  timerDisabled?: boolean;
}

export default function ContentView({
  assessment,
  textLocations,
  viewType,
  currentPage,
  currentAnswers,
  isLoadingTestInstructions,
  buttonTitle,
  setIsOpenedInstructions,
  handleChoiceSelect,
  handleSubmitClick,
  timerDisabled = false,
}: IContentView) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  const {
    title,
    testsQuantity,
    questionsQuantity,
    leftTime,
    introText,
    outroText,
    questionsList,
    maxTestIndexInModule,
  } = getTestsProps(assessment, textLocations);

  if (questionsList && maxTestIndexInModule) {
    return questionsList[currentPage - 1] ? (
      <CandidateTestElement
        currentPage={currentPage}
        isLoadingTestInstructions={isLoadingTestInstructions}
        currentAnswers={currentAnswers}
        setIsOpenedInstructions={setIsOpenedInstructions}
        handleChoiceSelect={handleChoiceSelect}
        timerDisabled={timerDisabled}
      />
    ) : currentPage === 0 ? (
      <WelcomeElement
        type={viewType}
        title={title}
        description={introText}
        testsQuantity={testsQuantity}
        questionsQuantity={questionsQuantity}
        leftTime={leftTime}
        timerDisabled={timerDisabled}
      >
        {isDesktopView ? <CustomButton title={buttonTitle} onClick={handleSubmitClick} /> : null}
      </WelcomeElement>
    ) : (
      <WelcomeElement type={viewType} description={outroText} />
    );
  }

  return <></>;
}

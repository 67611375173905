import { hasClientsState, hasOneClientState } from "@/shared/utils/auth/auth.utils";
import { useGetClientsHook } from "@/shared/hooks/use-get-clients.hook";
import { TDbUser } from "@/shared/hooks/auth/currentUser.hook";

export function useAdminInitializer({
  user,
  isSignedIn,
  isLoadingAuth,
}: {
  isSignedIn: boolean;
  isLoadingAuth: boolean;
  user?: TDbUser;
}) {
  const isAdmin = isSignedIn && Boolean(user?.isAdmin);
  const isClient = hasClientsState(isSignedIn, user);

  const hasOneClient = hasOneClientState(isSignedIn, user);
  const clientId = (hasOneClient && user?.clientUsers[0].clientId) || undefined;

  // TODO: state should be moved to global scope
  const { isLoading: isLoadingClients } = useGetClientsHook(isAdmin || isClient, isLoadingAuth || !isSignedIn);

  const isLoading = isLoadingClients;

  return {
    isAdmin,
    isClient,
    clientId,
    isLoading,
  };
}

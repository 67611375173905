import React from "react";
import cn from "classnames";
import { Divider } from "antd";
import MarkdownPreview from "@uiw/react-markdown-preview";

import {
  IBody,
  IQuestion,
  ISectionConfig,
  ITestElement,
  LanguagesIsoEnum,
} from "@/modules/assessments/types/test.interface";

import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { minutesToHms } from "@/shared/utils/common.utils";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";

import style from "./style.module.css";

export interface IQuestionItemProps {
  testElement: ITestElement;
  nextElementStartSectionConfig?: ISectionConfig;
  numberOfQuestions: number;
}

export function QuestionItem({ testElement, nextElementStartSectionConfig, numberOfQuestions }: IQuestionItemProps) {
  const elementPayload = testElement.config.payload.question
    ? testElement.config.payload.question
    : testElement.config.payload.body;

  const dividerTitle = testElement.config.startsSection
    ? `${testElement.config.startsSection.name} (${maybe(
        testElement.config.startsSection.timeLimit,
        minutesToHms,
        "no section time limit",
      )})`
    : null;

  const getTestElementRepresentation = (questionItemType: QuestionTypes, question: IQuestion | IBody) => {
    switch (questionItemType) {
      case QuestionTypes.SD:
        return (
          <p className={style.questionItem}>
            {question[LanguagesIsoEnum.enUS][0]} / {question[LanguagesIsoEnum.enUS][1]}
          </p>
        );
      case QuestionTypes.Text:
        return <MarkdownPreview source={String(question[LanguagesIsoEnum.enUS])} style={{ fontWeight: 400 }} />;
      case QuestionTypes.RadioImage:
        return (
          <MarkdownPreviewCustom className={style.questionItem} source={String(question[LanguagesIsoEnum.enUS])} />
        );
      case QuestionTypes.FollowNotFollow:
      case QuestionTypes.CorrectIncorrect:
      case QuestionTypes.LikelyTrueFalse:
      case QuestionTypes.MadeNotMade:
      case QuestionTypes.StrongWeak:
      case QuestionTypes.TrueFalse:
      case QuestionTypes.Radio:
      case QuestionTypes.RadioHorizontal:
      default:
        return <p className={style.questionItem}>{question[LanguagesIsoEnum.enUS]}</p>;
    }
  };

  return (
    <div key={testElement.config.key} className={style.questionItemContainer}>
      {testElement.config.startsSection && (
        <Divider className={cn(style.questionDivider, style.boldQuestionDivider)}>{dividerTitle}</Divider>
      )}
      <div className={style.inRow}>
        {getTestElementRepresentation(testElement.config.type, elementPayload)}
        <div className={style.questionItemAboutContainer}>
          <p className={style.questionItemAbout}>
            Element: {testElement.rank}/{numberOfQuestions}
          </p>
        </div>
      </div>
      {!nextElementStartSectionConfig && (
        <Divider
          className={cn(style.questionDivider, { [style.boldQuestionDivider]: testElement.config.breakAfter })}
        />
      )}
    </div>
  );
}

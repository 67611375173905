import React from "react";
import cn from "classnames";
import { Layout, Tooltip } from "antd";
import pspLogo from "@/assets/image/psp-logo.svg";
import { REQUIRED_QUESTIONS_MESSAGE } from "./candidate-assessment.constants";
import { CandidateAssessmentViewTypes, ICandidateAssessmentView } from "./CandidateAssessment";
import style from "./components/CandidateTestElement/style.module.css";

const { Footer } = Layout;

export default function CandidateAssessmentMobile({
  viewType,
  children,
  childrenSubmitButton,
  childrenNextButton,
  childrenBackButton,
  assessmentImageUrl,
  clientLogo,
  clientName,
  disabled,
  setIsVisibleTooltip,
}: ICandidateAssessmentView) {
  const backgroundColor = viewType === CandidateAssessmentViewTypes.start ? "#ffffff" : "#F5F3ED";
  const containerPadding = viewType === CandidateAssessmentViewTypes.start ? "0" : "15px 10px";

  return (
    <div className={style.container} style={{ backgroundColor: backgroundColor, padding: containerPadding }}>
      {viewType === CandidateAssessmentViewTypes.start && (
        <div className={cn(style.mobileWrapper, style.welcomeStartElement)}>
          <div className={style.mobileImageContainer}>
            {assessmentImageUrl && <img src={assessmentImageUrl} alt="" className={cn(style.mobileImage)} />}
          </div>
          <div className={style.mobileContentContainer}>
            <div className={cn(style.logoContainer, style.mobileLogoContainerHeight)}>
              {clientLogo && <img src={clientLogo} alt={clientName} />}
              <div className={style.mobileLogoContainerHeight}>
                <p className={style.mobileLogoFontSize}>Provided by</p>
                <img src={pspLogo} alt="PSP Metrics" className={style.mobilePspLogo} />
              </div>
            </div>
          </div>
          {children}
        </div>
      )}

      {(viewType === CandidateAssessmentViewTypes.test || viewType === CandidateAssessmentViewTypes.finish) && (
        <div className={style.mobileWrapper}>
          <div className={style.logoContainer}>
            <img src={pspLogo} alt="PSP Metrics" className={cn(style.mobilePspLogo, style.testMobilePspLogo)} />
          </div>
          <div
            className={cn(style.testWrapper, {
              [style.welcomeFinishElement]: viewType === CandidateAssessmentViewTypes.finish,
            })}
          >
            {assessmentImageUrl && (
              <div className={style.testImageContainer}>
                <img src={assessmentImageUrl} alt="" className={cn(style.testImage)} />
              </div>
            )}
            <div className={cn(style.testCompanyLogoContainer, style.mobileLogoContainerHeight)}>
              {clientLogo && <img src={clientLogo} alt={clientName} className={style.mobileLogoContainerHeight} />}
            </div>
            {children}
            {viewType === CandidateAssessmentViewTypes.test && (
              <div className={`${style.paginationColor} ${style.testButtonContainer}`}>
                <Tooltip
                  title={REQUIRED_QUESTIONS_MESSAGE}
                  trigger={["hover", "click"]}
                  visible={disabled}
                  onVisibleChange={(t) => setIsVisibleTooltip && setIsVisibleTooltip(t)}
                >
                  <span>{childrenNextButton}</span>
                </Tooltip>
                <div>{childrenBackButton}</div>
              </div>
            )}
          </div>
        </div>
      )}

      {viewType === CandidateAssessmentViewTypes.start && (
        <Footer className={style.mobileFooter}>{childrenSubmitButton}</Footer>
      )}
    </div>
  );
}

//TODO: rename accepted to claimed
export enum AssessmentInviteStatus {
  Invited = "invited",
  Accepted = "accepted",
  WithAnError = "with_an_error",
  InProgress = "in_progress",
  Done = "done",
  Started = "started",
  Rescinded = "rescinded",
}

export enum InvitesFit {
  best = "Best",
  middle = "Middle",
  worst = "Worst",
}

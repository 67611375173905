import { useEffect, useState } from "react";

import { TestInstructionsHttpService } from "@/shared/services/tests/test-instructions-http.service";
import { isValidNumericParam } from "@/shared/utils/common.utils";

export function useGetTestInstructions(testId: number | undefined, currentPage: number) {
  const [testInstructions, setTestInstructions] = useState<{
    [testId: string]: { [language: string]: string };
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(currentPage > 0);

  useEffect(() => {
    const asyncFunction = async () => {
      try {
        if (testId) {
          setIsLoading(true);
          const testInstructions = await TestInstructionsHttpService.getTestInstructionsByTestIds([testId]);
          setTestInstructions(testInstructions);
        }
      } catch (e: any) {
        console.error(`testInstructions, ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(testId)) {
      asyncFunction();
    }
  }, [testId]);

  return {
    isLoading,
    testInstructions,
  };
}

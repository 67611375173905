import { createContext } from "react";

import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

import { ITest } from "@/modules/assessments/types/test.interface";
import { IAssessment } from "@/modules/assessments/assessments.types";

export interface ICurrentPageContext {
  currentTest?: ITest;
  invite?: IAssessmentInvite;
  assessment?: IAssessment;
}

export const InviteContext = createContext<ICurrentPageContext>({});

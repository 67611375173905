import React, { useEffect, useState } from "react";
import cn from "classnames";
import { IAssessment } from "@/modules/assessments/assessments.types";
import { ChartTypes } from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";
import { Overview } from "@/modules/assessments/scenes/AssessmentReport/Overview/Overview";
import Suggestions from "@/modules/assessments/scenes/AssessmentReport/Suggestions/Suggestions";
import { StatementsMap } from "@/modules/assessments/services/scales.service";
import { IClient, IUser } from "@/modules/client/clients.interface";
import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { IMultiChart, ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import CategorizedCharts from "@/shared/components/charts/CategorizedCharts/CategorizedCharts";
import { IRadarResponsiveProps } from "@/shared/components/charts/RadarChart/RadarChartComponent";
import { IBarResponsiveProps } from "@/shared/components/charts/BarChart/BarChartComponent";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { formatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import PSPLogo from "@/assets/image/psp-logo.svg";
import style from "./style.module.css";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";

interface IReportToPrint {
  client?: Pick<IClient, "logoUrl" | "name">;
  candidate?: Pick<IUser, "fullName">;
  jobTitle?: string;
  roleName?: string;
  showRadar?: boolean;
  assessmentInvite?: Pick<IAssessmentInvite, "finishedOn">;
  assessment: Pick<IAssessment, "assessmentTests" | "name">;
  answers: ITestAnswer[];
  statements: StatementsMap;
  chartData: IMultiChart[];
  isScored: boolean;
  narrativeContent?: string | null;
}

export function ReportToPrint({
  candidate,
  client,
  jobTitle,
  roleName,
  showRadar = true,
  assessmentInvite,
  assessment,
  answers,
  statements,
  chartData,
  isScored,
  narrativeContent,
}: IReportToPrint) {
  const MAX_SELECTED_COMPETENCIES: number = 8;
  const [isLargeChart, setLargeChart] = useState<boolean>(false);
  const radarResponsiveProps: { sm: IRadarResponsiveProps; lg: IRadarResponsiveProps } = {
    sm: {
      margin: { top: 10, right: 80, left: 80, bottom: 10 },
      labelWidth: 100,
      containerWidth: "50%",
    },
    lg: {
      margin: { top: 10, right: 150, left: 150, bottom: 10 },
      labelWidth: 200,
      containerWidth: "100%",
    },
  };

  const barResponsiveProps: { sm: IBarResponsiveProps; lg: IBarResponsiveProps } = {
    sm: {
      labelWidth: 100,
      containerWidth: "100%",
      barHeight: 36,
    },
    lg: {
      labelWidth: 250,
      containerWidth: "100%",
      barHeight: 24,
    },
  };

  useEffect(() => {
    if (chartData.length !== 0) {
      setLargeChart(chartData[0].chartData.length > MAX_SELECTED_COMPETENCIES);
    }
  }, [chartData]);

  return (
    <div className={style.printReportContainer}>
      <table>
        <thead>
          <tr>
            <td>
              <div className={style.emptyHeader}></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className={style.reportContent}>
                <div className={style.assessmentDetails}>
                  <p className={style.assessmentName}><strong>{assessment.name}</strong></p>
                  <p className={style.jobDetail}><strong>Company Job Title:</strong> {jobTitle}</p>
                  <p className={style.jobDetail}><strong>Job Role:</strong> {roleName}</p>
                </div>
                {isScored && <Overview assessmentTests={assessment.assessmentTests} statements={statements} />}
                <div className={cn(style.charts, { [style.chartsInRow]: !isLargeChart })}>
                  {showRadar && (
                    <CategorizedCharts
                      chartMultiData={chartData}
                      chartType={ChartTypes.radar}
                      scene={ReportScene.print}
                      radarStyles={isLargeChart ? radarResponsiveProps.lg : radarResponsiveProps.sm}
                    />
                  )}

                  <div style={{ width: isLargeChart ? "100%" : "50%" }}>
                    {chartData.length !== 0 && (
                      <CategorizedCharts
                        chartMultiData={chartData}
                        chartType={ChartTypes.bar}
                        scene={ReportScene.print}
                        barStyles={isLargeChart ? barResponsiveProps.lg : barResponsiveProps.sm}
                      />
                    )}
                  </div>
                </div>
                <div className={style.reportSuggestions}>
                  <Suggestions assessmentTests={assessment.assessmentTests} answers={answers} />
                </div>
              </div>
            </td>
          </tr>
          {narrativeContent && (
            <tr>
              <td>
                <div>
                  <h2>Narrative</h2>
                  <MarkdownPreviewCustom source={narrativeContent} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className={style.emptyFooter}></div>
            </td>
          </tr>
        </tfoot>
      </table>
      <header className={style.reportHeader}>
        <div className={style.nameMatchRow}>
          <p className={style.candidateName}>{candidate?.fullName}</p>
          {/*TODO: use JobMath component*/}
          {/*//Hide Overall Job Match*/}
          {/*{typeof assessmentInvite?.netScore !== "undefined" && isScored && (*/}
          {/*  <div>*/}
          {/*    /!*TODO: change to JobMatch component*!/*/}
          {/*    <h1*/}
          {/*      className={cn(*/}
          {/*        style.match,*/}
          {/*        matchStyle[AssessmentService.getOverallScoreLabel(assessmentInvite.netScore)],*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      <span style={{ fontWeight: 400 }}>Overall Job Match: </span>*/}
          {/*      {AssessmentService.getOverallScoreLabel(assessmentInvite.netScore)}*/}
          {/*    </h1>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </header>

      <footer className={style.reportFooter}>
        <div className={style.footerWrapper}></div>
        <div className={style.footerRowContainer}>
          <img src={PSPLogo} alt="PSP Metrics" className={style.logoContainer} />
          <img src={client?.logoUrl} alt={`${client?.name}-logo`} className={style.logoContainer} />
        </div>
        <div className={style.footerRowContainer}>
          <p>Copyright &copy; {formatDate(new Date().toISOString(), "YYYY")} PSP Metrics</p>
          {assessmentInvite?.finishedOn && (
            <p>Finished On {formatDate(assessmentInvite.finishedOn, "MMMM Do, YYYY")}</p>
          )}
        </div>
      </footer>
    </div>
  );
}

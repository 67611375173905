import * as firebaseui from "firebaseui";
import { Auth, getAuth, GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";

import { FIREBASE_CONFIG } from "./firebase.constants";
import { FirebaseInterface } from "./firebase.interface";

const app = firebase.initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);

export class Firebase implements FirebaseInterface {
  public readonly auth: Auth = auth;
  public uiConfig: firebaseui.auth.Config;

  public constructor(singInRedirectUrl?: string) {
    const signInSuccessUrl = singInRedirectUrl || "/";

    this.uiConfig = {
      signInSuccessUrl,
      signInFlow: "popup",
      signInOptions: [GoogleAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult() {
          return false;
        }, // do not automatically redirect
      },
    };
  }

  public async getToken(forceRefresh?: boolean): Promise<string | undefined> {
    return this.auth?.currentUser?.getIdToken(forceRefresh ?? true);
  }

  public async isExpiredToken(): Promise<boolean> {
    const token = await this.auth.currentUser?.getIdTokenResult();
    if (!token) return true;
    return new Date(token.expirationTime) <= new Date();
  }

  signOut(): Promise<void> {
    return this.auth.signOut();
  }
}

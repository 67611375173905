import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tabs } from "antd";
import ClientTeam from "@/modules/admin/clientManagement/ClientTeam/ClientTeam";
import ClientTextsSettings from "@/modules/admin/clientManagement/ClientTextsSettings/ClientTextsSettings";
import CompetenciesClientSettings from "@/modules/admin/clientManagement/CompetenciesClientSettings/CompetenciesClientSettings";
import GeneralClientSettings from "@/modules/admin/clientManagement/GeneralClientSettings/GeneralClientSettings";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { Loading } from "@/shared/components/Loading/Loading";
import { capitalizeFirstLetter } from "@/shared/utils/common.utils";
import { getRoute } from "@/shared/utils/route/route.utils";
import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";
import {
  ASSESSMENT_ROUTE,
  CLIENT_COMPETENCIES_ROUTE,
  CLIENT_ROUTE,
  CLIENT_TEXTS_ROUTE,
  SETTINGS_ROUTE,
  USER_ROUTE,
} from "@/routing/AppRouter/routes.constants";

import style from "./style.module.css";

const { TabPane } = Tabs;

export function ClientSettings() {
  const { client_id: clientId } = useParams();
  const DEFAULT_CLIENT_SETTINGS_ROUTE = `/${CLIENT_ROUTE}/${clientId}/${SETTINGS_ROUTE}`;
  const { pathname } = useLocation();
  const pathList = pathname.split("/");
  const lastRouteParameter = pathList[pathList.length - 1];
  const activeTab = !lastRouteParameter ? SETTINGS_ROUTE : lastRouteParameter;
  const { client, isLoading: clientLoading } = useClientByIdHook(Number(clientId));
  const clientUsersCount = client?.clientUsers?.length;
  const clientAbility = useContext(ClientAbilityContext);
  const canViewClientSettings = clientAbility.can(Action.Read, Subject.CLIENT);
  const canUpdateClientCompetencyConfig = clientAbility.can(Action.Update, Subject.CLIENT, "config");
  const canUpdateClientText = clientAbility.can(Action.Update, Subject.CLIENT_TEXT);

  useRouteAccessController({
    slug: SETTINGS_ROUTE,
    hasPermission: canViewClientSettings,
    redirectPath: `${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}`,
  });

  useRouteAccessController({
    slug: CLIENT_COMPETENCIES_ROUTE,
    hasPermission: canUpdateClientCompetencyConfig,
  });

  useRouteAccessController({
    slug: CLIENT_TEXTS_ROUTE,
    hasPermission: canUpdateClientText,
  });

  if (clientLoading) {
    return <Loading />;
  }

  return (
    <div className="main-container">
      <SEO titleParams={[client?.name, lastRouteParameter ? capitalizeFirstLetter(lastRouteParameter) : "Settings"]} />
      <div className="inner-container">
        <Banner title={"Settings"} />

        <div className={style.container}>
          <Tabs activeKey={activeTab}>
            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_CLIENT_SETTINGS_ROUTE, "")} className={style.tabLink}>
                  General
                </Link>
              }
              key={SETTINGS_ROUTE}
            >
              <GeneralClientSettings client={client} isClientLoading={clientLoading} />
            </TabPane>

            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_CLIENT_SETTINGS_ROUTE, USER_ROUTE)} className={style.tabLink}>
                  Team{clientUsersCount ? ` (${clientUsersCount})` : ""}
                </Link>
              }
              key={USER_ROUTE}
            >
              <ClientTeam />
            </TabPane>

            {canUpdateClientCompetencyConfig && (
              <TabPane
                tab={
                  <Link
                    to={getRoute(DEFAULT_CLIENT_SETTINGS_ROUTE, CLIENT_COMPETENCIES_ROUTE)}
                    className={style.tabLink}
                  >
                    Competencies
                  </Link>
                }
                key={CLIENT_COMPETENCIES_ROUTE}
              >
                <CompetenciesClientSettings />
              </TabPane>
            )}

            {canUpdateClientText && (
              <TabPane
                tab={
                  <Link to={getRoute(DEFAULT_CLIENT_SETTINGS_ROUTE, CLIENT_TEXTS_ROUTE)} className={style.tabLink}>
                    Texts
                  </Link>
                }
                key={CLIENT_TEXTS_ROUTE}
              >
                <ClientTextsSettings />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useDispatch } from "react-redux";
import MDEditor from "@uiw/react-md-editor";
import { Alert, Collapse, Divider, Form, Input, Timeline, Switch } from "antd";
import cn from "classnames";

import { formItemLayout } from "@/shared/components/assessment/AboutAssessment/AboutAssessment";
import { ICompetency } from "@/shared/components/competencies/types";
import { CompetencyFormulasUtils } from "@/shared/components/competencies/competency-formulas.utils";
import { SubmitStatuses } from "@/shared/components/testManagement/TestElementInfoBlock";
import { updateCompetencyName } from "@/shared/services/store/competenciesStore";

import { CompetencyHttpService } from "@/modules/assessments/scenes/AddTests/services/competency-http.service";

import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { AceSupportedLanguages, CodeEditor } from "@/ui-components/commonComponents/CodeEditor/CodeEditor";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import "@/shared/components/assessment/AboutAssessment/index.css";
import mdStyle from "@/shared/components/assessment/AboutAssessment/style.module.css";
import style from "./style.module.css";

export const MAX_AMOUNT_HISTORY_FORMULAS = 5;

export function EditCompetencyForm({
  canEditDefaultEnabled,
  competency: { competencyId, name, competencyFormulas, interviewQuestions, explanation, key, isDefaultEnabled },
  refreshData,
}: {
  canEditDefaultEnabled: boolean;
  competency: ICompetency;
  refreshData: (param: any) => any;
}) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const competencyFormula = CompetencyFormulasUtils.getLatestFormulaVersion([...competencyFormulas]);

  const [enteredFormula, setEnteredFormula] = useState<string>(competencyFormula ? competencyFormula.formula : "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);

  const handleSaveClick = async (values: {
    name: string;
    formula: string;
    interviewQuestions: string;
    explanation: string;
    isDefaultEnabled: boolean;
  }) => {
    try {
      if (!values || !values.formula) return;

      setIsLoading(true);
      await CompetencyHttpService.updateCompetencyById(competencyId, {
        name: values.name,
        formula: values.formula,
        explanation: values.explanation ? values.explanation.trim() : values.explanation,
        interviewQuestions: values.interviewQuestions ? values.interviewQuestions.trim() : values.interviewQuestions,
        isDefaultEnabled: values.isDefaultEnabled,
      });
      dispatch(updateCompetencyName({ key, name: values.name }));
      setAlert(SubmitStatuses.success);
      refreshData(false);
    } catch (e: any) {
      setAlert(SubmitStatuses.error);
      console.error(`competency patch formula ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormulaChange = (e: string) => {
    setEnteredFormula(e);
    form.setFieldsValue({ formula: e });
  };

  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert showIcon message="Something went wrong. Please, retry later." type="error" />;
      case SubmitStatuses.success:
        return <Alert showIcon message="Success" type="success" />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };

  return (
    <div className={style.competencyFormContainer}>
      <Form
        form={form}
        name="editCompetency"
        {...formItemLayout}
        onFinish={handleSaveClick}
        requiredMark={false}
        initialValues={{
          name,
          interviewQuestions: interviewQuestions || "",
          explanation: explanation || "",
          formula: competencyFormula?.formula,
          isDefaultEnabled,
        }}
      >
        <div className={style.competencyHeaderBlock}>
          <Form.Item name="name" key="competencyName">
            <Input bordered={false} className={style.competencyName} />
          </Form.Item>
          <div className={style.competencySwitchGroup}>
            <p>Default to:</p>
            <p>Unselected</p>
            <Form.Item name="isDefaultEnabled" valuePropName="checked">
              <Switch disabled={!canEditDefaultEnabled} aria-label="Enable by default" />
            </Form.Item>
            <p>Selected</p>
          </div>
        </div>
        <Form.Item
          className={cn("inputTitle", mdStyle.mdEditor)}
          key="competencyExplanation"
          name="explanation"
          label="Explanation"
        >
          <MDEditor highlightEnable={false} textareaProps={{ placeholder: "Type..." }} />
        </Form.Item>
        <Form.Item
          className={cn("inputTitle", mdStyle.mdEditor)}
          key="competencyInterviewQuestions"
          name="interviewQuestions"
          label="Interview Questions"
        >
          <MDEditor highlightEnable={false} textareaProps={{ placeholder: "Type..." }} />
        </Form.Item>
        <Form.Item
          className="inputTitle"
          key="competencyFormula"
          name="formula"
          label={`Formula (v. ${competencyFormula?.version})`}
          rules={[
            {
              required: true,
              message: "Please Enter Competency Formula",
            },
          ]}
        >
          <CodeEditor
            editorMode={AceSupportedLanguages.JS}
            uniqueName={`formula_${competencyId}`}
            defaultContent={enteredFormula}
            onEditorChange={handleFormulaChange}
          />
        </Form.Item>

        {/* TODO: Create separated component CompetencyFormulaHistory */}
        <Timeline>
          <Collapse ghost collapsible="header">
            <Collapse.Panel header="History" key="history">
              {CompetencyFormulasUtils.sortDescending([...competencyFormulas])
                .slice(0, MAX_AMOUNT_HISTORY_FORMULAS)
                .map(({ version, formula }, index) => (
                  <Timeline.Item color={index === 0 ? "green" : "blue"} key={`${competencyId}_ver_${version}`}>
                    <p>Version: {version}</p>
                    <p>Formula: {formula}</p>
                  </Timeline.Item>
                ))}
            </Collapse.Panel>
          </Collapse>
        </Timeline>
        <div className={style.buttonContainer}>
          <CustomButton
            title="Save"
            isLoading={isLoading}
            buttonSize={ButtonSize.XSmall}
            disabled={!competencyFormula?.formula}
          >
            <SaveSVG aria-hidden="true" style={{ width: "16px", height: "16px" }} />
          </CustomButton>
          <div className={style.alertContainer}>{getAlert(alert)}</div>
        </div>
      </Form>
      <Divider />
    </div>
  );
}

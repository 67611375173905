import { ReactElement } from "react";

import style from "./style.module.css";

interface IParagraphWithIcon {
  children: ReactElement<any, any>;
  text: string;
}

export default function ParagraphWithIcon({ children, text }: IParagraphWithIcon) {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {children}
        <p>{text}</p>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { TestInstructionsHttpService } from "@/shared/services/tests/test-instructions-http.service";

export function useGetTestInstructions(testId: string | undefined) {
  const [testInstructions, setTestInstructions] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTestInstructions = async () => {
      try {
        setIsLoading(true);

        const testInstructions = await TestInstructionsHttpService.getTestInstructionsByTestId(testId);

        setTestInstructions(testInstructions);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
      }
    };

    fetchTestInstructions();
  }, []);

  return { testInstructions, isLoading };
}

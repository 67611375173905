import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";
import useAllTextLocations from "@/shared/components/assessment/AboutAssessment/hooks/useAllTextLocations";
import { Loading } from "@/shared/components/Loading/Loading";
import { SubmitStatuses } from "@/shared/components/testManagement/TestElementInfoBlock";
import style from "@/modules/admin/clientManagement/GeneralClientSettings/style.module.css";
import { useGetClient } from "@/modules/client/hooks/useGetClient";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import MDEditor from "@uiw/react-md-editor";

import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

export type UpdatedTextsType = Record<string, Record<string, string>>;

export default function ClientTextsSettings() {
  const { client_id: clientId } = useParams<{ client_id: string }>();
  const { client, isLoading: isClientLoading } = useGetClient(Number(clientId));
  const { textLocations, isLoading: isTextLocationsLoading } = useAllTextLocations();
  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);
  const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updatedTexts, setUpdatedTexts] = useState<UpdatedTextsType>({});
  const onSave = async () => {
    try {
      setUpdateLoading(true);

      await ClientHttpService.updateClientTexts(Number(clientId), updatedTexts);
      setAlert(SubmitStatuses.success);
    } catch (err) {
      setAlert(SubmitStatuses.error);
    } finally {
      setUpdateLoading(false);
    }
  };
  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert showIcon message="Please provide valid content for every available language." type="error" />;
      case SubmitStatuses.success:
        return <Alert showIcon message="Success" type="success" />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };

  useEffect(() => {
    setUpdatedTexts(
      client?.clientTexts?.reduce((acc, { locationId, body }) => {
        acc[locationId] = body;

        return acc;
      }, {} as any),
    );
  }, [client?.clientTexts]);

  const clientAbility = useContext(ClientAbilityContext);
  const canUpdateClientText = clientAbility.can(Action.Update, Subject.CLIENT_TEXT, "text");

  if (isClientLoading || isTextLocationsLoading) {
    return <Loading />;
  }

  return (
    <div className={style.container}>
      {textLocations.map((location) => {
        const clientText = client?.clientTexts?.find(({ locationId }) => locationId === location.locationId);
        const languageKeys = Object.keys(clientText?.body || { "en-US": "" });

        return (
          <div style={{ marginBottom: 30 }} key={location.locationId}>
            <h2>{location.name}</h2>

            {languageKeys.map((language) => (
              <div key={language}>
                <p style={{ textAlign: "right" }}>{language}</p>

                <MDEditor
                  highlightEnable={false}
                  height={300}
                  placeholder="Type..."
                  value={updatedTexts?.[location.locationId]?.[language]}
                  onChange={(value) => {
                    const clientTexts = updatedTexts?.[location.locationId] || {};

                    setUpdatedTexts({
                      ...updatedTexts,
                      [location.locationId]: { ...clientTexts, [language]: value },
                    });
                  }}
                />
              </div>
            ))}
          </div>
        );
      })}

      <div className={style.buttonContainer}>
        <CustomButton
          title="Save"
          buttonSize={ButtonSize.XSmall}
          disabled={!Object.keys(updatedTexts).length || !canUpdateClientText}
          onClick={onSave}
          isLoading={isUpdateLoading}
        >
          <SaveSVG className={style.svgSize} aria-hidden="true" />
        </CustomButton>

        <div className={style.alertContainer}>{getAlert(alert)}</div>
      </div>
    </div>
  );
}

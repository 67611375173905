import { useCallback, useState } from "react";

import { Firebase } from "@/shared/services/firebase";
import { CLIENT_ID } from "@/shared/hooks/use-get-clients.hook";

import { DB_USER_KEY } from "./currentUser.hook";
import { FIREBASE_ACCESS_TOKEN } from "./useFirebaseAuth.hook";

export function useAuthHook(firebase: Firebase) {
  const [isLoading, setIsLoading] = useState(false);

  const signOut = useCallback(async () => {
    try {
      setIsLoading(true);
      await firebase.signOut();
    } finally {
      setIsLoading(false);
    }

    localStorage.removeItem(DB_USER_KEY);
    localStorage.removeItem(FIREBASE_ACCESS_TOKEN);
    localStorage.removeItem(CLIENT_ID);
  }, []);

  return { signOut, isLoading };
}

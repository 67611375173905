import React from "react";
import cn from "classnames";
import { Col, Row, Tooltip } from "antd";
import pspLogo from "@/assets/image/psp-logo.svg";
import { REQUIRED_QUESTIONS_MESSAGE } from "./candidate-assessment.constants";
import { CandidateAssessmentViewTypes, ICandidateAssessmentView } from "./CandidateAssessment";
import style from "./components/CandidateTestElement/style.module.css";

export default function CandidateAssessmentDesktop({
  viewType,
  children,
  childrenNextButton,
  childrenBackButton,
  assessmentImageUrl,
  clientLogo,
  clientName,
  disabled,
  setIsVisibleTooltip,
}: ICandidateAssessmentView) {
  return (
    <div className={style.container}>
      <Row>
        <Col span={12}>
          <div className={style.desktopContentWrapper}>
            <div className={style.desktopContentContainer}>
              <div className={style.desktopLogoContainerHeight}>
                {clientLogo && <img src={clientLogo} alt={clientName} className={style.desktopLogoContainerHeight} />}
              </div>
              {children}
            </div>
            <div
              className={cn(style.desktopFooter, {
                [style.paginationColor]: viewType === CandidateAssessmentViewTypes.test,
              })}
            >
              {(viewType === CandidateAssessmentViewTypes.start ||
                viewType === CandidateAssessmentViewTypes.finish) && (
                <div className={style.desktopLogoContainerHeight}>
                  <p className={style.desktopLogoFontSize}>Provided by</p>
                  <img src={pspLogo} alt="PSP Metrics" className={style.desktopPspLogo} />
                </div>
              )}
              {viewType === CandidateAssessmentViewTypes.test && (
                <div className={style.pagination}>
                  <Tooltip
                    title={REQUIRED_QUESTIONS_MESSAGE}
                    trigger={["hover", "click"]}
                    visible={disabled}
                    onVisibleChange={(t) => setIsVisibleTooltip && setIsVisibleTooltip(t)}
                  >
                    <span>{childrenNextButton}</span>
                  </Tooltip>
                  <div>{childrenBackButton}</div>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={style.desktopImageContainer}>
            {assessmentImageUrl && <img src={assessmentImageUrl} alt="" className={style.desktopImage} />}
          </div>
        </Col>
      </Row>
    </div>
  );
}

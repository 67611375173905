import { useSelector } from "react-redux";

import { selectClientData, selectPickedClient } from "@/shared/services/store/clientStore";

import { IClient } from "../clients.interface";

export function useReduxClientsHook() {
  const selectedClient: number = useSelector(selectPickedClient);
  const reduxClients: IClient[] = useSelector(selectClientData);

  return { reduxClients, selectedClient };
}

import * as React from "react";
import { useContext, useState } from "react";
import { Col, Row } from "antd";

import { ITestSelect } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { ReportTestCompetencySelector } from "@/shared/components/assessment/report/ReportTestCompetencySelector/ReportTestCompetencySelector";

import CategorizedCharts from "@/shared/components/charts/CategorizedCharts/CategorizedCharts";
import { IMultiChart } from "@/shared/components/charts/types/chartTypes";

import { Loading } from "@/shared/components/Loading/Loading";

import { ReportContext } from "@/shared/contexts/report.context";

import style from "../style.module.css";

export enum ChartTypes {
  radar,
  bar,
}

const defaultMagnifier: number = 1;

interface IChartBlock {
  checkedTestId: number[];
  chartType: ChartTypes;
  chartMultiData: IMultiChart[];
  selected: ITestSelect[];
  checkedHideSameScore: boolean;
  dataLoading: boolean;
  compareHeader?: JSX.Element;
  setSelected: (testId: number, competencies: string[]) => void;
  onCheckShowTest: (checked: boolean, testId: number) => void;
}

export const CHART_COLORS = [
  "#122646",
  "#C39445",
  "#3E6AA5",
  "#C2B89D",
  "#6D688E",
  "#9E9257",
  "#9EB8B9",
  "#545B5F",
  "#AF5D3A",
  "#8B7F6B",
  "#483C32",
  "#627C88",
  "#FAD7A0",
  "#E67E22",
  "#F1C40F",
  "#986D36",
] as const;

/**
 * @description Get a brand-acceptable color. Colors will repeat if a particular view/component has more elements than defined colors.
 * @param n - the zero-based index for the color sequence
 */
export const getChartColor = (n: number) => {
  return CHART_COLORS[n % CHART_COLORS.length];
};

export default function ChartMultiBlock({
  checkedTestId,
  chartType,
  chartMultiData,
  selected,
  setSelected,
  onCheckShowTest,
  checkedHideSameScore,
  dataLoading,
  compareHeader,
}: IChartBlock) {
  const { scene } = useContext(ReportContext);

  const [magnifier, setMagnifier] = useState<number>(defaultMagnifier);
  const [hoveredTestId, setHoveredTestId] = useState<number>(-1);

  return (
    <Row className={style.rowContainer}>
      <Col xxl={15} xl={15} md={24} sm={24} xs={24} style={{ marginBottom: "20px" }}>
        {compareHeader}
        {dataLoading && <Loading />}
        <CategorizedCharts
          scene={scene}
          chartMultiData={chartMultiData}
          chartType={chartType}
          magnifier={magnifier}
          hoveredTestId={hoveredTestId}
          checkedHideSameScore={checkedHideSameScore}
        />
      </Col>
      <Col xxl={1} xl={1} md={0} sm={0} xs={0} />
      <Col xxl={8} xl={8} md={24} sm={24} xs={24}>
        {selected
          .sort((a, b) => a.testRank - b.testRank)
          .map(
            ({ testName, testId, allCompetencies, competencies, testColor, competenciesName, competencyColorMap }) => (
              <div
                key={testId}
                className={style.cardBackground}
                onMouseEnter={() => {
                  setMagnifier(1.6);
                  setHoveredTestId(testId);
                }}
                onMouseLeave={() => {
                  setMagnifier(defaultMagnifier);
                  setHoveredTestId(-1);
                }}
              >
                <ReportTestCompetencySelector
                  key={testId}
                  isChecked={checkedTestId.findIndex((id) => id === testId) !== -1}
                  testId={testId}
                  testName={testName}
                  allCompetencies={allCompetencies}
                  selectedCompetencies={competencies}
                  color={testColor}
                  onChangeSelected={setSelected}
                  onCheckboxClick={onCheckShowTest}
                  competencyColorMap={competencyColorMap}
                  addMoreTitle={competenciesName?.length && competenciesName[1]}
                />
              </div>
            ),
          )}
      </Col>
    </Row>
  );
}

import React, { useContext } from "react";
import { MAX_PTR_5 } from "@/modules/assessments/scenes/AssessmentCompetencies/CompetencyRangeComponent";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";
import { ClientCompetenciesService } from "@/modules/client/services/client-competencies.service";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { ReportContext } from "@/shared/contexts/report.context";
import { useGetReduxTestsCompetenciesHook } from "@/shared/services/store/hooks/useGetReduxTestsCompetencies.hook";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import style from "./style.module.css";

const suggestionBlockAbout: string =
  'Below you will find suggested behavior-based interview questions to help you further assess the candidate.  It is suggested that you ask the candidate these questions for each work behavior that the candidate scored below a "3".';

export default function Suggestions({ answers, assessmentTests }: { answers: ITestAnswer[]; assessmentTests?: IAssessmentTest[] }) {
  const competenciesByTest = assessmentTests
    ?.map(({ testId, config, test, rank }) => {
      return {
        rank,
        testId: testId,
        testName: test.name,
        competencies: Object.keys(config.competencies).filter((key) => config.competencies[key] !== false),
      };
    })
    .sort((first, second) => first.rank - second.rank);

  const { competenciesQuestions, testIds, clientConfig } = useContext(ReportContext);
  const reduxCompetencies = useGetReduxTestsCompetenciesHook(testIds);

  return (
    <div className={style.suggestionsContainer}>
      {competenciesQuestions.filter(({ questions }) => questions.length || Object.keys(questions).length).length !==
        0 && (
        <div className={style.suggestionTitle}>
          <h2>Interview Suggestions</h2>
          <h4>{suggestionBlockAbout}</h4>
        </div>
      )}

      {competenciesByTest &&
        competenciesByTest.map(({ testId, competencies }) => {
          const testQuestions = competenciesQuestions.find(
            ({ testId: competenciesTestId }) => competenciesTestId === testId,
          );
          const testAnswer = answers.find(({ testId: answersTestId }) => answersTestId === testId);
          if (!testQuestions?.questions || !testAnswer?.score.competencies) return;

          const scoredCompetencies = competencies
            .map((key) => {
              return {
                competencyKey: key,
                score: testAnswer.score.competencies[key],
              };
            })
            .sort((a, b) => {
              if (!a.score) return -1;
              if (!b.score) return 1;

              const firstScore = a.score.adjusted !== null ? a.score.adjusted : a.score.score;
              const secondScore = b.score.adjusted !== null ? b.score.adjusted : b.score.score;

              if (firstScore === secondScore) {
                if (a.score.score === b.score.score) {
                  return ClientCompetenciesService.compareCompetenciesByName({
                    firstKey: a.competencyKey,
                    secondKey: b.competencyKey,
                    testId,
                    competencies: reduxCompetencies.flat(),
                  });
                } else {
                  return a.score.score - b.score.score;
                }
              } else {
                return firstScore - secondScore;
              }
            });

          const competenciesSortedByScore = scoredCompetencies.map((item) => item.competencyKey);

          return competenciesSortedByScore.map(
            (competency: string) =>
              testQuestions.questions[competency]?.length && (
                <ul key={testId + competency}>
                  <h3>
                    {ClientCompetenciesService.getCustomCompetencyName({
                      base64Key: competency,
                      clientConfig,
                      testId,
                      competencies: reduxCompetencies.flat(),
                    })}
                    {" - "}
                    {testAnswer.score.competencies[competency].score}/{MAX_PTR_5}{" "}
                    {testAnswer.score.competencies[competency].score -
                      testAnswer.score.competencies[competency].adjusted !==
                      0 &&
                      (testAnswer.score.competencies[competency].adjusted >= 0 ? (
                        <>
                          {"(adjusted to "}
                          {testAnswer.score.competencies[competency].adjusted})
                        </>
                      ) : (
                        <>(below threshold)</>
                      ))}
                  </h3>
                  <MarkdownPreviewCustom
                    source={testQuestions.explanations[competency]}
                    className={style.explanation}
                  />
                  <MarkdownPreviewCustom source={testQuestions.questions[competency]} />
                </ul>
              ),
          );
        })}
    </div>
  );
}

import { Slider, Switch } from "antd";

import style from "./style.module.css";

interface ICompetencyRange {
  canChangeWeighting: boolean;
  canToggleInclusion: boolean;
  competencyKey: string;
  competencyValue: boolean | number;
  setValue: (competencyName: string, competencyValue: boolean | number) => void;
  setToggle: (competencyName: string, checked: boolean) => void;
  customCompetencyName?: string;
}

const marks = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
};

export const MAX_PTR_5 = 5;

export function CompetencyRangeComponent({
  canChangeWeighting,
  canToggleInclusion,
  competencyKey,
  competencyValue,
  setValue,
  setToggle,
  customCompetencyName,
}: ICompetencyRange) {
  const handleSliderClick = (value: number) => {
    setValue(competencyKey, value);
  };

  const handleSwitchClick = (checked: boolean) => {
    setToggle(competencyKey, checked);
  };

  return (
    <div className={style.competencyBlockContainer}>
      <p className={style.competencyName}>{customCompetencyName}</p>

      <div className={style.rangeBlock}>
        <Switch
          checked={typeof competencyValue === "boolean" ? competencyValue : true}
          onClick={handleSwitchClick}
          disabled={!canToggleInclusion}
        />
        <div style={{ minWidth: "80%" }}>
          <Slider
            marks={marks}
            value={typeof competencyValue === "number" ? competencyValue : 0}
            disabled={!canChangeWeighting}
            onChange={handleSliderClick}
            max={MAX_PTR_5 - 1}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Dropdown, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useAuthHook } from "@/shared/hooks/auth/useAuth.hook";
import { ReactComponent as UserSVG } from "@/assets/icons/feather-icons/user.svg";
import { ReactComponent as SwitchUserSVG } from "@/assets/icons/feather-icons/users.svg";
import { ReactComponent as LogoutSVG } from "@/assets/icons/feather-icons/log-out.svg";

import { ReactComponent as ArrowDownSVG } from "@/assets/icons/feather-icons/chevron-down.svg";
import { ReactComponent as SettingsSVG } from "@/assets/icons/feather-icons/settings.svg";
import { Firebase } from "@/shared/services/firebase";
import { UserContext } from "@/shared/contexts/user.context";
import { selectClientData } from "@/shared/services/store/clientStore";

import {
  CANDIDATE_ROUTE,
  CLIENT_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  USER_SETTINGS_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import style from "./style.module.css";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

export enum MenuKeys {
  Invites,
  Logout,
  Settings,
  SwitchClient,
}

export function UserAvatar() {
  const userAvatarSize: number = 36;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const firebase = new Firebase();
  const { signOut } = useAuthHook(firebase);
  const isCandidateRoute = pathname.includes(`${CANDIDATE_ROUTE}/`);
  const { firebaseUser, user } = useContext(UserContext);
  const clientsQuantity = useSelector(selectClientData).length;
  let userAvatarUrl: string = "";
  const ability = useContext(ClientAbilityContext);
  const canViewUserSettings = ability.can(Action.Read, Subject.USER_SETTING);

  if (firebaseUser && firebaseUser?.photoURL) {
    userAvatarUrl = firebaseUser.photoURL;
  }

  async function handleMenuItemClick(event: MenuInfo) {
    switch (Number(event.key)) {
      case MenuKeys.Settings:
        return navigate(`/${USER_SETTINGS_ROUTE}`);
      case MenuKeys.SwitchClient:
        return navigate(`/${CLIENT_ROUTE}`);
      case MenuKeys.Invites:
        return navigate(`/${CANDIDATE_ROUTE}/invites`);
      case MenuKeys.Logout: {
        const logOutRedirect = isCandidateRoute ? `/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}` : `/${LOGIN_ROUTE}`;

        await signOut();

        // Wrapped in a timeout to ensure that navigations within PrivateRoute components are superseded by this navigation
        setTimeout(() => navigate(`/${LOGOUT_ROUTE}?url=${encodeURIComponent(logOutRedirect)}`, { replace: true }), 0);
      }
    }
  }

  const getUserSpecificMenuItems = () => {
    if (isCandidateRoute) {
      return [
        {
          label: "Invites",
          key: MenuKeys.Invites,
          icon: <SwitchUserSVG aria-hidden="true" />,
        },
      ];
    }

    let menuItems = [];

    if (canViewUserSettings) {
      menuItems.push({
        label: "Settings",
        key: MenuKeys.Settings,
        icon: <SettingsSVG aria-hidden="true" />,
      });
    }

    if (user?.isAdmin || clientsQuantity > 1) {
      menuItems.push({
        label: "Switch Client",
        key: MenuKeys.SwitchClient,
        icon: <SwitchUserSVG aria-hidden="true" />,
      });
    }

    return menuItems;
  };

  const menu = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        ...getUserSpecificMenuItems(),
        {
          label: "Logout",
          key: MenuKeys.Logout,
          icon: <LogoutSVG aria-hidden="true" />,
        },
      ]}
    />
  );

  return (
    <div>
      <Dropdown autoFocus overlay={menu} placement="bottomRight" trigger={["click"]}>
        <Button className={style.userAvatarButton} aria-label="Profile settings">
          {userAvatarUrl ? (
            <div className={style.avatarContainer}>
              <Avatar
                size={userAvatarSize}
                src={userAvatarUrl}
                icon={<UserSVG />}
                onError={() => true}
                className={style.avatarCircle}
              />
            </div>
          ) : (
            <div className={style.avatarContainer}>
              <Avatar size={userAvatarSize} icon={<UserSVG />} className={style.avatarCircle} />
            </div>
          )}
          <ArrowDownSVG aria-hidden="true" />
        </Button>
      </Dropdown>
    </div>
  );
}

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import logo from "@/assets/image/psp-logo.svg";

import style from "./style.module.css";

export default function Maintenance() {
  return (
    <div className={style.wrapper}>
      <SEO titleParams={["Temporary Maintenance"]} />
      <div className={style.logo}>
        <img src={logo} alt="PSP Metrics" />
      </div>
      <div className={style.contentContainer}>
        <h1>
          <span aria-hidden="true">🚧 </span>Temporary Maintenance
        </h1>
        <p>
          We are currently performing scheduled maintenance. We apologize for any inconvenience and appreciate your
          patience. Please check back soon!
        </p>
      </div>
    </div>
  );
}

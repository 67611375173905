import React, { ReactElement } from "react";
import cn from "classnames";

import style from "./style.module.css";

interface IJobMatch {
  matchLabel: string;
  matchStyle?: string;
}

export function JobMatch({ matchLabel, matchStyle }: IJobMatch): ReactElement<IJobMatch> {
  return (
    <h1 className={cn(style.jobMatchLabel, style[matchStyle || matchLabel])}>
      <span>Overall Job Match: </span>
      {matchLabel}
    </h1>
  );
}

import React from "react";

import { ReactComponent as UsersSVG } from "@/assets/icons/feather-icons/users.svg";
import { ReactComponent as ClientSVG } from "@/assets/icons/feather-icons/briefcase.svg";
import { ReactComponent as AssessmentSVG } from "@/assets/icons/feather-icons/folder.svg";
import { ReactComponent as TestSVG } from "@/assets/icons/feather-icons/check-circle.svg";

import { QuickSearchKeys } from "../../types";

import style from "../../style.module.css";

const getIcon = (title: string) => {
  switch (title) {
    case QuickSearchKeys.assessments:
      return <AssessmentSVG />;
    case QuickSearchKeys.clients:
      return <ClientSVG />;
    case QuickSearchKeys.tests:
      return <TestSVG />;
    case QuickSearchKeys.invites:
    case QuickSearchKeys.candidates:
      return <UsersSVG />;
    default:
      return;
  }
};

export function SearchItemTitle(title: string) {
  return (
    <div className={style.categoryContainer}>
      {getIcon(title)}
      <span className={style.optionTitle}>{title}</span>
    </div>
  );
}

import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Loading } from "@/shared/components/Loading/Loading";
import { useValidateClientAccessHook } from "@/shared/hooks/access/useValidateClientAccess.hook";
import { useChangeClientContextHook } from "@/shared/hooks/context/useChangeClientContext.hook";
import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";

function useSwitchClient(clientId: string) {
  const { isAccessibleClient } = useValidateClientAccessHook(clientId);
  const { selectedClient } = useReduxClientsHook();
  useChangeClientContextHook(clientId, isAccessibleClient.state);

  /*
    Abilities are calculated for the current user in the GlobalProvider based on
    the value for the selected client in the redux store. We should not load
    any child views until we those values are in sync and have been computed.
  */
  return { isLoading: clientId !== String(selectedClient) };
}

export default function ClientLayout() {
  const { client_id } = useParams();
  const { isLoading } = useSwitchClient(String(client_id));

  if (isLoading) {
    return <Loading />;
  }

  return <Outlet />;
}

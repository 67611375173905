import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorPage from "@/modules/main/ErrorPage/ErrorPage";
import { ICommonErrorPage } from "@/modules/main/Forbidden/Forbidden";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

export default function ServerFail({ isSignedIn, isAdmin, isClient }: ICommonErrorPage) {
  const navigate = useNavigate();

  return (
    <ErrorPage
      isSignedIn={isSignedIn}
      isAdmin={isAdmin}
      isClient={isClient}
      errorReason={"Sorry, this page is not working now. Please, refresh it in a few minutes."}
    >
      <CustomButton
        title={"go back"}
        onClick={() => {
          navigate("/");
        }}
      />
    </ErrorPage>
  );
}

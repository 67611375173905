import * as React from "react";
import { Helmet } from "react-helmet-async";

interface IHeadHelmetProps {
  title?: string;
  titleParams?: (string | undefined)[];
  description?: string;
}

export function SEO({ title, titleParams, description }: IHeadHelmetProps) {
  const name: string = "PSP Metrics";
  const type: string = "website";
  const originPath = window.location.origin;
  let concatenatedTitle: string = name;
  if (titleParams) {
    titleParams
      .slice()
      .reverse()
      .forEach((param) => {
        if (param) {
          concatenatedTitle = `${param} - ` + concatenatedTitle;
        }
      });
  }
  const siteDescription = description || "Pre-employment testing for positions at all job levels.";

  return (
    <Helmet prioritizeSeoTags>
      <title>{title || concatenatedTitle}</title>
      <meta name="description" content={siteDescription} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title || concatenatedTitle} />
      <meta property="og:site_name" content={title || concatenatedTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:url" content={originPath} />

      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title || concatenatedTitle} />
      <meta name="twitter:description" content={siteDescription} />
    </Helmet>
  );
}

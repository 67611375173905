import { createContext, Dispatch, SetStateAction } from "react";

import { IAssessment } from "@/modules/assessments/assessments.types";

export type TAssessmentContext = {
  assessment?: IAssessment;
  setAssessment?: Dispatch<SetStateAction<IAssessment | undefined>>;
};

export const AssessmentContext = createContext<TAssessmentContext>({
  assessment: undefined,
});

import React from "react";

import { CustomMenu } from "@/modules/layout/Menu/CustomMenu";
import { UserAvatar } from "@/modules/layout/UserAvatar/UserAvatar";

import { Loading } from "@/shared/components/Loading/Loading";

import GlobalSearch from "@/ui-components/commonComponents/GlobalSearch/GlobalSearch";

import logo from "@/assets/image/psp-logo.svg";

import "./DefaultLayout.css";

interface IDefaultLayout {
  children: React.ReactElement<any, any>;
  isLoading: boolean;
}

export function DefaultAdminLayout({ children, isLoading }: IDefaultLayout) {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="header">
        <div className="inRow " style={{ flexGrow: 2, justifyContent: "flex-start" }}>
          <div style={{ marginRight: 60 }}>
            <div className="logo">
              <img src={logo} className="logo-image" alt="PSP Metrics" />
            </div>
          </div>
          <div style={{ flexGrow: 2 }}>
            <CustomMenu />
          </div>
        </div>
        <div className="inRow searchBlock">
          <GlobalSearch />
          <UserAvatar />
        </div>
      </div>
      {children}
    </>
  );
}

import * as React from "react";
import { Col, Row } from "antd";

import TableAssessments, { IAssessmentTable } from "@/modules/tables/TableAssessments";
import { ITest } from "@/modules/assessments/types/test.interface";

export function DesktopAssessmentsListComponent({
  tableData,
  handleClickNewAssessmentButton,
  ...rest
}: {
  tableData: IAssessmentTable[];
  handleClickNewAssessmentButton: () => void;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  setCurrentPage: (page: number) => void;
  onSortChange: (options: any) => void;
  sortOptions: { column: string | undefined; direction: string | undefined };
  loading: boolean;
  onAssessmentSearch: (q: string) => void;
  setPageSize: (size: number) => void;
  setTestIdsFilter: (value: number[]) => void;
  tests: ITest[];
  onAssessmentClone: (assessment: IAssessmentTable) => void;
}) {
  return (
    <Row gutter={30}>
      <Col xxl={24} xl={24} lg={24} md={24}>
        <TableAssessments dataList={tableData} onCreateAssessment={handleClickNewAssessmentButton} {...rest} />
      </Col>
    </Row>
  );
}

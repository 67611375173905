import { useEffect, useState } from "react";
import { IClient } from "@/modules/client/clients.interface";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { ClientHttpService } from "@/modules/client/services/client-http.service";

export function useGetClient(clientId: number) {
  const [client, setClient] = useState<IClient>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        setIsLoading(true);
        const clientFromAPI: IClient = await ClientHttpService.getClientById(Number(clientId));
        if (clientFromAPI) {
          setClient(clientFromAPI);
        }
      } catch (e: any) {
        console.error(`getting client error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(clientId) && clientId !== 0) {
      fetchClient();
    }
  }, [clientId]);

  return { client, isLoading };
}

import {
  ITestElementView,
  QuestionTypes,
  TestElementMode,
} from "@/shared/components/candidate/scenes/TestElement/TestElement";

import { LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import RadioGroup from "@/ui-components/testElementComponents/RadioGroup/RadioGroup";
import Likert5Group from "@/ui-components/testElementComponents/LikertGroup/Likert5Group";
import TrueFalseRadioGroup from "@/ui-components/testElementComponents/TrueFalseRadioGroup/TrueFalseRadioGroup";
import StatementsRatingGroup from "@/ui-components/testElementComponents/StatementsRatingGroup/StatementsRatingGroup";
import { LikelyTrueFalseRadioGroup } from "@/ui-components/testElementComponents/LikelyTrueFalseRadioGroup/LikelyTrueFalseRadioGroup";
import { TwoWayMatch } from "@/ui-components/testElementComponents/TwoWayMatch/TwoWayMatch";

export type AnswerElementComponentProps = Pick<
  ITestElementView,
  | "questionType"
  | "id"
  | "mode"
  | "onChoiceSelect"
  | "currentAnswer"
  | "choices"
  | "question"
  | "disableChoices"
  | "questionNumber"
>;

export function AnswerElementComponent({
  id,
  mode,
  disableChoices,
  choices,
  question,
  questionType,
  questionNumber,
  currentAnswer,
  onChoiceSelect,
}: AnswerElementComponentProps) {
  const onRadioChange = (e: any) => {
    onChoiceSelect({ elementId: id, choiceKey: e.target.id });
  };

  switch (questionType) {
    case QuestionTypes.TrueFalse:
    case QuestionTypes.FollowNotFollow:
    case QuestionTypes.CorrectIncorrect:
    case QuestionTypes.MadeNotMade:
    case QuestionTypes.StrongWeak:
      return (
        <TrueFalseRadioGroup
          id={String(id)}
          mode={mode}
          questionType={questionType}
          onRadioChange={onRadioChange}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
          trueKey={choices.find((element) => element.choice)?.key}
          falseKey={choices.find((element) => !element.choice)?.key}
          choices={choices}
        />
      );
    case QuestionTypes.LikelyTrueFalse:
      return (
        <LikelyTrueFalseRadioGroup
          id={id}
          mode={mode}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
          choices={choices}
          onRadioChange={onRadioChange}
        />
      );
    case QuestionTypes.SD:
      return (
        <StatementsRatingGroup
          id={id}
          mode={mode}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
          statements={question[LanguagesIsoEnum.enUS]}
          choices={choices}
          onRadioChange={onRadioChange}
        />
      );
    case QuestionTypes.Likert5:
      return (
        <Likert5Group
          id={id}
          mode={mode}
          disable={Boolean(disableChoices)}
          choices={choices}
          onChange={onRadioChange}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
        />
      );
    case QuestionTypes.Text:
      return <p></p>;
    case QuestionTypes.Radio:
    case QuestionTypes.RadioHorizontal:
    case QuestionTypes.RadioImage:
      return (
        <RadioGroup
          id={id}
          mode={mode}
          choices={choices}
          disable={mode !== TestElementMode.Admin && disableChoices}
          onChange={onRadioChange}
          isHorizontal={questionType === QuestionTypes.RadioHorizontal || questionType === QuestionTypes.RadioImage}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
        />
      );
    case QuestionTypes.TwoWayMatch:
      return (
        <TwoWayMatch
          choices={choices}
          currentAnswer={choices.find((element) => element.key === currentAnswer)?.choice}
          disabled={Boolean(disableChoices)}
          id={id}
          onChoiceSelect={onChoiceSelect}
          pairToMatch={question[LanguagesIsoEnum.enUS] as [string, string]}
          questionNumber={questionNumber}
        />
      );
  }
}

import { ChangeEvent } from "react";

import { TableCandidates } from "@/modules/tables/TableCandidates";

import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

export function DesktopInvitesList(props: {
  dataList: IAssessmentInvite[];
  onAddCandidates: () => void;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
  loading: boolean;
  onCandidateSearch: (search: string) => void;
  onSortChange: (options: any) => void;
  statuses: string[];
  onFilterStatus: (statuses: string[]) => void;
  filteredStatuses: string[];
  sortOptions: { column?: string; direction?: string };
  onInvitesRescind: (ids: number[]) => Promise<void>;
  onInvitesRescore: (ids: number[]) => void;
  onInvitesDisableTimer: (invites: Array<IAssessmentInvite>) => void;
  isSendable?: boolean;
  openQRModal?: () => void;
}) {
  return (
    <div className="tabs-inner-container-desktop">
      <TableCandidates {...props} />
    </div>
  );
}

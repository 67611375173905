import format from "format-duration";

export function removeUnderscore(str: string) {
  return str.replaceAll("_", " ");
}

export function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function isValidNumericParam(paramId: number | string | undefined | null): boolean {
  if (paramId === null) return false;
  return Number.isInteger(Number(paramId));
}

export const convertToTimeFormat = (milliseconds: number) => {
  milliseconds = Number(milliseconds);

  const allMinutes = milliseconds / 1000 / 60;
  const hours = Math.floor(allMinutes / 60);
  const minutes = Number((allMinutes - hours * 60).toFixed(0));

  return minutes < 10 ? `${hours} : 0${minutes}` : `${hours} : ${minutes}`;
};

export function isValidEmail(email: string) {
  return RegExp(/\S+@\S+\.\S+/).test(email);
}

/**
 * @deprecated use format-duration library instead
 * */
export const convertToFuzzyTimeFormat = (milliseconds: number) => {
  milliseconds = Number(milliseconds);

  const allMinutes = milliseconds / 1000 / 60;
  const hours = Math.floor(allMinutes / 60);
  const minutes = Number((allMinutes - hours * 60).toFixed(0));
  const fuzzyMinutes = minutes >= 45 ? 45 : minutes >= 30 ? 30 : minutes >= 15 ? 15 : 0;
  return fuzzyMinutes !== 0
    ? `about ${hours !== 0 ? hours : ""} ${hours !== 0 ? (hours > 1 ? "hrs, " : "hr, ") : ""}${fuzzyMinutes} mins`
    : `about ${hours}${hours > 1 ? "hrs" : "hr"}`;
};

export function makeReadableName(name: string) {
  return name.split("_").map(capitalizeFirstLetter).join(" ");
}

export function addSpaces(value: string) {
  return value.split(/(?=[A-Z])/).join(" ");
}

export function secToMin(seconds: number) {
  seconds = Number(seconds);
  return Math.floor(seconds / 60);
}

export function minToSec(minutes: number) {
  minutes = Number(minutes);
  return Math.floor(minutes * 60);
}

export function secToMls(seconds: number) {
  seconds = Number(seconds);
  return seconds * 1000;
}

export function mlsToSec(mls: number) {
  mls = Number(mls);
  return mls / 1000;
}

export function mlsToMin(mls: number) {
  mls = Number(mls);
  return secToMin(mlsToSec(mls));
}

export function secondsToHms(seconds: number) {
  seconds = Number(seconds);
  return format(secToMls(seconds), { leading: true });
}
export function minutesToHms(minutes: number) {
  minutes = Number(minutes);
  return format(secToMls(minToSec(minutes)), { leading: true });
}

import React, { useContext } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import { IAssessmentTest } from "@/modules/assessments/types/test.interface";

import { Loading, LoadingSize } from "@/shared/components/Loading/Loading";
import { AnalysisContext } from "@/shared/contexts/analysis.context";
import { ReportContext } from "@/shared/contexts/report.context";
import { sortElements } from "@/shared/utils/array/sort.utils";

import style from "./style.module.css";

interface ITableAnalysisScales {
  testName: string;
  testScale: string;
  rawScore?: number;
  percent?: number;
  percentile?: number | null;
  percentage?: number | null;
  norm?: string;
}

const analysisTestColumns: ColumnsType<ITableAnalysisScales> = [
  {
    key: "testName",
    title: "Test",
    dataIndex: "testName",
    className: style.tableColumn,
    width: "25%",
    sorter: {
      compare: (a, b) => {
        return sortElements(a.testName, b.testName);
      },
      multiple: 5,
    },
  },
  {
    key: "testScale",
    title: "Scale",
    dataIndex: "testScale",
    className: style.tableColumn,
    width: "30%",
    sorter: {
      compare: (a, b) => {
        return sortElements(a.testScale, b.testScale);
      },
      multiple: 4,
    },
  },
  {
    key: "rawScore",
    title: "Raw Score",
    dataIndex: "rawScore",
    className: style.tableColumn,
    sorter: {
      compare: (a, b) => {
        return sortElements(a.rawScore, b.rawScore);
      },
      multiple: 3,
    },
  },
  {
    key: "percent",
    title: "%",
    dataIndex: "percent",
    className: style.tableColumn,
    sorter: {
      compare: (a, b) => {
        return sortElements(a.percent, b.percent);
      },
      multiple: 2,
    },
    render: (value, record) => {
      if (typeof record.percentile === "number") {
        return (
          <Tooltip overlay={"Percentile"}>
            <p>{`${value} %`}</p>
          </Tooltip>
        );
      }
      if (typeof record.percentage === "number") {
        return (
          <Tooltip overlay={"Percentage"}>
            <p>{`${value} / 100`}</p>
          </Tooltip>
        );
      } else return "N/A";
    },
  },
  {
    key: "norm",
    title: "Norm Used",
    dataIndex: "norm",
    className: style.tableColumn,
    width: "25%",
    sorter: {
      compare: (a, b) => {
        return sortElements(a.norm, b.norm);
      },
      multiple: 1,
    },
  },
];

export default function TableAnalysisScales({ tests, loading }: { tests: IAssessmentTest[]; loading: boolean }) {
  const { testResults, norms } = useContext(AnalysisContext);
  const { scales } = useContext(ReportContext);
  const assessmentNormsScales = scales.map((scale) => {
    return {
      testId: scale[0].testId,
      norms: norms.find((norm) => (norm[0] ? norm[0].testId === scale[0].testId : false)) || [],
      scales: scale,
    };
  });

  const tableData = testResults
    .map(({ testId, normId }) => {
      const test = tests.filter((test) => test.testId === testId)[0];
      const testResult = testResults.filter((test) => test.testId === testId)[0];
      const norm = assessmentNormsScales
        .filter((scale) => scale.testId === testId)[0]
        .norms.filter((norm) => (norm ? norm.normId === normId : false))[0];
      const scales = assessmentNormsScales.filter((norm) => norm.testId === testId)[0].scales;

      if (test && testResult) {
        const scalesResult = testResult.scalesResult;
        return scales.map((scale) => {
          const percentile = scalesResult[scale.abbr].percentile;
          const percentage = scalesResult[scale.abbr].percentage;
          const percent = percentile || percentage;
          return {
            testName: test.test.name,
            testScale: `${scale.name} (${scale.abbr})`,
            rawScore: scalesResult[scale.abbr].raw,
            percent: percent ? Number((percent * 100).toFixed(2)) : 0,
            percentile,
            percentage,
            norm: norm ? norm.name : "N/A",
          };
        });
      }
      return [];
    })
    .flat();

  return (
    <div className={style.tableBlock}>
      <Table
        columns={analysisTestColumns}
        dataSource={tableData}
        size={"small"}
        pagination={false}
        scroll={{ y: 500 }}
        loading={{
          spinning: loading,
          indicator: <Loading size={LoadingSize.default} />,
        }}
      />
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/shared/components/Loading/Loading";
import { UserContext } from "@/shared/contexts/user.context";
import { AssessmentAbilityContext, ClientUserRolesContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { Action, Subject, CaslAbilityFactory } from "@/shared/services/permissions/casl-ability.factory";
import { useGetAssessmentUserRolesHook } from "@/shared/hooks/permissions/use-get-assessment-user-roles.hook";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";
import { IAssessment } from "@/modules/assessments/assessments.types";

export function AssessmentProvider({ children }: { children: React.ReactElement<any, any> }) {
  const navigate = useNavigate();
  const { assessment_id: assessmentId } = useParams();
  const [assessment, setAssessment] = useState<IAssessment>();

  const { user } = useContext(UserContext);

  const { assessmentRoles, isLoading: isLoadingAssessmentRoles } = useGetAssessmentUserRolesHook(Number(assessmentId));

  const caslAbilityFactory = new CaslAbilityFactory();

  const clientRoles = useContext(ClientUserRolesContext);
  caslAbilityFactory.createForAssessmentUser(assessmentRoles, assessment, user);
  caslAbilityFactory.createForClientUser(clientRoles, user);

  const assessmentAbility = caslAbilityFactory.buildForUser();

  const canReadAssessment = assessmentAbility.can(Action.Read, Subject.ASSESSMENT);

  useEffect(() => {
    // Move to 404 if the user has no access to the assessment
    if (!isLoadingAssessmentRoles && !canReadAssessment) {
      navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [assessmentId, isLoadingAssessmentRoles, canReadAssessment, navigate]);

  if (isLoadingAssessmentRoles || !canReadAssessment) {
    return <Loading />;
  }

  return (
    <AssessmentContext.Provider value={{ assessment, setAssessment }}>
      <AssessmentAbilityContext.Provider value={assessmentAbility}>{children}</AssessmentAbilityContext.Provider>
    </AssessmentContext.Provider>
  );
}

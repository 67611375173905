import React from "react";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import { INorm } from "@/shared/components/assessment/new/TestItem/TestItem";
import { sortDates, sortNumbers, sortStrings } from "@/shared/utils/array/sort.utils";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";

import { INormScale } from "@/modules/assessments/types/scale.interface";

import style from "@/modules/tables/style.module.css";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";

export const NOT_APPLICABLE_ABBR = "N/A";

export enum NormDistributionModeViewValues {
  "normal" = "Normal",
  "not_normal" = "Not Normal",
}

export const normColumns: ColumnsType<INorm> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
    className: style.tableColumnName,
    sorter: (a, b) => sortStrings(a.name, b.name),
    render: (value, record) => (
      <Tooltip
        title={record.name}
        placement={"topLeft"}
        overlayStyle={{
          maxWidth: "25%",
          minWidth: "50px",
        }}
      >
        <span>{record.name}</span>
      </Tooltip>
    ),
  },
  {
    key: "distributionMode",
    title: "Distribution Mode",
    dataIndex: "distributionMode",
    width: "10%",
    className: style.tableColumn,
    render: (value, record) => <span>{NormDistributionModeViewValues[record.distributionMode]}</span>,
  },
  {
    key: "clientId",
    title: "Client",
    dataIndex: "clientId",
    width: "15%",
    className: style.tableColumn,
    render: (value, record) => record.clientId || "Global",
  },
  {
    key: "populationSize",
    title: "Population Size",
    dataIndex: "populationSize",
    width: "15%",
    className: style.tableColumn,
  },
  {
    key: "createdBy",
    title: "Created By",
    dataIndex: "createdBy",
    width: "15%",
    className: style.tableColumn,
  },
  {
    key: "createdAt",
    title: "Created At",
    dataIndex: "createdAt",
    width: "10%",
    className: style.tableColumn,
    sorter: (a, b) => sortDates(new Date(String(a.createdAt)), new Date(String(b.createdAt))),
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
  },
  {
    key: "assessments",
    title: "Assessments",
    dataIndex: "assessments",
    width: "10%",
    className: style.tableColumn,
    sorter: (a, b) => sortNumbers(a.assessments || 0, b.assessments || 0),
  },
  {
    key: "assessmentsDone",
    title: "Finished Assessments",
    dataIndex: "assessmentsDone",
    width: "10%",
    className: style.tableColumn,
    sorter: (a, b) => sortNumbers(a.assessmentsDone || 0, b.assessmentsDone || 0),
  },
];

export const scaleColumns: ColumnsType<Partial<INormScale>> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "scale.name",
    ellipsis: true,
    className: style.tableColumnName,
    render: (value, record) => (
      <Tooltip
        title={record.scale && record.scale.name}
        placement={"topLeft"}
        overlayStyle={{
          maxWidth: "10%",
          minWidth: "50px",
        }}
      >
        <span>{record.scale && record.scale.name}</span>
      </Tooltip>
    ),
  },
  {
    key: "abbr",
    title: "Abbr",
    dataIndex: "scale.abbr",
    width: "8%",
    className: style.tableColumn,
    render: (value, record) => <span>{record.scale && record.scale.abbr}</span>,
  },
  {
    key: "mean",
    title: "Mean",
    dataIndex: "mean",
    width: "8%",
    className: style.tableColumn,
    render: (value) => (value || value !== null ? value : NOT_APPLICABLE_ABBR),
  },
  {
    key: "standardDeviation",
    title: "Standard Deviation",
    dataIndex: "standardDeviation",
    width: "10%",
    className: style.tableColumn,
    render: (value) => (value || value !== null ? value : NOT_APPLICABLE_ABBR),
  },
];

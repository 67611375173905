import { Radio } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { IChoice } from "@/modules/assessments/types/test.interface";

import { TestElementMode } from "@/shared/components/candidate/scenes/TestElement/TestElement";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import { markdownStyles } from "@/ui-components/testElementComponents/constants/markdownStyles";

import "./StatementsRatingGroup.css";

export const findKeys = (choicesList: IChoice[], choice: string) => {
  return choicesList.find((element) => String(element.choice) === String(choice))?.key;
};

export interface IStatementsRatingGroup {
  id: number;
  mode?: TestElementMode;
  currentAnswer: string | boolean | undefined;
  statements: string | [string, string];
  choices: IChoice[];
  onRadioChange: (e: any) => void;
}

enum MotivatorChoices {
  C1 = "1",
  C2 = "2",
  C3 = "3",
  C4 = "4",
  C5 = "5",
}

export default function StatementsRatingGroup({
  id,
  mode = TestElementMode.Candidate,
  currentAnswer,
  statements,
  choices,
  onRadioChange,
}: IStatementsRatingGroup) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;
  const isAdminView: boolean = mode === TestElementMode.Admin;

  return (
    <div className="testElementContainer" style={{ width: isAdminView ? "60%" : "100%" }}>
      <div className="statementsGroupContainer">
        <div className="statementContainer">
          <MarkdownPreviewCustom
            source={statements[0]}
            style={isDesktopView ? markdownStyles.motivatorsDesktop : markdownStyles.motivatorsMobile}
          />
        </div>
        <div className="statementContainer rightStatement">
          <MarkdownPreviewCustom
            source={statements[1]}
            style={isDesktopView ? markdownStyles.motivatorsDesktop : markdownStyles.motivatorsMobile}
          />
        </div>
      </div>
      <Radio.Group
        id={String(id)}
        name={String(id)}
        className="radioGroup"
        onChange={onRadioChange}
        value={String(currentAnswer)}
        disabled={isAdminView}
      >
        <Radio.Button id={findKeys(choices, MotivatorChoices.C1)} value={MotivatorChoices.C1} className="large" />
        <Radio.Button id={findKeys(choices, MotivatorChoices.C2)} value={MotivatorChoices.C2} className="medium" />
        <Radio.Button id={findKeys(choices, MotivatorChoices.C3)} value={MotivatorChoices.C3} className="small" />
        <Radio.Button id={findKeys(choices, MotivatorChoices.C4)} value={MotivatorChoices.C4} className="medium" />
        <Radio.Button id={findKeys(choices, MotivatorChoices.C5)} value={MotivatorChoices.C5} className="large" />
      </Radio.Group>
    </div>
  );
}

import moment from "moment";

export class AssessmentLogsService {
  static getAuditLogCaption(type: string, testName?: string) {
    return `${type.replace(/([A-Z])/g, " $1").trim()}${testName ? ` (${testName})` : ""}`;
  }

  static getEventDuration(duration: moment.Duration): string {
    const time = {
      "year(s)": duration.years(),
      "month(s)": duration.months(),
      "week(s)": duration.weeks(),
      "day(s)": duration.days(),
      "hour(s)": duration.hours(),
      "minute(s)": duration.minutes(),
      "second(s)": duration.seconds(),
    };

    return Object.entries(time)
      .map(([label, time]) => {
        if (label === "day(s)" && time) {
          const days = duration.subtract(duration.weeks() * 7, "days").days();

          return days && `${days} ${label}`;
        }

        return time && `${time} ${label}`;
      })
      .filter(Boolean)
      .join(" ");
  }
}

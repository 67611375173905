import React, { useCallback, useContext, useRef } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { sortNumbers } from "@/shared/utils/array/sort.utils";
import TestElement, {
  ITestChoice,
  QuestionFontSize,
  QuestionTypes,
} from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";
import useSetScrollable from "@/modules/candidate/hooks/useSetScrollable.hook";
import StaticArrow from "@/ui-components/commonComponents/StaticArrow/StaticArrow";
import { InviteContext } from "../../contexts/invite.context";
import { SectionContext } from "../../contexts/section.context";
import { CurrentPageContext } from "../../contexts/current-page.context";
import { TestInfo } from "../TestInfo/TestInfo";
import { QuestionHeader } from "../QuestionHeader/QuestionHeader";
import { AssessmentProgress } from "../AssessmentProgress/AssessmentProgress";
import style from "./style.module.css";
import { ITestElement } from "@/modules/assessments/types/test.interface";
import { GroupedTestElementsContainer } from "../GroupedTestElementsContainer/GroupedTestElementsContainer";

interface ICandidateTestElement {
  currentPage: number;
  isLoadingTestInstructions: boolean;
  currentAnswers: ITestChoice[];
  setIsOpenedInstructions: (value: boolean) => void;
  handleChoiceSelect: (value: ITestChoice) => void;
  timerDisabled: boolean;
}

export default function CandidateTestElement({
  currentPage,
  currentAnswers,
  isLoadingTestInstructions,
  setIsOpenedInstructions,
  handleChoiceSelect,
  timerDisabled,
}: ICandidateTestElement) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  const scrollableContainer = useRef(null);

  const { hasScroll, handleScroll } = useSetScrollable(scrollableContainer.current);

  const { currentTest } = useContext(InviteContext);
  const { questionsList } = useContext(CurrentPageContext);
  const { currentSectionElement, isTimedOutSection } = useContext(SectionContext);

  const groupSize = currentTest?.config?.groupSize;
  const groupHeaderText = currentTest?.config?.groupHeaderText?.[LanguagesIsoEnum.enUS];

  const getQuestionsCount = useCallback(() => {
    const sortedTestElements = questionsList.flat().sort((a, b) => sortNumbers(a.rank, b.rank));
    const currentTestElementIndex = sortedTestElements.findIndex(
      (element) => element.elementId === currentSectionElement?.elementId,
    );

    let questionsCount = 0;

    sortedTestElements.every((element, index) => {
      if (element.testId === currentTest?.testId && currentTestElementIndex <= index) {
        if (element.config.startsSection && currentTestElementIndex !== index) {
          return false;
        }

        if (element.config.type !== QuestionTypes.Text) {
          questionsCount += 1;
        }
      }

      return true;
    });

    return questionsCount;
  }, [currentSectionElement?.elementId, currentPage, currentTest?.testId]);

  const segments = partitionQuestions(questionsList, groupSize);

  return (
    <div className={style.testChildContainer}>
      {currentTest && (
        <TestInfo
          questionsCount={getQuestionsCount()}
          testTimeCount={timerDisabled ? undefined : currentSectionElement?.config.startsSection?.timeLimit}
        />
      )}

      <AssessmentProgress />

      <QuestionHeader
        setIsOpenedInstructions={setIsOpenedInstructions}
        isLoadingTestInstructions={isLoadingTestInstructions}
      />

      <div ref={scrollableContainer} className={style.testElementsContainer} onScroll={handleScroll}>
        <div id="first" />

        <div className={style.testElementsContainerFlex}>
          {segments[currentPage - 1].map((segment, index) => {
            if (segment.length === 1) {
              const element = segment[0];
              return (
                <TestElement
                  key={element.elementId}
                  id={element.elementId}
                  required={element.config.required}
                  disableChoices={isTimedOutSection}
                  choices={element.config.payload.choices}
                  currentAnswer={currentAnswers.find((answer) => answer.elementId === element.elementId)?.choiceKey}
                  questionType={element.config.type}
                  question={
                    element.config.type === QuestionTypes.Text
                      ? element.config.payload.body
                      : element.config.payload.question
                  }
                  questionNumber={element.questionNumber}
                  questionFontSize={QuestionFontSize.large}
                  onChoiceSelect={handleChoiceSelect}
                />
              );
            } else {
              return (
                <GroupedTestElementsContainer
                  key={index}
                  segment={segment}
                  groupHeaderText={groupHeaderText}
                  isTimedOutSection={isTimedOutSection}
                  currentAnswers={currentAnswers}
                  handleChoiceSelect={handleChoiceSelect}
                />
              );
            }
          })}
        </div>

        {!isDesktopView && hasScroll && <StaticArrow />}
      </div>
    </div>
  );
}

// Partitions an assessment's questions list into segments based on group
// size and section breaks for the GroupedTestElementsContainer component.
// Also assigns question numbers to each (non-instruction) test element within
// segments.
const partitionQuestions = (questionsList: ITestElement[][], groupSize: number | undefined): ITestElement[][][] => {
  const segments: ITestElement[][][] = [];
  let questionNumber = 1;

  questionsList.forEach((pageOfQuestions) => {
    const currentPageSegments: ITestElement[][] = [];
    let currentGroup: ITestElement[] = [];

    pageOfQuestions.forEach((question) => {
      const shouldStartNewSection = question.config.type === QuestionTypes.Text && question.config.breakAfter;

      if (shouldStartNewSection) {
        questionNumber = 1;
        if (currentGroup.length > 0) {
          currentPageSegments.push([...currentGroup]);
          currentGroup = [];
        }
        currentPageSegments.push([question]);
      } else if (groupSize) {
        question.questionNumber = questionNumber;
        questionNumber++;
        currentGroup.push(question);
        if (currentGroup.length === groupSize) {
          currentPageSegments.push([...currentGroup]);
          currentGroup = [];
        }
      } else {
        currentPageSegments.push([question]);
      }
    });

    // If after partitioning all questions we have a remainder of questions
    // left over, push the final group of question into it's own segement
    if (currentGroup.length > 0 && groupSize) {
      currentPageSegments.push(currentGroup);
    }

    segments.push(currentPageSegments);
  });

  return segments;
};

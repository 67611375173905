import { useEffect, useState } from "react";

import { useGetAnswersByIdHook } from "@/modules/assessments/hooks/use-get-candidate-answers.hook";

import { IUserAnswer } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";

export function useGetCompareAnswersHook() {
  const { fetchAnswers, answers, isLoading } = useGetAnswersByIdHook();
  const [usersAnswers, setUsersAnswers] = useState<IUserAnswer[]>([]);

  useEffect(() => {
    if (answers.length) {
      setUsersAnswers((prev) => [...prev, { inviteId: answers[0].inviteId, answers }]);
    }
  }, [answers]);

  return {
    usersAnswers,
    fetchAnswers,
    isLoading,
  };
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ICandidateAssessmentsData } from "@/modules/assessments/assessments.types";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useCandidateAssessmentsHook(userId: string | undefined) {
  const [data, setData] = useState<ICandidateAssessmentsData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidateAssessmentsData = async () => {
      if (!userId) {
        return;
      }

      try {
        setIsLoading(true);

        const candidateAssessmentsData = await AssessmentHttpService.fetchCandidateAssessmentsData(Number(userId));

        if (candidateAssessmentsData) {
          setData(candidateAssessmentsData);
        }
      } catch (e: any) {
        if (e.response.status === 404) return navigate(`/${NOT_FOUND_ROUTE}`);

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCandidateAssessmentsData();
  }, [userId]);

  return { data, isLoading };
}

import moment from "moment";

import { getLocale } from "./commonFormatter";

export function formatDate(date: string, format?: string): string {
  const userLanguage = getLocale();
  return format
    ? moment(date).locale(userLanguage).format(format).toString()
    : moment(date).locale(userLanguage).toString();
}

export function shortFormatDate(date: string): string {
  const formatPattern = "l";
  return formatDate(date, formatPattern);
}

export function longFormatDate(date: string): string {
  return formatDate(date);
}

export function formatDateAndTime(date: string): string {
  const formatPattern = "MMMM Do YYYY, h:mm:ss a";
  return formatDate(date, formatPattern);
}

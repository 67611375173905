import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Select } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { Loading } from "@/shared/components/Loading/Loading";
import { TestItem } from "@/shared/components/assessment/new/TestItem/TestItem";

import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { TestHttpService } from "@/shared/services/tests/test-http.service";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";

import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";

import { ReactComponent as CheckedSVG } from "@/assets/icons/checked.svg";

import { useAllClientTestsHook } from "../../hooks/useAllClientTestsHook";
import { IAssessmentTest, ITest } from "../../types/test.interface";
import { IAssessment } from "../../assessments.types";

import "./index.css";
import "../../styles/assessmentCommon.css";

export function AddTests({ isChangeable = true, isArchived = false }: { isChangeable?: boolean; isArchived: boolean }) {
  const { client_id: clientId, assessment_id: assessmentId } = useParams<{
    client_id: string;
    assessment_id: string;
  }>();

  const { assessment, setAssessment } = useContext(AssessmentContext);

  const { tests, isLoading } = useAllClientTestsHook(Number(clientId));

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedTests, setSelectedTests] = useState<IAssessmentTest[]>([]);

  const initialPlaceholderText = isArchived
    ? "You cannot add tests to the archived assessment"
    : isChangeable
    ? "Select tests"
    : "You cannot add tests, someone accepted this assessment";
  const [placeholder, setPlaceholder] = useState<string>(initialPlaceholderText);

  useEffect(() => {
    if (assessment?.assessmentTests) {
      setSelectedTests([...assessment.assessmentTests]);
      setSelectedItems(assessment.assessmentTests.map(({ test }) => test.name));
    }
  }, [assessment]);

  const onTestChange = async (event: string[]) => {
    let isIncreased = event.length > selectedItems.length;

    const difference = isIncreased
      ? event.filter((item) => !selectedItems.includes(item))
      : selectedItems.filter((item) => !event.includes(item));

    for (let i = 0; i < difference.length; i++) {
      const updatedTest: ITest | undefined = tests.find((item) => item.name === difference[i]);

      if (!updatedTest) {
        return;
      }

      try {
        if (isIncreased) {
          await TestHttpService.addTestToAssessment(
            String(clientId),
            String(assessmentId),
            String(updatedTest?.testId),
            {
              rank: selectedItems.length + 1,
            },
          );
        } else {
          await TestHttpService.deleteTestFromAssessmentById(
            String(clientId),
            String(assessmentId),
            String(updatedTest?.testId),
          );
        }
      } catch (e) {
        console.error(`Update tests error: ${e}`);
      }
    }

    setSelectedItems(event);

    const updAssessment: IAssessment = await AssessmentHttpService.getAssessmentByID(Number(assessmentId));
    if (updAssessment.assessmentTests) {
      setSelectedTests([...updAssessment.assessmentTests]);
    }

    if (setAssessment) {
      setAssessment(updAssessment);
    }
  };

  const handleDeleteTest = async (id: number) => {
    if (!assessment || !assessment.assessmentTests) {
      return;
    }

    const testForRemove: ITest | undefined = tests.find((item) => item.testId === id);

    await TestHttpService.deleteTestFromAssessmentById(
      String(clientId),
      String(assessmentId),
      String(testForRemove?.testId),
    );

    const updAssessment: IAssessment = await AssessmentHttpService.getAssessmentByID(Number(assessmentId));
    if (updAssessment.assessmentTests) {
      setSelectedTests([...updAssessment.assessmentTests]);
      setSelectedItems(updAssessment.assessmentTests.map(({ test }) => test.name));
    }

    if (setAssessment) {
      setAssessment(updAssessment);
    }
  };

  const onDragEnd = async ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    const rangedTests = Array.from(selectedTests);
    const [newOrder] = rangedTests.splice(source.index, 1);
    rangedTests.splice(destination.index, 0, newOrder);

    const rank = destination.index + 1;

    // TODO: Move to utils
    const filteredRank = rangedTests
      .map((aT) => {
        if (aT.testId === newOrder.test.testId) {
          return { ...aT, rank };
        }

        if (rank === aT.rank) {
          if (rank > newOrder.rank) {
            return { ...aT, rank: aT.rank - 1 };
          }

          if (rank < newOrder.rank) {
            return { ...aT, rank: aT.rank + 1 };
          }
        }

        return { ...aT, rank: rank > aT.rank ? aT.rank - 1 : aT.rank + 1 };
      })
      .sort((a, b) => {
        if (a.rank > b.rank) {
          return 1;
        }

        if (a.rank < b.rank) {
          return -1;
        }

        return 0;
      })
      .map((aT, index) => ({ ...aT, rank: index + 1 }));
    setSelectedTests(filteredRank);

    await TestHttpService.updateTestInAssessment(String(clientId), String(assessmentId), String(newOrder.test.testId), {
      rank,
    });

    const updAssessment: IAssessment = await AssessmentHttpService.getAssessmentByID(Number(assessmentId));
    if (updAssessment.assessmentTests) {
      setSelectedTests([...updAssessment.assessmentTests]);
    }
    if (setAssessment) {
      setAssessment(updAssessment);
    }
  };

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="addTestContainer">
        <div className="selectContainer">
          <Select
            mode="multiple"
            value={[...selectedItems]}
            showArrow
            placeholder={placeholder}
            maxTagPlaceholder={placeholder}
            onDropdownVisibleChange={(open) => {
              open ? setPlaceholder("") : setPlaceholder(initialPlaceholderText);
            }}
            maxTagCount={0}
            onChange={onTestChange}
            className="selector"
            menuItemSelectedIcon={<CheckedSVG />}
            disabled={isArchived || !isChangeable || !canManageAssessment}
            dropdownMatchSelectWidth={750}
            placement="bottomLeft"
            dropdownAlign={{ offset: [0, 10] }}
            options={tests.map((item) => {
              return {
                label: item.name,
                value: item.name,
              };
            })}
          />
        </div>

        {selectedTests.length === 0 && <p>You haven't added any test yet</p>}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"testsList"} isDropDisabled={!canManageAssessment || isArchived}>
            {(provided) => (
              <div
                className={"testList"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  width: "100%",
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedTests
                  .sort((a, b) => a.rank - b.rank)
                  .map(({ testId, rank, normId, test }, index) => (
                    <Draggable
                      key={testId}
                      draggableId={String(testId)}
                      index={index}
                      isDragDisabled={isArchived || !isChangeable || !canManageAssessment}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <TestItem
                            isChangeable={!isArchived && (isChangeable || !canManageAssessment)}
                            id={testId}
                            rank={rank}
                            normId={normId}
                            title={test.name}
                            elementList={test.testElements}
                            onDeleteClick={canManageAssessment && handleDeleteTest}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

import React from "react";
import { StatementsMap } from "@/modules/assessments/services/scales.service";
import style from "@/modules/assessments/scenes/AssessmentReport/Overall/style.module.css";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";

export function Overview({ assessmentTests, statements }: { assessmentTests?: IAssessmentTest[]; statements: StatementsMap }) {
  return (
    <>
      {Object.keys(statements).length !== 0 && (
        <div>
          <h2 className={style.overview}>Overview</h2>
          {assessmentTests?.map(({ testId }) => {
            const paragraphs = statements[testId] as Record<string, string[]>;
            if (!paragraphs) return null;

            return (
              <div key={testId} className={style.overallParagraph}>
                {Object.values(paragraphs).map((statements: string[], index: number) => (
                  <p key={index}>{statements.join(" ")}</p>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

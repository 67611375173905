import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Checkbox, Col, notification, Row, Tabs, Tooltip } from "antd";
import { useGetClientAssessmentInvitesStatsHook } from "@/modules/assessments/hooks/use-get-client-assessment-invites-stats.hook";
import AssessmentCompare from "@/modules/assessments/scenes/AssessmentCompare/AssessmentCompare";
import AssessmentReport from "@/modules/assessments/scenes/AssessmentReport/AssessmentReport";
import { capitalizeFirstLetter, convertToTimeFormat } from "@/shared/utils/common.utils";
import { getRoute } from "@/shared/utils/route/route.utils";
import { useAssessmentHook } from "@/shared/hooks/assessments/useAssessment.hook";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";
import { Loading } from "@/shared/components/Loading/Loading";
import { StatisticsBlock } from "@/shared/components/assessment/edit/StatisticsBlock/StatisticsBlock";
import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import {
  ASSESSMENT_EDIT_COMPARE_ROUTE,
  ASSESSMENT_EDIT_INVITES_ROUTE,
  ASSESSMENT_EDIT_TESTS_ROUTE,
  ASSESSMENT_ROUTE,
  CLIENT_ROUTE,
  COMPETENCIES_ROUTE,
  SETTINGS_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import { ReactComponent as AlertSVG } from "@/assets/icons/feather-icons/alert-triangle.svg";
import { ReactComponent as TrashSVG } from "@/assets/icons/feather-icons/trash.svg";
import { FileZipOutlined, DownloadOutlined } from "@ant-design/icons";
import { AddTests } from "../AddTests/AddTest";
import { AssessmentInvites } from "../AssessmentInvites/AssessmentsInvites";
import { AssessmentSettings } from "../AssessmentSettings/AssessmentSettings";
import { AssessmentCompetencies } from "../AssessmentCompetencies/AssessmentCompetencies";
import { validateCompetencies } from "./utils/tabValidator";
import style from "./style.module.css";
import "../../styles/assessmentCommon.css";
import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";
import { NextModalFooter } from "@/modules/candidate/assessment/CandidateAssessment/components/modals/ConfirmNextModal/NextModalFooter";
import { getFriendlyErrorMessage } from "@/shared/services/http";

const { TabPane } = Tabs;

function downloadFile(blob: Blob, filename: string): void {
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

function getFilenameFromContentDisposition(contentDisposition: string): string | null {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  return matches && matches[1] ? matches[1].replace(/['"]/g, "") : null;
}

export function AssessmentEditHeader() {
  const {
    client_id: clientId,
    assessment_id: assessmentId,
    invite_id: inviteId,
    tab_type: activeTab,
  } = useParams<{
    client_id: string;
    assessment_id: string;
    invite_id: string;
    tab_type: string;
  }>();
  const DEFAULT_ASSESSMENT_PAGE_ROUTE = `/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${assessmentId}`;
  const [isActionLoading, setActionLoading] = useState(false);
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const navigate = useNavigate();
  const [archiveOptions, setArchiveOptions] = useState({ keepTeam: false });
  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);
  const canAddUserToAssessment = assessmentAbility.can(Action.Create, Subject.ASSESSMENT_USER_PERMISSION);
  const {
    clientAssessmentInvitesStats: { accepted, done, in_progress, invited, started, with_an_error, rescinded },
    isLoading: invitesStatsLoading,
  } = useGetClientAssessmentInvitesStatsHook(Number(assessmentId));
  const isEmpty = accepted + done + in_progress + invited + started + with_an_error + rescinded === 0;
  const [claimedQuantity, setClaimedQuantity] = useState(0);

  useEffect(() => {
    setClaimedQuantity(accepted + started + in_progress + done);
  }, [accepted, started, in_progress, done]);

  const isChangeable: boolean = claimedQuantity === 0;

  const { assessment, isLoading } = useAssessmentHook(Number(assessmentId));

  let allQuestions = 0;
  let allTime = "";

  if (assessment && assessment.assessmentTests) {
    allQuestions = assessment.assessmentTests.reduce(
      (sum: number, element) => sum + element.test.testElements.length,
      0,
    );
    allTime = convertToTimeFormat(
      assessment.assessmentTests.reduce((sum: number, element) => sum + element.test.config.suggestedTime || 0, 0),
    );
  }

  const [isTestsTabValid, setIsTestsTabValid] = useState<boolean>(true);
  const [isCompetenciesTabValid, setIsCompetenciesTabValid] = useState<boolean>(true);
  const handleArchiveAssessment = async () => {
    try {
      setArchiveModalOpen(false);
      setActionLoading(true);
      await AssessmentHttpService.archiveAssessmentByID(Number(clientId), Number(assessmentId), archiveOptions);
      navigate(`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}`);
      notification.success({ message: "Successfully archived." });
    } catch (e) {
      console.error(`archive assessment error: ${e}`);
      notification.error({ message: JSON.stringify(e) });
    } finally {
      setActionLoading(false);
    }
  };

  const handleExportAssessment = async () => {
    try {
      setActionLoading(true);
      const response = await AssessmentHttpService.exportAssessment(Number(clientId), Number(assessmentId));
      const filename =
        getFilenameFromContentDisposition(response.headers["content-disposition"]) ??
        `assessments_${assessmentId}_export_${new Date().getTime()}.csv`;

      downloadFile(response.data, filename);
      notification.success({ message: "Successfully exported." });
    } catch (e) {
      notification.error({ message: getFriendlyErrorMessage(e) ?? "Oops, something went wrong. Please try again." });
    } finally {
      setActionLoading(false);
    }
  };

  const handleRemoveAssessment = async () => {
    try {
      setActionLoading(true);
      await AssessmentHttpService.removeAssessmentByID(Number(clientId), Number(assessmentId));
      navigate(`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}`);
      notification.success({ message: "Successfully removed." });
    } catch (e) {
      console.error(`remove assessment error: ${e}`);
      notification.error({ message: JSON.stringify(e) });
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading && assessment && assessment.assessmentTests) {
      const testsWithCompetencies = assessment.assessmentTests.map((test) => {
        return test.config.competencies;
      });
      setIsCompetenciesTabValid(validateCompetencies(testsWithCompetencies));
      setIsTestsTabValid(assessment.assessmentTests.length !== 0);
    }
  }, [assessment, isLoading]);

  useRouteAccessController({
    slug: ASSESSMENT_EDIT_TESTS_ROUTE,
    hasPermission: canManageAssessment,
  });

  useRouteAccessController({
    slug: COMPETENCIES_ROUTE,
    hasPermission: canManageAssessment,
  });

  useRouteAccessController({
    slug: SETTINGS_ROUTE,
    hasPermission: canAddUserToAssessment,
  });

  if (isLoading || !assessment || invitesStatsLoading || isActionLoading) {
    return <Loading />;
  }

  const isArchived = Boolean(assessment.archivedOn);

  return (
    <>
      <BasicModal
        autoHeight
        handleCancel={() => setArchiveModalOpen(false)}
        isModalOpen={isArchiveModalOpen}
        footer={<NextModalFooter handleOk={handleArchiveAssessment} handleCancel={() => setArchiveModalOpen(false)} />}
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <p>Are you sure want to archive the assessment?</p>

          <div style={{ marginTop: "40px", marginBottom: "20px" }}>
            <Checkbox
              checked={archiveOptions.keepTeam}
              onClick={() => setArchiveOptions({ keepTeam: !archiveOptions.keepTeam })}
            >
              Keep Team?
            </Checkbox>
          </div>
        </div>
      </BasicModal>

      <SEO
        titleParams={[
          assessment.name,
          capitalizeFirstLetter(String(activeTab === "invites" ? "Candidates" : activeTab)),
        ]}
      />

      <div className="main-container">
        <div className={style.wrapper}>
          <div className={style.assessmentEditHeader}>
            <Row>
              <Col span={12} className={style.descriptionBlock}>
                <div style={{ marginRight: "20px" }}>
                  <Banner
                    title={String(assessment?.name)}
                    description={
                      String(assessment?.description) === "undefined" ? " " : String(assessment?.description)
                    }
                  >
                    <div className={style.jobDescriptionContainer}>
                      <div className={style.jobDescriptionComponent}>
                        <p>
                          <span>Company Job Title:</span> {assessment?.clientJobTitle}
                        </p>
                      </div>
                      <div className={style.jobDescriptionComponent}>
                        <p>
                          <span>Job Role:</span> {assessment.role?.name}
                        </p>
                      </div>
                    </div>
                  </Banner>
                </div>
              </Col>
              <Col span={12}>
                <StatisticsBlock
                  allTests={assessment.assessmentTests?.length || 0}
                  allQuestions={allQuestions}
                  invitedPeople={invited}
                  allTime={allTime}
                  claimedPeople={claimedQuantity}
                  startedPeople={started}
                  acceptedPeople={accepted}
                  finishedPeople={done}
                  inProgressPeople={in_progress}
                />
              </Col>
            </Row>

            {canManageAssessment && (
              <>
                <div style={{ display: "flex", justifyContent: "right", marginRight: "5px" }}>
                  <CustomButton
                    disabled={Boolean(assessment.archivedOn) || !canManageAssessment}
                    title="archive assessment"
                    colorType={ButtonColorType.transparentDark}
                    buttonSize={ButtonSize.small}
                    onClick={() => setArchiveModalOpen(true)}
                  >
                    <FileZipOutlined />
                  </CustomButton>
                  <CustomButton
                    disabled={isEmpty || !canManageAssessment}
                    title="export assessment"
                    colorType={ButtonColorType.transparentDark}
                    buttonSize={ButtonSize.small}
                    onClick={handleExportAssessment}
                  >
                    <DownloadOutlined />
                  </CustomButton>
                </div>
              </>
            )}
          </div>

          <Tabs
            type="card"
            defaultActiveKey={"settings"}
            activeKey={activeTab}
            className="tabs"
            tabBarExtraContent={
              canManageAssessment && (
                <div className={style.removeButtonContainer}>
                  <CustomButton
                    disabled={!isEmpty || !canManageAssessment}
                    title="remove assessment"
                    colorType={ButtonColorType.transparentDark}
                    buttonSize={ButtonSize.small}
                    onClick={handleRemoveAssessment}
                  >
                    <TrashSVG aria-hidden="true" />
                  </CustomButton>
                </div>
              )
            }
          >
            {canManageAssessment && (
              <>
                <TabPane
                  tab={
                    <Link
                      to={getRoute(DEFAULT_ASSESSMENT_PAGE_ROUTE, ASSESSMENT_EDIT_TESTS_ROUTE)}
                      className={style.tabTitleContainer}
                    >
                      <span className={cn(style.tabName, style.tabNameWithIcon)}>Tests</span>
                      <div className={style.tabIconContainer}>
                        {!isTestsTabValid && (
                          <Tooltip title="No tests have been added yet">
                            <span>
                              <AlertSVG />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Link>
                  }
                  key={ASSESSMENT_EDIT_TESTS_ROUTE}
                >
                  <AddTests isChangeable={isChangeable} isArchived={isArchived} />
                </TabPane>
                <TabPane
                  tab={
                    <Link
                      to={getRoute(DEFAULT_ASSESSMENT_PAGE_ROUTE, COMPETENCIES_ROUTE)}
                      className={style.tabTitleContainer}
                    >
                      <span className={cn(style.tabName, style.tabNameWithIcon)}>Competencies</span>
                      <div className={style.tabIconContainer}>
                        {!isCompetenciesTabValid && (
                          <Tooltip title="There are test(s) with no turned on competencies">
                            <span>
                              <AlertSVG />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Link>
                  }
                  key={COMPETENCIES_ROUTE}
                >
                  <AssessmentCompetencies disabled={!isChangeable || isArchived} />
                </TabPane>
              </>
            )}
            {canAddUserToAssessment && (
              <TabPane
                tab={
                  <Link
                    to={getRoute(DEFAULT_ASSESSMENT_PAGE_ROUTE, SETTINGS_ROUTE)}
                    className={style.tabTitleContainer}
                  >
                    <span className={style.tabName}>Settings</span>
                  </Link>
                }
                key={SETTINGS_ROUTE}
              >
                <AssessmentSettings isArchived={isArchived} />
              </TabPane>
            )}
            <TabPane
              className={style.tab}
              tab={
                <Link
                  to={getRoute(DEFAULT_ASSESSMENT_PAGE_ROUTE, ASSESSMENT_EDIT_INVITES_ROUTE)}
                  className={style.tabTitleContainer}
                >
                  <span className={style.tabName}>Candidates</span>
                </Link>
              }
              key={ASSESSMENT_EDIT_INVITES_ROUTE}
            >
              {inviteId ? (
                <AssessmentReport />
              ) : (
                <AssessmentInvites
                  isSendable={isTestsTabValid && isCompetenciesTabValid}
                  stats={{ accepted, done, in_progress, invited, started, with_an_error, rescinded }}
                />
              )}
            </TabPane>
            <TabPane
              className={style.tab}
              disabled={done < 2}
              tab={
                done < 2 ? (
                  <span className={style.tabName}>Compare</span>
                ) : (
                  <Link
                    to={getRoute(DEFAULT_ASSESSMENT_PAGE_ROUTE, ASSESSMENT_EDIT_COMPARE_ROUTE)}
                    className={style.tabTitleContainer}
                  >
                    <span className={style.tabName}>Compare</span>
                  </Link>
                )
              }
              key={ASSESSMENT_EDIT_COMPARE_ROUTE}
            >
              <AssessmentCompare />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}

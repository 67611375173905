import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Firebase } from "@/shared/services/firebase";
import { useAuthHook } from "@/shared/hooks/auth/useAuth.hook";

import ErrorPage from "@/modules/main/ErrorPage/ErrorPage";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { CANDIDATE_ROUTE, FORBIDDEN_REASON_SEARCH, LOGIN_ROUTE } from "@/routing/AppRouter/routes.constants";

export enum ForbiddenReasons {
  acceptedInvite = "invite_was_accepted_by_other_user",
  unavailableClient = "unavailable_client",
  rescindedInvite = "rescinded_invite",
}

const textMessages: { [text: string]: string } = {
  default: "Sorry, this page is forbidden. Please, authorize.",
  [ForbiddenReasons.acceptedInvite]:
    "This invite was claimed by someone else. Please try to log in with the email you used when you first registered. If you think this is a mistake, please reply to your invite email and we’ll help!",
  [ForbiddenReasons.unavailableClient]: "Sorry, this page is forbidden. You have no access to this client yet.",
  [ForbiddenReasons.rescindedInvite]:
    "Sorry, this is no longer available. If you think this is a mistake, please reach out to the hiring manager.",
};
const buttonTitle: { [text: string]: string } = {
  default: "log in",
  [ForbiddenReasons.acceptedInvite]: "log out",
  [ForbiddenReasons.unavailableClient]: "open dashboard",
  [ForbiddenReasons.rescindedInvite]: "open dashboard",
};
const navigationRoute: { [text: string]: string } = {
  [ForbiddenReasons.acceptedInvite]: `/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`,
  [ForbiddenReasons.unavailableClient]: "/clients",
  [ForbiddenReasons.rescindedInvite]: "/clients",
};

export interface ICommonErrorPage {
  isSignedIn: boolean;
  isAdmin: boolean;
  isClient: boolean;
  isLoading?: boolean;
}

export default function Forbidden({ isSignedIn, isAdmin, isClient }: ICommonErrorPage) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const reason: string | null = new URLSearchParams(search).get(FORBIDDEN_REASON_SEARCH);

  const firebase = new Firebase();
  const { signOut } = useAuthHook(firebase);

  const handleButtonClick = async () => {
    if (isSignedIn && !isClient && reason !== ForbiddenReasons.rescindedInvite) {
      await signOut();
    }
    navigate((reason && navigationRoute[reason]) || "/");
  };

  return (
    <ErrorPage
      isSignedIn={isSignedIn}
      isAdmin={isAdmin}
      isClient={isClient}
      errorReason={(reason && textMessages[reason]) || textMessages.default}
    >
      <CustomButton
        title={(reason && isSignedIn && buttonTitle[reason]) || buttonTitle.default}
        onClick={handleButtonClick}
      />
    </ErrorPage>
  );
}

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

interface PermissionGuardProps {
  slug: string;
  hasPermission: boolean;
  redirectPath?: string;
}

export const useRouteAccessController = ({
  slug,
  hasPermission,
  redirectPath = NOT_FOUND_ROUTE,
}: PermissionGuardProps): void => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    const isMatchingSlug = pathname.endsWith(slug);

    if (isMatchingSlug && !hasPermission) {
      navigate(`/${redirectPath}`.replace(/\/\//g, "/"));
    }
  }, [hasPermission, navigate, slug, pathname, redirectPath]);
};

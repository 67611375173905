import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";

import { SubmitStatuses } from "@/shared/components/testManagement/TestElementInfoBlock";
import { AssessmentContext } from "@/shared/contexts/assessment.context";

import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";

import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { ClientCompetenciesService } from "@/modules/client/services/client-competencies.service";
import { CompetencyHttpService } from "@/modules/assessments/scenes/AddTests/services/competency-http.service";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ICompetencyView } from "@/modules/assessments/scenes/AssessmentCompetencies/AssessmentCompetencies";
import { CompetencyRangeComponent } from "@/modules/assessments/scenes/AssessmentCompetencies/CompetencyRangeComponent";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import commonStyle from "@/modules/assessments/scenes/AssessmentCompetencies/style.module.css";
import style from "./style.module.css";

import isTruthyOrNumber from "@/shared/utils/comparison/isTruthyOrNumber";

export function AssessmentCompetencyBlock({
  testId,
  testName,
  initialCompetencies,
  competenciesName,
  competencies,
  disabled = false,
}: ICompetencyView) {
  const { client_id: clientId, assessment_id: assessmentId } = useParams<{
    client_id: string;
    assessment_id: string;
  }>();
  const { client } = useClientByIdHook(Number(clientId));
  const { setAssessment } = useContext(AssessmentContext);

  const competenciesKeys = Object.keys(initialCompetencies).sort((a, b) =>
    ClientCompetenciesService.compareCompetenciesByName({
      firstKey: a,
      secondKey: b,
      testId,
      competencies: competencies,
    }),
  );

  const initialCompetencyValues = Object.values(initialCompetencies);
  const hasOneEnabledCompetency = initialCompetencyValues.length === 1 && isTruthyOrNumber(initialCompetencyValues[0]);

  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tempCompetencies, setTempCompetencies] = useState(initialCompetencies);

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      await CompetencyHttpService.updateCompetencyRangeInAssessment(Number(clientId), Number(assessmentId), testId, {
        competencies: tempCompetencies,
      });
      setAlert(SubmitStatuses.success);
    } catch (e: any) {
      setAlert(SubmitStatuses.error);
      console.error(`competency patch parameters ${e}`);
    } finally {
      setIsLoading(false);
    }

    const updAssessment: IAssessment = await AssessmentHttpService.getAssessmentByID(Number(assessmentId));

    if (setAssessment) {
      setAssessment(updAssessment);
    }
  };

  const handleSetValue = (competencyName: string, competencyValue: boolean | number) => {
    setTempCompetencies((prevState) => {
      return { ...prevState, [competencyName]: competencyValue };
    });
  };

  const handleSetCheck = (competencyName: string, checked: boolean) => {
    setTempCompetencies((prevState) => {
      return { ...prevState, [competencyName]: checked };
    });
  };

  const handleSelectAll = () => {
    let updatedCompetencies: { [key: string]: boolean | number } = {};
    competenciesKeys.forEach((competencyName) =>
      !Boolean(tempCompetencies[competencyName])
        ? Object.assign(updatedCompetencies, { [competencyName]: true })
        : Object.assign(updatedCompetencies, {
            [competencyName]: tempCompetencies[competencyName],
          }),
    );
    setTempCompetencies(updatedCompetencies);
  };
  const handleUnselectAll = () => {
    let updatedCompetencies: { [key: string]: boolean | number } = {};
    competenciesKeys.forEach((competencyName) => Object.assign(updatedCompetencies, { [competencyName]: false }));
    setTempCompetencies(updatedCompetencies);
  };

  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert message={"Something went wrong. Please, retry later."} type={"error"} showIcon />;
      case SubmitStatuses.success:
        return <Alert message={"Success"} type={"success"} showIcon />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };
  const title = competenciesName ? `${competenciesName[1]} (${testName})` : `Competencies (${testName})`;

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);

  return (
    <div className="tabs-inner-container-desktop">
      <div className={style.testCompetenciesContainer}>
        <div className={style.blockHeader}>
          <p className={style.title}>{title}</p>
          <div className={style.doubleButtonContainer}>
            <CustomButton
              disabled={disabled || !canManageAssessment || hasOneEnabledCompetency}
              title={"Unselect all"}
              colorType={ButtonColorType.transparentDark}
              buttonSize={ButtonSize.small}
              onClick={handleUnselectAll}
            />
            <CustomButton
              disabled={disabled || !canManageAssessment || hasOneEnabledCompetency}
              title={"Select all"}
              colorType={ButtonColorType.transparentDark}
              buttonSize={ButtonSize.small}
              onClick={handleSelectAll}
            />
          </div>
        </div>

        {competenciesKeys.map((key) => {
          const competency = ClientCompetenciesService.getCustomCompetencyName({
            base64Key: key,
            clientConfig: client?.config,
            testId,
            competencies: competencies!,
          });

          return (
            <CompetencyRangeComponent
              key={`${testId}_${key}`}
              competencyKey={key}
              customCompetencyName={competency}
              canChangeWeighting={isTruthyOrNumber(tempCompetencies[key]) && !disabled && canManageAssessment}
              canToggleInclusion={!disabled && canManageAssessment && !hasOneEnabledCompetency}
              competencyValue={tempCompetencies[key]}
              setValue={handleSetValue}
              setToggle={handleSetCheck}
            />
          );
        })}
        <div className={commonStyle.buttonContainer}>
          <CustomButton
            title="Save"
            onClick={handleSaveClick}
            isLoading={isLoading}
            buttonSize={ButtonSize.small}
            disabled={disabled || !canManageAssessment}
          >
            <SaveSVG className={style.svgSize} aria-hidden="true" />
          </CustomButton>
          <div className={style.alertContainer}>{getAlert(alert)}</div>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "@/shared/components/Loading/Loading";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { sortElements } from "@/shared/utils/array/sort.utils";

import { useGetFilteredCompetenciesHook } from "@/modules/admin/testManagement/hooks/use-get-filtered-competencies.hook";

import { EditCompetencyForm } from "./EditCompetencyForm";

import style from "../TestSettings/style.module.css";

export function TestCompetencies() {
  const { test_id: testId } = useParams();

  const [refreshData, setRefreshData] = useState({});
  const { competencies, filteredCompetencies, isLoading, handleSearch } = useGetFilteredCompetenciesHook(
    Number(testId),
    refreshData,
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={style.innerTabsContainer}>
      <TableHeader tableName={""} handleSearchChange={handleSearch} />
      {filteredCompetencies
        .sort((a, b) => sortElements(a.name, b.name))
        .map((competency) => (
          <EditCompetencyForm
            competency={competency}
            key={competency.competencyId}
            canEditDefaultEnabled={competencies.length > 1 || !competency.isDefaultEnabled}
            refreshData={() => setRefreshData({})}
          />
        ))}
    </div>
  );
}

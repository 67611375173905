import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";

import { IClient } from "@/modules/client/clients.interface";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { ReduxClientService } from "@/modules/client/services/redux-client.service";

import { Loading } from "@/shared/components/Loading/Loading";

import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { addClients, selectClientData } from "@/shared/services/store/clientStore";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CustomInput } from "@/ui-components/commonComponents/Input/Input";
import { UploadPhoto, UploadFolder } from "@/ui-components/commonComponents/UploadPhoto/UploadPhoto";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import { CLIENT_ROUTE } from "@/routing/AppRouter/routes.constants";

import style from "./style.module.css";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function GeneralClientSettings({
  client,
  isClientLoading,
}: {
  client: IClient | undefined;
  isClientLoading: boolean | undefined;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxClients = useSelector(selectClientData);

  const [form] = Form.useForm();
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (client) {
      setImageUrl(client.logoUrl);
      setIsLoading(false);
    }
  }, [client]);

  const handleFinish = async (values: { name: string }) => {
    if (!client?.clientId) {
      throw new Error("There is no client ID provided");
    }

    const clientData: { name: string; logoUrl: string } = {
      name: values.name,
      logoUrl: imageUrl,
    };

    try {
      setIsLoading(true);
      await ClientHttpService.updateClientById(clientData, Number(client.clientId));

      dispatch(
        addClients(
          ReduxClientService.updateClientService(reduxClients, {
            ...client,
            logoUrl: clientData.logoUrl,
            name: clientData.name,
          }),
        ),
      );
    } catch (e) {
      console.error(`clientDataUPD: ${e}`);
    } finally {
      setIsLoading(false);
      navigate(`/${CLIENT_ROUTE}/${client.clientId}`);
    }
  };

  const ability = useContext(ClientAbilityContext);
  const canUpdateClientLogo = ability.can(Action.Update, Subject.CLIENT, "logoUrl");
  const canUpdateClientName = ability.can(Action.Update, Subject.CLIENT, "name");

  if (isClientLoading || isLoading) {
    return <Loading />;
  }

  return (
    <div className={style.container}>
      <Form
        form={form}
        {...formItemLayout}
        onFinish={handleFinish}
        initialValues={{
          name: client && client.name,
          logo: imageUrl,
        }}
      >
        <div className={style.form}>
          <Form.Item className={style.photoContainer} name="logo" getValueFromEvent={normFile}>
            <UploadPhoto
              disabled={!canUpdateClientLogo}
              clientID={String(client && client.clientId)}
              onUpload={setImageUrl}
              uploadText="upload logo"
              defaultImageUrl={imageUrl}
              uploadFolder={UploadFolder.clientProfileFolder}
            />
          </Form.Item>
          <Form.Item
            className={style.inputTitle}
            key="name"
            name="name"
            label="Client Name"
            rules={[
              {
                required: true,
                message: "Input Client Name",
              },
            ]}
          >
            <CustomInput placeholder="Type..." disabled={!canUpdateClientName} />
          </Form.Item>
        </div>

        <CustomButton title="Save" isLoading={isLoading} disabled={!(canUpdateClientLogo || canUpdateClientName)}>
          <SaveSVG aria-hidden="true" />
        </CustomButton>
      </Form>
    </div>
  );
}

import { useEffect, useState } from "react";

import { ITestElement } from "@/modules/assessments/types/test.interface";
import { useGetTestInstructions } from "@/shared/hooks/tests/use-get-test-instructions";

export function useAssessmentInstructionsHook({
  testId,
  currentPage,
  questionsList,
}: {
  testId: number;
  currentPage: number;
  questionsList: ITestElement[][];
}) {
  const [isOpenedInstructions, setIsOpenedInstructions] = useState(false);

  const { testInstructions, isLoading } = useGetTestInstructions(testId, currentPage);

  useEffect(() => {
    if (questionsList[0].length !== 0 && currentPage !== 0 && currentPage <= questionsList.length) {
      setIsOpenedInstructions(true);
    }
  }, [testId]);

  return { isOpenedInstructions, setIsOpenedInstructions, testInstructions, isLoading };
}

import React from "react";
import cn from "classnames";
import { Upload } from "antd";

import { UploadProps } from "antd/lib/upload/interface";
import { LoadingOutlined } from "@ant-design/icons";

import { AssetsUtils } from "@/shared/services/assets/assets.utils";

import { useUploadAssessmentImageHook } from "@/ui-components/commonComponents/UploadPhoto/hooks/useUploadAssessmentImage.hook";

import { ReactComponent as UploadSVG } from "@/assets/icons/image.svg";

import { useUploadClientLogoHook } from "./hooks/useUploadClientLogo.hook";

import style from "./style.module.css";

export enum UploadFolder {
  assessmentFolder,
  clientProfileFolder,
}

type TUploadPhotoProps = UploadProps & {
  clientID: string;
  onUpload: (data: string) => void;
  uploadFolder: UploadFolder;
  defaultImageUrl?: string;
  disabled?: boolean;
  uploadText?: string;
};

export function UploadPhoto({
  clientID,
  onUpload,
  defaultImageUrl = "",
  disabled = false,
  uploadText = "add title photo",
  uploadFolder,
}: TUploadPhotoProps) {
  const uploadStrategy = {
    [UploadFolder.clientProfileFolder]: useUploadClientLogoHook(defaultImageUrl, onUpload, clientID),
    [UploadFolder.assessmentFolder]: useUploadAssessmentImageHook(defaultImageUrl, onUpload, clientID),
  };

  const { imageUrl, photoError, isLoading, uploadFile } = uploadStrategy[uploadFolder];

  return (
    <div
      className={cn(style.uploaderContainer, {
        [style.errorUpload]: photoError,
      })}
    >
      <Upload.Dragger
        style={{ backgroundColor: "transparent" }}
        name={"file"}
        maxCount={1}
        showUploadList={false}
        listType={"picture-card"}
        customRequest={uploadFile}
        className={cn(style.photoUploader)}
        beforeUpload={AssetsUtils.imageValidation}
        disabled={disabled}
      >
        {imageUrl ? (
          <div className={style.imgContainer}>
            <img src={imageUrl} alt="Custom upload" style={{ width: "100%" }} />
          </div>
        ) : isLoading ? (
          <div className={style.uploadIcon}>
            <LoadingOutlined style={{ fontSize: "50px" }} />
          </div>
        ) : (
          <div>
            <UploadSVG aria-hidden="true" />
            <p className={style.uploadTitle}>{uploadText}</p>
          </div>
        )}
      </Upload.Dragger>
    </div>
  );
}

import { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/shared/components/Loading/Loading";
import {
  PublicReportComponent,
  ReportScene,
  IPublicAssessment,
  IPublicAssessmentInvite,
} from "@/shared/components/assessment/report/PublicReportComponent/PublicReportComponent";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { ASSESSMENT_ROUTE, CLIENT_ROUTE } from "@/routing/AppRouter/routes.constants";
import { httpClient } from "@/shared/services/http";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import PSPLogo from "@/assets/image/psp-logo.svg";
import style from "./style.module.css";
import { NOT_FOUND_ROUTE, SERVER_FAIL_ROUTE } from "@/routing/AppRouter/routes.constants";
import { addCompetencies } from "@/shared/services/store/competenciesStore";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

interface IPublicReport {
  invite: IPublicAssessmentInvite;
  assessment: IPublicAssessment;
  answers: Array<ITestAnswer>;
}

function usePublicReport(shareUrl: string | undefined, isAuthLoading: boolean) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useContext(ClientAbilityContext);
  const [report, setReport] = useState<IPublicReport>();

  useEffect(() => {
    if (isAuthLoading || !shareUrl) return;

    async function getReport() {
      try {
        const { data }: { data: IPublicReport } = await httpClient.get(`/api/reports/${shareUrl}`);

        // In the future this could be cached in Redux (or elsewhere) so that the other component wouldn't need to refetch the data
        if (ability.can(Action.Read, Subject.ASSESSMENT)) {
          return navigate(
            `/${CLIENT_ROUTE}/${data.assessment.clientId}/${ASSESSMENT_ROUTE}/${data.assessment.assessmentId}/invites/${data.invite.inviteId}`,
          );
        }
        dispatch(addCompetencies(data.assessment.assessmentTests.map(({ test }) => test.competencies).flat()));
        setReport(data);
      } catch (e: any) {
        const errorRoute = e.response?.status?.toString()?.startsWith("4") ? NOT_FOUND_ROUTE : "/" + SERVER_FAIL_ROUTE; // there's a routing issue where /404 redirects to login. Should sort out eventually
        return navigate(errorRoute);
      }
    }

    getReport();
  }, [isAuthLoading, ability, shareUrl, navigate, dispatch]);

  return { report };
}

export function SharedReport({ firebaseLoading }: { firebaseLoading: boolean }) {
  const { share_url: shareUrl } = useParams<{ share_url: string }>();
  const { report } = usePublicReport(shareUrl, firebaseLoading);
  const usersAnswers = useMemo(() => {
    if (!report) return [];

    return [{ inviteId: Number(report.invite.inviteId), answers: report.answers }];
  }, [report]);

  if (!report) return <Loading />;

  return (
    <>
      <SEO titleParams={[report.invite.claimedByUser.fullName, report.assessment.name]} />
      <div className={style.sharedReportWrapper}>
        <div className={style.header}>
          <div className={style.logosContainer}>
            <img src={PSPLogo} alt="PSP Metrics" className={style.logoContainer} />
            {report.assessment.client?.logoUrl ? (
              <img
                src={report.assessment.client.logoUrl}
                alt={report.assessment.client.name}
                className={style.logoContainer}
              />
            ) : (
              <p className={style.clientName}>{report.assessment.client.name}</p>
            )}
          </div>
          <div className={style.assessmentDetails}>
            <h1>{report.assessment.name}</h1>
            <div className={style.jobDetailsContainer}>
              <p>
                <strong>Company Job Title:</strong> {report.assessment.clientJobTitle}
              </p>
              <p>
                <strong>Job Role:</strong> {report.assessment.roleName}
              </p>
            </div>
          </div>
        </div>
        <PublicReportComponent
          client={report.assessment.client}
          assessment={report.assessment}
          scene={ReportScene.single}
          user={report.invite.claimedByUser}
          usersAnswers={usersAnswers}
          invite={report.invite}
        />
      </div>
    </>
  );
}

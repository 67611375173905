import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/shared/components/Loading/Loading";
import ErrorPage from "@/modules/main/ErrorPage/ErrorPage";
import WelcomeElement from "@/shared/components/candidate/scenes/WelcomeElement/WelcomeElement";
import { convertToFuzzyTimeFormat } from "@/shared/utils/common.utils";
import { getRouteWithSearchParameter } from "@/shared/utils/route/route.utils";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CandidateAssessmentViewTypes } from "./CandidateAssessment";
import CandidateAssessmentDesktop from "./CandidateAssessmentDesktop";
import { TextLocations } from "@/modules/assessments/scenes/CreateAssessment/createAssessment.constants";
import { LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";
import {
  CANDIDATE_ROUTE,
  LOGIN_ROUTE,
  ASSESSMENT_ID_SEARCH,
  ASSESSMENT_CODE_SEARCH,
} from "@/routing/AppRouter/routes.constants";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import CandidateAssessmentMobile from "./CandidateAssessmentMobile";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";

type AssessmentPreview = {
  name: string;
  client: {
    name: string;
    logoUrl?: string;
  };
  titleImageUrl?: string;
  assessmentTexts: Array<{
    body: Record<string, string>;
    location: {
      name: string;
    };
  }>;
  meta: {
    totalQuestions: number;
    totalTests: number;
    totalTime: number;
  };
};

function useAssessmentPreview({ code, assessmentId }: { code?: string; assessmentId?: string }) {
  const [error, setError] = useState();
  const [assessment, setAssessment] = useState<AssessmentPreview>();

  useEffect(() => {
    if (!code && !assessmentId) {
      return;
    }

    async function getAssessmentPreview() {
      try {
        const { data } = await AssessmentHttpService.getAssessmentPreview({ code, assessmentId });
        setAssessment(data);
      } catch (e: any) {
        setError(e);
      }
    }

    getAssessmentPreview();
  }, [code, assessmentId]);
  return {
    assessment,
    error,
  };
}

export default function PublicCandidateRegistration() {
  const navigate = useNavigate();
  const params = useParams();
  const searchParam = params.assessmentId ? ASSESSMENT_ID_SEARCH : ASSESSMENT_CODE_SEARCH;
  const param = params.assessmentId ?? params.code;
  const { assessment, error } = useAssessmentPreview(params);
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  if (error) {
    return (
      <ErrorPage
        isSignedIn={false}
        isClient={false}
        isAdmin={false}
        errorReason="Sorry, this is no longer available. If you think this is a mistake, please reach out to the hiring manager."
      />
    );
  }

  if (!assessment) {
    return <Loading />;
  }

  const introText = assessment.assessmentTexts.find((text: any) => text.location.name === TextLocations.introText);
  const leftTime = convertToFuzzyTimeFormat(assessment.meta.totalTime);
  const redirectPath = getRouteWithSearchParameter(`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`, searchParam, String(param));

  return (
    <>
      {isDesktopView ? (
        <>
          <CandidateAssessmentDesktop
            viewType={CandidateAssessmentViewTypes.start}
            clientLogo={assessment.client.logoUrl}
            clientName={assessment.client.name}
            assessmentImageUrl={assessment.titleImageUrl}
          >
            <WelcomeElement
              type={CandidateAssessmentViewTypes.start}
              title={assessment.name}
              description={introText?.body?.[LanguagesIsoEnum.enUS]} // Replace with user's locale
              testsQuantity={assessment.meta.totalTests}
              questionsQuantity={assessment.meta.totalQuestions}
              leftTime={leftTime}
            >
              <CustomButton
                title="Register to Start"
                onClick={() => {
                  navigate(redirectPath);
                }}
              />
            </WelcomeElement>
          </CandidateAssessmentDesktop>
        </>
      ) : (
        <>
          <CandidateAssessmentMobile
            viewType={CandidateAssessmentViewTypes.start}
            clientLogo={assessment.client.logoUrl}
            clientName={assessment.client.name}
            assessmentImageUrl={assessment.titleImageUrl}
            childrenSubmitButton={
              <CustomButton
                title="Register to Start"
                onClick={() => {
                  navigate(redirectPath);
                }}
                fullWidth={true}
              />
            }
          >
            <WelcomeElement
              type={CandidateAssessmentViewTypes.start}
              title={assessment.name}
              description={introText?.body?.[LanguagesIsoEnum.enUS]} // Replace with user's locale
              testsQuantity={assessment.meta.totalTests}
              questionsQuantity={assessment.meta.totalQuestions}
              leftTime={leftTime}
            />
          </CandidateAssessmentMobile>
        </>
      )}
    </>
  );
}

import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";
import TableClients from "@/modules/tables/TableClients";

import { UserContext } from "@/shared/contexts/user.context";
import { Loading } from "@/shared/components/Loading/Loading";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { CLIENT_ROUTE, NEW_ROUTE } from "@/routing/AppRouter/routes.constants";

export function Clients() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const { reduxClients, selectedClient } = useReduxClientsHook();

  const clientsList = reduxClients.map((client) => {
    const selected: boolean = client.clientId === selectedClient;

    return { selected, ...client };
  });

  const handleAddClient = () => {
    navigate(`/${CLIENT_ROUTE}/${NEW_ROUTE}`);
  };

  if (!user || !selectedClient || !reduxClients.length) {
    return <Loading />;
  }

  if (clientsList.length === 1) {
    return <Navigate to={`/${CLIENT_ROUTE}/${clientsList[0].clientId}`} replace={true} />;
  }

  return (
    <div className="main-container">
      <SEO titleParams={["Clients"]} />
      <div className="inner-container">
        <TableClients isAdmin={user.isAdmin} dataList={clientsList} onAddClient={handleAddClient} />
      </div>
    </div>
  );
}

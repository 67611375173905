import * as React from "react";

import { IUserAnswer } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";

import TableAuditAssessmentLog from "@/modules/tables/TableAuditAssessmentLog";
import TableAuditTestSectionLogs from "@/modules/tables/TableAuditTestSectionLogs";

export default function Audit({ answers, isAdmin }: { answers: IUserAnswer[]; isAdmin: boolean }) {
  return (
    <div>
      <TableAuditAssessmentLog />
      {isAdmin && <TableAuditTestSectionLogs answers={answers} />}
    </div>
  );
}

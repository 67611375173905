import React, { ChangeEvent, useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import cn from "classnames";

import { getUniqueKeysValues, TestElementInfoBlock } from "@/shared/components/testManagement/TestElementInfoBlock";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { isSubstring } from "@/shared/utils/comparison/comparison";

import { IBody, IQuestion, ISectionConfig, ITest, LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import style from "./style.module.css";
import "./table.css";
import RenderedHtml from "@/ui-components/commonComponents/RenderedHtml/RenderedHtml";

export interface IQuestionTable {
  key: string;
  id: number;
  rank: number;
  breakAfter: boolean;
  startsSection?: ISectionConfig;
  required?: boolean;
  questionEn: string | [string, string] | IBody;
  fullQuestion: IQuestion;
  questionType: QuestionTypes;
  scales: string;
  fullScales: { choice: boolean | string; scales: string[] }[];
  languages: string;
}

export const questionsColumns: ColumnsType<IQuestionTable> = [
  {
    key: "rank",
    title: "#",
    dataIndex: "rank",
    width: "5%",
    render: (value, record) =>
      record.required ? (
        <p>
          {value}
          <span className={style.asteriskSize}>*</span>
        </p>
      ) : (
        value
      ),
    className: style.tableColumnName,
  },
  {
    key: "questionEn",
    title: "Element",
    dataIndex: "questionEn",
    ellipsis: true,
    className: style.tableColumnName,
    render: (value) => (
      <Tooltip
        title={<RenderedHtml>{value}</RenderedHtml>}
        color={"white"}
        overlayInnerStyle={{ color: "black" }}
        placement={"topLeft"}
        overlayStyle={{
          maxWidth: "75%",
          minWidth: "50px",
        }}
      >
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    key: "questionType",
    title: "Element Type",
    dataIndex: "questionType",
    width: "15%",
    className: style.tableColumn,
  },
  {
    key: "scales",
    title: "Scales",
    dataIndex: "scales",
    width: "10%",
    className: style.tableColumn,
  },
  {
    key: "languages",
    title: "Languages",
    dataIndex: "languages",
    width: "15%",
    className: style.tableColumn,
  },
];

export default function TableQuestions({ test }: { test: ITest }) {
  const [testElements, setTestElements] = useState<IQuestionTable[]>([]);

  const getRowStyles = ({ breakAfter, startsSection }: IQuestionTable) => {
    if (breakAfter && startsSection) {
      return cn(style.tableBreakAfterRow, style.tableStartSectionRow);
    }
    if (startsSection) {
      return style.tableStartSectionRow;
    }
    if (breakAfter) {
      return style.tableBreakAfterRow;
    }
    return style.tableOrdinaryRow;
  };

  useEffect(() => {
    if (!test) {
      return;
    }

    const updateTestElements: IQuestionTable[] = test.testElements.map((testElement) => {
      const languages = Object.keys(testElement.config.payload?.question || testElement.config.payload?.body)
        .toString()
        .replace(/,/g, ", ");

      return {
        languages,
        key: testElement.config.key,
        id: testElement.elementId,
        rank: testElement.rank,
        breakAfter: testElement.config.breakAfter,
        startsSection: testElement.config.startsSection,
        required: testElement.config.required,
        questionEn: testElement.config.payload.body
          ? testElement.config.payload.body[LanguagesIsoEnum.enUS]
          : typeof testElement.config.payload.question[LanguagesIsoEnum.enUS] === "string"
          ? testElement.config.payload.question[LanguagesIsoEnum.enUS]
          : `${testElement.config.payload.question[LanguagesIsoEnum.enUS][0]} / ${
              testElement.config.payload.question[LanguagesIsoEnum.enUS][1]
            }`,
        fullQuestion: testElement.config.payload.body
          ? testElement.config.payload.body
          : testElement.config.payload.question,
        questionType: testElement.config.type,
        fullScales: testElement.config.payload.choices
          ? testElement.config.payload.choices.map(({ choice, scales }) => {
              return { choice: choice, scales: scales };
            })
          : [{ choice: "", scales: [""] }],
        scales:
          testElement.config.type !== QuestionTypes.Text
            ? Object.keys(
                getUniqueKeysValues(
                  testElement.config.payload.choices.map(({ scales }) => {
                    return scales;
                  }),
                ),
              )
                .toString()
                .replace(/,/g, ", ")
            : "-",
      };
    });

    setTestElements(updateTestElements.sort((a, b) => a.rank - b.rank));
  }, [test]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (testElements) {
      setTestElements(
        testElements.filter((item: IQuestionTable) => isSubstring(item.questionEn.toString(), event.target.value)),
      );
    }
  };

  return (
    <div className={style.tableBlock}>
      <TableHeader tableName={`${test.name}'s test elements`} handleSearchChange={(e) => handleSearchChange(e)} />
      <Table
        columns={questionsColumns}
        dataSource={testElements}
        className="testElementsTable"
        rowClassName={(record) => getRowStyles(record)}
        expandable={{
          expandedRowRender: (record) => (
            <TestElementInfoBlock
              id={record.id}
              questionType={record.questionType}
              fullScales={record.fullScales}
              question={record.fullQuestion}
              required={record.required}
            />
          ),
        }}
        pagination={false}
      />
    </div>
  );
}

import React, { ReactElement, useEffect } from "react";
import cn from "classnames";
import { Button } from "antd";

import { ReactComponent as ArrowRightSVG } from "@/assets/icons/feather-icons/chevron-right.svg";
import { ReactComponent as ArrowLeftSVG } from "@/assets/icons/feather-icons/chevron-left.svg";

import styles from "./style.module.css";

export enum ButtonSize {
  large,
  small,
  XSmall,
  XXSmall,
}

export enum ButtonColorType {
  light,
  transparentLight,
  dark,
  transparentDark,
}

export interface ICustomButton {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  rightIcon?: React.ReactNode;
  arrowAfter?: boolean;
  arrowBefore?: boolean;
  isLoading?: boolean;
  isEnabledKeydown?: boolean;
  style?: React.CSSProperties;
  colorType?: ButtonColorType;
  buttonSize?: ButtonSize;
  fullWidth?: boolean;
  submitted?: boolean;
  isMobileView?: boolean;
}

function respondsToEnter(element: HTMLElement) {
  const tagName = element.tagName.toLowerCase();
  const type = element.getAttribute("type")?.toLowerCase() ?? "";

  if (tagName === "textarea" || (tagName === "a" && element.hasAttribute("href"))) {
    return true;
  }

  if (tagName === "input" && (type === "text" || type === "submit" || type === "button")) {
    return true;
  }

  if (tagName === "button") {
    return true;
  }

  if (element.getAttribute("role") === "button") {
    return true;
  }

  return false;
}

export function CustomButton({
  disabled,
  title,
  onClick,
  children,
  rightIcon,
  arrowAfter,
  arrowBefore,
  fullWidth,
  isMobileView,
  isLoading = false,
  isEnabledKeydown = false,
  style,
  colorType = ButtonColorType.dark,
  buttonSize = ButtonSize.large,
  submitted = true,
}: ICustomButton): ReactElement<any, any> {
  const getStyle = () => {
    return fullWidth ? { width: "100%", ...style } : { ...style };
  };

  useEffect(() => {
    if (!isEnabledKeydown) return;

    const rootEl = document.getElementById("root")!;
    const listener = (event: any) => {
      const buttonEl = document.getElementById(title); // antd doesn't accommodate ref props
      if ((event.code === "Enter" || event.code === "NumpadEnter") && buttonEl && !respondsToEnter(event.target)) {
        event.preventDefault();
        return buttonEl.click();
      }
    };

    // This won't work in modals, since they are rendered outside of the React root by design.
    // This is an acceptable tradeoff, since eventually the candidate experience will be a form,
    // which has this behavior out-of-the-box.
    rootEl.addEventListener("keydown", listener);

    return () => rootEl.removeEventListener("keydown", listener);
  }, [isEnabledKeydown, title]);

  return (
    <Button
      id={title}
      type="primary"
      htmlType={submitted ? "submit" : "reset"}
      icon={children ? children : <span className={styles.noChildL} />}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      style={getStyle()}
      className={cn(styles.buttonContainer, {
        [styles.buttonLarge]: buttonSize === ButtonSize.large,
        [styles.buttonSmall]: buttonSize === ButtonSize.small,
        [styles.buttonXSmall]: buttonSize === ButtonSize.XSmall,
        [styles.buttonXXSmall]: buttonSize === ButtonSize.XXSmall,
        [styles.light]: colorType === ButtonColorType.light,
        [styles.dark]: colorType === ButtonColorType.dark,
        [styles.transparentDark]: colorType === ButtonColorType.transparentDark,
        [styles.transparentLight]: colorType === ButtonColorType.transparentLight,
        [styles.loading]: Boolean(!children),
      })}
    >
      {arrowBefore && <ArrowLeftSVG aria-hidden="true" />}
      <span
        className={cn(styles.buttonTitle, {
          [styles.withIconBefore]: arrowBefore || Boolean(children),
          [styles.withIconAfter]: Boolean(arrowAfter) || Boolean(rightIcon),
        })}
      >
        {title}
      </span>
      {arrowAfter && <ArrowRightSVG aria-hidden="true" />}
      {Boolean(rightIcon) && rightIcon}
    </Button>
  );
}

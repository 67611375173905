import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as PlusSVG } from "@/assets/icons/feather-icons/plus-16-white.svg";
import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import TableHeader from "@/shared/components/TableHeader/TableHeader";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import tableStyle from "@/modules/tables/style.module.css";

import { TeamListComponent } from "./components/TeamListComponent/TeamList.component";
import { getAddUserToAssessmentPageLink } from "./components/AddUserToAssessmentComponent/AddUserToAssessment.component";

export type AssessmentTeamSceneProps = { isArchived?: boolean };

export function AssessmentTeamScene({ isArchived }: AssessmentTeamSceneProps) {
  const navigate = useNavigate();
  const { client_id: clientId, assessment_id: assessmentId } = useParams<{
    client_id: string;
    assessment_id: string;
  }>();

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canCreateAssessmentUser = assessmentAbility.can(Action.Create, Subject.ASSESSMENT_USER_PERMISSION);

  return (
    <div className={tableStyle.tableBlock}>
      <TableHeader
        tableName="Assessment Team"
        hasSearchField={false}
        handleSearchChange={function (e: any): void {
          throw new Error("Function not implemented.");
        }}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CustomButton
            title="Add User"
            disabled={isArchived || !canCreateAssessmentUser}
            buttonSize={ButtonSize.small}
            colorType={ButtonColorType.dark}
            onClick={() => {
              navigate(getAddUserToAssessmentPageLink(Number(clientId), Number(assessmentId)));
            }}
          >
            <PlusSVG aria-hidden="true" />
          </CustomButton>
        </div>
      </TableHeader>

      <TeamListComponent assessmentId={Number(assessmentId)} isArchived={isArchived} />
    </div>
  );
}

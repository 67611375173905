import { httpClient } from "@/shared/services/http";

export class JobRolesHttpService {
  static async getAllJobRoles() {
    const { data: jobRoles } = await httpClient.get(`api/jobs/roles`);

    return jobRoles;
  }

  static async getClientJobTitle(clientId: number) {
    const { data: clientJobTitles } = await httpClient.get(`api/clients/${clientId}/jobs`);
    return clientJobTitles;
  }
}

import { httpClient } from "@/shared/services/http";

import { IQuestion } from "@/modules/assessments/types/test.interface";

export class testElementHttpService {
  static async updateTestElementById(elementId: number, payload: { [key: string]: IQuestion }, required?: boolean) {
    const { data } = await httpClient.patch(`/api/test-elements/${elementId}`, { ...payload, required });
    return data;
  }
}

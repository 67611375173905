import { useCacheResult } from "@/shared/hooks/use-fetch.hook";
import { PermissionsHttpService } from "@/shared/services/permissions/permissions-http.service";

import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";

const cacheKey = "ASSESSMENT_USER_ROLES";

export function useGetAssessmentUserRolesHook(assessmentId?: number, canExecute = true) {
  const { data = [], isLoading } = useCacheResult<string | number>(
    cacheKey,
    PermissionsHttpService.getAssessmentUserRoles,
    [assessmentId],
    [assessmentId],
    canExecute,
  );

  return {
    isLoading,
    assessmentRoles: data as AssessmentRoleEnum[],
  };
}

import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs } from "antd";
import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { NOTIFICATIONS_ROUTE, USER_SETTINGS_ROUTE } from "@/routing/AppRouter/routes.constants";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";
import NotificationSettings from "../NotificationSettings/NotificationSettings";

const { TabPane } = Tabs;

export function UserSettings() {
  const { pathname } = useLocation();
  const pathList = pathname.split("/");
  const slug = pathList[pathList.length - 1];
  const activeTab = slug === USER_SETTINGS_ROUTE ? NOTIFICATIONS_ROUTE : slug;

  const clientAbility = useContext(ClientAbilityContext);
  const canViewUserSettings = clientAbility.can(Action.Read, Subject.USER_SETTING);
  const canViewNotificationSettings = clientAbility.can(Action.Read, Subject.USER_NOTIFICATION_SETTING);

  useRouteAccessController({
    slug: USER_SETTINGS_ROUTE,
    hasPermission: canViewUserSettings,
  });

  useRouteAccessController({
    slug: NOTIFICATIONS_ROUTE,
    hasPermission: canViewNotificationSettings,
  });

  return (
    <div className="main-container">
      <div className="inner-container">
        <Banner title="User Settings" />
        <Tabs activeKey={activeTab}>
          <TabPane key={NOTIFICATIONS_ROUTE} tab={<Link to={NOTIFICATIONS_ROUTE}>Notifications</Link>}>
            <NotificationSettings />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

import { IScale } from "@/modules/assessments/types/scale.interface";

import { httpClient } from "@/shared/services/http";

export class TestHttpService {
  static async getAllTestsAsAdmin() {
    const { data: tests } = await httpClient.get(`/api/tests`);

    return tests;
  }

  static async getTestsStats() {
    const { data: tests } = await httpClient.get(`/api/tests?stats=true`);

    return tests;
  }

  static async getTestById(testId: number) {
    const { data: test } = await httpClient.get(`/api/tests/${testId}`);

    return test;
  }

  static async getSpecificTestScales(testId: number): Promise<IScale[]> {
    const { data: scales } = await httpClient.get(`/api/tests/${testId}/scales`);
    return scales;
  }

  static async getAllClientTests(clientId: number) {
    const { data: tests } = await httpClient.get(`/api/clients/${clientId}/tests`);

    return tests;
  }

  static async addTestToAssessment(clientId: string, assessmentId: string, testId: string, postData: { rank: number }) {
    const { data: addedTest } = await httpClient.post(
      `/api/clients/${clientId}/assessments/${assessmentId}/tests/${testId}`,
      postData,
    );
    return addedTest;
  }

  static async updateTestInAssessment(
    clientId: string,
    assessmentId: string,
    testId: string,
    postData: { rank?: number; normId?: number },
  ) {
    return httpClient.patch(`/api/clients/${clientId}/assessments/${assessmentId}/tests/${testId}`, postData);
  }

  static async deleteTestFromAssessmentById(clientId: string, assessmentId: string, testId: string) {
    const { data: removedTest } = await httpClient.delete(
      `/api/clients/${clientId}/assessments/${assessmentId}/tests/${testId}`,
    );
    return removedTest;
  }

  static async getTestScales(clientId: string, testId: number): Promise<IScale[]> {
    const { data: scales } = await httpClient.get(`/api/clients/${clientId}/tests/${testId}/scales`);
    return scales;
  }
}

import { createContext } from "react";
import { ICompetencyQuestions } from "@/modules/admin/testManagement/hooks/useAllCompetencies.hook";
import { IScale } from "@/modules/assessments/types/scale.interface";
import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { IClientConfig } from "@/modules/client/clients.interface";

export type TReportContext = {
  testIds: number[] | undefined;
  scales: IScale[][];
  competenciesQuestions: ICompetencyQuestions[];
  scene?: ReportScene;
  clientConfig?: IClientConfig | null;
};

export const ReportContext = createContext<TReportContext>({
  testIds: [],
  competenciesQuestions: [],
  scales: [],
});

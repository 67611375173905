import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, notification } from "antd";
import cn from "classnames";
import MDEditor from "@uiw/react-md-editor";

import { useGetTestInstructions } from "@/modules/admin/testManagement/hooks/useGetTestInstructions.hook";

import { Loading } from "@/shared/components/Loading/Loading";
import { TestInstructionsHttpService } from "@/shared/services/tests/test-instructions-http.service";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import style from "@/shared/components/assessment/AboutAssessment/style.module.css";
import commonStyle from "../TestSettings/style.module.css";
import questionStyle from "@/shared/components/candidate/scenes/TestElement/style.module.css";

export default function TestInstructions() {
  const { test_id } = useParams();
  const { testInstructions, isLoading } = useGetTestInstructions(test_id);
  const [form] = Form.useForm();
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const onFinish = async () => {
    try {
      setUpdating(true);
    await TestInstructionsHttpService.updateTestInstructionsBody(test_id, form.getFieldsValue(true));

      notification.success({ message: "Test instructions are updated." });
    } catch (err) {
      console.error(err);
      notification.error({ message: "Test instructions are failed to update." });
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(testInstructions);
  }, [testInstructions]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={commonStyle.innerTabsContainer}>
      <Form form={form} name="testInstructions" onFinish={onFinish} requiredMark={false}>
        {Object.keys(form.getFieldsValue(true)).map((language) => (
          <Form.Item className={cn(style.inputTitle, style.mdEditor)} key={language} name={language} label={language}>
            <MDEditor highlightEnable={false} height={300} placeholder="Type..." className={questionStyle.questionTextWrapper} />
          </Form.Item>
        ))}

        <div style={{ display: "flex", justifyContent: "right", marginTop: 25 }}>
          <CustomButton title="Save" isLoading={isUpdating}>
            <SaveSVG className={style.svgSize} aria-hidden="true" />
          </CustomButton>
        </div>
      </Form>
    </div>
  );
}

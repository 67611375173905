import { Badge, Radio } from "antd";
import cn from "classnames";

import { IChoice, LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import { QuestionTypes, TestElementMode } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { getScaleRepresentation, getUniqueKeysValues } from "@/shared/components/testManagement/TestElementInfoBlock";

import badgeStyle from "@/shared/components/assessment/report/TestAnalysisBlock/style.module.css";
import style from "./style.module.css";

export function getAnswersNames() {
  return {
    true: {
      [QuestionTypes.TrueFalse]: {
        [LanguagesIsoEnum.enUS]: "yes",
      },
      [QuestionTypes.FollowNotFollow]: {
        [LanguagesIsoEnum.enUS]: "follows",
      },
      [QuestionTypes.CorrectIncorrect]: {
        [LanguagesIsoEnum.enUS]: "correct",
      },
      [QuestionTypes.MadeNotMade]: {
        [LanguagesIsoEnum.enUS]: "made",
      },
      [QuestionTypes.StrongWeak]: {
        [LanguagesIsoEnum.enUS]: "strong",
      },
    },
    false: {
      [QuestionTypes.TrueFalse]: {
        [LanguagesIsoEnum.enUS]: "no",
      },
      [QuestionTypes.FollowNotFollow]: {
        [LanguagesIsoEnum.enUS]: "does not follow",
      },
      [QuestionTypes.CorrectIncorrect]: {
        [LanguagesIsoEnum.enUS]: "incorrect",
      },
      [QuestionTypes.MadeNotMade]: {
        [LanguagesIsoEnum.enUS]: "not made",
      },
      [QuestionTypes.StrongWeak]: {
        [LanguagesIsoEnum.enUS]: "weak",
      },
    },
  };
}

interface ITrueFalseRadioGroup {
  id: string;
  mode?: TestElementMode;
  choices?: IChoice[];
  onRadioChange: (e: any) => void;
  currentAnswer: boolean | string | undefined;
  questionType:
    | QuestionTypes.TrueFalse
    | QuestionTypes.FollowNotFollow
    | QuestionTypes.CorrectIncorrect
    | QuestionTypes.MadeNotMade
    | QuestionTypes.StrongWeak;
  trueKey: string | undefined;
  falseKey: string | undefined;
}

export const badgeColor = "#66CC99";

export default function TrueFalseRadioGroup({
  id,
  mode = TestElementMode.Candidate,
  choices,
  onRadioChange,
  currentAnswer,
  questionType,
  trueKey,
  falseKey,
}: ITrueFalseRadioGroup) {
  const showBadge: boolean = mode === TestElementMode.Admin;

  const trueScales: string[] | undefined = choices?.filter((choice) => choice.choice === true)[0].scales;
  const falseScales: string[] | undefined = choices?.filter((choice) => choice.choice === false)[0].scales;

  const trueScaleValue = trueScales ? getUniqueKeysValues([trueScales]) : {};
  const falseScaleValue = falseScales ? getUniqueKeysValues([falseScales]) : {};
  const trueScalePoints = getScaleRepresentation(trueScaleValue);
  const falseScalePoints = getScaleRepresentation(falseScaleValue);

  return (
    <Radio.Group
      size={"large"}
      id={String(id)}
      name={String(id)}
      value={currentAnswer}
      onChange={onRadioChange}
      className={cn(style.answersContainer, { [style.analysis]: showBadge })}
    >
      <Badge
        count={showBadge && trueScalePoints ? `+${trueScalePoints}` : 0}
        offset={[2, -2]}
        color={badgeColor}
        className={badgeStyle.badge}
      >
        <Radio value={true} id={trueKey}>
          <p>{getAnswersNames().true[questionType][LanguagesIsoEnum.enUS]}</p>
        </Radio>
      </Badge>
      <Badge
        count={showBadge && falseScalePoints ? `+${falseScalePoints}` : 0}
        offset={[2, -2]}
        color={badgeColor}
        className={badgeStyle.badge}
      >
        <Radio value={false} id={falseKey}>
          <p>{getAnswersNames().false[questionType][LanguagesIsoEnum.enUS]}</p>
        </Radio>
      </Badge>
    </Radio.Group>
  );
}

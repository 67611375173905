import React, { ReactElement, useState } from "react";
import { Col, Row, Tooltip } from "antd";

import CustomSearch from "@/ui-components/commonComponents/Input/Search";

import style from "./style.module.css";

interface ITableHeader {
  tableName: string;
  hasSearchField?: boolean;
  children?: ReactElement<any, any>;
  handleSearchChange?: (e: any) => void;
}

export default function TableHeader({ tableName, children, handleSearchChange, hasSearchField = true }: ITableHeader) {
  const childrenCol = {
    withSearch: {
      xxl: 4,
      xl: 5,
      lg: 5,
      md: 6,
      sm: 7,
    },
    withoutSearch: {
      xxl: 10,
      xl: 12,
      lg: 13,
      md: 15,
      sm: 17,
    },
  };
  const childrenColGrid = hasSearchField ? childrenCol.withSearch : childrenCol.withoutSearch;
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <Row className={style.tableTitleRow}>
      <Tooltip title={tableName} placement={"topLeft"} overlayStyle={{ maxWidth: "75%", minWidth: "50px" }}>
        <Col xxl={13} xl={11} lg={10} md={8} sm={6} className={style.tableTitle}>
          {tableName}
        </Col>
      </Tooltip>

      <Col span={1} />

      <Col {...childrenColGrid} className={style.tableButton}>
        {children ? children : null}
      </Col>

      {hasSearchField && (
        <>
          <Col xxl={5} xl={6} lg={7} md={8} sm={9} className={style.tableSearch}>
            <CustomSearch
              value={searchValue}
              onSearchChange={(e) => {
                setSearchValue(e.target.value);
                if (handleSearchChange) {
                  handleSearchChange(e);
                }
              }}
            />
          </Col>
        </>
      )}
    </Row>
  );
}

export function sortStrings(a: string, b: string) {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function sortNumbers(a: number, b: number) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function sortDates(a: Date, b: Date) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export const sortElements = (a: string | number | Date | undefined, b: string | number | Date | undefined): number => {
  if (!a && !b) return 0;
  if (typeof a === "string" && typeof b === "string") {
    return sortStrings(a, b);
  } else if (typeof a === "number" && typeof b === "number") {
    return sortNumbers(a, b);
  } else if (typeof a === "object" && typeof b === "object") {
    return sortDates(a, b);
  }

  return 0;
};

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { Button, Divider, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { Loading } from "@/shared/components/Loading/Loading";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { TestHttpService } from "@/shared/services/tests/test-http.service";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { isSubstring } from "@/shared/utils/comparison/comparison";

import { IAssessmentTest, ITestElement } from "@/modules/assessments/types/test.interface";
import { useTestNormsHook } from "@/modules/assessments/scenes/AddTests/hooks/useTestNorms.hook";

import { QuestionItem } from "../QuestionItem/QuestionItem";
import { getNextElementStartSectionConfig } from "../utils/testElements.utils";

import { ReactComponent as DraggerSVG } from "@/assets/icons/more-horizontal.svg";

import style from "./style.module.css";

export interface ITestItemProps {
  key?: number;
  id: number;
  title: string;
  rank: number;
  normId: number;
  elementList: ITestElement[];
  onDeleteClick?: any;
  isChangeable?: boolean;
}

export enum NormDistributionModeEnum {
  NORMAL = "normal",
  NOT_NORMAL = "not_normal",
}

// TODO: use consolidated place for the types
export interface INorm {
  normId: number;
  version: number;
  name: string;
  description: string;
  numberOfCases: number;
  testId: number;
  distributionMode: NormDistributionModeEnum;
  populationSize: number | null;
  clientId: number | null;
  createdBy: number | null;
  createdAt?: string;
  updatedAt?: string;
  assessments?: number;
  assessmentTests?: IAssessmentTest[];
  assessmentsDone?: number;
}

export const getNormName = (norms: INorm[], normId: number): string => {
  const foundNorm: INorm | undefined = norms.find((item) => item.normId === normId);
  return foundNorm ? foundNorm.name : "";
};
export const getNormId = (norms: INorm[], normName: string): number => {
  const foundNorm: INorm | undefined = norms.find((item) => item.name === normName);
  return foundNorm ? foundNorm.normId : -1;
};

export function TestItem({ id, title, rank, normId, elementList, onDeleteClick, isChangeable }: ITestItemProps) {
  const { client_id: clientId, assessment_id: assessmentId } = useParams<{
    client_id: string;
    assessment_id: string;
  }>();

  const [isShownQuestionList, setIsShownQuestionList] = useState<boolean>(false);
  const { norms, isLoading } = useTestNormsHook(id);

  const [normName, setNormName] = useState<string>(norms.length !== 0 ? norms[0].name : "");

  useEffect(() => {
    setNormName(getNormName(norms, normId));
  }, [norms]);

  const onNormChange = async (event: string) => {
    setNormName(event);
    const selectedNormId = getNormId(norms, event);
    await TestHttpService.updateTestInAssessment(String(clientId), String(assessmentId), String(id), {
      rank: rank,
      normId: selectedNormId,
    });
  };

  const handleQuestionClick = () => {
    setIsShownQuestionList((prevState) => !prevState);
  };

  const handleDeleteItemClick = () => {
    onDeleteClick && onDeleteClick(id);
  };

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);
  const canReadAssessment = assessmentAbility.can(Action.Read, Subject.ASSESSMENT);

  if (isLoading) {
    return (
      <div className={style.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.draggerContainer}>
        <DraggerSVG />
      </div>
      <div className={style.inRow}>
        <div style={{ alignContent: "center" }}>
          <p className={style.title}>{title}</p>
        </div>
        {isChangeable && (
          <Button
            shape="round"
            className={style.deleteButton}
            onClick={handleDeleteItemClick}
            disabled={!canManageAssessment}
          >
            <DeleteOutlined className={style.deleteIcon} disabled={!canManageAssessment} />
          </Button>
        )}
      </div>

      <Divider />
      <div className={style.inRow}>
        <div className={style.testOptions}>
          <Button
            className={style.questionButton}
            size="large"
            htmlType="submit"
            onClick={handleQuestionClick}
            disabled={!canReadAssessment}
          >
            <p className={style.buttonTitle}>{`Elements (${elementList ? elementList.length : 0})`}</p>

            <span
              className={cn(style.arrow, style.arrowDown, {
                [style.turnUp]: isShownQuestionList,
              })}
            />
          </Button>
          {norms.length !== 0 && (
            <Select
              showSearch
              disabled={!canManageAssessment || !isChangeable}
              placeholder="Select a test norm"
              options={norms
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((norm: INorm) => {
                  return { value: norm.name, label: norm.name };
                })}
              optionFilterProp="children"
              filterOption={(input, option) => isSubstring(String(option?.value), input)}
              onChange={onNormChange}
              value={normName}
              className={style.normSelector}
            />
          )}
        </div>
      </div>

      {isShownQuestionList && elementList && (
        <div className={style.questionContainer}>
          {elementList
            .sort((a, b) => a.rank - b.rank)
            .map((element) => {
              return (
                <QuestionItem
                  key={element.config.key}
                  nextElementStartSectionConfig={getNextElementStartSectionConfig(elementList, element.config.key)}
                  testElement={element}
                  numberOfQuestions={elementList?.length}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

import { CSSProperties, useContext } from "react";
import { Link } from "react-router-dom";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { ADMIN_ROUTE, ASSESSMENT_ROUTE, CLIENT_ROUTE, SETTINGS_ROUTE } from "@/routing/AppRouter/routes.constants";

const defaultMenuItemStyle: CSSProperties = { textTransform: "uppercase", color: "#253955", cursor: "pointer" };

export const useMenuItems = (clientId: number, clientName: string | undefined): ItemType[] => {
  const labelClientName = clientName || "";
  const clientAbility = useContext(ClientAbilityContext);
  const isAdmin = clientAbility.can(Action.Manage, Subject.ALL);
  const canViewClientSettings = clientAbility.can(Action.Read, Subject.CLIENT);

  const adminDashboardMenuItem = {
    label: (
      <Link to={`/${ADMIN_ROUTE}`} style={defaultMenuItemStyle}>
        admin dashboard
      </Link>
    ),
    key: ADMIN_ROUTE,
  };

  const clientDashboardMenuItem = {
    label: (
      <Link to={`/${CLIENT_ROUTE}/${clientId}/${SETTINGS_ROUTE}`} style={defaultMenuItemStyle}>
        {labelClientName} dashboard
      </Link>
    ),
    key: CLIENT_ROUTE,
  };

  const assessmentMenuItem = {
    label: (
      <Link to={`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}`} style={defaultMenuItemStyle}>
        assessments
      </Link>
    ),
    key: ASSESSMENT_ROUTE,
  };

  const menuItems: ItemType[] = [];

  if (isAdmin) {
    menuItems.push(adminDashboardMenuItem);
  }

  if (canViewClientSettings) {
    menuItems.push(clientDashboardMenuItem);
  }

  menuItems.push(assessmentMenuItem);

  return menuItems;
};

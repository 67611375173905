import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "@/routing/AppRouter/routes.constants";

export interface IPrivateRouterProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export const PrivateAdminRoute = ({ isAuthenticated, isAdmin }: IPrivateRouterProps) => {
  const location = useLocation();
  if (!(isAuthenticated && isAdmin)) {
    return (
      <Navigate to={`/${LOGIN_ROUTE}`} state={{ redirectPath: location.pathname + location.search + location.hash }} />
    );
  }

  return <Outlet />;
};

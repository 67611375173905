import { createSlice } from "@reduxjs/toolkit";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { IAssessmentRole, IClientUserRole } from "@/shared/services/roles/roles-http.service";

export interface IRolesStore {
  isLoaded: boolean;
  data: {
    assessmentRoles: IAssessmentRole[];
    clientRoles: IClientUserRole[];
  };
}

export const slice = createSlice({
  name: "roles",
  initialState: {
    isLoaded: false,
    data: { assessmentRoles: [], clientRoles: [] },
  } as IRolesStore,
  reducers: {
    // Filter client roles to exclude the deprecated 'Assessment Creator' role
    // and only include ADMIN or USER roles
    addRoles: (state, action) => {
      const { isLoading, roles } = action.payload;
      let filteredClientRoles: IClientUserRole[] = [];

      if (roles.clientRoles) {
        filteredClientRoles = roles.clientRoles.filter(
          (role: IClientUserRole) => role.name === ClientRoleEnum.ADMIN || role.name === ClientRoleEnum.USER,
        );
      }

      return {
        ...state,
        isLoaded: !isLoading,
        data: {
          assessmentRoles: roles.assessmentRoles,
          clientRoles: filteredClientRoles || [],
        },
      };
    },
  },
});

export const { addRoles } = slice.actions;

export const selectRoles = (state: { roles: IRolesStore }) => (state.roles.isLoaded ? state.roles.data : []);

export const selectClientRoles = (state: { roles: IRolesStore }) =>
  state.roles.isLoaded ? state.roles.data.clientRoles : [];

export const selectAssessmentRoles = (state: { roles: IRolesStore }) =>
  state.roles.isLoaded ? state.roles.data.assessmentRoles : [];

export default slice.reducer;

import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { Loading } from "@/shared/components/Loading/Loading";
import useAllTextLocations from "@/shared/components/assessment/AboutAssessment/hooks/useAllTextLocations";
import { UserContext } from "@/shared/contexts/user.context";
import { useGetCandidateAnswersHook } from "@/shared/hooks/candidate/use-get-candidate-answers.hook";
import { useInitializeCandidateHook } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { useTrackCandidateEventsHook } from "@/shared/hooks/candidate/use-track-candidate-events.hook";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";
import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CANDIDATE_ROUTE } from "@/routing/AppRouter/routes.constants";
import ContentView from "./components/ContentView/ContentView";
import { useCurrentAnswerHook } from "./hooks/useCurrentAnswer.hook";
import { getNextButtonText, getTestsProps } from "../../utils/candidateAssessment.utils";
import { useClientAssessmentHook } from "../../hooks/useClientAssessment.hook";
import CandidateAssessmentDesktop from "./CandidateAssessmentDesktop";
import CandidateAssessmentMobile from "./CandidateAssessmentMobile";
import { TestInstructions } from "./components/TestInstructions/TestInstructions";
import { TimeOutModal } from "./components/modals/TimeOutModal/TimeOutModal";
import { TimerErrorModal } from "./components/modals/TimerErrorModal/TimerErrorModal";
import { ConfirmNextModal } from "./components/modals/ConfirmNextModal/ConfirmNextModal";
import { UnansweredQuestionsModal } from "./components/modals/UnansweredQuestionsModal/UnansweredQuestionsModal";
import { SectionContext } from "./contexts/section.context";
import { InviteContext } from "./contexts/invite.context";
import { CurrentPageContext } from "./contexts/current-page.context";
import { useSectionsHook } from "./hooks/useSections.hook";
import { useCurrentPageHook } from "./hooks/useCurrentPage.hook";
import { useAssessmentTestHook } from "./hooks/useAssessmentTest.hook";
import { useAssessmentInstructionsHook } from "./hooks/useAssessmentInstructions.hook";
import { getMainButtonTitle, getViewType } from "./utils/candidate-assessment-view.utils";

export enum CandidateAssessmentViewTypes {
  test,
  start,
  finish,
}

export interface ICandidateAssessmentView {
  viewType: CandidateAssessmentViewTypes;
  children: ReactElement<any, any>;
  childrenSubmitButton?: ReactElement<any, any>;
  childrenNextButton?: ReactElement<any, any>;
  childrenBackButton?: ReactElement<any, any>;
  onPreviousClick?: () => void;
  assessmentImageUrl?: string;
  testImageUrl?: string;
  clientLogo?: string;
  clientName?: string;
  onClick?: () => void;
  disabled?: boolean;
  setIsVisibleTooltip?: Dispatch<SetStateAction<boolean>>;
}

//TODO: add a CandidateAssessment context here.
export default function CandidateAssessment({
  firebaseLoading,
  userId,
}: {
  firebaseLoading: boolean;
  userId?: number;
}) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;
  const navigate = useNavigate();

  const { code } = useParams();
  const { user } = useContext(UserContext);
  const isAuthenticated: boolean = Boolean(user && user.userId);

  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  const { invite } = useInitializeCandidateHook(firebaseLoading, userId);

  const { assessment, isLoading } = useClientAssessmentHook(code, invite?.assessmentId);
  const { textLocations, isLoading: textLocationsLoading } = useAllTextLocations();
  const { client, isLoading: isLoadingClient } = useClientByIdHook(Number(assessment?.clientId));

  const { imageUrl, questionsList } = useMemo(
    () => getTestsProps(assessment, textLocations),
    [assessment, textLocations],
  );

  const {
    answers,
    saveTestElementAnswer,
    isLoading: isLoadingAnswers,
  } = useGetCandidateAnswersHook(invite?.inviteId, Boolean(user?.userId));

  const { currentAnswers, setCurrentAnswers, currentPage, setCurrentPage } = useCurrentPageHook({
    questionsList,
    answers,
  });

  const { test, isLoading: isLoadingTest } = useAssessmentTestHook({ questionsList, assessment, invite, currentPage });

  const {
    setIsOpenedInstructions,
    isOpenedInstructions,
    testInstructions,
    isLoading: isLoadingInstructions,
  } = useAssessmentInstructionsHook({ questionsList, currentPage, testId: Number(test?.testId) });

  const {
    errorState,
    isTimedOutSection,
    currentSectionElement,
    timeLeft,
    sectionsTime,
    isOpenedTimeOutModal,
    setIsOpenedTimeOutModal,
  } = useSectionsHook({
    test,
    invite,
    currentPage,
    answers,
    questionsList,
    isOpenedInstructions,
  });

  const {
    isOpenedConfirmationModal,
    setIsOpenedConfirmationModal,
    isOpenedUnansweredModal,
    setIsOpenedUnansweredModal,
    isAllowToGoBack,
    isAllowToGoNext,
    isLoadingButton: isLoadingBtn,
    isStartingLoading,
    handleBackClick,
    handleNextClick,
    handleSubmitClick,
    handleChoiceSelect,
    finishAssessment,
    goNextTest,
    goNextSection,
    goBackToUnanswered,
  } = useCurrentAnswerHook({
    test,
    invite,
    assessment,
    questionsList,
    answers,
    saveTestElementAnswer,
    currentPage,
    setCurrentPage,
    currentAnswers,
    setCurrentAnswers,
    sectionsTime,
    isTimedOutSection,
    currentSectionElement,
  });

  useTrackCandidateEventsHook(invite?.inviteId, test?.testId);

  const viewType = useCallback(() => getViewType(questionsList, currentPage), [questionsList, currentPage]);
  const buttonTitle = useCallback(
    () => getMainButtonTitle(isAuthenticated, invite?.status),
    [isAuthenticated, invite?.status],
  );
  const submitClick = useCallback(async () => {
    await handleSubmitClick();
    setIsOpenedInstructions(true);
  }, [invite?.status, test?.testId]);

  useEffect(() => {
    if (invite) {
      if (invite.status === AssessmentInviteStatus.Done) {
        navigate(`/${CANDIDATE_ROUTE}/invites`);
      }
    }
  }, [invite]);

  if (isLoading || isLoadingClient || textLocationsLoading || isStartingLoading) {
    return <Loading />;
  }

  const currentPageIndex = currentPage - 1;
  const isLoadingButton = isLoadingBtn || isLoadingAnswers || isLoadingTest;
  const nextButtonText = getNextButtonText(currentPage, questionsList);

  return (
    <InviteContext.Provider value={{ invite, currentTest: test, assessment }}>
      <CurrentPageContext.Provider value={{ questionsList, currentPage }}>
        <SectionContext.Provider value={{ currentSectionElement, isTimedOutSection, timeLeft, errorState }}>
          <SEO titleParams={[assessment?.name]} />

          <BasicModal isModalOpen={isOpenedInstructions} handleCancel={() => setIsOpenedInstructions(false)}>
            <TestInstructions instructions={test?.testId ? testInstructions[test?.testId] : {}} />
          </BasicModal>

          <ConfirmNextModal
            isOpen={isOpenedConfirmationModal}
            handleNextClick={handleNextClick}
            setIsOpenedConfirmationModal={setIsOpenedConfirmationModal}
          />

          <UnansweredQuestionsModal
            isOpen={isOpenedUnansweredModal}
            setIsOpenModal={setIsOpenedUnansweredModal}
            handleBack={goBackToUnanswered}
            handleNextClick={handleNextClick}
          />

          <TimeOutModal
            test={test}
            goNextTest={goNextTest}
            goNextSection={goNextSection}
            finishAssessment={finishAssessment}
            isOpen={isOpenedTimeOutModal}
            setIsOpenModal={setIsOpenedTimeOutModal}
          />

          <TimerErrorModal errorState={errorState} />

          {isDesktopView && (
            <CandidateAssessmentDesktop
              assessmentImageUrl={imageUrl}
              viewType={viewType()}
              disabled={!isAllowToGoNext && isVisibleTooltip && !isLoadingButton}
              setIsVisibleTooltip={setIsVisibleTooltip}
              clientLogo={client?.logoUrl}
              clientName={client?.name}
              childrenNextButton={
                <>
                  {questionsList[currentPageIndex] && (
                    <CustomButton
                      arrowAfter
                      isEnabledKeydown
                      title={nextButtonText}
                      isLoading={isLoadingButton}
                      onClick={() => handleNextClick()}
                      disabled={!isAllowToGoNext}
                    />
                  )}
                </>
              }
              childrenBackButton={
                <>
                  {questionsList[currentPageIndex] && (
                    <CustomButton
                      arrowBefore
                      title="back"
                      colorType={ButtonColorType.transparentDark}
                      onClick={() => handleBackClick()}
                      disabled={!isAllowToGoBack}
                    />
                  )}
                </>
              }
            >
              <ContentView
                assessment={assessment}
                textLocations={textLocations}
                viewType={viewType()}
                currentPage={currentPage}
                isLoadingTestInstructions={isLoadingInstructions}
                currentAnswers={currentAnswers}
                buttonTitle={buttonTitle()}
                setIsOpenedInstructions={setIsOpenedInstructions}
                handleChoiceSelect={handleChoiceSelect}
                handleSubmitClick={submitClick}
                timerDisabled={Boolean(invite?.timerDisabled)}
              />
            </CandidateAssessmentDesktop>
          )}

          {!isDesktopView && (
            <CandidateAssessmentMobile
              disabled={!isAllowToGoNext && isVisibleTooltip && !isLoadingButton}
              setIsVisibleTooltip={setIsVisibleTooltip}
              assessmentImageUrl={imageUrl}
              viewType={viewType()}
              clientLogo={client?.logoUrl}
              clientName={client?.name}
              childrenSubmitButton={<CustomButton title={buttonTitle()} onClick={submitClick} fullWidth={true} />}
              childrenNextButton={
                <>
                  {questionsList[currentPageIndex] && (
                    <CustomButton
                      arrowAfter
                      isMobileView
                      isEnabledKeydown
                      title={nextButtonText}
                      buttonSize={ButtonSize.large}
                      colorType={ButtonColorType.dark}
                      isLoading={isLoadingButton}
                      onClick={() => handleNextClick()}
                      disabled={!isAllowToGoNext}
                    />
                  )}
                </>
              }
              childrenBackButton={
                <>
                  {questionsList[currentPageIndex] && (
                    <CustomButton
                      arrowBefore
                      isMobileView
                      title="back"
                      buttonSize={ButtonSize.large}
                      colorType={ButtonColorType.transparentDark}
                      onClick={() => handleBackClick()}
                      disabled={!isAllowToGoBack}
                    />
                  )}
                </>
              }
            >
              <ContentView
                assessment={assessment}
                textLocations={textLocations}
                viewType={viewType()}
                currentPage={currentPage}
                isLoadingTestInstructions={isLoadingInstructions}
                currentAnswers={currentAnswers}
                buttonTitle={buttonTitle()}
                setIsOpenedInstructions={setIsOpenedInstructions}
                handleChoiceSelect={handleChoiceSelect}
                handleSubmitClick={submitClick}
                timerDisabled={Boolean(invite?.timerDisabled)}
              />
            </CandidateAssessmentMobile>
          )}
        </SectionContext.Provider>
      </CurrentPageContext.Provider>
    </InviteContext.Provider>
  );
}

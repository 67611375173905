import * as React from "react";
import { useEffect, useState } from "react";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";

import { MIN_AXIS_QUANTITY_FOR_RADAR } from "@/shared/components/assessment/report/utils/reportUtils";
import { IChart, ICommonChart } from "@/shared/components/charts/types/chartTypes";
import { transformDataToMultiChart } from "@/shared/components/charts/utils/transformDataToChartData";
import CustomTooltip from "@/shared/components/charts/CustomTooltip/CustomTooltip";
import {
  getRadarTicksCount,
  getAllValuesOfSingleCompetency,
  getChartDataWithHiddenScores,
  sortDataByNameAndTestRank,
  ChartDataType,
  chartMaxValues,
} from "@/shared/components/charts/utils/chartUtils";

import "../styles/chartStyles.css";

export interface IRadarResponsiveProps {
  labelWidth?: number;
  margin?: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
  containerWidth?: string;
}

interface IRadarChart extends ICommonChart {
  magnifier?: number;
  hoveredTestId?: number;
  radarHeight?: number;
  tickLabelLimit?: number;
  responsiveProps?: IRadarResponsiveProps;
}

export function RadarChartComponent({
  usersChartData,
  isHiddenSameScore,
  magnifier,
  hoveredTestId,
  responsiveProps,
  chartDataType = ChartDataType.ptr5,
  radarHeight = 600,
  tickLabelLimit = 70,
}: IRadarChart) {
  const [chartData, setChartData] = useState<IChart[]>(
    transformDataToMultiChart(sortDataByNameAndTestRank(usersChartData)),
  );
  const defaultOpacity: number = 0.25;

  useEffect(() => {
    setChartData(transformDataToMultiChart(sortDataByNameAndTestRank(usersChartData)));
  }, [usersChartData]);

  useEffect(() => {
    if (isHiddenSameScore) {
      const onlyValues = getAllValuesOfSingleCompetency(chartData);
      const filteredData = getChartDataWithHiddenScores(onlyValues, chartData);
      setChartData(filteredData);
    } else setChartData(transformDataToMultiChart(sortDataByNameAndTestRank(usersChartData)));
  }, [isHiddenSameScore]);

  const customLabelTick = (props: any) => {
    const LABEL_WIDTH: number = responsiveProps?.labelWidth || 200;
    const LABEL_HEIGHT: number = 70;
    const { payload, x, y, stroke, radius } = props;
    const cos: number = Number(Math.cos((Math.PI * payload.coordinate) / 180).toFixed(4));
    const sin: number = Number(Math.sin((Math.PI * payload.coordinate) / 180).toFixed(4));
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <foreignObject
          width={LABEL_WIDTH}
          height={LABEL_HEIGHT}
          radius={radius}
          stroke={stroke}
          x={cos === 0 ? x - LABEL_WIDTH / 2 : cos > 0 ? x : x - LABEL_WIDTH}
          y={sin === 0 ? y - LABEL_HEIGHT / 2 : sin > 0 ? y - LABEL_HEIGHT / 2 : y}
          className="recharts-text recharts-polar-angle-axis-tick-value"
        >
          <p
            style={{
              textAlign: cos === 0 ? "center" : cos > 0 ? "left" : "right",
              flexDirection: cos === 0 ? "row" : cos > 0 ? "row" : "row-reverse",
              verticalAlign: "middle",
              padding: "0 10px",
              color: "#535353",
              lineHeight: "1.1em",
              height: "inherit",
              display: "flex",
              alignItems: sin < 0 ? "flex-start" : "center",
            }}
          >
            {tickFormatter(payload.value)}
          </p>
        </foreignObject>
      </g>
    );
  };

  const tickFormatter = (value: string) => {
    if (value) {
      const limit = tickLabelLimit;
      if (value.length < limit) return value;
      return `${value.substring(0, limit)}...`;
    }
    return value;
  };

  const CustomizedDot = (props: any) => {
    const {
      cx,
      cy,
      payload: {
        value,
        payload: { testId, testColor, reportCompetencyColor },
      },
    } = props;

    const DEFAULT_DOT_SIZE = 7;

    if (isNaN(value) || value === null) {
      return <></>;
    }
    return (
      <circle
        cx={cx}
        cy={cy}
        r={magnifier ? (DEFAULT_DOT_SIZE * (hoveredTestId === testId ? magnifier : 1)) / 2 : DEFAULT_DOT_SIZE / 2}
        fill={reportCompetencyColor || testColor || "black"}
      />
    );
  };

  const CustomizedActiveDot = (props: any) => {
    const {
      cx,
      cy,
      payload: { value, testColor, reportCompetencyColor },
    } = props;

    const DEFAULT_ACTIVE_DOT_SIZE = 12;

    if (isNaN(value) || value === null) {
      return <></>;
    }
    return (
      <circle
        cx={cx}
        cy={cy}
        r={DEFAULT_ACTIVE_DOT_SIZE / 2}
        stroke={reportCompetencyColor || testColor || "red"}
        strokeWidth="6"
        fill="#ffffff"
      />
    );
  };

  function customValueTick(props: any) {
    const { payload, x, y, textAnchor, stroke, radius } = props;
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          <tspan x={x + 7} dy="0.5em">
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  if (chartData.length < MIN_AXIS_QUANTITY_FOR_RADAR) {
    if (isHiddenSameScore) {
      return !chartData.length ? (
        <p className="textExplanation">All selected candidates got the same score for all competencies</p>
      ) : (
        <p className="textExplanation">
          Competencies with different scores are not enough to display radar chart correctly
        </p>
      );
    }
    return <p className="textExplanation">Please select more competencies to display a radar chart</p>;
  }
  return (
    <ResponsiveContainer
      width={responsiveProps?.containerWidth || "100%"}
      height={radarHeight}
      className="chartContainer"
    >
      <RadarChart
        data={chartData}
        margin={
          responsiveProps?.margin || {
            top: 10,
            right: 150,
            left: 150,
            bottom: 10,
          }
        }
      >
        <Tooltip content={<CustomTooltip />} />
        <PolarGrid />
        <PolarAngleAxis dataKey="competencyName" allowDuplicatedCategory={false} tick={customLabelTick} />
        <PolarRadiusAxis
          type="number"
          angle={90}
          scale={"linear"}
          domain={([min]) => [Math.min(0, min - 1), chartMaxValues[chartDataType]]}
          tickCount={getRadarTicksCount(getAllValuesOfSingleCompetency(chartData), chartDataType)}
          label={0}
          tick={customValueTick}
        />
        {usersChartData.map(({ name, inviteId, color }) => (
          <Radar
            key={inviteId}
            label={false}
            name={name}
            dataKey={inviteId}
            fill={color}
            fillOpacity={defaultOpacity}
            dot={<CustomizedDot />}
            activeDot={<CustomizedActiveDot />}
            isAnimationActive={false}
          />
        ))}
      </RadarChart>
    </ResponsiveContainer>
  );
}

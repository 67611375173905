import { useEffect, useState } from "react";

import { TestHttpService } from "@/shared/services/tests/test-http.service";
import { TestAnswerStatusEnum } from "@/shared/services/tests/tests.types";

import { ISectionConfig } from "@/modules/assessments/types/test.interface";

export type ITestsStats = {
  testsAnswers: Record<string, Record<TestAnswerStatusEnum, { countOfAnswers: number }>>;
  testsElements: Record<string, { countOfElements: number }>;
  assessmentTests: Record<string, { countOfAssessments: number }>;
  testsNorms: Record<string, { countOfNorms: number }>;
  testsTime: Record<string, { suggestedTime: number }>;
  testsSections: Record<string, ISectionConfig[]>;
};

export function useGetTestsStatsHook(): {
  testsStats: ITestsStats;
  isLoading: boolean;
} {
  const [testsStats, setTestsStats] = useState<ITestsStats>({
    testsAnswers: {},
    testsElements: {},
    assessmentTests: {},
    testsNorms: {},
    testsTime: {},
    testsSections: {},
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTestsStats = async () => {
      try {
        setIsLoading(true);

        const testsStats = await TestHttpService.getTestsStats();

        setTestsStats(testsStats);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
      }
    };

    fetchTestsStats();
  }, []);

  return { testsStats, isLoading };
}

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Table, Select, Popover } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import { DeleteOutlined } from "@ant-design/icons";

import { Loading } from "@/shared/components/Loading/Loading";
import { selectClientRoles } from "@/shared/services/store/rolesStore";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { makeReadableName } from "@/shared/utils/common.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";

import { useAssessmentHook } from "@/shared/hooks/assessments/useAssessment.hook";
import { useGetClientUsersHook } from "@/shared/hooks/users/use-get-client-users.hook";

import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";
import { getAssessmentRolesList } from "@/modules/assessments/scenes/AssessmentTeam/assessment-roles.utils";

import { useRemoveAssessmentUserHook } from "@/modules/assessments/hooks/use-remove-assessment-user.hook";
import { useManageAssessmentUserHook } from "@/modules/assessments/hooks/use-manage-assessment-user.hook";
import { useGetAssessmentUsersPermissionsHook } from "@/modules/assessments/hooks/use-get-assessment-users-permissions.hook";

import styles from "./team-list.styles.module.css";

export type TeamListComponentProps = {
  assessmentId: number;
  isArchived?: boolean;
};

export function TeamListComponent({ assessmentId, isArchived }: TeamListComponentProps) {
  const {
    assessmentPermissions,
    isLoading: isLoadingAssessmentPermissions,
    refreshAssessmentPermissions,
  } = useGetAssessmentUsersPermissionsHook(assessmentId);

  // TODO: Move to global state
  const { assessment, isLoading: isLoadingAssessment } = useAssessmentHook(Number(assessmentId));
  const { users: clientUsers, isLoading: isLoadingClientUsers } = useGetClientUsersHook(assessment?.clientId);

  const { manageAssessmentUser, isLoading: isLoadingUserToAssessment } = useManageAssessmentUserHook();
  const { removeAssessmentUser, isLoading: isLoadingRemoveAction } = useRemoveAssessmentUserHook();

  const updateUserRoleAndRefresh = async ({ userId, roleName }: { userId: number; roleName: AssessmentRoleEnum }) => {
    await manageAssessmentUser(Number(assessmentId), Number(userId), roleName);
    await refreshAssessmentPermissions();
  };

  const removeUserRoleAndRefresh = async ({ userId, assessmentId }: { userId: number; assessmentId: number }) => {
    await removeAssessmentUser(assessmentId, userId);
    await refreshAssessmentPermissions();
  };

  const clientRoles = useSelector(selectClientRoles);

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canRemoveAssessmentUser = assessmentAbility.can(Action.Delete, Subject.ASSESSMENT_USER_PERMISSION);
  const canUpdateAssessmentUser = assessmentAbility.can(Action.Update, Subject.ASSESSMENT_USER_PERMISSION);

  const assessmentUsersTableColumns: ColumnsType<{
    key: number;
    userId: number;
    fullName: string;
    roleName: string;
    isAdmin: boolean;
  }> = [
    {
      key: "fullName",
      title: "User",
      dataIndex: "fullName",
      width: "50%",
      defaultSortOrder: "ascend",
    },
    {
      key: "roleName",
      title: "Role In This Assessment",
      width: "45%",
      dataIndex: "roleName",
      render: (value, record) =>
        record.isAdmin ? (
          <b>Client Admin</b>
        ) : (
          <Select
            showSearch
            placeholder="Select a role"
            disabled={record.isAdmin || !canUpdateAssessmentUser || isArchived}
            options={getAssessmentRolesList().map(({ value, name }) => ({ value, label: name }))}
            optionFilterProp="children"
            filterOption={(input, option) => isSubstring(String(option?.label), input)}
            onChange={(roleName) => updateUserRoleAndRefresh({ roleName, userId: Number(record.userId) })}
            value={value}
            className={styles.selectContainer}
          />
        ),
    },
    {
      key: "userId",
      title: "",
      dataIndex: "userId",
      width: "4%",
      render: (userId, record) =>
        !record.isAdmin && (
          <Popover
            trigger="click"
            content={
              <CustomButton
                title="Remove"
                disabled={record.isAdmin || !canRemoveAssessmentUser || isArchived}
                buttonSize={ButtonSize.small}
                isLoading={isLoadingUserToAssessment}
                onClick={() => removeUserRoleAndRefresh({ assessmentId, userId })}
              />
            }
          >
            <DeleteOutlined disabled={isArchived || record.isAdmin} />
          </Popover>
        ),
    },
  ];

  if (isLoadingAssessmentPermissions || isLoadingAssessment || isLoadingClientUsers) {
    return <Loading />;
  }

  const clientUserAdmins = clientUsers
    .filter(({ isAdmin, clientUsers }) => {
      if (isAdmin) {
        return false;
      }

      if (clientUsers) {
        const clientUserRole = clientRoles.find((role) => role.roleId === clientUsers[0].roleId);

        return clientUserRole?.name === ClientRoleEnum.ADMIN;
      }

      return false;
    })
    .map((user) => ({
      key: user.userId,
      userId: user.userId,
      fullName: user.fullName,
      roleName: makeReadableName(
        clientRoles.find((role) => user?.clientUsers && role.roleId === user?.clientUsers[0].roleId)?.name ||
          ClientRoleEnum.USER,
      ),
      isAdmin: true,
    }));

  const assessmentUsers = assessmentPermissions
    .filter(
      ({ userId, user }) =>
        !user?.isAdmin && !clientUserAdmins.find((clientUserAdmin) => clientUserAdmin.userId === userId),
    )
    .map(({ user, role }) => ({
      roleName: makeReadableName(role?.name || AssessmentRoleEnum.USER),
      fullName: user?.fullName || "",
      userId: user?.userId || 0,
      key: user?.userId || 0,
      isAdmin: false,
    }));

  return (
    <div className={styles.teamListContainer}>
      <Table
        loading={isLoadingUserToAssessment || isLoadingRemoveAction}
        columns={assessmentUsersTableColumns}
        dataSource={[...clientUserAdmins, ...assessmentUsers]}
        scroll={{ x: "1000px" }}
        style={{ width: "100%" }}
      />
    </div>
  );
}

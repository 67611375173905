import axios from "axios";

function isClientSideError(statusCode?: number) {
  if (!statusCode) return false;

  return statusCode >= 400 && statusCode < 500;
}

export function getFriendlyErrorMessage(error: unknown): string | undefined {
  if (axios.isAxiosError(error) && isClientSideError(error.response?.status)) {
    return error.response?.data?.message;
  }
  return;
}

import { Badge, Radio } from "antd";
import classNames from "classnames";

import { IChoice } from "@/modules/assessments/types/test.interface";

import badgeStyle from "@/shared/components/assessment/report/TestAnalysisBlock/style.module.css";
import { TestElementMode } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { getScaleRepresentation, getUniqueKeysValues } from "@/shared/components/testManagement/TestElementInfoBlock";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import { badgeColor } from "@/ui-components/testElementComponents/TrueFalseRadioGroup/TrueFalseRadioGroup";

import styles from "./radio-group.module.css";
import RenderedHtml from "@/ui-components/commonComponents/RenderedHtml/RenderedHtml";

interface IRadioGroup {
  id: number;
  onChange?: (e: any) => void;
  currentAnswer: boolean | string | undefined;
  choices: IChoice[];
  mode?: TestElementMode;
  disable?: boolean;
  isHorizontal?: boolean;
}

export default function RadioGroup({
  id,
  onChange,
  currentAnswer,
  choices,
  isHorizontal = false,
  mode = TestElementMode.Candidate,
  disable = false,
}: IRadioGroup) {
  const isSvg = String(choices[0].choice).includes("svg");
  const showBadge: boolean = mode === TestElementMode.Admin;

  return (
    <Radio.Group
      size="large"
      id={String(id)}
      name={String(id)}
      value={currentAnswer}
      onChange={onChange}
      className={classNames({
        [styles.answersRadioGroupContainer]: !isHorizontal,
        [styles.answersRadioGroupContainerHorizontal]: isHorizontal,
        [styles.analysis]: showBadge,
      })}
    >
      {choices.map(({ key, choice, customName, scales }) => {
        return (
          <Badge
            key={key}
            count={showBadge && scales.length !== 0 ? `+${getScaleRepresentation(getUniqueKeysValues([scales]))}` : 0}
            offset={[2, -2]}
            color={badgeColor}
            className={badgeStyle.badge}
          >
            <Radio
              id={key}
              disabled={disable}
              value={choice}
              className={classNames(styles.radio, {
                [styles.radioSvg]: isSvg,
                [styles.radioChecked]: choice === currentAnswer && isSvg,
              })}
            >
              {isSvg ? (
                <MarkdownPreviewCustom source={String(choice)} className={styles.optionsAlign} />
              ) : (
                <RenderedHtml>{customName || String(choice)}</RenderedHtml>
              )}
            </Radio>
          </Badge>
        );
      })}
    </Radio.Group>
  );
}

import { useSelector } from "react-redux";

import { selectAllCompetencies } from "@/shared/services/store/competenciesStore";
import { sortElements } from "@/shared/utils/array/sort.utils";

export function useGetReduxTestsCompetenciesHook(testIds?: number[]) {
  return useSelector(selectAllCompetencies)
    .filter((competency) => testIds?.includes(competency.testId))
    .sort((first, second) => sortElements(first.name, second.name));
}

import * as React from "react";
import { ReactElement } from "react";
import { Divider, Pagination } from "antd";

import { Loading, LoadingSize } from "@/shared/components/Loading/Loading";

import styles from "./style.module.css";

interface IPaginatedDropdown {
  menu: ReactElement;
  loading: boolean;
  currentPage: number;
  totalInvitesQuantity: number;
  handlePagination: (pageIndex: number) => Promise<void>;
}

export default function PaginatedDropdown({
  menu,
  loading,
  currentPage,
  totalInvitesQuantity,
  handlePagination,
}: IPaginatedDropdown) {
  if (loading) {
    return <Loading size={LoadingSize.small} />;
  }

  return (
    <div>
      {menu}
      <Divider className={styles.divider} />
      <div className={styles.paginationContainer}>
        <Pagination simple current={currentPage} total={totalInvitesQuantity} onChange={handlePagination} />
      </div>
    </div>
  );
}

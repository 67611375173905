import { parseAsync } from "json2csv";
import { isValidEmail } from "@/shared/utils/common.utils";

export class CandidateUtils {
  static async fromTextToCsvCandidates(candidates: string) {
    const csvFields = ["email", "fullName"];

    const candidatesJSON = candidates
      .split("\n")
      .filter((value) => value)
      .map((value) =>
        value.split(";").reduce((acc, v, i) => {
          const field = csvFields[i];

          if (!field || field === v) {
            return acc;
          }

          if (field === "email" && !isValidEmail(v)) {
            throw new Error(`Please check email "${v}" on correctness`);
          }

          return { ...acc, [field]: v.trim() || "" };
        }, {}),
      );

    const candidatesCSV = await parseAsync(candidatesJSON, {
      delimiter: ";",
      fields: csvFields,
    });

    return new Blob([candidatesCSV], { type: "text/csv;charset=utf-8" });
  }
}

import { makeReadableName } from "@/shared/utils/common.utils";
import {
  AssessmentRoleEnum,
  AssessmentRolesDescription,
} from "@/modules/assessments/services/assessment-permissions-http.service";

export function getAssessmentRolesList(): { value: string; name: string; description?: string }[] {
  return Object.values(AssessmentRoleEnum).map((role) => ({
    value: role,
    description: AssessmentRolesDescription[role],
    name: makeReadableName(role),
  }));
}

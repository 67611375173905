import React, { useEffect, useState } from "react";
import { Alert, Form, Switch } from "antd";
import MDEditor from "@uiw/react-md-editor";

import { IQuestion, LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import { Likert5Choices } from "@/shared/constants/test-elements.constants";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { AceSupportedLanguages, CodeEditor } from "@/ui-components/commonComponents/CodeEditor/CodeEditor";
import { getAnswersNames } from "@/ui-components/testElementComponents/TrueFalseRadioGroup/TrueFalseRadioGroup";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import { testElementHttpService } from "./services/test-element-http.service";

import cn from "classnames";
import editorStyle from "@/shared/components/assessment/AboutAssessment/style.module.css";
import testElementStyle from "@/shared/components/candidate/scenes/TestElement/style.module.css";
import style from "./style.module.css";

export const getUniqueKeysValues = (arr: string[][]): { [key: string]: number } => {
  return arr.reduce((acc: { [key: string]: number }, el: string[]) => {
    el.map((scale) => {
      acc[scale] = (acc[scale] || 0) + 1;
      return acc;
    });
    return acc;
  }, {});
};

export const getScaleRepresentation = (scales: { [key: string]: number }): string => {
  return Object.entries(scales)
    .map((a) => {
      return `${a[1]}${a[0]}`;
    })
    .toString();
};

export enum SubmitStatuses {
  error = "error",
  warning = "warning",
  success = "success",
  initial = "initial",
}

interface ITestElementInfoBlock {
  id: number;
  questionType: QuestionTypes;
  fullScales: { choice: boolean | string; scales: string[] }[];
  question: IQuestion;
  required?: boolean;
}

export function TestElementInfoBlock({ id, questionType, fullScales, question, required }: ITestElementInfoBlock) {
  const [form] = Form.useForm();

  const isTextElement: boolean = questionType === QuestionTypes.Text;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);
  const [isRequired, setRequired] = useState<boolean | undefined>(required);

  const firstAnswerScales: { [key: string]: number } = getUniqueKeysValues([fullScales[0].scales]);
  const lastAnswerScales: { [key: string]: number } = getUniqueKeysValues([fullScales[fullScales.length - 1].scales]);

  const [enteredFullQuestion, setEnteredFullQuestion] = useState<string>(JSON.stringify(question));

  const handleEditorChange = (e: string) => {
    setEnteredFullQuestion(e);
  };

  const getQuestionScalesRating = (questionType: QuestionTypes) => {
    switch (questionType) {
      case QuestionTypes.TrueFalse:
      case QuestionTypes.StrongWeak:
      case QuestionTypes.MadeNotMade:
      case QuestionTypes.FollowNotFollow:
      case QuestionTypes.CorrectIncorrect:
        return (
          <>
            <p>
              {getAnswersNames().true[questionType][LanguagesIsoEnum.enUS]} :{" "}
              <span>
                {getScaleRepresentation(firstAnswerScales) ? `+ ${getScaleRepresentation(firstAnswerScales)}` : "-"}
              </span>
            </p>
            <p>
              {getAnswersNames().false[questionType][LanguagesIsoEnum.enUS]} :{" "}
              <span>
                {getScaleRepresentation(lastAnswerScales) ? `+ ${getScaleRepresentation(lastAnswerScales)}` : "-"}
              </span>
            </p>
          </>
        );
      case QuestionTypes.SD:
        return (
          <>
            <p>
              {question[LanguagesIsoEnum.enUS][0]} (S1) :
              <span>
                {getScaleRepresentation(firstAnswerScales) ? `+ ${getScaleRepresentation(firstAnswerScales)}` : "-"}
              </span>
            </p>
            <p>
              {question[LanguagesIsoEnum.enUS][1]} (S2) :
              <span>
                {getScaleRepresentation(lastAnswerScales) ? `+ ${getScaleRepresentation(lastAnswerScales)}` : "-"}
              </span>
            </p>
          </>
        );
      case QuestionTypes.Likert5:
        return (
          <>
            {fullScales.map((option) => (
              <p>
                {Likert5Choices[Number(option.choice)]} :
                <span>
                  {getScaleRepresentation(getUniqueKeysValues([option.scales]))
                    ? `+ ${getScaleRepresentation(getUniqueKeysValues([option.scales]))}`
                    : "-"}
                </span>
              </p>
            ))}
          </>
        );
      case QuestionTypes.Radio:
      case QuestionTypes.LikelyTrueFalse:
        return (
          <>
            {fullScales.map((scale) => (
              <p>
                {scale.choice} :
                <span>
                  {getScaleRepresentation(getUniqueKeysValues([scale.scales]))
                    ? `+ ${getScaleRepresentation(getUniqueKeysValues([scale.scales]))}`
                    : "-"}
                </span>
              </p>
            ))}
          </>
        );
      case QuestionTypes.Text:
        return <></>;
    }
  };

  const handleSaveClick = async () => {
    const payloadKey = isTextElement ? "body" : "question";
    let payloadValue = null;

    try {
      payloadValue = isTextElement ? form.getFieldsValue(true) : JSON.parse(enteredFullQuestion);
    } catch (err) {
      return setAlert(SubmitStatuses.error);
    }

    try {
      setAlert(SubmitStatuses.initial);
      setIsLoading(true);
      const response = await testElementHttpService.updateTestElementById(
        id,
        {
          [payloadKey]: payloadValue,
        },
        !isTextElement ? isRequired : undefined,
      );
      setAlert(
        JSON.stringify(payloadValue) === JSON.stringify(response.config.payload[payloadKey])
          ? SubmitStatuses.success
          : SubmitStatuses.warning,
      );
    } catch (e: any) {
      setAlert(SubmitStatuses.error);
      console.error(`question patch ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert message={"Please provide some content for every available language."} type={"error"} showIcon />;
      case SubmitStatuses.warning:
        return <Alert message={'Supported localizations are: "en-US", "es-ES"'} type={"warning"} showIcon />;
      case SubmitStatuses.success:
        return <Alert message={"Success"} type={"success"} showIcon />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...question, isRequired: required });
  }, [question, required]);

  return (
    <div>
      <Form form={form} name="testInstructions" onFinish={handleSaveClick} requiredMark={false}>
        <div className={style.scalesContainer}>
          {getQuestionScalesRating(questionType)}
          {!isTextElement && (
            <Switch
              checkedChildren="Required"
              unCheckedChildren="Not Required"
              checked={isRequired}
              onClick={setRequired}
              style={{ fontWeight: "bolder" }}
            />
          )}
        </div>

        <div>
          {isTextElement ? (
            Object.keys(question).map((language) => (
              <Form.Item
                key={language}
                name={language}
                label={language}
                className={cn(editorStyle.mdEditor, testElementStyle.questionTextWrapper)}
              >
                <MDEditor highlightEnable={false} height={300} placeholder="Type..." />
              </Form.Item>
            ))
          ) : (
            <CodeEditor
              editorMode={AceSupportedLanguages.JSON}
              uniqueName={`testElement_${id}`}
              defaultContent={JSON.stringify(question)}
              onEditorChange={handleEditorChange}
            />
          )}

          <div className={style.buttonContainer}>
            <CustomButton title={"Save"} isLoading={isLoading} buttonSize={ButtonSize.XSmall}>
              <SaveSVG className={style.svgSize} aria-hidden="true" />
            </CustomButton>

            <div className={style.alertContainer}>{getAlert(alert)}</div>
          </div>
        </div>
      </Form>
    </div>
  );
}

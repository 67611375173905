import React, { useMemo } from "react";
import classNames from "classnames";
import ReactCountdown from "react-countdown";
import { mlsToSec, secondsToHms } from "@/shared/utils/common.utils";
import AlertImage from "./alert.png";

import styles from "./styles.module.css";

const MIN_ALERT_TIME_MINUTES = 2;

interface ICountdown {
  timerKey?: string;
  /**
   * @description time (in ms). If initially set to 0, the timer won't start automatically.
   */
  timeToCount: number;
  /**
   * @description tick interval (in ms)
   * @default 1000
   */
  intervalDelay?: number;
}

function TimeRemainingDisplay({ total, showAlert = false }: { total: number; showAlert?: boolean }) {
  const time = secondsToHms(mlsToSec(total));

  return (
    <p className={classNames(styles.timerContent, styles.time, { [styles.alertTime]: showAlert })}>
      {showAlert && <img src={AlertImage} alt="time running out" />}
      {time}
    </p>
  );
}

export function Countdown({ timerKey, timeToCount, intervalDelay = 1000 }: ICountdown) {
  const date = useMemo(() => {
    return Date.now() + timeToCount;
  }, [timeToCount]);
  return (
    <ReactCountdown
      key={timerKey}
      date={date}
      intervalDelay={intervalDelay}
      renderer={({ minutes, completed, total }) => {
        if (completed) {
          return <p className={classNames(styles.timerContent, styles.alertTime)}>No time left</p>;
        }

        return <TimeRemainingDisplay total={total} showAlert={minutes < MIN_ALERT_TIME_MINUTES} />;
      }}
    />
  );
}

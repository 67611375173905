import React, { useEffect, useMemo, useState } from "react";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { Form, notification } from "antd";
import { CustomInput, InputType } from "@/ui-components/commonComponents/Input/Input";
import { ButtonColorType, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";
import { useAllAssessmentsHook } from "../../hooks/useAllAssessments.hook";
import { Loading } from "@/shared/components/Loading/Loading";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { InviteHttpService } from "../../services/invite-http.service";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { AssessmentInviteStatus } from "../AssessmentInvites/invites.constants";
import { useAssessmentHook } from "@/shared/hooks/assessments/useAssessment.hook";
import { CLIENT_ROUTE, ASSESSMENT_ROUTE, ASSESSMENT_INVITE_ROUTE } from "@/routing/AppRouter/routes.constants";
import { getFriendlyErrorMessage } from "@/shared/services/http";

import style from "./style.module.css";

function useAssessmentInvites(assessmentId: number, ids: Array<string> | null) {
  const [data, setData] = useState<Array<IAssessmentInvite>>();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!ids) return;

    async function fetchInvites() {
      try {
        const { data }: { data: Array<IAssessmentInvite> } = await InvitesHttpService.getAssessmentInvitesByParameters(
          assessmentId,
          { status: AssessmentInviteStatus.Done, ids: ids ?? undefined },
        );
        setData(data);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Failed to load candidates");
      }
    }

    fetchInvites();
  }, [assessmentId, ids]);

  return { data, error };
}

function pluralize(str: string, count: number = 0, plural?: string) {
  if (count === 1) return str;
  return plural ?? str + "s";
}

export default function RescoreAssessment() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const inviteIds = useGetQueryCode("invites", true);
  const { client_id, assessment_id } = useParams<{ client_id: string; assessment_id: string }>();
  const assessmentId = Number(assessment_id);
  const { assessment } = useAssessmentHook(assessmentId);
  const { assessments, isLoading: isLoadingAssessments } = useAllAssessmentsHook(
    Number(client_id),
    { sortOptions: { column: "name", direction: "ASC" } },
    true,
  );
  const { data: invites, error: inviteError } = useAssessmentInvites(assessmentId, inviteIds);

  const isError = Boolean(inviteError);
  const isLoading = !isError && isLoadingAssessments;
  const backPath = `/${CLIENT_ROUTE}/${client_id}/${ASSESSMENT_ROUTE}/${assessment_id}/${ASSESSMENT_INVITE_ROUTE}`;

  const assessmentOptions = useMemo(() => {
    if (!assessment) return [];

    let includedTestIdMap: { [k: number]: true } = {};
    assessment.assessmentTests?.forEach(({ testId }) => {
      includedTestIdMap[testId] = true;
    });

    return assessments
      .filter((assessmentToFilter) => {
        return (
          assessment.assessmentId !== assessmentToFilter.assessmentId &&
          !assessmentToFilter.archivedOn &&
          assessmentToFilter.assessmentTests?.length &&
          assessmentToFilter.assessmentTests.some(({ testId }) => includedTestIdMap[testId])
        );
      })
      .map((assessment) => ({
        value: String(assessment.assessmentId),
        name: assessment.name,
      }));
  }, [assessment, assessments]);

  return (
    <div className={style.background}>
      <SEO titleParams={["Assessment", "Rescore"]} />
      {isLoading || assessment === undefined || invites === undefined ? (
        <Loading />
      ) : (
        <div className={style.mainContainer}>
          <h1>Rescore Candidates</h1>
          {isError ? (
            <p>Oops! Something went wrong. Please refresh the page to try again.</p>
          ) : invites.length === 0 ? (
            <div className={style.emptyState}>
              <p>None of the selected candidates are eligible for rescoring. Please go back and select again.</p>
              <div className={style.buttonContainer}>
                <CustomButton title="Go back" onClick={() => navigate(backPath)} />
              </div>
            </div>
          ) : assessmentOptions.length === 0 ? (
            <div className={style.emptyState}>
              <p>There are no available assessments to choose from.</p>
              <p>
                If you'd like to rescore {pluralize("this", invites.length, "these")}{" "}
                {pluralize("candidate", invites.length)} for a new role, then you must create the appropriate assessment
                first.
              </p>
            </div>
          ) : (
            <>
              <div className={style.fieldGroup}>
                <p className={style.sectionLabel}>Eligible candidates</p>
                <ul>
                  {invites.map((invite) => (
                    <li key={invite.inviteId}>{invite.claimedByUser?.fullName}</li>
                  ))}
                </ul>
              </div>
              <div className={style.fieldGroup}>
                <p className={style.sectionLabel}>Rescoring from</p>
                <p>{assessment.name}</p>
              </div>
              <Form
                form={form}
                name="AssessmentRescore"
                requiredMark={false}
                initialValues={{
                  targetAssessmentId: null,
                }}
                onFinish={async (values) => {
                  try {
                    setIsSubmitting(true);
                    await InviteHttpService.rescoreInvites({
                      sourceAssessmentId: assessment.assessmentId,
                      targetAssessmentId: values.targetAssessmentId,
                      inviteIds: invites.map(({ inviteId }) => inviteId),
                    });
                    setIsSubmitting(false);
                    notification.success({
                      message: "Rescore initiated. You may need to wait a few minutes for the scoring to process.",
                    });
                    navigate(
                      `/${CLIENT_ROUTE}/${client_id}/${ASSESSMENT_ROUTE}/${values.targetAssessmentId}/${ASSESSMENT_INVITE_ROUTE}`,
                    );
                  } catch (error) {
                    setIsSubmitting(false);
                    notification.error({
                      message: getFriendlyErrorMessage(error) ?? "Oops, something went wrong. Please try again.",
                    });
                  }
                }}
              >
                <Form.Item
                  name="targetAssessmentId"
                  label="Rescoring to"
                  rules={[{ required: true, message: "Target assessment is required" }]}
                >
                  <CustomInput placeholder="Select..." inputType={InputType.select} selectOptions={assessmentOptions} />
                </Form.Item>
                <div className={style.buttonContainer}>
                  <CustomButton title="Rescore" isLoading={isSubmitting} />
                  <CustomButton
                    title="Cancel"
                    colorType={ButtonColorType.transparentDark}
                    onClick={() => navigate(backPath)}
                  />
                </div>
              </Form>
            </>
          )}
        </div>
      )}
    </div>
  );
}

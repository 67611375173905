import { httpClient } from "@/shared/services/http";

const USERS = "users";
const ACCEPT_INVITE = "accept-invite";

export class UserInviteConfirmationHttpService {
  static async acceptUserInvite(email: string) {
    return httpClient.post(`/api/${USERS}/${ACCEPT_INVITE}`, {
      email,
    });
  }
}

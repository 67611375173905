import { IAssessment } from "@/modules/assessments/assessments.types";
import { TTestAnswer } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { ChartCategories } from "@/shared/components/charts/types/chartTypes";

// TODO: GET RID of duplicated interfaces
export enum LanguagesIsoEnum {
  enUS = "en-US",
}

interface LocalizedLanguageText {
  [key: string]: string | undefined;
}

export enum ChartCategoryEnum {
  BaS = "BehaviorsAndSkills",
  MS = "Motivators",
}
export interface ITestConfig {
  suggestedTime: number;
  backButton: boolean;
  chartCategory: ChartCategoryEnum;
  competenciesName?: [string, string];
  groupSize?: number;
  groupHeaderText?: LocalizedLanguageText;
}

export interface ITest {
  createdAt: string;
  updatedAt: string;
  testId: number;
  key?: number;
  name: string;
  config: ITestConfig;
  clientId: number;
  testElements: ITestElement[];
  testAnswers: TTestAnswer[];
  assessmentTests: IAssessment[];
}

export interface IAssessmentTest {
  createdAt?: string;
  updatedAt?: string;
  assessmentTestId: number;
  rank: number;
  normId: number;
  assessmentId: number;
  testId: number;
  config: { competencies: { [key: string]: boolean | 0 | 1 | 2 | 3 | 4 } };
  test: {
    createdAt: string;
    updatedAt: string;
    testId: number;
    name: string;
    reportColor: string | null;
    testElements: ITestElement[];
    config: {
      suggestedTime: number;
      chartCategory: ChartCategories;
      competenciesName?: [string, string];
    };
    clientId: number;
    includeInOverallScore?: boolean;
  };
}

export type ISectionConfig = {
  name: string;
  timeLimit?: number; // in minutes. if null, then section is untimed
};

export interface ITestElement {
  createdAt: string;
  updatedAt: string;
  elementId: number;
  testId: number;
  rank: number;
  config: {
    key: string;
    type: QuestionTypes;
    breakAfter: boolean;
    payload: {
      choices: IChoice[];
      question: IQuestion;
    } & { body: IBody };
    required?: boolean;
    /**
     * @description
     * Only in case when element starts Section
     *
     * Section - it's an abstraction that gives opportunity to divide group of elements and make some logic (i.e. timing)
     * */
    startsSection?: ISectionConfig;
  };
  questionNumber?: number
}

export interface IQuestion {
  [key: string]: string | [string, string];
}

export interface IBody {
  [key: string]: string;
}

export interface IChoice {
  key: string;
  choice: boolean | string;
  scales: string[];

  // When choice need to be displayed differently for the end User
  customName?: string;
}

import { Dispatch, SetStateAction } from "react";

import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";

import { NextModalFooter } from "./NextModalFooter";

export function ConfirmNextModal({
  isOpen,
  handleNextClick,
  setIsOpenedConfirmationModal,
}: {
  isOpen: boolean;
  handleNextClick: (isConfirmed?: boolean) => void;
  setIsOpenedConfirmationModal: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <BasicModal
      autoHeight
      handleCancel={() => setIsOpenedConfirmationModal(false)}
      isModalOpen={isOpen}
      footer={
        <NextModalFooter
          handleOk={async () => {
            await handleNextClick(true);
            setIsOpenedConfirmationModal(false);
          }}
          handleCancel={() => setIsOpenedConfirmationModal(false)}
        />
      }
    >
      <>
        <p>You have unanswered questions on this page and you won't be able to go back to them.</p>
        <p />
        <p>Are you sure you want to go to the next page?</p>
      </>
    </BasicModal>
  );
}

import React, { useContext } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import { Loading } from "@/shared/components/Loading/Loading";
import { statusText } from "@/shared/components/candidate/scenes/InviteCard/InviteCard";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { DB_USER_KEY } from "@/shared/hooks/auth/currentUser.hook";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";

import { ICandidateClientAssessments } from "@/modules/assessments/assessments.types";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { useCandidateAssessmentsHook } from "@/modules/candidate/hooks/useCandidateAssessments.hook";
import { IUser } from "@/modules/client/clients.interface";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { ASSESSMENT_ROUTE, CLIENT_ROUTE, REPORT_MENU_ITEM } from "@/routing/AppRouter/routes.constants";

import { ReactComponent as AuditSVG } from "@/assets/icons/feather-icons/activity.svg";

import tableStyle from "../../tables/style.module.css";

export default function CandidateUserAssessments() {
  const { userId } = useParams();
  const { data, isLoading } = useCandidateAssessmentsHook(userId);

  const currentUser: IUser = JSON.parse(String(localStorage.getItem(DB_USER_KEY)));
  const isAdmin: boolean = currentUser?.isAdmin;

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);

  const assessmentColumns: ColumnsType<ICandidateClientAssessments> = [
    {
      key: "name",
      title: "Assessment",
      dataIndex: ["assessment", "name"],
      className: tableStyle.tableColumnName,
      width: "25%",
      ellipsis: true,
      sorter: (a, b) => a.assessment.name.localeCompare(b.assessment.name),
      render: (value, record) => (
        <Tooltip
          title={record.assessment.name}
          placement={"topLeft"}
          overlayStyle={{ maxWidth: "75%", minWidth: "50px" }}
        >
          <Link
            to={`/${CLIENT_ROUTE}/${record.assessment.clientId}/assessments/${record.assessment.assessmentId}/tests`}
            className={tableStyle.tableLink}
          >
            <span style={{ textDecoration: record.assessment.archivedOn ? "line-through" : "unset" }}>{value}</span>
          </Link>
        </Tooltip>
      ),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: ["invite", "status"],
      className: tableStyle.tableColumn,
      sorter: (a, b) => a.invite.status.localeCompare(b.invite.status),
      render: (value) => statusText[value],
    },
    {
      key: "createdAt",
      title: "Invited On",
      dataIndex: ["invite", "createdAt"],
      className: tableStyle.tableColumn,
      render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      sorter: (a, b) => Number(new Date(a.invite.createdAt)) - Number(new Date(b.invite.createdAt)),
    },
    {
      key: "claimedOn",
      title: "Accepted On",
      dataIndex: ["invite", "claimedOn"],
      className: tableStyle.tableColumn,
      render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      sorter: (a, b) => Number(new Date(a.invite.claimedOn)) - Number(new Date(b.invite.claimedOn)),
    },
    {
      key: "startedOn",
      title: "Started On",
      dataIndex: ["invite", "startedOn"],
      className: tableStyle.tableColumn,
      render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      sorter: (a, b) => Number(new Date(a.invite.startedOn || 0)) - Number(new Date(b.invite.startedOn || 0)),
    },
    {
      key: "finishedOn",
      title: "Finished On",
      dataIndex: ["invite", "finishedOn"],
      className: tableStyle.tableColumn,
      render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      sorter: (a, b) => Number(new Date(a.invite.finishedOn || 0)) - Number(new Date(b.invite.finishedOn || 0)),
    },
    {
      key: "code",
      title: "Code",
      dataIndex: ["invite", "code"],
      className: tableStyle.tableColumnCode,
    },
    {
      key: "actions",
      title: "",
      className: cn(tableStyle.tableColumn, tableStyle.toLeft),
      render: (record) => {
        const reportURL = `/${CLIENT_ROUTE}/${record.assessment.clientId}/${ASSESSMENT_ROUTE}/${record.assessment.assessmentId}/invites/${record.invite.inviteId}`;
        return (
          <div className={cn(tableStyle.actionsContainer, tableStyle.minActionsGap)}>
            {(isAdmin || canManageAssessment) && (
              <Tooltip overlay="Audit">
                <Link to={`${reportURL}?${REPORT_MENU_ITEM}=audit`} aria-label={`View ${record.assessment.name} audit`}>
                  <Button className={tableStyle.resendButton}>
                    <AuditSVG />
                  </Button>
                </Link>
              </Tooltip>
            )}
            <Link to={reportURL}>
              <Button
                className={tableStyle.resendButton}
                disabled={record.invite.status !== AssessmentInviteStatus.Done}
              >
                <Row className={tableStyle.centred}>
                  <div
                    className={cn(tableStyle.buttonNameContainer, {
                      [tableStyle.disabledLink]: record.invite.status !== AssessmentInviteStatus.Done,
                    })}
                  >
                    Report
                  </div>
                </Row>
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <>
      <SEO titleParams={[data.fullName, "Candidate Assessments"]} />

      <div className="main-container">
        <div className="inner-container">
          <Row gutter={30}>
            <Col xxl={24} xl={24} lg={24} md={24}>
              <div>
                <Banner title={`${data.fullName} (${data.email})`} />

                {data.clients.map((client) => (
                  <div
                    className={cn(tableStyle.tableBlock, "table-assessments")}
                    key={client.clientId}
                    style={{ marginBottom: 30 }}
                  >
                    <TableHeader tableName={client.name} hasSearchField={false} />

                    <Table
                      columns={assessmentColumns}
                      dataSource={client.assessments.map((assessment) => ({
                        key: assessment.invite.inviteId,
                        ...assessment,
                      }))}
                      scroll={{ x: "1200px" }}
                      style={{ width: "100%" }}
                      pagination={{
                        position: ["bottomLeft"],
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                ))}

                {!data.clients.length && <Table dataSource={[]} />}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

import * as React from "react";
import { useEffect, useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { IChart, ICommonChart, IMultiChart } from "@/shared/components/charts/types/chartTypes";
import {
  chartMaxValues,
  ChartDataType,
  getAllValuesOfSingleCompetency,
  getBarTicksCount,
  getChartDataWithHiddenScores,
  sortByTestOrderColorAndName,
} from "@/shared/components/charts/utils/chartUtils";
import { transformDataToMultiChart } from "@/shared/components/charts/utils/transformDataToChartData";
import CustomTooltip from "@/shared/components/charts/CustomTooltip/CustomTooltip";

import "../styles/chartStyles.css";

export interface IBarResponsiveProps {
  labelWidth?: number;
  containerWidth?: string;
  barHeight?: number;
}

interface IBarChart extends ICommonChart {
  scene?: ReportScene;
  yAxisWidth?: number;
  hideXAxis?: boolean;
  barHeight?: number;
  responsiveProps?: IBarResponsiveProps;
  min_max?: [number, number];
}

const sortDataByTestOrderColorAndName = (usersChartData: IMultiChart[]): IMultiChart[] => {
  return usersChartData.map((data) => {
    const sortedChartData = sortByTestOrderColorAndName(data.chartData);
    return { ...data, chartData: sortedChartData };
  });
};

export function BarChartComponent({
  usersChartData,
  scene,
  isHiddenSameScore,
  yAxisWidth = 300,
  hideXAxis = false,
  chartDataType = ChartDataType.ptr5,
  barHeight,
  responsiveProps,
  min_max,
}: IBarChart) {
  const BAR_HEIGHT = barHeight || responsiveProps?.barHeight || 50;

  const [chartData, setChartData] = useState<IChart[]>(
    transformDataToMultiChart(sortDataByTestOrderColorAndName(usersChartData)),
  );

  const CustomLabelTick = (props: any) => {
    const { payload, x, y } = props;
    const labelHeight = responsiveProps?.barHeight || BAR_HEIGHT;
    const labelWidth = responsiveProps?.labelWidth || yAxisWidth;
    return (
      <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
        <foreignObject
          width={labelWidth}
          height={labelHeight}
          x={x - labelWidth}
          y={y - Number(labelHeight) / 2}
          style={{ margin: "auto" }}
        >
          <p className="barChartTickLabel">{payload.value}</p>
        </foreignObject>
      </g>
    );
  };

  useEffect(() => {
    setChartData(transformDataToMultiChart(sortDataByTestOrderColorAndName(usersChartData)));
  }, [usersChartData]);

  useEffect(() => {
    if (isHiddenSameScore) {
      const onlyValues = getAllValuesOfSingleCompetency(chartData);
      const filteredData = getChartDataWithHiddenScores(onlyValues, chartData);
      setChartData(filteredData);
    } else setChartData(transformDataToMultiChart(sortDataByTestOrderColorAndName(usersChartData)));
  }, [isHiddenSameScore]);

  const tickFormatter = (value: string) => {
    if (value) {
      const limit = 200;
      if (value.length < limit) return value;
      return `${value.substring(0, limit)}...`;
    }
    return value;
  };

  return (
    <ResponsiveContainer
      width={responsiveProps?.containerWidth || "100%"}
      height={hideXAxis ? chartData.length * BAR_HEIGHT + 20 : chartData.length * BAR_HEIGHT + 50}
      className="chartContainer"
    >
      {!chartData.length && scene === ReportScene.compare ? (
        isHiddenSameScore ? (
          <p className="textExplanation">All selected candidates got the same score for all competencies</p>
        ) : (
          <p className="textExplanation">Please select more competencies to display a radar chart</p>
        )
      ) : (
        <BarChart
          layout="vertical"
          data={chartData}
          margin={{
            top: 0,
            right: 10,
            left: 50,
            bottom: 0,
          }}
        >
          <XAxis
            hide={hideXAxis}
            type="number"
            scale={"linear"}
            orientation={"top"}
            domain={min_max ? min_max : ([min]) => [Math.min(0, min), chartMaxValues[chartDataType]]}
            tickCount={
              min_max
                ? chartMaxValues[chartDataType] - min_max[0]
                : getBarTicksCount(getAllValuesOfSingleCompetency(chartData), chartDataType)
            }
          />
          <YAxis
            dataKey="competencyName"
            type="category"
            width={responsiveProps?.labelWidth || yAxisWidth}
            tick={<CustomLabelTick />}
            tickFormatter={tickFormatter}
          />
          <Tooltip content={<CustomTooltip />} />
          {usersChartData.map(({ name, inviteId, color }) => (
            <Bar key={inviteId} label={false} name={name} dataKey={inviteId} fill={color}>
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={scene === ReportScene.compare ? color : entry.reportCompetencyColor || entry.testColor}
                />
              ))}
            </Bar>
          ))}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
}

import React from "react";
import "./App.css";
import AppRouter from "./routing/AppRouter/Router";
import * as Sentry from "@sentry/react";
import { Layout } from "antd";
import { HelmetProvider } from "react-helmet-async";
import Maintenance from "@/modules/main/Maintenance/Maintenance";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";

const { Content } = Layout;

function App() {
  document.documentElement.setAttribute("data-color-mode", "light");

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Layout className="layout">
        <SEO />
        <Content>
          <div className="App">
            {process.env.REACT_APP_MAINTENANCE_MODE === "true" ? <Maintenance /> : <AppRouter />}
          </div>
        </Content>
      </Layout>
    </HelmetProvider>
  );
}

export default Sentry.withErrorBoundary(App, {});

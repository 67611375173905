import { useEffect, useState } from "react";

import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { IClient, IClientCompetencies } from "@/modules/client/clients.interface";

import { isValidNumericParam } from "@/shared/utils/common.utils";

import { HttpStatus } from "@/routing/services/statusCodes";

export interface IError {
  hasError: boolean;
  errorCode?: number;
}

export function useClientCompetenciesHook(clientId: number) {
  const [clientCompetencies, setClientCompetencies] = useState<IClientCompetencies[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<IError>({ hasError: false });
  const fetchClientCompetencies = async () => {
    try {
      setIsLoading(true);

      const data: IClientCompetencies[] = await ClientHttpService.getClientCompetenciesById(Number(clientId));

      if (data) {
        setClientCompetencies(data);
      }
    } catch (e: any) {
      console.error(`getting clientCompetenciesById error: ${e}`);
      setError({ hasError: true, errorCode: e.response.data.statusCode });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientCompetencies();
  }, [clientId]);

  return { clientCompetencies, isLoading, error };
}

import { useEffect, useState } from "react";

export default function useSetScrollable(scrollableElement: HTMLElement | null) {
  const [hasScroll, setHasScroll] = useState<boolean>(false);

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + 5 > scrollHeight - clientHeight) {
      setHasScroll(false);
    } else setHasScroll(true);
  };

  useEffect(() => {
    if (scrollableElement) {
      const { clientHeight, scrollHeight } = scrollableElement;
      setHasScroll(clientHeight < scrollHeight);
    }
  }, [scrollableElement]);

  return { hasScroll, handleScroll };
}

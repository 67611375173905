import { useEffect, useState } from "react";

import { INorm } from "@/shared/components/assessment/new/TestItem/TestItem";
import { NormsHttpService } from "@/shared/services/norms/norms-http.service";

export function useGetTestNormsHook(testId: number) {
  const [norms, setNorms] = useState<INorm[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const norms = await NormsHttpService.getTestNorms(testId);
        setNorms(norms);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [testId]);

  return { norms, isLoading };
}

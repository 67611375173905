import { LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import questionStyle from "@/shared/components/candidate/scenes/TestElement/style.module.css";
import cn from "classnames";

export function TestInstructions({ instructions }: { instructions: { [language: string]: string } }) {
  if (!instructions || !instructions[LanguagesIsoEnum.enUS]) {
    return <></>;
  }

  return (
    <MarkdownPreviewCustom
      source={instructions[LanguagesIsoEnum.enUS]}
      className={cn(questionStyle.questionMarkdownWrapper, questionStyle.questionTextWrapper)}
    />
  );
}

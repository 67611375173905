import { createSlice } from "@reduxjs/toolkit";

import { ICompetency } from "@/shared/components/competencies/types";

export interface ICompetencyStore {
  isLoaded: boolean;
  data: ICompetency[];
}

export const slice = createSlice({
  name: "competencies",
  initialState: {
    isLoaded: false,
    data: [],
  } as ICompetencyStore,
  reducers: {
    addCompetencies: (state, action) => ({
      ...state,
      isLoaded: true,
      data: [...state.data, ...action.payload],
    }),
    updateCompetencyName: (state, action) => ({
      ...state,
      data: state.data.map((competency) => {
        return competency.key !== action.payload.key
          ? competency
          : {
              ...competency,
              name: action.payload.name,
            };
      }),
    }),
  },
});

export const { addCompetencies, updateCompetencyName } = slice.actions;

export const selectAllCompetencies = (state: { competencies: ICompetencyStore }) => state.competencies.data;

export default slice.reducer;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";

import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { isValidNumericParam } from "@/shared/utils/common.utils";

import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useGetAssessmentInviteHook(assessmentInviteId: number | undefined) {
  const navigate = useNavigate();
  const [assessmentInvite, setAssessmentInvite] = useState<IAssessmentInvite | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAssessmentInvite = async () => {
      try {
        setIsLoading(true);
        const assessmentInvite: IAssessmentInvite = await InvitesHttpService.getAssessmentInviteById(
          Number(assessmentInviteId),
        );
        setAssessmentInvite(assessmentInvite);
      } catch (e: any) {
        console.error(`Get Assessment Invite error: ${e}`);
        navigate(`/${NOT_FOUND_ROUTE}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (isValidNumericParam(assessmentInviteId)) {
      fetchAssessmentInvite();
    } else {
      navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [assessmentInviteId]);

  return { assessmentInvite, isLoading };
}

import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Affix, Button, Modal, Tabs, Tooltip } from "antd";

import { useGetAllCandidateAnswersHook } from "@/modules/assessments/hooks/use-get-candidate-answers.hook";
import { useAssessmentNormsHook } from "@/modules/assessments/scenes/AddTests/hooks/useTestNorms.hook";
import { AssessmentService } from "@/modules/assessments/services/assessment.service";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";
import TableAnalysisScales from "@/modules/tables/TableAnalysisScales";

import { JobMatch } from "@/shared/components/assessment/report/JobMatch/JobMatch";
import { computeAssessmentScore } from "@/shared/components/assessment/service/computeAssessment.service";
import { TestAnalysisBlock } from "@/shared/components/assessment/report/TestAnalysisBlock/TestAnalysisBlock";
import {
  IAnsweredCompetency,
  IAnsweredScoreOverall,
  IAnsweredScoreScales,
} from "@/shared/components/charts/types/chartTypes";
import {
  computeAllTestScores,
  IComputeTestRequestData,
  ITestScoreResponse,
} from "@/shared/components/assessment/service/computeTest.service";
import { Loading } from "@/shared/components/Loading/Loading";

import { AnalysisContext, TTestAnalysisAnswers } from "@/shared/contexts/analysis.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { ReactComponent as DribbleSVG } from "@/assets/icons/feather-icons/dribbble.svg";

import style from "./style.module.css";

const { TabPane } = Tabs;

export interface ITestAnswerScore {
  testId: number;
  competencies: IAnsweredCompetency;
  overall: IAnsweredScoreOverall;
  scales: IAnsweredScoreScales;
}

interface IAnalysisBlock {
  assessmentInvite?: IAssessmentInvite;
  container: HTMLElement | null;
}

export const getTestById = (tests: IAssessmentTest[] | undefined, id: number): IAssessmentTest | undefined => {
  return tests?.find((test) => test.testId === id);
};
const arrow = "\u2794";

export function AnalysisBlock({ assessmentInvite, container }: IAnalysisBlock) {
  const { assessment } = useContext(AssessmentContext);
  const { tests, testIds } = useMemo(() => {
    if (!assessment?.assessmentTests) return {};
    const tests = assessment.assessmentTests.sort((a, b) => a.rank - b.rank);
    return {
      tests,
      testIds: tests.map(({ testId }) => testId),
    };
  }, [assessment]);

  const { answers, isLoading: answersLoading } = useGetAllCandidateAnswersHook(
    Number(assessmentInvite?.inviteId),
    testIds,
  );
  const { norms, isLoading: normsLoading } = useAssessmentNormsHook(testIds);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [initialTestAnswers, setInitialTestAnswers] = useState<TTestAnalysisAnswers[]>([]);
  const [updatedTestsAnswers, setUpdatedTestsAnswers] = useState<TTestAnalysisAnswers[]>([]);
  const [testResults, setTestResults] = useState<ITestScoreResponse[]>([]);

  const [selectedTestId, setSelectedTestId] = useState<number>();
  const [hasChangedElements, setHasChangedElements] = useState<boolean>(false);

  const [overallNetScore, setOverallNetScore] = useState<number | undefined>(assessmentInvite?.netScore);

  const [showModal, setShowModal] = useState<boolean>(false);

  const setInitialTestResults = useCallback(() => {
    const initialTestResults: ITestScoreResponse[] = answers.map(
      ({
        answer: {
          score: { competencies, scales, overall },
        },
        testId,
      }) => {
        let normId: number | undefined = undefined;
        if (tests) {
          const currentTest = tests.filter((test) => test.testId === testId)[0];
          if (currentTest) {
            normId = currentTest.normId;
          }
        }

        return {
          testId,
          normId,
          competenciesResult: competencies,
          overallScore: overall,
          scalesResult: scales,
        };
      },
    );
    setTestResults(initialTestResults);
  }, [answers, tests]);

  const handleResetClick = () => {
    setUpdatedTestsAnswers(initialTestAnswers);
    setOverallNetScore(assessmentInvite?.netScore);
    setHasChangedElements(false);
    setInitialTestResults();
  };

  const handleRecalculateClick = async () => {
    const requestData: IComputeTestRequestData[] = updatedTestsAnswers.map(({ answers, testId, normId }) => {
      return {
        testId,
        payload: {
          answers,
          normId,
          config: getTestById(assessment?.assessmentTests, testId)?.config,
        },
      };
    });
    try {
      setLoading(true);

      const testScores: ITestScoreResponse[] | undefined = await computeAllTestScores(requestData);
      if (testScores) {
        setTestResults(testScores);
        const updatedTestScores: Omit<ITestAnswerScore, "testId">[] = testScores.map((score) => {
          return {
            scales: score.scalesResult,
            competencies: score.competenciesResult,
            overall: score.overallScore,
          };
        });
        const assessmentScore = await computeAssessmentScore({
          knockoutFormula: assessment?.knockoutFormula || "",
          testAnswers: updatedTestScores,
        });
        setOverallNetScore(assessmentScore.netScore);
      }
    } catch (e: any) {
      console.error(`Recalculation error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assessment && answers.length > 0) {
      const castedAnswers: TTestAnalysisAnswers[] = answers.map(({ answer, testId }) => {
        return {
          testId,
          times: answer.times,
          normId: Number(getTestById(assessment.assessmentTests, testId)?.normId),
          answers: answer.answer.map((ans) => {
            return {
              elementId: ans.elementId,
              choiceKey: ans.answerKey,
            };
          }),
        };
      });

      setInitialTestAnswers(castedAnswers);
      setUpdatedTestsAnswers(castedAnswers);
      setInitialTestResults();
    }
  }, [assessment, answers, setInitialTestResults]);

  useEffect(() => {
    if (tests && tests.length !== 0) {
      setSelectedTestId(tests[0].testId);
    }
  }, [tests]);

  if (answersLoading || normsLoading) {
    return <Loading />;
  }

  return (
    <AnalysisContext.Provider
      value={{
        initialTestAnswers,
        updatedTestsAnswers,
        testResults,
        norms,
        setUpdatedTestsAnswers,
        setHasChangedElements,
      }}
    >
      <Affix target={() => container}>
        <div className={style.analysisHeaderContainer}>
          <div className={style.analysisHeader}>
            {typeof overallNetScore !== "undefined" &&
              typeof assessmentInvite?.netScore !== "undefined" &&
              overallNetScore !== null &&
              assessmentInvite.netScore !== null && (
                <JobMatch
                  matchStyle={AssessmentService.getOverallScoreLabel(overallNetScore)}
                  matchLabel={
                    AssessmentService.getOverallScoreLabel(overallNetScore) !==
                    AssessmentService.getOverallScoreLabel(assessmentInvite?.netScore)
                      ? `${AssessmentService.getOverallScoreLabel(
                          assessmentInvite?.netScore,
                        )} ${arrow} ${AssessmentService.getOverallScoreLabel(overallNetScore)}`
                      : AssessmentService.getOverallScoreLabel(assessmentInvite?.netScore)
                  }
                />
              )}
            {hasChangedElements && (
              <div className={style.buttonsContainer}>
                <Button size="large" type="primary" onClick={handleRecalculateClick}>
                  <p className={style.buttonTitle}>recalculate</p>
                </Button>
                <CustomButton
                  title="reset to default"
                  buttonSize={ButtonSize.small}
                  colorType={ButtonColorType.transparentDark}
                  onClick={handleResetClick}
                />
              </div>
            )}
          </div>
          <Button size="large" type="primary" onClick={() => setShowModal(true)}>
            <p className={style.buttonTitle}>scale details</p>
          </Button>
        </div>
      </Affix>
      <div className={style.analysisContainer}>
        <Tabs activeKey={String(selectedTestId)} onChange={(key) => setSelectedTestId(Number(key))}>
          {tests &&
            tests.map((test) => (
              <TabPane
                tab={
                  <span className={style.tabTitle}>
                    <p>{test.test.name}</p>
                    {test.test.includeInOverallScore && (
                      <Tooltip overlay={"Change answers and recalculate to see Overall Job Match difference"}>
                        <DribbleSVG />
                      </Tooltip>
                    )}
                  </span>
                }
                key={test.testId}
              >
                <TestAnalysisBlock testId={test.testId} />
              </TabPane>
            ))}
        </Tabs>
      </div>
      {isLoading && (
        <div className={style.loadingContainer}>
          <Loading />
        </div>
      )}

      <Modal
        title="Scale Details"
        className={style.scaleModal}
        visible={showModal}
        width="60%"
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        footer={[]}
      >
        <TableAnalysisScales tests={tests || []} loading={normsLoading} />
      </Modal>
    </AnalysisContext.Provider>
  );
}

import * as React from "react";
import { Tag } from "antd";

import { splitPattern } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { getChartColor } from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";

import style from "./style.module.css";

export default function TagsContainer({
  selectedUsers,
  setSelectedUsers,
  onChangeSelected,
}: {
  selectedUsers: string[];
  setSelectedUsers: (value: string[]) => void;
  onChangeSelected: (values: string[]) => void;
}) {
  const handleCloseTag = (removedTag: string) => {
    const newTags = selectedUsers.filter((tag) => tag !== removedTag);
    setSelectedUsers(newTags);
    onChangeSelected(newTags);
  };
  return (
    <div className={style.tagsContainer}>
      {selectedUsers.length !== 0 &&
        selectedUsers.map((selectedUser, index) => {
          const tagColor = getChartColor(index);
          const tagName = selectedUser.split(splitPattern).at(0);
          return (
            <Tag
              key={selectedUser}
              color={tagColor}
              closable
              className={style.tag}
              onClose={(e) => {
                e.preventDefault();
                handleCloseTag(selectedUser);
              }}
            >
              <span>{tagName}</span>
            </Tag>
          );
        })}
    </div>
  );
}

import { ReactComponent as Arrow } from "@/assets/icons/feather-icons/arrow-down.svg";

import style from "./style.module.css";

export default function StaticArrow() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <p>More Below</p>
        <Arrow aria-hidden="true"/>
      </div>
    </div>
  );
}

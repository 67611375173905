import { createContext } from "react";

import { ITestElement } from "@/modules/assessments/types/test.interface";
import { SectionErrorState } from "@/modules/candidate/assessment/CandidateAssessment/hooks/useSections.hook";

export interface ISectionContext {
  timeLeft: number | null;
  isTimedOutSection: boolean;
  errorState: SectionErrorState;
  currentSectionElement?: ITestElement;
}

export const SectionContext = createContext<ISectionContext>({
  timeLeft: null,
  isTimedOutSection: false,
  errorState: { genericTimerError: false, concurrentTimerError: false },
});

import * as React from "react";
import { Table } from "antd";

import { Loading } from "@/shared/components/Loading/Loading";

import style from "@/modules/tables/style.module.css";

import { useGetNormScalesHook } from "../../hooks/use-get-norm-scales.hook";

import { scaleColumns } from "./norm-table-columns";

export function NormScales({ normId }: { normId: number }) {
  const { normScales, isLoading } = useGetNormScalesHook(normId);

  if (isLoading) {
    return <Loading />;
  }

  const percentileColumns = normScales
    .map(({ percentileDistribution }) => {
      if (!percentileDistribution) {
        return [];
      }

      return Object.keys(percentileDistribution).map((percent) => ({
        key: String(percent),
        title: `${percent}%`,
        dataIndex: percent,
        width: "4%",
        className: style.tableColumn,
      }));
    })
    .reduce((acc, percentileColumn) => {
      percentileColumn.map((percentileDistribution) => {
        if (!acc.find((rec) => percentileDistribution.key === rec.key)) {
          acc.push(percentileDistribution);
        }
      });

      return acc;
    }, scaleColumns);

  const percentileData = normScales.map(({ percentileDistribution, ...normScale }) => {
    if (!percentileDistribution) {
      return normScale;
    }

    return {
      ...normScale,
      ...Object.keys(percentileDistribution).reduce((acc, percent) => {
        return { ...acc, [percent]: percentileDistribution[percent] };
      }, {}),
    };
  });

  return (
    <Table
      rowKey="normScaleId"
      columns={percentileColumns}
      dataSource={percentileData}
      pagination={false}
      scroll={{ x: "1550px" }}
      style={{ width: "100%" }}
    />
  );
}

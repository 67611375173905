import { useEffect, useState } from "react";
import { IUserNotificationSetting } from "../types/user-notification-settings.interface";
import { UserNotificationSettingsHttpService } from "../services.ts/user-notification-settings.service";
import { getFriendlyErrorMessage } from "@/shared/services/http";

export function useGetAllUserNotificationSettings() {
  const [currentUserSettings, setCurrentUserSettings] = useState<IUserNotificationSetting[]>([]);
  const [isLoadingNotificationSettings, setIsLoadingNotificationSettings] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchUserNotificationSettings = async () => {
      try {
        setIsLoadingNotificationSettings(true);

        const { data: userNotificationSettings }: { data: IUserNotificationSetting[] } =
          await UserNotificationSettingsHttpService.getCurrentUserNotificationSettings();

        setCurrentUserSettings(userNotificationSettings);
      } catch (error) {
        setError(getFriendlyErrorMessage(error) ?? "An error occurred while loading user notification settings");
      } finally {
        setIsLoadingNotificationSettings(false);
      }
    };

    fetchUserNotificationSettings();
  }, []);

  return { currentUserSettings, error, isLoadingNotificationSettings };
}

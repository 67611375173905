import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Loading } from "@/shared/components/Loading/Loading";
import WelcomeElement from "@/shared/components/candidate/scenes/WelcomeElement/WelcomeElement";
import useAllTextLocations from "@/shared/components/assessment/AboutAssessment/hooks/useAllTextLocations";
import { UserContext } from "@/shared/contexts/user.context";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import {
  ASSESSMENT_ID_SEARCH,
  ASSESSMENT_ROUTE,
  CANDIDATE_ROUTE,
  LOGIN_ROUTE,
  SELF_REGISTER_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import { getTestsProps } from "../../utils/candidateAssessment.utils";
import CandidateAssessmentDesktop from "./CandidateAssessmentDesktop";
import CandidateAssessmentMobile from "./CandidateAssessmentMobile";
import { getRouteWithSearchParameter } from "@/shared/utils/route/route.utils";
import { CandidateAssessmentViewTypes } from "@/modules/candidate/assessment/CandidateAssessment/CandidateAssessment";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { useClientAssessmentHook } from "../../hooks/useClientAssessment.hook";

export default function CandidateAssessmentSelfRegister() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { assessmentId } = useParams();
  const isAuthenticated: boolean = Boolean(user && user.userId);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${ASSESSMENT_ROUTE}/${assessmentId}/${SELF_REGISTER_ROUTE}`);
    }
  }, []);

  // Load assessment data for the candidate by passing in a truthy value for the code. This hook
  // is used on other Candidate views, as opposed to useAssessmentHook, which has additional admin-related
  // permission checks. Eventually, this hook should a) be renamed and b) conditionally accept codes.
  const { assessment, isLoading } = useClientAssessmentHook("self_register", Number(assessmentId));
  const { client, isLoading: isLoadingClient } = useClientByIdHook(Number(assessment?.clientId));

  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;
  const { textLocations, isLoading: textLocationsLoading } = useAllTextLocations();
  const { imageUrl, title, testsQuantity, questionsQuantity, leftTime, introText } = getTestsProps(
    assessment,
    textLocations,
  );
  const buttonTitle = "register to start";
  const viewType = CandidateAssessmentViewTypes.start;

  const handleSubmitCLick = () => {
    return navigate(
      getRouteWithSearchParameter(`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`, ASSESSMENT_ID_SEARCH, String(assessmentId)),
    );
  };

  if (isLoading || textLocationsLoading || isLoadingClient) {
    return <Loading />;
  }

  return (
    <div>
      {isDesktopView && (
        <CandidateAssessmentDesktop
          assessmentImageUrl={imageUrl}
          viewType={viewType}
          clientLogo={client?.logoUrl}
          clientName={client?.name}
        >
          <WelcomeElement
            type={viewType}
            title={title}
            description={introText}
            testsQuantity={testsQuantity}
            questionsQuantity={questionsQuantity}
            leftTime={leftTime}
          >
            {isDesktopView ? <CustomButton title={buttonTitle} onClick={handleSubmitCLick} /> : null}
          </WelcomeElement>
        </CandidateAssessmentDesktop>
      )}

      {!isDesktopView && (
        <CandidateAssessmentMobile
          clientLogo={client?.logoUrl}
          clientName={client?.name}
          assessmentImageUrl={imageUrl}
          viewType={viewType}
          childrenSubmitButton={<CustomButton title={buttonTitle} onClick={handleSubmitCLick} fullWidth={true} />}
        >
          <WelcomeElement
            type={viewType}
            title={title}
            description={introText}
            testsQuantity={testsQuantity}
            questionsQuantity={questionsQuantity}
            leftTime={leftTime}
          />
        </CandidateAssessmentMobile>
      )}
    </div>
  );
}

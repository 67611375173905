export interface IEditorAutocompleteWords {
  name: string;
  value: string;
  caption: string;
  meta: string;
  score: number;
}

export const editorCustomSnippetsList: IEditorAutocompleteWords[] = [
  {
    name: "function MEAN",
    value: "MEAN",
    caption: "MEAN",
    meta: "functions",
    score: 1000,
  },
  {
    name: "function PTR5",
    value: "PTR5",
    caption: "PTR5",
    meta: "functions",
    score: 1000,
  },
  {
    name: "function PTR3",
    value: "PTR3",
    caption: "PTR3",
    meta: "functions",
    score: 1000,
  },
];

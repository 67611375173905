import { ISectionConfig } from "@/modules/assessments/types/test.interface";

export function sectionsTimeSum(startsSections?: ISectionConfig[]): number {
  if (!startsSections) {
    return 0;
  }

  return startsSections.reduce((acc, startsSection) => {
    if (startsSection.timeLimit) {
      acc += startsSection.timeLimit;
    }
    return acc;
  }, 0);
}

export function sectionsTimeString(startsSections?: ISectionConfig[]) {
  if (!startsSections) {
    return "";
  }

  return startsSections.reduce((acc, startsSection) => {
    const timeLimit = startsSection.timeLimit ?? 0;

    if (!acc.length) {
      acc = timeLimit === 0 ? String(0) : `${timeLimit}m`;
    } else {
      acc += timeLimit === 0 ? ` + ${timeLimit}` : ` + ${timeLimit}m`;
    }

    return acc;
  }, "");
}

import "firebase/compat/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Firebase } from "@/shared/services/firebase";
import { LoginPageComponent } from "@/shared/components/auth/login-page/login-page.component";
import { SIGN_IN_SUCCESS_URL_ADMIN } from "@/shared/services/firebase/firebase.constants";

export function AdminSignIn({ isLoading, isSignedIn }: { isLoading: boolean; isSignedIn: boolean }) {
  const location = useLocation();
  const [wasSignedIn] = useState(isSignedIn);

  const firebase = new Firebase();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn && !isLoading) {
      const state = (location.state ?? {}) as { redirectPath?: string };
      // Only navigate to the saved redirect path if the user was logged out and when they attempted to access the page.
      // Unauthorized requests to paths for signed in users will redirect them here, which will redirect them to the appropriate landing page (instead of making them login again). E.g., if a client admin tries to access /admin
      const redirectPath = state.redirectPath && !wasSignedIn ? state.redirectPath : SIGN_IN_SUCCESS_URL_ADMIN;

      navigate(redirectPath);
    }
  }, [isSignedIn, isLoading, navigate, location, wasSignedIn]);

  // Don't use is conditional statements with the LoginPageComponent
  return <LoginPageComponent firebase={firebase} authTitle="Authorization" pageTitle="Login" />;
}

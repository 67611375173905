import cn from "classnames";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { IChoice, IQuestion, LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import { markdownStyles } from "@/ui-components/testElementComponents/constants/markdownStyles";
import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";

import { AnswerElementComponent } from "./components/AnswerElementComponent";

import style from "./style.module.css";

export enum TestElementMode {
  Candidate,
  Admin,
}

export enum QuestionFontSize {
  large,
  small,
  xsmall,
}

// TODO: Rename to TestElementTypes
/**
 * @description
 * SD - https://en.wikipedia.org/wiki/Semantic_differential
 * LikelyTrueFalse - [T (true), LT (likely true), LI (lack of information), LF (likely false), F (false)]
 * Text - with body param
 * Likert5 - https://en.wikipedia.org/wiki/Likert_scale
 * */
export enum QuestionTypes {
  // Question Types
  TrueFalse = "true_false",
  FollowNotFollow = "follow_not_follow",
  CorrectIncorrect = "correct_incorrect",
  MadeNotMade = "made_not_made",
  StrongWeak = "strong_weak",
  LikelyTrueFalse = "likely_true_false",
  SD = "semantic_differential",
  Radio = "radio",
  RadioHorizontal = "radio_h",
  RadioImage = "radio_i",
  Likert5 = "likert_5",
  TwoWayMatch = "two_way_match",

  // Content Types
  Text = "text",
}

export interface ITestChoice {
  elementId: number;
  choiceKey?: string;
}

export type ITestElementView = {
  id: number;
  currentAnswer?: string;
  mode?: TestElementMode;
  disableChoices?: boolean;
  questionType: QuestionTypes;
  question: IQuestion;
  choices: IChoice[];
  questionFontSize: QuestionFontSize;
  questionNumber?: number,
  onChoiceSelect: (value: ITestChoice) => void;

  required?: boolean;
};

// TODO: move props to the context
export default function TestElement({
  id,
  choices,
  question,
  questionType,
  questionNumber,
  currentAnswer,
  onChoiceSelect,
  questionFontSize,
  required = false,
  disableChoices = false,
  mode = TestElementMode.Candidate,
}: ITestElementView) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  const questionTest = required
    ? `${String(question[LanguagesIsoEnum.enUS])} *`
    : String(question[LanguagesIsoEnum.enUS]);

  return (
    <div>
      {(questionType === QuestionTypes.Text || questionType === QuestionTypes.RadioImage) && (
        <MarkdownPreviewCustom
          source={questionTest}
          className={cn(style.questionMarkdownWrapper, {
            [style.questionAlign]: questionType === QuestionTypes.RadioImage,
            [style.questionTextWrapper]: questionType === QuestionTypes.Text,
          })}
        />
      )}
      {(questionType === QuestionTypes.TrueFalse ||
        questionType === QuestionTypes.Radio ||
        questionType === QuestionTypes.Likert5 ||
        questionType === QuestionTypes.RadioHorizontal ||
        questionType === QuestionTypes.FollowNotFollow ||
        questionType === QuestionTypes.CorrectIncorrect ||
        questionType === QuestionTypes.MadeNotMade ||
        questionType === QuestionTypes.StrongWeak ||
        questionType === QuestionTypes.LikelyTrueFalse) && (
        <div className={style.questionContainer}>
          <p
            className={cn(style.question, {
              [style.desktopQuestion]: isDesktopView,
              [style.desktopQuestionSmall]: isDesktopView && questionFontSize === QuestionFontSize.small,
              [style.questionSmall]: questionFontSize === QuestionFontSize.small,
              [style.analysisDesktop]: mode === TestElementMode.Admin,
            })}
          >
            <MarkdownPreviewCustom
              source={questionTest}
              className={cn(style.questionMarkdownWrapper)}
              style={
                mode === TestElementMode.Admin
                  ? markdownStyles.analysis
                  : isDesktopView
                  ? markdownStyles.desktop
                  : markdownStyles.mobile
              }
            />
          </p>
        </div>
      )}

      <div className={style.answerChoicesContainer}>
        <AnswerElementComponent
          id={id}
          mode={mode}
          disableChoices={disableChoices}
          choices={choices}
          question={question}
          questionType={questionType}
          questionNumber={questionNumber}
          currentAnswer={currentAnswer}
          onChoiceSelect={onChoiceSelect}
        />
      </div>
    </div>
  );
}

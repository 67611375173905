import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";

import { useAllCompetenciesQuestionsHook } from "@/modules/admin/testManagement/hooks/useAllCompetencies.hook";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";

import { SubmitStatuses } from "@/shared/components/testManagement/TestElementInfoBlock";
import { AssessmentCompetencyBlock } from "@/shared/components/assessment/AssessmentCompetencyBlock/AssessmentCompetencyBlock";
import { ICompetency } from "@/shared/components/competencies/types";
import { Loading } from "@/shared/components/Loading/Loading";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { AceSupportedLanguages, CodeEditor } from "@/ui-components/commonComponents/CodeEditor/CodeEditor";

import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";

import style from "./style.module.css";

export interface ICompetencyView {
  testName: string;
  rank: number;
  testId: number;
  initialCompetencies: { [competency: string]: boolean | number };
  disabled?: boolean;
  competenciesName?: [string, string];
  competencies?: ICompetency[];
}

const getAllCompetencies = (assessment?: IAssessment): ICompetencyView[] => {
  if (!assessment || !assessment.assessmentTests) {
    return [];
  }

  return assessment.assessmentTests.map((test) => {
    return {
      testName: test.test.name,
      rank: test.rank,
      testId: test.testId,
      initialCompetencies: test.config.competencies,
      competenciesName: test.test.config.competenciesName,
    };
  });
};

export function AssessmentCompetencies({ disabled }: { disabled: boolean }) {
  const { client_id: clientId, assessment_id: assessmentId } = useParams();

  const { assessment } = useContext(AssessmentContext);
  const testIds: number[] | undefined = assessment?.assessmentTests?.map(({ testId }) => testId);

  const { competencies, isLoading: competenciesLoading } = useAllCompetenciesQuestionsHook(testIds);

  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);

  const [enteredFormula, setEnteredFormula] = useState<string>(assessment?.knockoutFormula || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const allAssessmentCompetencies: ICompetencyView[] = getAllCompetencies(assessment).sort((a, b) => a.rank - b.rank);

  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert message={"Something went wrong. Please, retry later."} type={"error"} showIcon />;
      case SubmitStatuses.success:
        return <Alert message={"Success"} type={"success"} showIcon />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };

  const handleEditorChange = (e: string) => {
    setEnteredFormula(e);
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      if (assessment) {
        await AssessmentHttpService.updateAssessment(Number(clientId), Number(assessmentId), {
          name: assessment.name,
          description: assessment.description,
          roleId: assessment.jobRoleId,
          clientJobTitle: assessment.clientJobTitle,
          knockoutFormula: enteredFormula,
          titleImageUrl: assessment.titleImageUrl,
        });
      }
      setAlert(SubmitStatuses.success);
    } catch (e: any) {
      console.error(`knockout patch formula ${e}`);
      setAlert(SubmitStatuses.error);
    } finally {
      setIsLoading(false);
    }
  };

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);

  if (competenciesLoading) {
    return <Loading />;
  }

  const allCompetencies = competencies.flat();

  return (
    <div className="container">
      {allAssessmentCompetencies.map(({ testName, rank, testId, initialCompetencies, competenciesName }) => (
        <AssessmentCompetencyBlock
          disabled={disabled}
          key={testId}
          rank={rank}
          testName={testName}
          testId={testId}
          initialCompetencies={initialCompetencies}
          competenciesName={competenciesName}
          competencies={allCompetencies}
        />
      ))}
      <div className="tabs-inner-container-desktop">
        <div className={style.editor}>
          <Banner title={"Knockout Formula"}>
            <div className={style.descriptionBlock}>
              <p>
                A knockout formula makes sure that if a candidate failed a knockout skill or competency, they are
                disqualified even if they generally scored favorably otherwise.
              </p>
              <p>
                An example formula that makes sure that a candidate scored 3 or better in <b>Sociability</b> would look
                like{" "}
                <b>
                  <i>{`return this.S >= 3;`}</i>
                </b>
              </p>
            </div>
          </Banner>
          <CodeEditor
            editorMode={AceSupportedLanguages.JS}
            uniqueName={`competency_in_assessment_${assessment?.assessmentId}`}
            defaultContent={enteredFormula}
            onEditorChange={handleEditorChange}
          />
          <div className={style.buttonContainer}>
            <CustomButton
              disabled={disabled || !canManageAssessment}
              title={"Save"}
              onClick={handleSaveClick}
              isLoading={isLoading}
              buttonSize={ButtonSize.small}
            >
              <SaveSVG className={style.svgSize} aria-hidden="true" />
            </CustomButton>
            <div className={style.alertContainer}>{getAlert(alert)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";
import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { httpClient } from "@/shared/services/http";

export class ClientHttpService {
  static async getAllClients() {
    const { data: clients } = await httpClient.get(`/api/clients`);

    return clients;
  }

  static async getClientById(id: number) {
    const { data: client } = await httpClient.get(`/api/clients/${id}`);

    return client;
  }

  static async getClientCompetenciesById(clientId: number) {
    const { data } = await httpClient.get(`/api/clients/${clientId}/custom-competencies`);

    return data;
  }

  static async updateClientCompetenciesById(
    clientId: number,
    customCompetencies: Record<string, Record<string, string>>,
  ) {
    const { data } = await httpClient.put(`/api/clients/${clientId}/custom-competencies`, { customCompetencies });

    return data;
  }

  static async addClientByName(data: { name: string }) {
    return httpClient.post(`/api/clients`, data);
  }

  static async updateClientById(data: { name: string; logoUrl: string }, clientId: number) {
    return httpClient.patch(`api/clients/${clientId}`, data);
  }

  static async addUserToClient(
    clientId: string,
    data: {
      email: string;
      fullName: string;
      clientRole?: ClientRoleEnum;
      assessmentId?: number;
      assessmentRole?: AssessmentRoleEnum;
    },
  ) {
    return httpClient.post(`/api/clients/${clientId}/invites`, data);
  }
  static async updateClientUserRole(
    clientId: number,
    userId: number,
    payload: {
      roleId: number;
    },
  ) {
    return httpClient.patch(`/api/clients/${clientId}/users/${userId}`, payload);
  }

  static updateClientTexts(clientId: number, clientTexts: Record<string, Record<string, string>>) {
    return httpClient.patch(`/api/clients/${clientId}/client-texts`, { clientTexts });
  }
}

import * as React from "react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetCompareAnswersHook } from "@/modules/assessments/hooks/use-get-compare-answers.hook";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";

import { Loading } from "@/shared/components/Loading/Loading";
import { ReportComponent, ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import ReportCompareHeader from "@/shared/components/assessment/report/ReportCompareHeader/ReportCompareHeader";

import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { ReportCompareContext } from "@/shared/contexts/reportCompare.context";

export default function AssessmentCompare() {
  const { client_id } = useParams();

  const { assessment } = useContext(AssessmentContext);
  const { client } = useClientByIdHook(Number(client_id));
  const { fetchAnswers, usersAnswers, isLoading: answersLoading } = useGetCompareAnswersHook();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isHiddenSameScore, setIsHiddenSameScore] = useState<boolean>(false);

  if (!client || !assessment) {
    return <Loading />;
  }

  return (
    <ReportCompareContext.Provider value={{ isHiddenSameScore, setIsHiddenSameScore, fetchAnswers, answersLoading }}>
      <ReportComponent
        isPublic
        client={client}
        assessment={assessment}
        selectedUsers={selectedUsers}
        scene={ReportScene.compare}
        usersAnswers={usersAnswers}
        userAnswersLoading={answersLoading}
        compareComponent={
          <ReportCompareHeader
            selectedUsers={selectedUsers}
            showHideSameScore={true}
            setSelectedUsers={setSelectedUsers}
          />
        }
      />
    </ReportCompareContext.Provider>
  );
}

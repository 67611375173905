import { useEffect, useState } from "react";

import { ITest } from "@/modules/assessments/types/test.interface";
import { TestHttpService } from "@/shared/services/tests/test-http.service";

export function useGetAllTestsHook() {
  const [tests, setTests] = useState<ITest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        setIsLoading(true);

        const tests: ITest[] = await TestHttpService.getAllTestsAsAdmin();
        if (tests.length !== 0) {
          setTests(tests);
        }
      } catch (e: any) {
        console.error(`Get All Admin tests error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTests();
  }, []);

  return { tests, isLoading };
}

import React from "react";
import { Col, Row } from "antd";

import { StatementsMap } from "@/modules/assessments/services/scales.service";
import { IAssessment } from "@/modules/assessments/assessments.types";
import { ChartTypes } from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";
import { Overview } from "@/modules/assessments/scenes/AssessmentReport/Overview/Overview";
import Suggestions from "@/modules/assessments/scenes/AssessmentReport/Suggestions/Suggestions";
import { ReportScene } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import CategorizedCharts from "@/shared/components/charts/CategorizedCharts/CategorizedCharts";
import { IMultiChart, ITestAnswer } from "@/shared/components/charts/types/chartTypes";

import style from "./style.module.css";

interface IOverallComponent {
  answers: ITestAnswer[];
  statements: StatementsMap;
  showRadar?: boolean;
  chartData: IMultiChart[];
  assessment: Pick<IAssessment, 'assessmentTests'>;
}

export default function Overall({
  answers,
  statements,
  showRadar = true,
  chartData,
  assessment,
}: IOverallComponent) {
  return (
    <Row className={style.overallContainer}>
      <Col span={16}>
        <div className={style.overallTextBlock}>
          <Overview assessmentTests={assessment.assessmentTests} statements={statements} />
          <Suggestions answers={answers} assessmentTests={assessment.assessmentTests} />
        </div>
      </Col>
      <Col span={8}>
        <div className={style.chartsContainer}>
          {showRadar && (
            <CategorizedCharts
              chartMultiData={chartData}
              chartType={ChartTypes.radar}
              scene={ReportScene.single}
              radarStyles={{ radarHeight: 400, tickLabelLimit: 12 }}
            />
          )}
          <CategorizedCharts
            chartMultiData={chartData}
            chartType={ChartTypes.bar}
            scene={ReportScene.single}
            barStyles={{ yAxisWidth: 150 }}
          />
        </div>
      </Col>
    </Row>
  );
}

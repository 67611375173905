import { useEffect, useState } from "react";

import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { ITextLocation } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";

// TODO: Use redux to make request less frequently
export default function useAllTextLocations() {
  const [textLocations, setTextLocations] = useState<ITextLocation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTextLocations = async () => {
      try {
        setIsLoading(true);
        setTextLocations(await AssessmentHttpService.getAllTextLocations());
      } catch (e) {
        console.error(`get all text locations error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTextLocations().catch(console.error);
  }, []);
  return { textLocations, isLoading };
}

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CompetencyHttpService } from "@/modules/assessments/scenes/AddTests/services/competency-http.service";

import { ICompetency } from "@/shared/components/competencies/types";
import { addCompetencies } from "@/shared/services/store/competenciesStore";
import { useGetReduxTestsCompetenciesHook } from "@/shared/services/store/hooks/useGetReduxTestsCompetencies.hook";
import { getUniqueElements } from "@/shared/utils/array/array.utils";

export type QuestionsMap = Record<string, string>;
export type ExplanationsMap = Record<string, string>;
export interface ICompetencyQuestions {
  questions: QuestionsMap;
  explanations: ExplanationsMap;
  testId: number;
}

const groupCompetenciesByTestId = (
  competencies: ICompetency[],
): { uniqueTestIds: number[]; groupedCompetencies: ICompetency[][] } => {
  const uniqueTestIds = getUniqueElements(competencies.map((competency) => competency.testId));
  const groupedCompetencies = uniqueTestIds.map((testId) =>
    competencies.filter((competency) => competency.testId === testId),
  );
  return { uniqueTestIds, groupedCompetencies };
};

const getCompetencyQuestions = (competencies: ICompetency[][]) => {
  return competencies.map((competencies: ICompetency[]) => {
    const questionsMap: QuestionsMap = {};
    const explanationMap: ExplanationsMap = {};

    competencies.forEach(({ key, interviewQuestions, explanation }: ICompetency) => {
      if (!interviewQuestions || !explanation) return;

      questionsMap[key] = interviewQuestions;
      explanationMap[key] = explanation;
    });

    return { testId: competencies[0].testId, questions: questionsMap, explanations: explanationMap };
  });
};

export function useAllCompetenciesQuestionsHook(testIds?: number[]) {
  const dispatch = useDispatch();
  const reduxCompetencies = useGetReduxTestsCompetenciesHook(testIds);
  const [competencies, setCompetencies] = useState<ICompetency[][]>([]);
  const [competenciesQuestions, setCompetenciesQuestions] = useState<ICompetencyQuestions[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Create a stable reference so that the effect will run when the tests change, but not each time the hook is rendered with a new array of the same values.
  const serializedTestIds = testIds?.join(",");

  useEffect(() => {
    const { uniqueTestIds: uniqueReduxCompetenciesTestIds, groupedCompetencies: reduxCompetenciesGroupedByTestId } =
      groupCompetenciesByTestId(reduxCompetencies);
    const missingTestIds = testIds?.filter((id) => !uniqueReduxCompetenciesTestIds.includes(id));

    const fetchCompetenciesQuestions = async () => {
      if (!missingTestIds) {
        setIsLoading(false);
        return;
      }
      if (!missingTestIds.length) {
        setCompetencies(reduxCompetenciesGroupedByTestId);
        const competencyQuestions = getCompetencyQuestions(reduxCompetenciesGroupedByTestId);
        setCompetenciesQuestions(competencyQuestions);
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const missedCompetencies = await CompetencyHttpService.getAllCompetencies(missingTestIds);
        const { groupedCompetencies: competencies } = groupCompetenciesByTestId(missedCompetencies);

        const allCompetencies = reduxCompetenciesGroupedByTestId.concat(competencies);
        setCompetencies((prevState) => [...prevState, ...allCompetencies]);
        dispatch(addCompetencies(competencies.flat()));

        const competencyQuestions = getCompetencyQuestions(allCompetencies);
        setCompetenciesQuestions((prevState) => [...prevState, ...competencyQuestions]);
      } catch (e: any) {
        console.error(`Get test competencies error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompetenciesQuestions();
  }, [dispatch, serializedTestIds]);

  return { competenciesQuestions, competencies, isLoading };
}

import * as React from "react";
import { useContext } from "react";
import cn from "classnames";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table/Table";

import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { IUserAnswer } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";
import { isValidNumericParam, minToSec, secondsToHms } from "@/shared/utils/common.utils";

import style from "./style.module.css";
import useGetAssessmentTestSectionLogsHook from "@/shared/hooks/tests/use-get-assessment-test-section-logs.hook";

interface IAssessmentSectionsLogs {
  testElementRank: number;
  testId: number;
  key: string;
  sectionName?: string;
  sectionTime: string;
  usedTime: string;
}

const columns: ColumnsType<IAssessmentSectionsLogs> = [
  {
    key: "sectionName",
    title: "Section",
    dataIndex: "sectionName",
    className: style.tableColumn,
  },
  {
    key: "sectionTime",
    title: "Time Limit",
    dataIndex: "sectionTime",
    className: style.tableColumn,
    render: (value) => (value !== "No Limit" ? `${value} m` : value),
  },
  {
    key: "usedTime",
    title: "Used Time",
    dataIndex: "usedTime",
    className: style.tableColumn,
    render: (value, record) => {
      return (
        <span className={cn({ [style.dangerousColor]: value === record.sectionTime })}>
          {value !== "N/A" ? `${value} m` : value}
        </span>
      );
    },
  },
];

export default function TableAuditTestSectionLogs({ answers }: { answers: IUserAnswer[] }) {
  const testSectionTimeLogs = useGetAssessmentTestSectionLogsHook(answers);

  return (
    <>
      {Boolean(testSectionTimeLogs.length) && (
        <div className={cn(style.auditTable, style.lastTable)}>
          <TableHeader tableName="Test Section Logs" hasSearchField={false} />
          <Table
            columns={columns}
            dataSource={testSectionTimeLogs}
            style={{ width: "100%" }}
            pagination={{
              position: ["bottomLeft"],
              showSizeChanger: true,
            }}
          />
        </div>
      )}
    </>
  );
}

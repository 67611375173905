import { createContext } from "react";
import { createContextualCan } from "@casl/react";

import { CaslAbilityFactory } from "@/shared/services/permissions/casl-ability.factory";
import { ClientRoleEnum } from "@/modules/client/client-roles.interface";

export const ClientUserRolesContext = createContext<ClientRoleEnum[]>([]);

export const ClientAbilityContext = createContext(new CaslAbilityFactory().buildForUser());
export const CanClient = createContextualCan(ClientAbilityContext.Consumer);

export const AssessmentAbilityContext = createContext(new CaslAbilityFactory().buildForUser());
export const CanAssessment = createContextualCan(AssessmentAbilityContext.Consumer);

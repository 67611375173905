import { useEffect, useState } from "react";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { ITestElement } from "@/modules/assessments/types/test.interface";
import { findQuestionAnswers, getQuestionElements } from "../utils/candidate-assessment-view.utils";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

function getCurrentAnswers({
  answers,
  questionsList,
  page,
}: {
  answers: ITestAnswer[];
  questionsList: ITestElement[][];
  page: number;
}) {
  const currentPageQuestions = questionsList[page - 1];
  const questionAnswers = findQuestionAnswers(answers, currentPageQuestions);

  return questionAnswers.length
    ? questionAnswers
    : getQuestionElements(currentPageQuestions).map(
        ({
          elementId,
          config: {
            type,
            payload: { choices },
          },
        }) => {
          // For Two-Way Match Questions, the initial state on the questions
          // page should treat unchecked checkboxes not as unanswered questions
          // but as choices explicitly set to 'false'.
          const selectedChoiceKey =
            type === QuestionTypes.TwoWayMatch
              ? { choiceKey: choices?.find((choice) => choice.choice === false)?.key }
              : {};

          return { elementId, ...selectedChoiceKey };
        },
      );
}

export function useCurrentPageHook({
  questionsList,
  answers,
}: {
  questionsList: ITestElement[][];
  answers: ITestAnswer[];
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentAnswers, setCurrentAnswers] = useState<ITestChoice[]>([]); // think of these as unsaved

  useEffect(() => {
    setCurrentAnswers(getCurrentAnswers({ answers, questionsList, page: currentPage }));
  }, [answers, questionsList, currentPage]);

  return { currentPage, setCurrentPage, currentAnswers, setCurrentAnswers };
}

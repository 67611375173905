import { sortNumbers } from "@/shared/utils/array/sort.utils";

import { ITestElement } from "@/modules/assessments/types/test.interface";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";

export function getTestElementSection(questionsList: ITestElement[][], testId?: number, currentPage?: number) {
  if (!currentPage) {
    return;
  }

  const currentPageElements = questionsList[currentPage - 1];

  let section = currentPageElements?.find((element) => element.config.startsSection);

  if (section) {
    return section;
  }

  if (!currentPageElements) {
    return;
  }

  const currentElement = currentPageElements[0];

  const testQuestions = questionsList.filter((elements) => elements[0].testId === testId);
  let sortedTestElements = testQuestions.flat().sort((a, b) => sortNumbers(b.rank, a.rank));

  const currentTestElementIndex = sortedTestElements.findIndex(
    (element) => element.elementId === currentElement.elementId,
  );

  sortedTestElements = sortedTestElements.slice(currentTestElementIndex);

  return sortedTestElements.find((element) => element.config.startsSection);
}

export function getTestTimes(answers: ITestAnswer[], testId?: number) {
  return answers.find((answer) => answer.testId === testId)?.times;
}

export function getNextSectionIndex(questionsList: ITestElement[][], currentPage: number) {
  return questionsList.findIndex((elements, index) =>
    elements.find((element) => element.config.startsSection && index > currentPage - 1),
  );
}

export function getIfNextSectionNewTest(questionsList: ITestElement[][], currentPage: number, testId?: number) {
  const currentSection = getTestElementSection(questionsList, testId, currentPage);

  if (!currentSection) {
    return false;
  }

  return questionsList.slice(currentPage - 1).every((elements) => {
    const nextTestElement = elements.find(
      (element) =>
        (element.config.startsSection && element.elementId !== currentSection.elementId) || element.testId !== testId,
    );

    return !Boolean(nextTestElement?.testId === testId);
  });
}

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, notification, Space, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useClientCompetenciesHook } from "@/modules/client/hooks/useClientCompetencies.hook";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { IClientCompetencies } from "@/modules/client/clients.interface";
import { Loading } from "@/shared/components/Loading/Loading";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { isSubstring } from "@/shared/utils/comparison/comparison";
import { CustomInput } from "@/ui-components/commonComponents/Input/Input";
import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";
import tableStyles from "@/modules/tables/style.module.css";
import style from "@/modules/admin/clientManagement/GeneralClientSettings/style.module.css";

function getFilters(values: Set<string>): { text: string; value: string }[] {
  return Array.from(values)
    .sort((a, b) => a.localeCompare(b))
    .map((name) => ({ text: name, value: name }));
}

const clientCompetenciesColumns: ColumnType<IClientCompetencies>[] = [
  {
    key: "testName",
    title: "Test Name",
    dataIndex: "testName",
    width: "20%",
    sorter: { compare: (a, b) => a.testName.localeCompare(b.testName), multiple: 2 },
    onFilter: (value, record) => record.testName.indexOf(value as string) === 0,
  },
  {
    key: "name",
    title: "Competency/Skill Name",
    dataIndex: "name",
    render: (value: string, record) => {
      return (
        <div>
          <p>{value}</p>
          <p style={{ fontSize: "12px" }}>{record.explanation}</p>
        </div>
      );
    },
    sorter: { compare: (a, b) => a.name.localeCompare(b.name), multiple: 1 },
    onFilter: (value, record) => record.name.indexOf(value as string) === 0,
  },
  {
    key: "customCompetencyName",
    title: "Custom Name",
    dataIndex: "customCompetencyName",
    width: "30%",
    sorter: {
      compare: (a, b) => (a.customCompetencyName ? a.customCompetencyName.localeCompare(b.customCompetencyName) : 1),
      multiple: 3,
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  },
];

export default function CompetenciesClientSettings() {
  const { client_id: clientId } = useParams<{ client_id: string }>();
  const ability = useContext(ClientAbilityContext);
  const canUpdateClientConfig = ability.can(Action.Update, Subject.CLIENT, "config");

  clientCompetenciesColumns[2].render = (value, record) => {
    const MAX_CHAR_COMP = 100;

    return (
      <Form.Item
        className={style.inputTitle}
        key={record.competencyId}
        name={`${record.testId}_${record.key}`}
        rules={[
          {
            max: MAX_CHAR_COMP,
            message: `Competency custom name cannot be longer than ${MAX_CHAR_COMP} characters`,
          },
        ]}
      >
        <CustomInput placeholder="Type Custom Competency..." disabled={!canUpdateClientConfig} />
      </Form.Item>
    );
  };

  const [form] = Form.useForm();

  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [searchCustomCompetencyName, setSearchCustomCompetencyName] = useState<string>("");
  const [filteredCompetencies, setFilteredCompetencies] = useState<IClientCompetencies[] | null>(null);

  // TODO: Move to global state
  const { clientCompetencies, isLoading: isCustomCompetenciesLoading } = useClientCompetenciesHook(Number(clientId));

  const handleUpdate = async () => {
    try {
      setUpdateLoading(true);
      const customCompetenciesMap: Record<string, Record<string, string>> = {};

      Object.entries(form.getFieldsValue(true)).forEach(([key, value]) => {
        if (!value) return;

        const [testId, base64Key] = key.split("_");

        if (!customCompetenciesMap[testId]) {
          customCompetenciesMap[testId] = {};
        }

        customCompetenciesMap[testId][base64Key] = value as string;
      });

      await ClientHttpService.updateClientCompetenciesById(Number(clientId), customCompetenciesMap);

      notification.success({
        message: <p>Competencies successfully updated.</p>,
      });
    } catch (e) {
      console.error(`updateCustomCompetencies failed: ${e}`);
      notification.error({
        message: <p>Competencies updating failed.</p>,
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleSearch = () => {
    const filteredCompetencies: IClientCompetencies[] | null = clientCompetencies.filter((competency) => {
      if (!searchCustomCompetencyName) {
        return competency;
      }
      if (competency.customCompetencyName) {
        if (isSubstring(competency.customCompetencyName, searchCustomCompetencyName)) return competency;
      }
      return null;
    });
    setFilteredCompetencies(filteredCompetencies);
  };

  const resetSearch = () => {
    setFilteredCompetencies(null);
    setSearchCustomCompetencyName("");
  };

  useEffect(() => {
    const existingCustomCompetencies: Record<string, string> = {};
    const testNames: Set<string> = new Set();
    const skillNames: Set<string> = new Set();
    const customSkillNames: Set<string> = new Set();

    clientCompetencies.forEach(({ testId, key, customCompetencyName, testName, name }) => {
      existingCustomCompetencies[`${testId}_${key}`] = customCompetencyName;
      testNames.add(testName);
      skillNames.add(name);
      if (customCompetencyName) {
        customSkillNames.add(customCompetencyName);
      }
    });

    clientCompetenciesColumns[0].filters = getFilters(testNames);
    clientCompetenciesColumns[1].filters = getFilters(skillNames);

    form.setFieldsValue(existingCustomCompetencies);
  }, [clientCompetencies.length]);

  clientCompetenciesColumns[2].filterDropdown = ({ confirm }) => {
    return (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          autoFocus
          placeholder="Search by custom name"
          value={searchCustomCompetencyName}
          onChange={(e) => setSearchCustomCompetencyName(e.target.value)}
          onPressEnter={() => {
            handleSearch();
            confirm();
          }}
          style={{ marginBottom: 8, display: "block" }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch();
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: "#253955", borderColor: "#253955" }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              resetSearch();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };

  if (isCustomCompetenciesLoading) {
    return <Loading />;
  }

  return (
    <div className={tableStyles.tableBlock} style={{ position: "relative" }}>
      <Form form={form} onFinish={handleUpdate} initialValues={{}}>
        <Table
          rowKey={(record) => record.competencyId}
          dataSource={filteredCompetencies || clientCompetencies}
          columns={clientCompetenciesColumns}
          pagination={{ position: ["bottomLeft"] }}
        />

        <div style={{ position: "absolute", bottom: 8, right: 30 }}>
          <CustomButton
            title="Update"
            buttonSize={ButtonSize.small}
            isLoading={isUpdateLoading}
            disabled={!canUpdateClientConfig}
          >
            <SaveSVG aria-hidden="true" />
          </CustomButton>
        </div>
      </Form>
    </div>
  );
}

import React from "react";
import { Col, Row } from "antd";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";

import { AboutAssessment, AssessmentActions } from "@/shared/components/assessment/AboutAssessment/AboutAssessment";

import { CREATE_NEW_ASSESSMENT_TITLE, CREATE_NEW_ASSESSMENT_DESCRIPTION } from "./createAssessment.constants";

const title: string = CREATE_NEW_ASSESSMENT_TITLE;
const description: string = CREATE_NEW_ASSESSMENT_DESCRIPTION;

export function CreateAssessment() {
  return (
    <div className="main-container">
      <div className="inner-container">
        <Row>
          <Col span={20}>
            <Banner title={title} description={description} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AboutAssessment type={AssessmentActions.NEW} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

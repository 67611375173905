import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Collapse, Divider } from "antd";

import {
  IAssessmentTest,
  ISectionConfig,
  ITestElement,
  LanguagesIsoEnum,
} from "@/modules/assessments/types/test.interface";

import { secondsToHms } from "@/shared/utils/common.utils";
import { ITestAnswerTimes } from "@/shared/components/charts/types/chartTypes";
import { getTestById } from "@/shared/components/assessment/report/AnalysisBlock/AnalysisBlock";
import {
  ALL_SCALES_OPTION,
  TestAnalysisFilterBlock,
} from "@/shared/components/assessment/report/TestAnalysisFiltersBlock/TestAnalysisFilterBlock";
import { filterElements, isAnswersDifferent } from "@/shared/components/assessment/report/utils/analysisUtils";
import { isPreviousElementWithBreakAfter } from "@/shared/components/assessment/new/utils/testElements.utils";
import { QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { AnalysisContext, TTestAnalysisAnswers } from "@/shared/contexts/analysis.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";

import { TestElementAnalysisComponent } from "@/shared/components/assessment/report/TestAnalysisBlock/components/TestElementAnalysisComponent";

import style from "./style.module.css";

const { Panel } = Collapse;

const getAnswerByTestId = (testAnswers: TTestAnalysisAnswers[], id: number): TTestAnalysisAnswers | undefined => {
  return testAnswers.find((answer) => answer.testId === id);
};

export enum FilterKeys {
  text = "text",
  scale = "scale",
  isChanged = "isChanged",
  isUnanswered = "isUnanswered",
}

export interface IFilter {
  [key: string]: string | boolean;
}

export interface ITestAnalysisBlock {
  testId: number;
}

export function getDividerContent(elementKey: string, startsSection?: ISectionConfig, times?: ITestAnswerTimes | null) {
  if (!times || !elementKey || !startsSection?.name) {
    return startsSection?.name ?? "";
  }

  return startsSection.timeLimit && times[elementKey] && times[elementKey].time
    ? `${startsSection.name} (${secondsToHms(times[elementKey].time)})`
    : startsSection.name;
}

export function TestAnalysisBlock({ testId }: ITestAnalysisBlock) {
  const { assessment } = useContext(AssessmentContext);
  const { initialTestAnswers, updatedTestsAnswers, setUpdatedTestsAnswers, setHasChangedElements } =
    useContext(AnalysisContext);
  const testAnswer: TTestAnalysisAnswers | undefined = updatedTestsAnswers.find((ans) => ans.testId === testId);
  const initialAnswer: TTestAnalysisAnswers | undefined = initialTestAnswers.find((ans) => ans.testId === testId);
  const test: IAssessmentTest | undefined = getTestById(assessment?.assessmentTests, testId);
  const isScoredTest = test?.test.includeInOverallScore;

  const initialFilter: IFilter = {
    [FilterKeys.text]: "",
    [FilterKeys.scale]: ALL_SCALES_OPTION,
    [FilterKeys.isChanged]: false,
    [FilterKeys.isUnanswered]: false,
  };

  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const [filteredTestElements, setFilteredTestElements] = useState<ITestElement[]>([]);

  const handleChangeScales = (scale: string) => {
    setFilter((prev) => {
      return {
        ...prev,
        [FilterKeys.scale]: scale,
      };
    });
  };
  const handleShowChanged = (state: boolean) => {
    setFilter((prev) => {
      return {
        ...prev,
        [FilterKeys.isChanged]: state,
      };
    });
  };
  const handleShowUnanswered = (state: boolean) => {
    setFilter((prev) => {
      return {
        ...prev,
        [FilterKeys.isUnanswered]: state,
      };
    });
  };

  const handleChangeSearchText = (substring: string) => {
    setFilter((prev) => {
      return {
        ...prev,
        [FilterKeys.text]: substring,
      };
    });
  };

  const handleChangeNorm = async (id: number) => {
    const other = updatedTestsAnswers.filter((ans) => ans.testId !== testId);
    if (testAnswer) {
      const current: TTestAnalysisAnswers = {
        testId,
        normId: id,
        answers: testAnswer.answers,
        times: testAnswer?.times || null,
      };
      if (setUpdatedTestsAnswers) {
        setUpdatedTestsAnswers([...other, current]);
      }
      if (setHasChangedElements) {
        setHasChangedElements(isAnswersDifferent(initialAnswer, current));
      }
    }
  };

  useEffect(() => {
    if (test) {
      if (initialAnswer && testAnswer) {
        setFilteredTestElements(
          filterElements(test.test.testElements, filter, initialAnswer.answers, testAnswer.answers),
        );
      }
    }
  }, [filter]);

  useEffect(() => {
    if (test && test.test.testElements.length !== 0) {
      setFilteredTestElements(test.test.testElements);
    }
  }, [test]);

  return (
    <div>
      {test && (
        <TestAnalysisFilterBlock
          test={test}
          normId={getAnswerByTestId(updatedTestsAnswers, testId)?.normId}
          changedState={Boolean(filter[FilterKeys.isChanged])}
          unansweredState={Boolean(filter[FilterKeys.isUnanswered])}
          searchValue={String(filter[FilterKeys.text])}
          scale={String(filter[FilterKeys.scale])}
          handleSetNorm={handleChangeNorm}
          handleSetSelectedScales={handleChangeScales}
          handleShowChanged={handleShowChanged}
          handleShowUnanswered={handleShowUnanswered}
          handleSearchText={handleChangeSearchText}
        />
      )}

      {filteredTestElements
        .sort((a, b) => a.rank - b.rank)
        .map(
          ({
            elementId,
            config: {
              type,
              key,
              required,
              startsSection,
              payload: { question, choices, body },
            },
          }) => {
            const isPreviousWithBreakAfter = isPreviousElementWithBreakAfter(key, test?.test.testElements);
            return (
              <div key={key}>
                <Divider className={cn(style.divider, { [style.thickDivider]: isPreviousWithBreakAfter })}>
                  {getDividerContent(key, startsSection, testAnswer?.times)}
                </Divider>

                {type === QuestionTypes.Text ? (
                  <Collapse ghost>
                    <Panel
                      key={key}
                      header={
                        <MarkdownPreviewCustom
                          source={`${body[LanguagesIsoEnum.enUS].slice(0, 23)}...`}
                        ></MarkdownPreviewCustom>
                      }
                    >
                      <TestElementAnalysisComponent
                        testId={testId}
                        elementId={elementId}
                        type={type}
                        choices={choices}
                        text={body}
                        required={required}
                        testAnswer={testAnswer}
                        initialAnswer={initialAnswer}
                      />
                    </Panel>
                  </Collapse>
                ) : (
                  <TestElementAnalysisComponent
                    disableChoices={!isScoredTest}
                    testId={testId}
                    elementId={elementId}
                    type={type}
                    choices={choices}
                    text={question}
                    required={required}
                    testAnswer={testAnswer}
                    initialAnswer={initialAnswer}
                  />
                )}
              </div>
            );
          },
        )}
    </div>
  );
}

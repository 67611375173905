import React from "react";

import { UserAvatar } from "@/modules/layout/UserAvatar/UserAvatar";
import { Loading } from "@/shared/components/Loading/Loading";

import style from "./style.module.css";

export default function CandidateLayout({
  isAuthenticated,
  isLoading,
  children,
}: {
  isAuthenticated: boolean;
  isLoading: boolean;
  children: React.ReactElement<any, any>;
}) {
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className={style.desktopHeader}>{isAuthenticated && <UserAvatar />}</div>
      <div>{children}</div>
    </div>
  );
}

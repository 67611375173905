import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CLIENT_ID } from "@/shared/hooks/use-get-clients.hook";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { updateSelectedClient } from "@/shared/services/store/clientStore";

export function useChangeClientContextHook(clientId: string, isAccessibleClient: boolean) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isValidNumericParam(String(clientId)) && isAccessibleClient) {
      dispatch(updateSelectedClient(Number(clientId)));
      localStorage.setItem(CLIENT_ID, clientId);
    }
  }, [clientId, isAccessibleClient]);
}

import { httpClient } from "@/shared/services/http";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { PaginationResponse } from "@/shared/interfaces/pagination.interface";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { FORBIDDEN_REASON_SEARCH, FORBIDDEN_ROUTE } from "@/routing/AppRouter/routes.constants";
import { getRouteWithSearchParameter } from "@/shared/utils/route/route.utils";
import { ForbiddenReasons } from "@/modules/main/Forbidden/Forbidden";

export interface GetInvitesParameters {
  status?: string | string[];
  take?: number;
  skip?: number;
  candidateFilter?: string;
  ids?: string | Array<string>;
  sortOptions?: { column: string | undefined; direction: string | undefined };
}

export class InvitesHttpService {
  static async getAssessmentInviteById(assessmentInviteId: number) {
    const { data: invite } = await httpClient.get(`/api/assessments/invites/${assessmentInviteId}`);

    return invite;
  }

  static async getAssessmentInviteByCode(code: string) {
    return httpClient.get(`/api/assessments/candidates/invites/${code}`);
  }

  static async claimAssessmentInviteByCode(code: string) {
    const { data } = await httpClient.post(`/api/assessments/candidates/invites?code=${code}`);

    return data;
  }

  static async getAllUserAssessmentInvites() {
    const { data } = await httpClient.get(`/api/assessments/candidates/invites`);

    return data;
  }

  static async getAssessmentInvitesCount(assessmentId: number) {
    const { data } = await httpClient.get(`/api/assessments/${assessmentId}/count`);

    return data;
  }

  static async getAllAssessmentInvites(assessmentId: number) {
    const { data } = await httpClient.get<PaginationResponse<IAssessmentInvite>>(
      `/api/assessments/${assessmentId}/candidates/invites`,
    );

    return data.data;
  }

  static async getAllAssessmentInvitesByCandidate(
    assessmentId: number,
    candidateFilter: string,
  ): Promise<IAssessmentInvite[]> {
    const { data } = await httpClient.get<PaginationResponse<IAssessmentInvite>>(
      `/api/assessments/${assessmentId}/candidates/invites?candidate=${candidateFilter}`,
    );

    return data.data;
  }

  static async getAssessmentInvitesByParameters(assessmentId: number, parameters: GetInvitesParameters) {
    const { status, skip, take, candidateFilter, ids, sortOptions } = parameters;
    const filterStatus = status && (Array.isArray(status) ? status.join(",") : status);
    const idsFilter = ids && (Array.isArray(ids) ? ids.join(",") : ids);
    const searchParams = new URLSearchParams();

    if (idsFilter) {
      searchParams.set("ids", idsFilter);
    }

    if (candidateFilter) {
      searchParams.set("candidate", candidateFilter);
    }

    if (filterStatus) {
      searchParams.set("status", filterStatus);
    }

    if (typeof skip === "number" && typeof take === "number") {
      searchParams.set("take", String(take));
      searchParams.set("skip", String(skip));
    }

    if (sortOptions?.column && sortOptions?.direction) {
      searchParams.set("sortBy", sortOptions.column);
      searchParams.set("sort", sortOptions.direction);
    }

    const search = searchParams.toString();
    const url = `/api/assessments/${assessmentId}/candidates/invites` + (search ? `?${search}` : "");

    const { data } = await httpClient.get(url);
    return data;
  }

  static async getClientAssessmentInvitesStats(
    clientId: number,
  ): Promise<Record<string, Record<AssessmentInviteStatus, number>>> {
    const { data } = await httpClient.get(`/api/clients/${clientId}/assessments/candidates/invites/stats`);
    return data;
  }

  static async getAssessmentInvitesStats(assessmentId: number) {
    const { data } = await httpClient.get(`api/assessments/${assessmentId}/candidates/invites/stats`);
    return data;
  }

  static async addTestElementAnswer(
    inviteId: number,
    testId: number,
    { answers, isLast, isBack }: { answers: ITestChoice[]; isLast?: boolean; isBack?: boolean },
    navigate: (key: string) => void,
  ) {
    try {
      const { data } = await httpClient.patch(`/api/invites/${inviteId}/tests/${testId}/answers`, {
        answers,
        isLast,
        isBack,
      });

      return data;
    } catch (err: any) {
      const errorMessage = err.response.data.message as string;

      if (errorMessage.includes("rescinded")) {
        return navigate(
          getRouteWithSearchParameter(
            `/candidate/${FORBIDDEN_ROUTE}`,
            FORBIDDEN_REASON_SEARCH,
            ForbiddenReasons.rescindedInvite,
          ),
        );
      }

      throw err;
    }
  }

  static async increaseSectionTime(
    inviteId: number,
    testId: number,
    { elementKey, deltaTime }: { elementKey: string; deltaTime: number },
  ) {
    const { data } = await httpClient.patch(`/api/invites/${inviteId}/tests/${testId}/times`, {
      elementKey,
      deltaTime,
    });
    return data;
  }

  static startAssessment(inviteId: number) {
    return httpClient.post(`/api/assessments/invites/${inviteId}/start`);
  }

  static updateInviteNarrative(inviteId: number, content: string) {
    return httpClient.patch(`/api/assessments/invites/${inviteId}/narrative`, { content });
  }
}

import "firebase/compat/auth";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Firebase } from "@/shared/services/firebase";
import { SIGN_IN_SUCCESS_URL_CANDIDATE } from "@/shared/services/firebase/firebase.constants";

import { useGetAssessmentQueryCode } from "@/shared/hooks/use-get-assessment-query-code";
import { LoginPageComponent } from "@/shared/components/auth/login-page/login-page.component";
import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";

import { ASSESSMENT_ID_SEARCH } from "@/routing/AppRouter/routes.constants";

export function CandidateSignIn({ isLoading, isSignedIn }: { isLoading: boolean; isSignedIn: boolean }) {
  const code = useGetAssessmentQueryCode();
  const assessmentId = useGetQueryCode(ASSESSMENT_ID_SEARCH);
  let redirectURL = `${SIGN_IN_SUCCESS_URL_CANDIDATE}?`;

  if (code) {
    redirectURL += `code=${code}`;
  }

  if (assessmentId) {
    const assessmentIdParam = `${ASSESSMENT_ID_SEARCH}=${assessmentId}`;
    redirectURL += `${code ? "&" : ""}${assessmentIdParam}`;
  }

  const firebase = new Firebase(redirectURL);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn && !isLoading) {
      navigate(redirectURL);
    }
  }, [isLoading, navigate, code, redirectURL, isSignedIn]);

  // Don't use is conditional statements with the LoginPageComponent
  return <LoginPageComponent firebase={firebase} authTitle="Please Log In or Register" pageTitle="Candidate Login" />;
}

import { useEffect, useState } from "react";

import { isValidNumericParam } from "@/shared/utils/common.utils";
import { InviteHttpService } from "@/modules/assessments/services/invite-http.service";
import moment from "moment";
import { AssessmentLogsService } from "@/modules/assessments/services/assessment-logs.service";

export interface IAssessmentInviteLog {
  assessmentLogId: number;
  inviteId: number;
  eventId: string;
  createdAt: Date;
  key?: number;
  test?: { name: string } | null;
  after?: string | null;
}

export function useGetAssessmentInviteLogs(inviteId: number): {
  assessmentInviteLogs: IAssessmentInviteLog[];
  isLoading: boolean;
} {
  const [assessmentInviteLogs, setAssessmentInviteLogs] = useState<IAssessmentInviteLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        if (inviteId) {
          setIsLoading(true);
          let previousEventDate: Date | null = null;
          const assessmentInviteLogs = (await InviteHttpService.getAssessmentInviteLogs(inviteId)).map((log, index) => {
            const duration = moment.duration(moment(log.createdAt).utc(true).diff(moment(previousEventDate).utc(true)));
            const assessmentLog = {
              ...log,
              key: log.assessmentLogId,
              after: previousEventDate ? AssessmentLogsService.getEventDuration(duration) : null,
            };
            previousEventDate = log.createdAt;

            return assessmentLog;
          });
          setAssessmentInviteLogs(assessmentInviteLogs);
        }
      } catch (e: any) {
        console.error(`assessmentInviteLogs, ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(inviteId)) {
      fetchLogs();
    }
  }, [inviteId]);

  return {
    isLoading,
    assessmentInviteLogs,
  };
}

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ITextLocation } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import { TextLocations } from "@/modules/assessments/scenes/CreateAssessment/createAssessment.constants";
import { IJobRole } from "@/shared/components/assessment/AboutAssessment/hooks/useAllJobRoles.hook";

interface IInitialValues {
  name: string;
  role: number | string | undefined;
  clientJobTitle: string;
  description: string;
  enableStartReminder: boolean;
  startReminderDays: number;
  enableFinishReminder: boolean;
  finishReminderDays: number;
  [TextLocations.introText]: string | undefined;
  [TextLocations.outroText]: string | undefined;
}

export function getInitialAssessmentValues(
  textLocations: ITextLocation[],
  jobRolesList: IJobRole[],
  assessment?: IAssessment,
) {
  const introTextId: number | undefined = textLocations.find(
    (textLocation) => textLocation.name === TextLocations.introText,
  )?.locationId;
  const outroTextId: number | undefined = textLocations.find(
    (textLocation) => textLocation.name === TextLocations.outroText,
  )?.locationId;

  let initialValues: IInitialValues = {
    name: "",
    role: undefined,
    clientJobTitle: "",
    description: "",
    enableStartReminder: false,
    startReminderDays: 3,
    enableFinishReminder: false,
    finishReminderDays: 3,
    [TextLocations.introText]: "",
    [TextLocations.outroText]: "",
  };

  if (assessment?.name) {
    initialValues.name = assessment.name;
  }
  if (assessment?.description) {
    initialValues.description = assessment.description;
  }

  if (assessment?.assessmentTexts) {
    if (assessment.assessmentTexts?.length !== 0) {
      initialValues[TextLocations.introText] = assessment.assessmentTexts.find(
        (text) => text.locationId === introTextId,
      )?.body?.["en-US"];
      initialValues[TextLocations.outroText] = assessment.assessmentTexts.find(
        (text) => text.locationId === outroTextId,
      )?.body?.["en-US"];
    }
  }

  if (assessment?.jobRoleId && jobRolesList.length > 0) {
    initialValues.role = jobRolesList.find((item) => item.roleid === assessment.jobRoleId)?.name;
  }

  if (assessment?.clientJobTitle) {
    initialValues.clientJobTitle = assessment.clientJobTitle;
  }

  if (assessment?.startReminderDays) {
    initialValues.enableStartReminder = true;
    initialValues.startReminderDays = assessment.startReminderDays;
  }

  if (assessment?.finishReminderDays) {
    initialValues.enableFinishReminder = true;
    initialValues.finishReminderDays = assessment.finishReminderDays;
  }

  return initialValues;
}

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export class SentryService {
  static init() {
    return Sentry.init({
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
    });
  }
}

import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { ReportContext } from "@/shared/contexts/report.context";
import { Loading } from "@/shared/components/Loading/Loading";
import { ChartCategories, IChart, IMultiChart, ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { getCompetencyProperty, ReportProps } from "@/shared/components/charts/utils/chartUtils";
import { NarrativeBlock } from "@/shared/components/assessment/report/NarrativeBlock/NarrativeBlock";
import { ReportToPrint } from "@/shared/components/assessment/report/ReportToPrint/ReportToPrint";
import { ReportSidebarMenu } from "@/shared/components/assessment/report/ReportSidebarMenu/ReportSidebarMenu";
import {
  canRadarBeShown,
  isDateExpired,
  isScoredAssessment,
} from "@/shared/components/assessment/report/utils/reportUtils";
import { formatDateAndTime } from "@/shared/utils/formaters/dataFormater/formatDate";
import { useAllCompetenciesQuestionsHook } from "@/modules/admin/testManagement/hooks/useAllCompetencies.hook";
import ChartMultiBlock, {
  ChartTypes,
  getChartColor,
} from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";
import Overall from "@/modules/assessments/scenes/AssessmentReport/Overall/Overall";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";
import { IScale } from "@/modules/assessments/types/scale.interface";
import { ScalesService, StatementsMap } from "@/modules/assessments/services/scales.service";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { IClient, IUser } from "@/modules/client/clients.interface";
import { ClientCompetenciesService } from "@/modules/client/services/client-competencies.service";
import "@/modules/assessments/styles/assessmentCommon.css";
import "@/modules/assessments/styles/reportCommon.css";
import style from "@/modules/assessments/scenes/AssessmentReport/style.module.css";
import { ICompetency } from "@/shared/components/competencies/types";
import { ICompetencyColorMap } from "../ReportComponent/ReportComponent";

export enum ReportScene {
  single,
  compare,
  print,
}
export enum ReportContentView {
  overall,
  radar,
  bar,
  analysis,
  audit,
  narrative,
}

export interface ITestSelect {
  testId: number;
  testRank: number;
  testColor: string;
  testName: string;
  chartCategory: ChartCategories;
  competencies: string[];
  allCompetencies: string[];
  competenciesName?: [string, string];
  competencyColorMap: ICompetencyColorMap;
  showOnChart: boolean;
}

export interface IUserAnswer {
  inviteId: number;
  answers: ITestAnswer[];
}

type AssessmentTestWithRelations = IAssessmentTest & {
  test: IAssessmentTest["test"] & {
    scales: Array<IScale>;
    competencies: Array<Omit<ICompetency, "competencyFormulas">>;
  };
};
export interface IPublicAssessment {
  assessmentId: number;
  name: string;
  roleName: string;
  clientId: number;
  client: PublicClient;
  assessmentTests: Array<AssessmentTestWithRelations>;
  clientJobTitle: string;
}
export interface IPublicAssessmentInvite {
  inviteId: number;
  status: AssessmentInviteStatus;
  finishedOn?: string;
  claimedByUser: PublicUser;
  narrative?: IPublicNarrative;
  grossScore: number;
  netScore: number;
}

interface IPublicNarrative {
  content: string;
}
type PublicUser = Pick<IUser, "fullName">;
type PublicClient = Pick<IClient, "name" | "logoUrl" | "config">;

interface IPublicReportComponent {
  client: PublicClient;
  assessment: IPublicAssessment;
  scene: ReportScene;
  user: PublicUser;
  usersAnswers: IUserAnswer[];
  invite: IPublicAssessmentInvite;
}

function groupSelectedCompetenciesByTest(
  assessmentTests: IAssessmentTest[] | undefined,
  competencies: ICompetency[][],
): ITestSelect[] {
  if (!assessmentTests) return [];

  let colorSequence = 0;
  return assessmentTests
    .sort((a, b) => a.rank - b.rank)
    .map(({ rank, testId, config, test }) => {
      const testColor = getChartColor(colorSequence++);
      const allCompetencies = Object.keys(config.competencies);
      const selectedCompetenciesList = allCompetencies.filter((key) => config.competencies[key] !== false);

      const competencyList = competencies.find((list) => list[0].testId === testId);
      let competencyColorMap: ICompetencyColorMap = {};
      if (competencyList) {
        competencyList.forEach((competency) => {
          if (!competency.reportColor) return;

          if (!competencyColorMap[competency.reportColor]) {
            competencyColorMap[competency.reportColor] = getChartColor(colorSequence++);
          }
        });
      }

      return {
        testId: testId,
        testRank: rank,
        testColor,
        testName: test.name,
        chartCategory: test.config.chartCategory,
        competencies: selectedCompetenciesList,
        competenciesName: test.config.competenciesName,
        competencyColorMap,
        allCompetencies: selectedCompetenciesList,
        showOnChart: true,
      };
    });
}

export function PublicReportComponent({
  client,
  assessment,
  scene,
  usersAnswers,
  user,
  invite,
}: IPublicReportComponent) {
  const testIds: number[] | undefined = assessment.assessmentTests?.map(({ testId }) => testId);
  const { competencies, competenciesQuestions } = useAllCompetenciesQuestionsHook(testIds);
  const [selectedKeys, setSelectedKeys] = useState<ITestSelect[]>([]);
  const [contentView, setContentView] = useState<ReportContentView>();
  const selectedTestIds = useMemo<number[]>(() => {
    return selectedKeys.filter(({ showOnChart }) => showOnChart).map(({ testId }) => testId);
  }, [selectedKeys]);
  const scales = useMemo(() => {
    if (!assessment.assessmentTests) return [];

    return assessment.assessmentTests.map((assessmentTest) => assessmentTest.test.scales);
  }, [assessment.assessmentTests]);
  const testScalesMap = useMemo<Record<string, IScale[]>>(() => {
    const scalesMap: Record<string, IScale[]> = {};
    scales.forEach((scale) => {
      scalesMap[scale[0].testId] = scale;
    });
    return scalesMap;
  }, [scales]);
  const statements = useMemo<StatementsMap>(() => {
    if (!usersAnswers.length) return {};

    return ScalesService.calculateScales(usersAnswers[0].answers, testScalesMap);
  }, [usersAnswers, testScalesMap]);

  const handleSelectedCompetencies = (testId: number, competencies: string[]) => {
    setSelectedKeys((prev) => {
      return prev.map((selectedKey) => {
        if (selectedKey.testId !== testId) return selectedKey;

        return {
          ...selectedKey,
          competencies,
        };
      });
    });
  };

  const handleSelectedTests = (checked: boolean, testId: number) => {
    setSelectedKeys((prev) => {
      return prev.map((selectedKey) => {
        if (selectedKey.testId !== testId) return selectedKey;

        return {
          ...selectedKey,
          showOnChart: checked,
        };
      });
    });
  };

  const chartData = useMemo<IMultiChart[]>(() => {
    if (selectedKeys[0] && selectedKeys[0].competencies.length !== 0) {
      const checkedTests: ITestSelect[] = selectedTestIds.map((id) => {
        const index = selectedKeys.findIndex((item) => item.testId === id);
        return selectedKeys[index];
      });
      const answers: ITestAnswer[] | undefined = usersAnswers.find(
        (item) => item.inviteId === Number(invite.inviteId),
      )?.answers;
      if (answers && answers.length > 0) {
        const allCompetencies = competencies.flat();
        const aggregatedData: IChart[][] = checkedTests.map((key) => {
          const answer = answers.find((item) => {
            return item.testId === key.testId;
          });
          return key.competencies.map((competency) => {
            const originalCompetencyReportColor =
              getCompetencyProperty(allCompetencies, competency, key.testId, ReportProps.reportColor) ?? "none";
            return {
              competency: competency,
              competencyName: ClientCompetenciesService.getCustomCompetencyName({
                base64Key: competency,
                clientConfig: client?.config,
                testId: key.testId,
                competencies: allCompetencies,
              }),
              reportCompetencyColor: key.competencyColorMap[originalCompetencyReportColor],
              value:
                answer && answer.score.competencies[competency]
                  ? typeof answer?.score.competencies[competency].adjusted === "number"
                    ? answer.score.competencies[competency].adjusted
                    : answer.score.competencies[competency].score
                  : undefined,
              testRank: key.testRank,
              chartCategory: key.chartCategory,
              testId: key.testId,
              testColor: key.testColor,
              explanation: getCompetencyProperty(allCompetencies, competency, key.testId, ReportProps.explanation),
            };
          });
        });

        return [
          {
            name: user.fullName,
            inviteId: invite.inviteId,
            color: checkedTests[0].testColor,
            chartData: aggregatedData.flat(),
          },
        ];
      }
    }

    return [];
  }, [selectedKeys, user, invite.inviteId, client, competencies, selectedTestIds, usersAnswers]);

  useEffect(() => {
    if (!competencies || !competencies.length) return;

    setSelectedKeys(groupSelectedCompetenciesByTest(assessment.assessmentTests, competencies));
  }, [assessment, competencies]);

  const availableDataKeys = selectedKeys.filter((key) => {
    return usersAnswers[0].answers.some((answer) => answer.testId === key.testId);
  });
  const showRadar = canRadarBeShown(availableDataKeys);
  const showOverall =
    Object.keys(statements).length !== 0 &&
    competenciesQuestions.some(({ questions }) => questions.length || Object.keys(questions).length);
  const isScored = isScoredAssessment({
    netScore: invite.netScore,
    grossScore: invite.grossScore,
    assessmentTests: assessment.assessmentTests,
  });
  const expiryDate = moment(invite?.finishedOn).add(1, "year");

  if (!selectedKeys.length || !competencies.length || !competenciesQuestions.length) {
    return <Loading />;
  }

  return (
    <ReportContext.Provider
      value={{
        scene,
        testIds,
        competenciesQuestions,
        scales,
        clientConfig: client.config,
      }}
    >
      <div className="container">
        {scene === ReportScene.single && usersAnswers.length > 0 && (
          <div className={style.printContainer}>
            <ReportToPrint
              showRadar={showRadar}
              candidate={user}
              client={client}
              jobTitle={assessment.clientJobTitle}
              roleName={assessment.roleName}
              assessmentInvite={invite}
              assessment={assessment}
              answers={usersAnswers[0].answers}
              statements={statements}
              chartData={chartData}
              isScored={isScored}
              narrativeContent={invite.narrative?.content}
            />
          </div>
        )}
        <Layout className={style.singleLayout} style={{ height: "calc(var(--doc-height) - 125px)" }} hasSider={true}>
          <Sider className={style.sidebarWrapper} width={205}>
            <div>
              {scene === ReportScene.single && (
                <div>
                  <p className={style.candidateName}>{user.fullName}</p>
                </div>
              )}
              <ReportSidebarMenu
                showRadar={showRadar}
                isDone={true}
                isViewerAdmin={false}
                isScored={isScored}
                showOverall={showOverall}
                isCompareView={false}
                onMenuClick={(menuItem: any) => setContentView(Number(menuItem.key))}
                hasNarrative={Boolean(invite.narrative?.content)}
              />
            </div>
          </Sider>
          <Content className={style.contentWrapper}>
            {isDateExpired(expiryDate.toString()) && (
              <p className={style.warningContainer}>
                This report was valid until {formatDateAndTime(expiryDate.toString())}
              </p>
            )}
            {usersAnswers.length
              ? contentView === ReportContentView.overall &&
                assessment && (
                  <div className={style.contentChild}>
                    <Overall
                      showRadar={showRadar}
                      assessment={assessment}
                      answers={usersAnswers[0].answers}
                      statements={statements}
                      chartData={chartData}
                    />
                  </div>
                )
              : null}
            {(contentView === ReportContentView.radar || contentView === ReportContentView.bar) && (
              <div className={style.contentChild}>
                <div>
                  <ChartMultiBlock
                    dataLoading={false}
                    checkedTestId={selectedTestIds}
                    selected={selectedKeys}
                    chartType={contentView === ReportContentView.radar ? ChartTypes.radar : ChartTypes.bar}
                    chartMultiData={chartData}
                    checkedHideSameScore={false}
                    setSelected={handleSelectedCompetencies}
                    onCheckShowTest={handleSelectedTests}
                  />
                </div>
              </div>
            )}

            {contentView === ReportContentView.narrative && (
              <div className={style.contentChild}>
                <NarrativeBlock content={invite.narrative?.content} allowEdit={false} />
              </div>
            )}
          </Content>
        </Layout>
      </div>
    </ReportContext.Provider>
  );
}

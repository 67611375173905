import { httpClient } from "@/shared/services/http";

export class TestInstructionsHttpService {
  static async getTestInstructionsByTestIds(testIds: number[]): Promise<{
    [testId: string]: { [language: string]: string };
  }> {
    const testIdQuery = testIds.map((testId) => `testId=${testId}&`);

    const { data: testInstructions } = await httpClient.get(`/api/test-instructions?${testIdQuery}`);

    return testInstructions;
  }

  static async getTestInstructionsByTestId(testId: string | undefined): Promise<Record<string, string>> {
    if (!testId) {
      throw new Error("No test id provided");
    }

    const { data } = await httpClient.get(`/api/test-instructions?testId=${testId}`);

    return data[testId];
  }

  static async updateTestInstructionsBody(testId: string | undefined, body: Record<string, string>) {
    if (!testId) {
      throw new Error("No test id provided");
    }

    const { data } = await httpClient.put(`/api/test-instructions/${testId}/update-body`, body);

    return data;
  }
}

import React, { useContext } from "react";
import cn from "classnames";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Countdown } from "@/shared/components/Countdown/Countdown";
import { SectionContext } from "../../contexts/section.context";
import { DELTA_TIME_SEC } from "../../hooks/useSections.hook";

import style from "./style.module.css";

export interface IQuestionHeader {
  isLoadingTestInstructions: boolean;
  setIsOpenedInstructions: (value: boolean) => void;
}

export function QuestionHeader({ isLoadingTestInstructions, setIsOpenedInstructions }: IQuestionHeader) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  const { currentSectionElement, timeLeft } = useContext(SectionContext);

  // TODO: Create text button component
  return (
    <div id="question-header" className={cn(style.stage, { [style.desktopStage]: isDesktopView })}>
      <button
        className={style.stageButton}
        disabled={isLoadingTestInstructions}
        onClick={() => setIsOpenedInstructions(true)}
      >
        Instructions
      </button>

      {timeLeft !== null && (
        <Countdown
          timerKey={`${currentSectionElement?.config.startsSection?.name || "countdown"}-${timeLeft}`}
          timeToCount={timeLeft}
          intervalDelay={DELTA_TIME_SEC * 1000}
        />
      )}
    </div>
  );
}

import { useEffect, useState } from "react";

import { GetInvitesParameters, InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { statusText } from "@/shared/components/candidate/scenes/InviteCard/InviteCard";

export function usePaginatedAssessmentInvitesHook(assessmentId: number, parameters: GetInvitesParameters) {
  const [assessmentInvites, setAssessmentInvites] = useState<IAssessmentInvite[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data: assessmentInvites, totalCount }: { data: IAssessmentInvite[]; totalCount: number } =
          await InvitesHttpService.getAssessmentInvitesByParameters(Number(assessmentId), parameters);
        const updatedAssessmentInvitesByKey = assessmentInvites.map((invite: IAssessmentInvite) => {
          const result = { key: invite.inviteId, ...invite };

          result.statusName = statusText[invite.status];
          if (result.claimedBy) {
            result.candidateFullName = result.claimedByUser ? `${result.claimedByUser.fullName}` : "";
          } else {
            result.candidateFullName = "";
          }

          return result;
        });

        setTotalCount(totalCount);
        setAssessmentInvites(updatedAssessmentInvitesByKey);
        setIsLoading(false);
      } catch (e: any) {
        console.error(`Paginated Assessment Invites error: ${e}`);
      }
    })();
  }, [
    assessmentId,
    parameters?.skip,
    parameters?.take,
    parameters?.status,
    parameters?.candidateFilter,
    parameters?.sortOptions,
  ]);

  return { assessmentInvites, isLoading, totalCount };
}

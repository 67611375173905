import { useEffect, useState } from "react";

import { IScale } from "@/modules/assessments/types/scale.interface";

import { TestHttpService } from "@/shared/services/tests/test-http.service";
import { isValidNumericParam } from "@/shared/utils/common.utils";

export function useGetClientAssessmentScales(clientId: string, testIds: number[] | undefined) {
  const [scales, setScales] = useState<IScale[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchScales = async () => {
    if (!testIds || !testIds.length) return;
    try {
      setIsLoading(true);
      const testScalesMap: IScale[][] = await Promise.all(
        testIds.map((testId) => TestHttpService.getTestScales(clientId, testId)),
      );
      setScales(testScalesMap);
    } catch (e) {
      console.error(`get public scales error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (clientId || (testIds && testIds.length)) {
      if (isValidNumericParam(clientId)) {
        fetchScales();
      }
    }
  }, [clientId, testIds?.length]);

  return {
    scales,
    isLoading,
  };
}

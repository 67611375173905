import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, Select, Tag } from "antd";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { ClientCompetenciesService } from "@/modules/client/services/client-competencies.service";
import { getCompetencyProperty, ReportProps } from "@/shared/components/charts/utils/chartUtils";
import { useGetReduxTestsCompetenciesHook } from "@/shared/services/store/hooks/useGetReduxTestsCompetencies.hook";
import { ReactComponent as CheckedSVG } from "@/assets/icons/checked.svg";
import style from "./style.module.css";
import "./index.css";
import { ICompetencyColorMap } from "../ReportComponent/ReportComponent";

export interface IReportTestCompetencySelector {
  color: string;
  testName: string;
  testId: number;
  allCompetencies: string[];
  selectedCompetencies: string[];
  isChecked: boolean;
  onChangeSelected: (testId: number, competencies: string[]) => void;
  onCheckboxClick: (checked: boolean, testId: number) => void;
  addMoreTitle?: string;
  competencyColorMap: ICompetencyColorMap;
}

export function ReportTestCompetencySelector({
  testId,
  testName,
  color,
  allCompetencies,
  selectedCompetencies,
  isChecked,
  onChangeSelected,
  onCheckboxClick,
  addMoreTitle = "competencies",
  competencyColorMap,
}: IReportTestCompetencySelector) {
  const reduxCompetencies = useGetReduxTestsCompetenciesHook([testId]);

  const PRIMARY_COLOR = "#FFFFFF";
  const SECONDARY_COLOR = `${color}1A`;
  const { client_id } = useParams<{ client_id: string }>();
  const { client } = useClientByIdHook(Number(client_id));
  const [checked, setChecked] = useState<boolean>(isChecked);
  const [bgColor, setBgColor] = useState<string>(PRIMARY_COLOR);
  const [tagsColor, setTagsColor] = useState<string>(SECONDARY_COLOR);

  const clientAbility = useContext(ClientAbilityContext);
  const canUpdateReportSettings = clientAbility.can(Action.Update, Subject.REPORT_SETTING);

  const toggleChecked = (event: any) => {
    onCheckboxClick(event.target.checked, testId);
    setChecked((prevState) => !prevState);
  };
  const [selected, setSelected] = useState<string[]>([]);

  const handleCloseTag = (removedTag: string) => {
    const newTags = selected.filter((tag) => tag !== removedTag);
    setSelected(newTags);
    onChangeSelected(testId, newTags);
  };

  const handleChange = (e: string[]) => {
    const selectedItems = e.map((competency) => String(competency.split("_").at(-1)));
    setSelected(selectedItems);
    onChangeSelected(testId, selectedItems);
  };

  useEffect(() => {
    if (selectedCompetencies) {
      setSelected(selectedCompetencies);
      onChangeSelected(testId, selectedCompetencies);
    }
  }, []);

  const addMoreCaption = `Add more ${addMoreTitle}`;
  const [placeholder, setPlaceholder] = useState<string>(addMoreCaption);

  return (
    <div
      className={style.container}
      style={{ backgroundColor: bgColor }}
      onMouseEnter={() => {
        setBgColor(SECONDARY_COLOR);
        setTagsColor(PRIMARY_COLOR);
      }}
      onMouseLeave={() => {
        setBgColor(PRIMARY_COLOR);
        setTagsColor(SECONDARY_COLOR);
      }}
    >
      <div className={style.cardHeader}>
        <div className={style.flexContainer}>
          <div style={{ backgroundColor: color }} className={style.testColorMark}></div>
          <p className={style.testName}>{testName}</p>
        </div>
        {canUpdateReportSettings && (
          <div className={style.flexContainer}>
            <label htmlFor={`show-test-toggle-${testId}`} className={style.checkboxGroup}>
              Show on chart
            </label>
            <Checkbox id={`show-test-toggle-${testId}`} checked={checked} onClick={toggleChecked} />
          </div>
        )}
      </div>
      <div className={style.tagsContainer}>
        {selected
          .sort((a, b) =>
            ClientCompetenciesService.compareCompetenciesByName({
              firstKey: a,
              secondKey: b,
              testId,
              competencies: reduxCompetencies,
            }),
          )
          .map((competencyName) => {
            const competency = ClientCompetenciesService.getCustomCompetencyName({
              base64Key: competencyName,
              clientConfig: client?.config,
              testId,
              competencies: reduxCompetencies,
            });
            const competencyReportColor = getCompetencyProperty(
              reduxCompetencies,
              competencyName,
              testId,
              ReportProps.reportColor,
            );
            const color = competencyReportColor ? competencyColorMap[competencyReportColor] : null;

            return (
              <Tag
                key={competencyName}
                color={color ? `${color}1A` : tagsColor}
                closable={canUpdateReportSettings}
                id={`${testId}:${competencyName}`}
                onClose={(e) => {
                  e.preventDefault();
                  handleCloseTag(competencyName);
                }}
              >
                {competency}
              </Tag>
            );
          })}
      </div>
      {canUpdateReportSettings && (
        <div className={style.selectContainer}>
          <Select
            showArrow
            mode="multiple"
            placeholder={addMoreCaption}
            options={allCompetencies.map((competencyB64) => {
              const customCompetencyName = ClientCompetenciesService.getCustomCompetencyName({
                base64Key: competencyB64,
                clientConfig: client?.config,
                testId,
                competencies: reduxCompetencies,
              });

              return {
                label: customCompetencyName,
                value: `${customCompetencyName}_${competencyB64}`,
              };
            })}
            value={selected
              .map(
                (competencyB64) =>
                  `${ClientCompetenciesService.getCustomCompetencyName({
                    base64Key: competencyB64,
                    clientConfig: client?.config,
                    testId,
                    competencies: reduxCompetencies,
                  })}_${competencyB64}`,
              )
              .sort((first, second) => first.toUpperCase().localeCompare(second.toUpperCase()))}
            filterSort={(a, b) => (a.label < b.label ? -1 : 1)}
            maxTagPlaceholder={placeholder}
            onDropdownVisibleChange={(open) => (open ? setPlaceholder("") : setPlaceholder(addMoreCaption))}
            maxTagCount={0}
            menuItemSelectedIcon={<CheckedSVG />}
            placement="bottomLeft"
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}

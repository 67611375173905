import { useEffect, useState } from "react";

import { IUser } from "@/modules/client/clients.interface";

import { UserHttpService } from "@/shared/services/user/user-http.service";

export function useGetAllAdminUsersHook() {
  const [adminUsers, setAdminUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        setIsLoading(true);

        const adminUsers = await UserHttpService.getAllAdminUsers();
        setAdminUsers(adminUsers);
      } catch (e) {
        console.error(`Get all admin users error: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdminUsers();
  }, []);

  return { adminUsers, isLoading };
}

import { useEffect, useState } from "react";

import { JobRolesHttpService } from "../services/JobRolesHttpService";

interface IClientJobTitle {
  value: string;
}

export function useAllClientJobTitles(clientId: number) {
  const [clientJobTitleList, setClientJobTitleList] = useState<IClientJobTitle[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const clientJobTitle: string[] = await JobRolesHttpService.getClientJobTitle(clientId);
        const updatedJobTitle: IClientJobTitle[] = clientJobTitle.map((title) => {
          return { value: title };
        });
        setClientJobTitleList(updatedJobTitle);
      } catch (e: any) {
        console.error(`all clients job titles error: ${e}`);
      }
    })();
  }, []);

  return { clientJobTitleList, isLoading };
}

import { ReactNode } from "react";
import { Tooltip } from "antd";

import { unknownValue } from "@/shared/utils/formaters/unknownValue/unknownValue";

export function formatTableData(
  something?: string | number,
  formatter?: (s: any) => any,
  tooltipFormatter?: (s: any) => any,
  fallback?: string,
): ReactNode {
  if (something) {
    if (formatter) {
      if (tooltipFormatter) {
        return <Tooltip overlay={tooltipFormatter(something)}>{formatter(something)}</Tooltip>;
      } else {
        return <span>{formatter(something)}</span>;
      }
    }
    return <span>{something}</span>;
  }
  return <span>{unknownValue(fallback)}</span>;
}

import { useContext, useEffect, useState } from "react";

import { UserContext } from "@/shared/contexts/user.context";
import { useGetTestHook } from "@/shared/hooks/tests/useGetTestHook";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { ITestElement } from "@/modules/assessments/types/test.interface";

import { findCurrentTestId } from "../utils/candidate-assessment-view.utils";

export function useAssessmentTestHook({
  invite,
  assessment,
  currentPage,
  questionsList,
}: {
  invite?: IAssessmentInvite;
  assessment?: IAssessment;
  currentPage: number;
  questionsList: ITestElement[][];
}) {
  const { user } = useContext(UserContext);

  const [testId, setTestId] = useState<number | undefined>();
  const { test, isLoading } = useGetTestHook(Number(testId), Boolean(user?.userId));

  useEffect(() => {
    if (!assessment || !invite) {
      return;
    }

    let questionsIndex = currentPage ? currentPage - 1 : currentPage;

    if (!questionsList[questionsIndex]) {
      return;
    }

    setTestId(findCurrentTestId(questionsList, invite.testAnswers, questionsIndex, assessment?.assessmentTests));
  }, [currentPage, assessment]);

  return { test, isLoading };
}

import React from "react";
import { StyledFirebaseAuth } from "react-firebaseui";

import { Firebase } from "@/shared/services/firebase";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import logo from "@/assets/image/psp-logo.svg";

import style from "./style.module.css";

// Don't use is conditional statements with the LoginPageComponent
export function LoginPageComponent({
  authTitle,
  pageTitle,
  firebase,
}: {
  authTitle: string;
  pageTitle: string;
  firebase: Firebase;
}) {
  return (
    <div className={style.wrapper}>
      <SEO titleParams={[pageTitle]} />
      <div className={style.logo}>
        <img src={logo} className={style.logoImage} alt="PSP Metrics" />
      </div>
      <div className={style.container}>
        <p className={style.title}>{authTitle}</p>
        <StyledFirebaseAuth className={style.firebaseUi} uiConfig={firebase.uiConfig} firebaseAuth={firebase.auth} />
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";

import { UserHttpService } from "@/shared/services/user/user-http.service";

import { IUser } from "@/modules/client/clients.interface";

export function useGetClientUsersHook(clientId?: number) {
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!clientId) return;

    (async () => {
      try {
        setIsLoading(true);

        const clientUsers: IUser[] = await UserHttpService.getClientUsers(clientId);
        setUsers(clientUsers);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  return {
    users,
    isLoading,
  };
}

type TState = {
  isSignedIn: boolean;
  isLoading: boolean;
  user: any;
  error: any;
};

export function isSignedInState(firebase: TState): boolean {
  return Boolean(
    firebase.isSignedIn && !firebase.isLoading && firebase.user && !firebase?.isLoading && !firebase?.error,
  );
}

const isClient = (clients: object[]): boolean => {
  return clients && clients.length !== 0;
};

const hasOneClient = (clients: object[]): boolean => {
  return clients && clients.length === 1;
};

export function hasClientsState(isSigned: boolean, user: any): boolean {
  return isSigned && user && !user?.isAdmin && isClient(user.clientUsers);
}

export function hasOneClientState(isSigned: boolean, user: any): boolean {
  return hasClientsState(isSigned, user) && hasOneClient(user.clientUsers);
}

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addRoles } from "@/shared/services/store/rolesStore";
import { IAssessmentRole, IClientUserRole, RolesHttpService } from "@/shared/services/roles/roles-http.service";

export function useGetAllRolesHook(isSignedIn: boolean = false) {
  const dispatch = useDispatch();

  const [clientUserRoles, setClientUserRoles] = useState<IClientUserRole[]>();
  const [isLoadingClientRoles, setIsLoadingClientRoles] = useState<boolean>(false);

  const [assessmentRoles, setAssessmentRoles] = useState<IAssessmentRole[]>();
  const [isLoadingAssessmentRoles, setIsLoadingAssessmentRoles] = useState<boolean>(false);

  useEffect(() => {
    if (!isSignedIn) return;

    (async () => {
      try {
        setIsLoadingClientRoles(true);

        const clientUserRolesRes = await RolesHttpService.getClientUserRoles();
        setClientUserRoles(clientUserRolesRes);
      } finally {
        setIsLoadingClientRoles(false);
      }
    })();
  }, [isSignedIn]);

  useEffect(() => {
    if (!isSignedIn) return;

    (async () => {
      try {
        setIsLoadingAssessmentRoles(true);

        const assessmentRolesRes = await RolesHttpService.getAssessmentRoles();
        setAssessmentRoles(assessmentRolesRes);
      } finally {
        setIsLoadingAssessmentRoles(false);
      }
    })();
  }, [isSignedIn]);

  useEffect(() => {
    dispatch(
      addRoles({
        roles: { clientRoles: clientUserRoles, assessmentRoles },
        isLoading: isLoadingClientRoles && isLoadingAssessmentRoles,
      }),
    );
  }, [isLoadingClientRoles, isLoadingAssessmentRoles]);

  return {
    clientUserRoles,
    assessmentRoles,
    isLoading: isLoadingClientRoles && isLoadingAssessmentRoles,
  };
}

import { httpClient } from "../http";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";

export type IClientUserRole = {
  roleId: number;
  name: ClientRoleEnum;
  createdAt: string;
  updatedAt: string;
};

export type IAssessmentRole = {
  roleId: number;
  name: AssessmentRoleEnum;
  createdAt: string;
  updatedAt: string;
};

export class RolesHttpService {
  static async getClientUserRoles(): Promise<IClientUserRole[]> {
    const { data: clientUserRoles } = await httpClient.get(`/api/clients/users/roles`);

    return clientUserRoles;
  }

  static async getAssessmentRoles(): Promise<IAssessmentRole[]> {
    const { data: assessmentRoles } = await httpClient.get(`/api/assessments/roles`);

    return assessmentRoles;
  }
}

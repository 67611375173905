export const markdownStyles = {
  mobile: {
    fontSize: "1.375rem",
    lineHeight: "1.375em",
    color: "#4E4E4E",
  },
  desktop: {
    fontSize: "1.75rem",
    lineHeight: "1.25em",
    color: "#4E4E4E",
  },
  analysis: {
    fontSize: "16px",
    lineHeight: "1.125em",
    color: "rgba(0, 0, 0, 0.75)",
  },
  motivatorsDesktop: {
    fontSize: "14px",
    lineHeight: "1.25em",
    color: "#4E4E4E",
  },
  motivatorsMobile: {
    fontSize: "12px",
    lineHeight: "1.25em",
    color: "#4E4E4E",
  },
};

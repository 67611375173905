import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { ITest } from "@/modules/assessments/types/test.interface";
import { BasicModal } from "@/ui-components/commonComponents/Modal/BasicModal";

import { CurrentPageContext } from "../../../contexts/current-page.context";
import { getIfNextSectionNewTest } from "../../../utils/section.utils";
import { getIfNextQuestionNewTest, getIfTheEndOfTheAssessment } from "../../../utils/candidate-assessment-view.utils";

import { TimeOutModalFooter } from "./TimeOutModalFooter";

export function TimeOutModal({
  test,
  isOpen,
  goNextTest,
  setIsOpenModal,
  finishAssessment,
  goNextSection,
}: {
  isOpen: boolean;
  goNextTest: () => void;
  goNextSection: () => void;
  finishAssessment: () => void;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  test?: ITest;
}) {
  const { questionsList, currentPage } = useContext(CurrentPageContext);
  const [modalInfo, setModalInfo] = useState<{
    okText: string;
    content: string;
    ifNextQuestionNewTest: boolean;
    ifNextSectionNewTest: boolean;
    ifTheEndOfTheAssessment: boolean;
  }>({
    content: "",
    okText: "",
    ifNextQuestionNewTest: false,
    ifNextSectionNewTest: false,
    ifTheEndOfTheAssessment: false,
  });

  useEffect(() => {
    const ifNextQuestionNewTest = getIfNextQuestionNewTest(questionsList, currentPage);
    const ifNextSectionNewTest = getIfNextSectionNewTest(questionsList, currentPage, test?.testId);
    const ifTheEndOfTheAssessment = getIfTheEndOfTheAssessment(questionsList, currentPage, test?.testId);

    let content = "You've run out of time on this section. Press next to move to the next section.";
    let okText = "Skip To Next Section";

    if (ifNextQuestionNewTest || ifNextSectionNewTest) {
      content = "You've run out of time on this test. Press next to move to the next test.";
      okText = "Skip To Next Test";
    }

    if (ifTheEndOfTheAssessment) {
      content = "Your time is over. Press next to finish.";
      okText = "Finish And Submit";
    }

    setModalInfo({ content, okText, ifNextQuestionNewTest, ifNextSectionNewTest, ifTheEndOfTheAssessment });
  }, [currentPage, test?.testId]);

  return (
    <BasicModal
      autoHeight
      handleCancel={() => setIsOpenModal(false)}
      isModalOpen={isOpen}
      footer={
        <TimeOutModalFooter
          okText={modalInfo.okText}
          cancelText="Close And Review"
          handleOk={async () => {
            if (modalInfo.ifTheEndOfTheAssessment) {
              finishAssessment();
            } else if (modalInfo.ifNextQuestionNewTest || modalInfo.ifNextSectionNewTest) {
              goNextTest();
            } else {
              goNextSection();
            }

            setIsOpenModal(false);
          }}
          handleCancel={() => setIsOpenModal(false)}
        />
      }
    >
      <>
        <p>{modalInfo.content}</p>
      </>
    </BasicModal>
  );
}

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Progress } from "antd";
import {
  TeamOutlined,
  ExpandOutlined,
  EllipsisOutlined,
  AlignLeftOutlined,
  ClockCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { AssessmentStatusComponent } from "@/modules/assessments/components/assessment-status/assessment-status.component";
import { IAssessmentTable } from "@/modules/tables/TableAssessments";

import styles from "./assessment-list-mobile.module.css";

import "./index.css";

export function MobileAssessmentsListComponent({
  tableData,
  handleClickNewAssessmentButton,
}: {
  tableData: IAssessmentTable[];
  handleClickNewAssessmentButton: () => void;
}) {
  const ability = useContext(ClientAbilityContext);
  const canCreateAssessment = ability.can(Action.Create, Subject.ASSESSMENT);

  return (
    <div>
      <div className={styles.assessmentItemButton}>
        <CustomButton title="new assessment" onClick={handleClickNewAssessmentButton} disabled={!canCreateAssessment} />
      </div>

      {tableData.map(({ assessmentId, name, tests, questions, inProgress, finished, invited, timing }) => (
        <Link
          key={assessmentId}
          className={styles.assessmentItem}
          to={invited ? `${assessmentId}/invites` : `${assessmentId}/tests`}
        >
          <div className={styles.assessmentItemStatus}>
            <AssessmentStatusComponent isActive />
            <div className={styles.assessmentItemStatusSettings}>
              <ExpandOutlined aria-hidden="true" />
              <EllipsisOutlined aria-hidden="true" />
            </div>
          </div>

          <div className={styles.assessmentItemContent}>
            <h2 className={styles.assessmentItemContentTitle}>{name}</h2>
            <div className={styles.assessmentItemScores}>
              <div className={styles.assessmentItemScoresItem}>
                <UnorderedListOutlined />
                <p>{`${tests} ${tests === 1 ? "test" : "tests"}`}</p>
              </div>
              <div className={styles.assessmentItemScoresItem}>
                <AlignLeftOutlined aria-hidden="true" />
                <p>{`${questions} ${questions === 1 ? "question" : "questions"}`}</p>
              </div>
              <div className={styles.assessmentItemScoresItem}>
                <ClockCircleOutlined aria-hidden="true" />
                <p>{`${timing} min`}</p>
              </div>
              <div className={styles.assessmentItemScoresItem}>
                <TeamOutlined aria-hidden="true" />
                <p>{`${invited} ${invited === 1 ? "person" : "people"}`}</p>
              </div>
            </div>

            <div className={styles.assessmentItemScores}>
              <div className={styles.assessmentItemProgressItem}>
                <p>{`${inProgress} in progress`}</p>
                <Progress showInfo={false} percent={(inProgress / invited) * 100} />
              </div>
              <div className={styles.assessmentItemProgressItem}>
                <p>{`${finished} finished`}</p>
                <Progress showInfo={false} percent={(finished / invited) * 100} />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

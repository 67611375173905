import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

import { ClientHttpService } from "@/modules/client/services/client-http.service";

import { AddNewCharacter } from "@/shared/components/AddNew/AddNewCharacter";
import { addSingleClient } from "@/shared/services/store/clientStore";

import { ADMIN_ROUTE, CLIENT_ROUTE } from "@/routing/AppRouter/routes.constants";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

export default function CreateNewClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateClient = async (value: { name: string }) => {
    try {
      const response = await ClientHttpService.addClientByName(value);

      if (response.status === 201) {
        dispatch(addSingleClient({ key: response.data.clientId, ...response.data }));
        notification.success({
          message: (
            <p>
              Client <span style={{ fontWeight: 700 }}> {response.data.name}</span> was created
            </p>
          ),
        });
        setTimeout(() => {
          navigate(`/${ADMIN_ROUTE}/${CLIENT_ROUTE}`);
        }, 3000);
      }
    } catch (e: any) {
      notification.error({ message: e.message });
    } finally {
    }
  };

  return (
    <>
      <SEO titleParams={["Clients", "Add Client"]} />
      <AddNewCharacter
        title="Add Client"
        formName="addClient"
        onSubmit={handleCreateClient}
        inputProps={[
          {
            name: "name",
            label: "Full Name",
            errorText: "Input client name",
            required: true,
          },
        ]}
        buttonProps={{ title: "Save" }}
      />
    </>
  );
}

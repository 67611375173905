import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { ReportContext } from "@/shared/contexts/report.context";
import { ReportCompareContext } from "@/shared/contexts/reportCompare.context";

import { Loading } from "@/shared/components/Loading/Loading";
import { ChartCategories, IChart, IMultiChart, ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { getCompetencyProperty, ReportProps } from "@/shared/components/charts/utils/chartUtils";
import { useGetClientAssessmentScales } from "@/shared/components/assessment/hooks/useGetClientAssessmentScales";
import { AnalysisBlock } from "@/shared/components/assessment/report/AnalysisBlock/AnalysisBlock";
import { NarrativeBlock } from "@/shared/components/assessment/report/NarrativeBlock/NarrativeBlock";
import { ReportToPrint } from "@/shared/components/assessment/report/ReportToPrint/ReportToPrint";
import { ReportSidebarMenu } from "@/shared/components/assessment/report/ReportSidebarMenu/ReportSidebarMenu";
import {
  canRadarBeShown,
  isDateExpired,
  isScoredAssessment,
} from "@/shared/components/assessment/report/utils/reportUtils";

import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { isValidNumericParam } from "@/shared/utils/common.utils";
import { formatDateAndTime } from "@/shared/utils/formaters/dataFormater/formatDate";

import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { DB_USER_KEY } from "@/shared/hooks/auth/currentUser.hook";

import { useAllCompetenciesQuestionsHook } from "@/modules/admin/testManagement/hooks/useAllCompetencies.hook";

import ChartMultiBlock, {
  ChartTypes,
  getChartColor,
  CHART_COLORS,
} from "@/modules/assessments/scenes/AssessmentReport/ChartBlock/ChartMultiBlock";
import Overall from "@/modules/assessments/scenes/AssessmentReport/Overall/Overall";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import Audit from "@/modules/assessments/scenes/AssessmentReport/Audit/Audit";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";
import { IScale } from "@/modules/assessments/types/scale.interface";
import { ScalesService, StatementsMap } from "@/modules/assessments/services/scales.service";
import { IAssessment } from "@/modules/assessments/assessments.types";

import { IClient, IUser } from "@/modules/client/clients.interface";
import { ClientCompetenciesService } from "@/modules/client/services/client-competencies.service";

import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";

import {
  ASSESSMENT_EDIT_INVITES_ROUTE,
  ASSESSMENT_ROUTE,
  CLIENT_ROUTE,
  NOT_FOUND_ROUTE,
} from "@/routing/AppRouter/routes.constants";

import { ReactComponent as BackSVG } from "@/assets/icons/feather-icons/chevron-left.svg";

import "@/modules/assessments/styles/assessmentCommon.css";
import "@/modules/assessments/styles/reportCommon.css";
import style from "@/modules/assessments/scenes/AssessmentReport/style.module.css";

export enum ReportScene {
  single,
  compare,
  print,
}
export enum ReportContentView {
  overall,
  radar,
  bar,
  analysis,
  audit,
  narrative,
}

export interface ITestSelect {
  testId: number;
  testRank: number;
  testColor: string;
  testName: string;
  chartCategory: ChartCategories;
  competencies: string[];
  allCompetencies: string[];
  competenciesName?: [string, string];
  competencyColorMap: ICompetencyColorMap;
}

export interface IUserAnswer {
  inviteId: number;
  answers: ITestAnswer[];
}

export interface ICompetencyColorMap {
  [key: string]: typeof CHART_COLORS[number];
}

interface IReportComponent {
  client: IClient;
  assessment: IAssessment;
  scene: ReportScene;
  user?: IUser;
  usersAnswers: IUserAnswer[];
  userAnswersLoading?: boolean;
  invite?: IAssessmentInvite;
  inviteId?: number;
  isPublic?: boolean;
  selectedUsers: string[];
  candidateSelector?: any;
  compareComponent?: JSX.Element;
  setUserAnswers?: (userAnswers: IUserAnswer[]) => void;
}
export const splitPattern = "*_*";

export function ReportComponent({
  client,
  assessment,
  scene,
  usersAnswers,
  user,
  invite,
  inviteId,
  selectedUsers,
  compareComponent,
  userAnswersLoading = false,
  isPublic = false,
}: IReportComponent) {
  const navigate = useNavigate();
  const isDone = scene === ReportScene.single ? invite?.status === AssessmentInviteStatus.Done : true;
  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);
  const canReadAssessment = assessmentAbility.can(Action.Read, Subject.ASSESSMENT);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const currentUser: IUser = JSON.parse(String(localStorage.getItem(DB_USER_KEY)));
  const isAdmin: boolean = currentUser?.isAdmin;
  const clientId = client.clientId;
  const { answersLoading: compareAnswersLoading } = useContext(ReportCompareContext);
  const { isHiddenSameScore, setIsHiddenSameScore } = useContext(ReportCompareContext);
  const testIds: number[] | undefined = assessment?.assessmentTests?.map(({ testId }) => testId);
  const {
    competencies,
    competenciesQuestions,
    isLoading: competenciesLoading,
  } = useAllCompetenciesQuestionsHook(testIds);
  const { scales, isLoading: scalesLoading } = useGetClientAssessmentScales(String(clientId), testIds);
  const [preselectedCompetencies, setPreselectedCompetencies] = useState<ITestSelect[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<ITestSelect[]>([]);
  const [chartSelectedKeys, setChartSelectedKeys] = useState<ITestSelect[]>([]);
  const [selectedTestsId, setSelectedTestsId] = useState<number[]>([]);
  const [chartData, setChartData] = useState<IMultiChart[]>([]);
  const isScored = isScoredAssessment({
    netScore: invite?.netScore,
    grossScore: invite?.grossScore,
    assessmentTests: assessment?.assessmentTests,
  });
  const [testScalesMap, setTestScalesMap] = useState<Record<string, IScale[]>>({});
  const [statements, setStatements] = useState<StatementsMap>({});

  useEffect(() => {
    if (scales) {
      const testScalesMap: Record<string, IScale[]> = {};
      scales.forEach((scale) => {
        testScalesMap[scale[0].testId] = scale;
      });
      setTestScalesMap(testScalesMap);
    }
  }, [scales]);

  useEffect(() => {
    if (!isDone) return;
    if (usersAnswers.length) {
      setStatements(ScalesService.calculateScales(usersAnswers[0].answers, testScalesMap));
    }
  }, [usersAnswers, testScalesMap]);

  useEffect(() => {
    if (assessment && competencies && competencies.length) {
      let colorSequence = 0;
      const assessmentTests: IAssessmentTest[] | undefined = assessment.assessmentTests;
      if (assessmentTests) {
        const preselectedCompetenciesGroupedByTest: ITestSelect[] = assessmentTests
          .sort((a, b) => a.rank - b.rank)
          .map(({ rank, testId, config, test }) => {
            const testColor = getChartColor(colorSequence++);
            const allCompetencies = Object.keys(config.competencies);
            const selectedCompetenciesList = allCompetencies.filter((key) => config.competencies[key] !== false);

            const competencyList = competencies.find((list) => list[0].testId === testId);
            let competencyColorMap: ICompetencyColorMap = {};
            if (competencyList) {
              competencyList.forEach((competency) => {
                if (!competency.reportColor) return;

                if (!competencyColorMap[competency.reportColor]) {
                  competencyColorMap[competency.reportColor] = getChartColor(colorSequence++);
                }
              });
            }

            return {
              testId: testId,
              testRank: rank,
              testColor,
              testName: test.name,
              chartCategory: test.config.chartCategory,
              competencies: selectedCompetenciesList,
              competenciesName: test.config.competenciesName,
              competencyColorMap,
              allCompetencies: isAdmin ? allCompetencies : selectedCompetenciesList,
            };
          });
        if (preselectedCompetenciesGroupedByTest) {
          setPreselectedCompetencies(preselectedCompetenciesGroupedByTest);
        }
      }
    }
  }, [assessment, isAdmin, competencies]);

  useEffect(() => {
    if (preselectedCompetencies.length) {
      setSelectedKeys(preselectedCompetencies);
      setChartSelectedKeys(preselectedCompetencies);
      setSelectedTestsId(preselectedCompetencies.map((test) => test.testId));
    }
  }, [preselectedCompetencies]);

  useEffect(() => {
    const checkedTests: ITestSelect[] = selectedTestsId.map((id) => {
      const index = selectedKeys.findIndex((item) => item.testId === id);
      return selectedKeys[index];
    });
    setChartSelectedKeys(checkedTests);
  }, [selectedKeys, selectedTestsId]);

  const [contentView, setContentView] = useState<ReportContentView>();

  const handleSelectedCompetencies = (testId: number, competencies: string[]) => {
    const checkedTests: ITestSelect[] = selectedTestsId.map((id) => {
      const index = selectedKeys.findIndex((item) => item.testId === id);
      return selectedKeys[index];
    });
    const chartTestSelects = checkedTests.map((selectedKey) => {
      if (selectedKey.testId === testId) {
        selectedKey.competencies = competencies;
        return selectedKey;
      } else {
        return selectedKey;
      }
    });
    const testSelects = selectedKeys.map((selectedKey) => {
      if (selectedKey.testId === testId) {
        selectedKey.competencies = competencies;
        return selectedKey;
      } else {
        return selectedKey;
      }
    });
    setChartSelectedKeys(chartTestSelects);
    setSelectedKeys(testSelects);
  };

  const handleShowOnRadarCheck = (checked: boolean, testId: number) => {
    if (checked) {
      setSelectedTestsId((prevState) => [...prevState, testId]);
    } else setSelectedTestsId((prevState) => prevState.filter((item) => item !== testId));
  };

  useEffect(() => {
    if (setIsHiddenSameScore) {
      setIsHiddenSameScore(false);
    }
  }, [scene]);

  const aggregateData = (singleInviteId?: number): void => {
    if (selectedKeys[0] && selectedKeys[0].competencies.length !== 0) {
      const checkedTests: ITestSelect[] = selectedTestsId
        .map((id) => {
          const index = selectedKeys.findIndex((item) => item.testId === id);
          return selectedKeys[index];
        })
        .sort((a, b) => a.testRank - b.testRank);

      let userColorSequence = 0;
      const updatedUsersAnswers: IMultiChart[] = selectedUsers.map((nameId, index) => {
        const inviteId: number = singleInviteId ? singleInviteId : Number(nameId.split(splitPattern).at(-1));
        if (inviteId && isValidNumericParam(inviteId)) {
          const answers: ITestAnswer[] | undefined = usersAnswers.find(
            (item) => item.inviteId === Number(inviteId),
          )?.answers;

          if (answers && answers.length > 0) {
            const allCompetencies = competencies.flat();
            const aggregatedData: IChart[][] = checkedTests.map((key) => {
              const answer = answers.find((item) => {
                return item.testId === key.testId;
              });

              return key.competencies.map((competency) => {
                const originalCompetencyReportColor =
                  getCompetencyProperty(allCompetencies, competency, key.testId, ReportProps.reportColor) ?? "none";

                return {
                  competency: competency,
                  competencyName: ClientCompetenciesService.getCustomCompetencyName({
                    base64Key: competency,
                    clientConfig: client?.config,
                    testId: key.testId,
                    competencies: competencies.flat(),
                  }),
                  reportCompetencyColor: key.competencyColorMap[originalCompetencyReportColor],
                  value:
                    answer && answer.score.competencies[competency]
                      ? typeof answer?.score.competencies[competency].adjusted === "number"
                        ? answer.score.competencies[competency].adjusted
                        : answer.score.competencies[competency].score
                      : undefined,
                  testRank: key.testRank,
                  chartCategory: key.chartCategory,
                  testId: key.testId,
                  testColor: key.testColor,
                  explanation: getCompetencyProperty(
                    competencies.flat(),
                    competency,
                    key.testId,
                    ReportProps.explanation,
                  ),
                };
              });
            });
            return {
              name: String(nameId.split(splitPattern).at(0)),
              inviteId: Number(inviteId),
              color: getChartColor(userColorSequence++),
              chartData: aggregatedData.flat(),
            };
          }
        }
        return { name: "", inviteId: -1, color: "", chartData: [] };
      });
      if (updatedUsersAnswers.length !== 0 && Boolean(updatedUsersAnswers[0].chartData.length !== 0)) {
        setChartData(updatedUsersAnswers);
      } else setChartData([]);
    }
  };

  useEffect(() => {
    if (!isDone) return;
    if (scene === ReportScene.compare) {
      aggregateData();
    }
  }, [usersAnswers, selectedUsers, chartSelectedKeys, selectedKeys]);

  useEffect(() => {
    if (!isDone) return;
    if (scene === ReportScene.single) {
      if (inviteId && isValidNumericParam(inviteId)) {
        if (usersAnswers.length) {
          aggregateData(Number(inviteId));
        }
      } else navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [inviteId, usersAnswers, chartSelectedKeys, selectedKeys, competencies]);

  const availableDataKeys = selectedKeys.filter((key) => {
    return usersAnswers.some((userAnswer) => userAnswer.answers.some((answer) => answer.testId === key.testId));
  });
  const showRadar = selectedKeys ? canRadarBeShown(availableDataKeys) : true;

  if (scalesLoading || competenciesLoading) {
    return <Loading />;
  }

  return (
    <ReportContext.Provider
      value={{
        scene,
        testIds,
        competenciesQuestions,
        scales,
        clientConfig: client.config,
      }}
    >
      <div className="container">
        {isDone && scene === ReportScene.single && usersAnswers.length > 0 && assessment && (
          <div className={style.printContainer}>
            <ReportToPrint
              showRadar={showRadar}
              candidate={user}
              client={client}
              jobTitle={assessment.clientJobTitle}
              roleName={assessment.role?.name}
              assessmentInvite={invite}
              assessment={assessment}
              answers={usersAnswers[0].answers}
              statements={statements}
              chartData={chartData}
              isScored={isScored}
              narrativeContent={invite?.narrative?.content}
            />
          </div>
        )}
        <Layout
          className={style.singleLayout}
          style={{ height: isPublic ? "calc(var(--doc-height) - 125px)" : "calc(var(--doc-height) - 215px)" }}
          hasSider
        >
          <Sider className={style.sidebarWrapper} width={205}>
            <div>
              {scene === ReportScene.single && (
                <div>
                  {!isPublic && (
                    <CustomButton
                      title={"Back to invites"}
                      onClick={() => {
                        navigate(
                          `/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${assessment.assessmentId}/${ASSESSMENT_EDIT_INVITES_ROUTE}`,
                        );
                      }}
                      buttonSize={ButtonSize.XXSmall}
                      colorType={ButtonColorType.transparentDark}
                    >
                      <BackSVG aria-hidden="true" />
                    </CustomButton>
                  )}
                  <p className={style.candidateName}>{user?.fullName || user?.email || invite?.email}</p>
                </div>
              )}

              {/*//Hide Overall Job Match*/}
              {/*{typeof invite?.netScore !== "undefined" && isScored && (*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      width: "100%",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <JobMatch matchLabel={AssessmentService.getOverallScoreLabel(invite.netScore)} />*/}
              {/*  </div>*/}
              {/*)}*/}

              <ReportSidebarMenu
                showRadar={showRadar}
                isDone={isDone}
                isViewerAdmin={isAdmin}
                isScored={isScored}
                showOverall={
                  Object.keys(statements).length !== 0 ||
                  competenciesQuestions.filter(({ questions }) => questions.length || Object.keys(questions).length)
                    .length !== 0
                }
                isCompareView={scene === ReportScene.compare}
                onMenuClick={(menuItem: any) => setContentView(Number(menuItem.key))}
                hasNarrative={Boolean(invite?.narrative?.content)}
              />
            </div>
          </Sider>
          <Content className={style.contentWrapper}>
            {isDateExpired(moment(invite?.finishedOn).add(1, "year").toString()) && (
              <p className={style.warningContainer}>
                This report was valid until {formatDateAndTime(moment(invite?.finishedOn).add(1, "year").toString())}
              </p>
            )}
            {isDone && usersAnswers.length
              ? contentView === ReportContentView.overall &&
                assessment && (
                  <div className={style.contentChild}>
                    <Overall
                      showRadar={showRadar}
                      assessment={assessment}
                      answers={usersAnswers[0].answers}
                      statements={statements}
                      chartData={chartData}
                    />
                  </div>
                )
              : null}
            {isDone && (contentView === ReportContentView.radar || contentView === ReportContentView.bar) && (
              <div className={style.contentChild}>
                <div>
                  <ChartMultiBlock
                    dataLoading={userAnswersLoading || compareAnswersLoading}
                    checkedTestId={selectedTestsId}
                    selected={selectedKeys}
                    chartType={contentView === ReportContentView.radar ? ChartTypes.radar : ChartTypes.bar}
                    chartMultiData={chartData}
                    checkedHideSameScore={scene === ReportScene.compare ? isHiddenSameScore : false}
                    compareHeader={compareComponent}
                    setSelected={handleSelectedCompetencies}
                    onCheckShowTest={handleShowOnRadarCheck}
                  />
                </div>
              </div>
            )}

            {(isAdmin || canManageAssessment) &&
              scene === ReportScene.single &&
              contentView === ReportContentView.audit && (
                <div className={style.contentChild}>
                  <Audit answers={usersAnswers} isAdmin={isAdmin} />
                </div>
              )}

            {isAdmin && isDone && contentView === ReportContentView.analysis && (
              <div className={style.contentChild} ref={setContainer}>
                <AnalysisBlock assessmentInvite={invite} container={container} />
              </div>
            )}

            {canReadAssessment && isDone && contentView === ReportContentView.narrative && (
              <div className={style.contentChild}>
                <NarrativeBlock
                  inviteId={inviteId}
                  allowEdit={isAdmin && !invite?.assessment?.archivedOn}
                  content={invite?.narrative?.content}
                  updatedAt={invite?.narrative?.updatedAt}
                  updatedByUser={invite?.narrative?.lastUpdatedByUser}
                />
              </div>
            )}
          </Content>
        </Layout>
      </div>
    </ReportContext.Provider>
  );
}

import { httpClient } from "@/shared/services/http";

export class AssetsHttpService {
  static async uploadAssessmentPhoto(postFile: FormData, { clientId }: { clientId: number }) {
    return await httpClient.post(`/api/clients/${clientId}/assessments/images`, postFile, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static async uploadClientLogo(postFile: FormData, clientId: number) {
    return await httpClient.post(`api/clients/${clientId}/images`, postFile, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
}

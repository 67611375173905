import React, { useContext } from "react";
import { Col, Row } from "antd";

import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";

import { AboutAssessment, AssessmentActions } from "@/shared/components/assessment/AboutAssessment/AboutAssessment";

import { AssessmentTeamScene } from "../AssessmentTeam/AssessmentTeam.scene";

import "../../styles/assessmentCommon.css";

export function AssessmentSettings({
  isChangeable = true,
  isArchived = false,
}: {
  isChangeable?: boolean;
  isArchived?: boolean;
}) {
  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canUpdateAssessment = assessmentAbility.can(Action.Update, Subject.ASSESSMENT);

  return (
    <div className="container">
      <Row align="middle" justify="center">
        {canUpdateAssessment && (
          <Col span={24}>
            <div className="tabs-inner-container-desktop">
              <AboutAssessment type={AssessmentActions.EDIT} isChangeable={!isArchived && isChangeable} />
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className="tabs-inner-container-desktop">
            <AssessmentTeamScene isArchived={isArchived} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

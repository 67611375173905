import classNames from "classnames";

import styles from "./assessment-status.module.css";

export function AssessmentStatusComponent({ isActive = true }: { isActive?: boolean }) {
  return (
    <div
      className={classNames(styles.assessmentStatus, {
        [styles.assessmentStatusActive]: isActive,
        [styles.assessmentStatusInactive]: !isActive,
      })}
    >
      <span className={styles.assessmentStatusIcon} />
      <p className={styles.assessmentStatusText}>
        {isActive && "Active"}
        {!isActive && "Inactive"}
      </p>
    </div>
  );
}

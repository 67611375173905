import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Checkbox, Form, Select } from "antd";

import { IScale } from "@/modules/assessments/types/scale.interface";
import { IAssessmentTest } from "@/modules/assessments/types/test.interface";

import { getNormId, getNormName, INorm } from "@/shared/components/assessment/new/TestItem/TestItem";
import { AnalysisContext } from "@/shared/contexts/analysis.context";
import { ReportContext } from "@/shared/contexts/report.context";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";

import CustomSearch from "@/ui-components/commonComponents/Input/Search";

import "./fitlers-block-styles.css";

export const ALL_SCALES_OPTION = "All Scales";

interface ITestAnalysisFiltersBlock {
  test: IAssessmentTest;
  normId: number | undefined;
  changedState: boolean;
  unansweredState: boolean;
  searchValue: string;
  scale: string;
  handleSetNorm: (id: number) => void;
  handleSetSelectedScales: (scales: string) => void;
  handleShowChanged: (state: boolean) => void;
  handleShowUnanswered: (state: boolean) => void;
  handleSearchText: (substring: string) => void;
}

export function TestAnalysisFilterBlock({
  test,
  normId,
  changedState,
  unansweredState,
  searchValue,
  scale,
  handleSetNorm,
  handleSetSelectedScales,
  handleShowChanged,
  handleShowUnanswered,
  handleSearchText,
}: ITestAnalysisFiltersBlock) {
  const { scales: assessmentScales } = useContext(ReportContext);
  const { norms: assessmentNorms } = useContext(AnalysisContext);
  const isScoredTest: boolean | undefined = test.test.includeInOverallScore;
  const testNorms: INorm[] | undefined =
    assessmentNorms.find((norm) => (norm[0] ? norm[0].testId === test.testId : false)) || [];
  const testScales: IScale[] | undefined = assessmentScales.find((scale) => scale[0].testId === test.testId) || [];
  const allScalesInTest: string[] = [
    ALL_SCALES_OPTION,
    ...testScales.sort((a, b) => sortElements(a.abbr, b.abbr)).map((scale) => `${scale.abbr} - ${scale.name}`),
  ];

  const [normName, setNormName] = useState<string>(getNormName(testNorms, Number(normId)));

  const onNormChange = (event: string) => {
    setNormName(event);
    const selectedNormId = getNormId(testNorms, event);
    handleSetNorm(selectedNormId);
  };

  const onChangeScales = (event: string) => {
    handleSetSelectedScales(event);
  };

  useEffect(() => {
    if (normId) {
      setNormName(getNormName(testNorms, normId));
    }
  }, [normId]);

  useEffect(() => {
    setNormName(getNormName(testNorms, test.normId));
  }, [testNorms]);

  return (
    <div className="filtersBlock">
      <Form layout="vertical" className="formWrapper">
        {testNorms.length !== 0 && (
          <Form.Item label="Norm" className="formItem">
            <Select
              showSearch
              placeholder="Select a test norm"
              options={testNorms
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((norm: INorm) => {
                  return { value: norm.name, label: norm.name };
                })}
              optionFilterProp="children"
              filterOption={(input, option) => isSubstring(String(option?.value), input)}
              onChange={onNormChange}
              value={normName}
              className="select"
            />
          </Form.Item>
        )}
        <Form.Item label="Filter" className={cn("formItem", "analysisSearch")}>
          <CustomSearch
            placeholder="Type to search ..."
            onSearchChange={(e) => handleSearchText(e.target.value)}
            value={searchValue}
          />
        </Form.Item>
        {allScalesInTest.length !== 0 && (
          <Form.Item label="Affected Scale" className="formItem">
            <Select
              showSearch
              placeholder="Select scale to filter"
              options={allScalesInTest.map((scale) => {
                return {
                  value: scale.split(" - ")[0],
                  label: scale,
                };
              })}
              optionFilterProp="children"
              filterOption={(input, option) => isSubstring(String(option?.value), input)}
              onChange={onChangeScales}
              defaultValue={ALL_SCALES_OPTION}
              value={scale}
              className="select"
            />
          </Form.Item>
        )}
        {isScoredTest && (
          <Form.Item className="checkBoxContainer" label=" " name="changed">
            <Checkbox checked={changedState} onChange={(event) => handleShowChanged(event.target.checked)}>
              <p>Show Only Changed</p>
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item className="checkBoxContainer" label=" " name="unanswered">
          <Checkbox checked={unansweredState} onChange={(event) => handleShowUnanswered(event.target.checked)}>
            <p>Show Unanswered</p>
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
}

import { ISectionConfig, ITestElement } from "@/modules/assessments/types/test.interface";

function getCurrentElementIndex(elements: ITestElement[], currentElementKey: string): number {
  return elements.findIndex((element) => element.config.key === currentElementKey);
}

export function getNextElementStartSectionConfig(
  elements: ITestElement[],
  currentElementKey: string,
): ISectionConfig | undefined {
  const currentElementIndex = getCurrentElementIndex(elements, currentElementKey);
  const nextElement = elements[currentElementIndex + 1];

  return nextElement ? nextElement.config.startsSection : undefined;
}

export function isPreviousElementWithBreakAfter(currentElementKey: string, elements?: ITestElement[]): boolean {
  if (!elements) {
    return false;
  }
  const currentElementIndex = getCurrentElementIndex(elements, currentElementKey);
  const previousElement = elements[currentElementIndex - 1];
  return previousElement ? previousElement.config.breakAfter : false;
}

import React, { useState, useContext } from "react";
import { useGetAllUserNotificationSettings } from "../../hooks/get-all-user-notification-settings.hook";
import { UserNotificationSettingsHttpService } from "../../services.ts/user-notification-settings.service";
import { getFriendlyErrorMessage } from "@/shared/services/http";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { Form, Switch, notification } from "antd";
import { Loading } from "@/shared/components/Loading/Loading";
import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";
import style from "./style.module.css";

interface IUserNotificationSettingBase {
  id: number;
  enabled: boolean;
}

interface IUserNotificationSettingDetails extends IUserNotificationSettingBase {
  description: string;
}

interface IUserNotificationFormValues {
  [key: string]: boolean;
}

export default function NotificationSettings() {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUserSettings, isLoadingNotificationSettings, error } = useGetAllUserNotificationSettings();

  const isError = Boolean(error);
  const isLoading = !isError && isLoadingNotificationSettings;

  const ability = useContext(ClientAbilityContext);
  const canUpdateUserNotificationSetting = ability.can(Action.Update, Subject.USER_NOTIFICATION_SETTING);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={style.container}>
      {isError ? (
        <p>{error}</p>
      ) : (
        <Form
          form={form}
          name="UserNotificationSettings"
          requiredMark={false}
          initialValues={Object.fromEntries(currentUserSettings.map((setting) => [setting.id, setting.enabled]))}
          onFinish={async (values: IUserNotificationFormValues) => {
            try {
              setIsSubmitting(true);
              const updatedUserSettings = Object.entries(values).map(([id, enabled]) => ({
                id: parseInt(id),
                enabled,
              }));

              await UserNotificationSettingsHttpService.updateCurrentUserNotificationSettings(updatedUserSettings);
              setIsSubmitting(false);
              notification.success({
                message: "Settings updated successfully.",
              });
            } catch (error) {
              setIsSubmitting(false);
              notification.error({
                message: getFriendlyErrorMessage(error) ?? "Oops, something went wrong. Please try again.",
              });
            }
          }}
        >
          {currentUserSettings.map((currentUserSetting) => (
            <Notification
              key={currentUserSetting.id}
              id={currentUserSetting.id}
              description={currentUserSetting.description}
              enabled={currentUserSetting.enabled}
            />
          ))}
          <div className={style.buttonContainer}>
            <CustomButton
              disabled={!canUpdateUserNotificationSetting}
              title={"Save"}
              isLoading={isSubmitting}
              buttonSize={ButtonSize.small}
            >
              <SaveSVG className={style.svgSize} aria-hidden="true" />
            </CustomButton>
          </div>
        </Form>
      )}
    </div>
  );
}

function Notification({ id, description, enabled }: IUserNotificationSettingDetails) {
  return (
    <Form.Item name={id} label={description} valuePropName="checked">
      <Switch defaultChecked={enabled} />
    </Form.Item>
  );
}

import { ITest, IAssessmentTest } from "@/modules/assessments/types/test.interface";

export class TestsUtils {
  static getAssessmentTestsStats(tests: ITest[], assessmentTests?: IAssessmentTest[]) {
    const defaultStats = { timingMs: 0, questions: 0 };

    if (!assessmentTests) {
      return defaultStats;
    }

    return assessmentTests.reduce((acc, assessmentTest) => {
      const test = tests.find(({ testId }) => testId === assessmentTest.testId);

      if (!test || !test.config.suggestedTime) {
        return acc;
      }

      return {
        timingMs: acc.timingMs + test.config.suggestedTime,
        questions: acc.questions + test.testElements.filter(({ config }) => config.payload).length,
      };
    }, defaultStats);
  }
}

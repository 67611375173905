import { HttpStatus } from "@/routing/services/statusCodes";
import { FORBIDDEN_ROUTE, NOT_FOUND_ROUTE, SERVER_FAIL_ROUTE } from "@/routing/AppRouter/routes.constants";

export default function getErrorRoute(statusCode: number, reason?: string): string {
  if (statusCode === HttpStatus.NOT_FOUND) {
    return `/${NOT_FOUND_ROUTE}`;
  }
  if (statusCode === HttpStatus.FORBIDDEN) {
    if (reason) {
      return `/${FORBIDDEN_ROUTE}?forbidden_reason=${reason}`;
    }
    return `/${FORBIDDEN_ROUTE}`;
  }
  if (statusCode === HttpStatus.INTERNAL_SERVER_ERROR) {
    return `/${SERVER_FAIL_ROUTE}`;
  }
  return "";
}

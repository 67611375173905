import React from "react";
import { Col, Progress, Row, Statistic, Tooltip } from "antd";
import { BarChartOutlined } from "@ant-design/icons";

import "./index.css";

export interface IStatisticsBlockProps {
  allTests: number;
  allQuestions?: number;
  invitedPeople: number;
  allTime?: string;
  claimedPeople: number;
  startedPeople: number;
  finishedPeople: number;
  acceptedPeople: number;
  inProgressPeople: number;
}

export function StatisticsBlock({
  allTests,
  allQuestions,
  invitedPeople,
  allTime,
  claimedPeople,
  startedPeople,
  finishedPeople,
  acceptedPeople,
  inProgressPeople,
}: IStatisticsBlockProps) {
  const claimedPercent = (claimedPeople * 100) / invitedPeople;
  const startedPercent = (startedPeople * 100) / invitedPeople;
  const finishedPercent = (finishedPeople * 100) / invitedPeople;

  return (
    <div>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <Statistic title="Tests" value={allTests} suffix={<BarChartOutlined aria-hidden="true" />} />
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <Statistic title="Elements" value={allQuestions} suffix={<BarChartOutlined aria-hidden="true" />} />
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <div>
            <Statistic title="People" value={invitedPeople} suffix={<BarChartOutlined aria-hidden="true" />} />
            <div className="progressWrapper">
              <Tooltip
                color="#FFFFFF"
                title={
                  <div className="progress-tooltip">
                    <p>{invitedPeople} Invited</p>
                    <p>{claimedPeople} Accepted:</p>
                    <p className="sub in-progress">{acceptedPeople} Not Started</p>
                    <p className="sub in-progress">{startedPeople} Started</p>
                    <p className="sub in-progress">{inProgressPeople} In Progress</p>
                    <p className="sub finished">{finishedPeople} Finished </p>
                  </div>
                }
              >
                <Progress
                  className="progressElement"
                  percent={claimedPercent}
                  trailColor="#E1EBFE"
                  strokeColor="#96B5F0"
                  success={{ percent: finishedPercent, strokeColor: "#225082" }}
                  showInfo={false}
                  strokeWidth={12}
                />
              </Tooltip>
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <Statistic title="Time" value={allTime} suffix={<BarChartOutlined aria-hidden="true" />} />
        </Col>
      </Row>
    </div>
  );
}

import { ComputeHttpService } from "@/shared/components/assessment/service/compute-http.service";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import {
  IAnsweredCompetency,
  IAnsweredScoreOverall,
  IAnsweredScoreScales,
} from "@/shared/components/charts/types/chartTypes";

export interface ITestScoreResponse {
  testId: number;
  normId?: number;
  competenciesResult: IAnsweredCompetency;
  overallScore: IAnsweredScoreOverall;
  scalesResult: IAnsweredScoreScales;
}

export interface IComputeTestRequestData {
  testId: number;
  payload: IComputeTestScorePayload;
}

export interface IComputeTestScorePayload {
  answers: ITestChoice[];
  normId?: number;
  config?: {
    competencies: { [key: string]: boolean | 0 | 1 | 2 | 3 | 4 };
  };
}

export async function computeTestScore(testId: number, payload: IComputeTestScorePayload) {
  if (isValidNumericParam(testId)) {
    try {
      return await ComputeHttpService.computeTestScore(testId, payload);
    } catch (e: any) {
      console.error(`Compute test score error: ${e}`);
    }
  }
}

export async function computeAllTestScores(
  requestData: IComputeTestRequestData[],
): Promise<ITestScoreResponse[] | undefined> {
  try {
    return await Promise.all(
      requestData.map(async ({ testId, payload }) => {
        const response: Omit<ITestScoreResponse, "testId"> = await ComputeHttpService.computeTestScore(testId, payload);
        return { testId, normId: payload.normId, ...response };
      }),
    );
  } catch (e: any) {
    console.error(`Compute test score error: ${e}`);
  }
}

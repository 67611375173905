import React, { createContext } from "react";

import { INorm } from "@/shared/components/assessment/new/TestItem/TestItem";
import { ITestAnswerTimes } from "@/shared/components/charts/types/chartTypes";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { ITestScoreResponse } from "@/shared/components/assessment/service/computeTest.service";

export type TTestAnalysisAnswers = {
  testId: number;
  normId: number;
  answers: ITestChoice[];
  times: ITestAnswerTimes | null;
};

export type TAnalysisContext = {
  initialTestAnswers: TTestAnalysisAnswers[];
  updatedTestsAnswers: TTestAnalysisAnswers[];
  testResults: ITestScoreResponse[];
  norms: INorm[][];
  setUpdatedTestsAnswers?: React.Dispatch<React.SetStateAction<TTestAnalysisAnswers[]>>;
  setHasChangedElements?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AnalysisContext = createContext<TAnalysisContext>({
  initialTestAnswers: [],
  updatedTestsAnswers: [],
  testResults: [],
  norms: [],
});

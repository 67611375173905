import { httpClient } from "@/shared/services/http";

const USERS = "users";
const NOTIFICATION_SETTINGS = "notification-settings";

export class UserNotificationSettingsHttpService {
  static async getCurrentUserNotificationSettings() {
    return httpClient.get(`/api/${USERS}/me/${NOTIFICATION_SETTINGS}`);
  }

  static async updateCurrentUserNotificationSettings(notificationSettings: { id: number; enabled: boolean }[]) {
    return httpClient.patch(`/api/${USERS}/me/${NOTIFICATION_SETTINGS}/update-all`, {
      settings: notificationSettings,
    });
  }
}

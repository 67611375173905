import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AutoComplete, Col, Form, Row, Input, Switch, InputNumber } from "antd";
import cn from "classnames";
import { ReactComponent as SaveSVG } from "@/assets/icons/feather-icons/save.svg";
import { UserContext } from "@/shared/contexts/user.context";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { Loading } from "@/shared/components/Loading/Loading";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { SubmitStatuses } from "@/shared/components/testManagement/TestElementInfoBlock";
import useAllTextLocations from "@/shared/components/assessment/AboutAssessment/hooks/useAllTextLocations";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import {
  IAssessmentData,
  ICustomInput,
  INewAssessmentValues,
} from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import { TextLocations } from "@/modules/assessments/scenes/CreateAssessment/createAssessment.constants";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { useGetClient } from "@/modules/client/hooks/useGetClient";
import LocationText from "@/ui-components/commonComponents/LocationText/LocationText";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CustomInput, InputType } from "@/ui-components/commonComponents/Input/Input";
import { UploadPhoto } from "@/ui-components/commonComponents/UploadPhoto/UploadPhoto";
import { UploadFolder } from "@/ui-components/commonComponents/UploadPhoto/UploadPhoto";
import {
  ASSESSMENT_ROUTE,
  CLIENT_ROUTE,
  NEW_ROUTE,
  NOT_FOUND_ROUTE,
  TESTS_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import { useAllJobRolesHook } from "./hooks/useAllJobRoles.hook";
import { useAllClientJobTitles } from "./hooks/useAllClientJobTitles";
import { getInitialAssessmentValues } from "./utils";
import tableStyle from "@/modules/tables/style.module.css";
import "./index.css";
import style from "./style.module.css";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";

export enum AssessmentActions {
  NEW = "NEW",
  EDIT = "EDIT",
}

export interface IAboutAssessmentProps {
  type: AssessmentActions;
  isChangeable?: boolean;
  title?: string;
  defaultImageUrl?: string;
  texts?: any;
}

export const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const CLIENT_JOB_TITLE_MAX_LENGTH = 256;

export function AboutAssessment({ type, isChangeable = true }: IAboutAssessmentProps) {
  const navigate = useNavigate();
  const { client_id: clientId } = useParams<{ client_id: string }>();
  const { user } = useContext(UserContext);
  const { assessment, setAssessment } = useContext(AssessmentContext);
  const { client } = useGetClient(Number(clientId));
  const { textLocations, isLoading: textLocationsLoading } = useAllTextLocations();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<SubmitStatuses>(SubmitStatuses.initial);
  const { jobRolesList, isLoading: jobRolesLoading } = useAllJobRolesHook();
  const { clientJobTitleList } = useAllClientJobTitles(Number(clientId));
  const [form] = Form.useForm();
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const getAlert = (status: SubmitStatuses) => {
    switch (status) {
      case SubmitStatuses.error:
        return <Alert message={"Something went wrong. Please, retry later."} type={"error"} showIcon />;
      case SubmitStatuses.success:
        return <Alert message={"Success"} type={"success"} showIcon />;
      case SubmitStatuses.initial:
      default:
        return;
    }
  };

  const onFinish = async (values: INewAssessmentValues) => {
    if (!user || !user.userId) {
      console.log(`Have not found user id`);
      return;
    }

    let assessmentBody: IAssessmentData = {
      userId: user.userId,
      name: values.name,
      description: values.description,
      roleId: jobRolesList.find((item) => item.value === values.role)?.roleid,
      clientJobTitle: values.clientJobTitle,
      titleImageUrl: imageUrl ? imageUrl : assessment?.titleImageUrl || "",
      texts: [
        {
          locationName: TextLocations.introText,
          body: values[TextLocations.introText],
        },
        {
          locationName: TextLocations.outroText,
          body: values[TextLocations.outroText],
        },
      ],
    };

    if (values.enableStartReminder === false) {
      assessmentBody.startReminderDays = null;
    } else if (values.startReminderDays) {
      assessmentBody.startReminderDays = values.startReminderDays;
    }

    if (values.enableFinishReminder === false) {
      assessmentBody.finishReminderDays = null;
    } else if (values.finishReminderDays) {
      assessmentBody.finishReminderDays = values.finishReminderDays;
    }

    try {
      setIsLoading(true);

      if (type === AssessmentActions.NEW) {
        const { data } = await AssessmentHttpService.createNewAssessment(Number(clientId), assessmentBody);

        navigate(`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${data.assessmentId}/${TESTS_ROUTE}`);
      }

      if (type === AssessmentActions.EDIT && assessment) {
        const { data } = await AssessmentHttpService.updateAssessment(
          Number(clientId),
          assessment.assessmentId,
          assessmentBody,
        );

        if (setAssessment) {
          setAssessment({ ...assessment, ...data });
        }
        setAlert(SubmitStatuses.success);
      }
    } catch (e: any) {
      console.error(`patch assessment error: ${e}`);
      setAlert(SubmitStatuses.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isValidNumericParam(clientId)) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, [clientId, navigate]);

  useEffect(() => {
    if (type !== AssessmentActions.NEW || !client || !textLocations?.length || !client.clientTexts?.length) return;

    textLocations.forEach(({ locationId, name }) => {
      const clientText = client.clientTexts?.find((clientText) => clientText.locationId === locationId);

      return clientText && form.setFieldsValue({ [name]: clientText.body?.["en-US"] });
    });
  }, [client, textLocations]);

  const [inputLength, setInputLength] = useState<number>(
    assessment?.clientJobTitle ? assessment.clientJobTitle.length : 0,
  );
  const onInput = (value: string) => {
    const trimmedValue: string = value.slice(0, CLIENT_JOB_TITLE_MAX_LENGTH);
    form.setFieldsValue({ clientJobTitle: trimmedValue });
    setInputLength(trimmedValue.length);
  };

  const clientAbility = useContext(ClientAbilityContext);
  const canCreateAssessment = clientAbility.can(Action.Create, Subject.ASSESSMENT);
  const canUpdateAssessment = clientAbility.can(Action.Update, Subject.ASSESSMENT);

  useRouteAccessController({
    slug: NEW_ROUTE,
    hasPermission: canCreateAssessment,
  });

  if ((!assessment?.name && type === AssessmentActions.EDIT) || textLocationsLoading || jobRolesLoading) {
    return <Loading />;
  }

  const canEditFields =
    !assessment?.archivedOn &&
    isChangeable &&
    (type === AssessmentActions.NEW ? canCreateAssessment : canUpdateAssessment);

  return (
    <div className={tableStyle.tableBlock}>
      <TableHeader tableName="Assessment" hasSearchField={false} handleSearchChange={() => {}} />
      <Form
        form={form}
        name="assessmentMain"
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={getInitialAssessmentValues(textLocations, jobRolesList, assessment)}
        requiredMark={false}
      >
        <Row className={style.rowContainer} gutter={30}>
          <Col xxl={8} xl={8} md={12} xs={24} sm={24}>
            <div className={style.imageContainer}>
              <Form.Item validateFirst className={style.image} name="photo" getValueFromEvent={normFile}>
                <UploadPhoto
                  onUpload={setImageUrl}
                  disabled={!canEditFields}
                  clientID={String(clientId)}
                  defaultImageUrl={assessment?.titleImageUrl}
                  uploadFolder={UploadFolder.assessmentFolder}
                />
              </Form.Item>
            </div>
          </Col>

          <Col xxl={14} xl={14} md={12} xs={24} sm={24}>
            <Form.Item
              className="inputTitle"
              key="name"
              name="name"
              label="Assessment Name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Assessment Name",
                },
              ]}
            >
              <Input
                showCount
                placeholder="Type..."
                disabled={!canEditFields}
                maxLength={128}
                className={style.dropdown}
              />
            </Form.Item>
            <Form.Item
              className="inputTitle"
              key="role"
              name="role"
              label="Job Role"
              rules={[
                {
                  required: true,
                  message: "Please Enter Job Role",
                },
                () => ({
                  validator(_, value) {
                    const foundIndex = jobRolesList.findIndex((item) => item.name === value);
                    if (value && foundIndex === -1) {
                      return Promise.reject(new Error("Job Role should match the value from the options list"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AutoComplete
                options={jobRolesList}
                placeholder="Start type to select a job role..."
                disabled={!canEditFields}
                className={style.dropdown}
                filterOption={(inputValue, option) =>
                  option ? String(option.value).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                }
              />
            </Form.Item>
            <Form.Item
              className={cn("inputTitle", style.mdEditor)}
              data-length={`${inputLength} / ${CLIENT_JOB_TITLE_MAX_LENGTH}`}
              name="clientJobTitle"
              key="clientJobTitle"
              label="Company Job Title"
              rules={[
                {
                  required: true,
                  message: "Please Enter Company Job Title",
                },
              ]}
            >
              <AutoComplete
                options={clientJobTitleList}
                placeholder={"Type..."}
                disabled={!canEditFields}
                className={style.dropdown}
                filterOption={(inputValue, option) =>
                  option ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                }
                onChange={onInput}
              ></AutoComplete>
            </Form.Item>
            <Form.Item className="inputTitle" key="description" name="description" label="Assessment Description">
              <CustomInput
                className={style.dropdown}
                placeholder="Type..."
                disabled={!canEditFields}
                inputType={InputType.textarea}
                maxLength={1000}
              />
            </Form.Item>
          </Col>
        </Row>

        {textLocations.map((item: ICustomInput) => (
          <Row key={item.locationId}>
            <Col span={24}>
              <LocationText
                mode="edit"
                form={form}
                location={item.name}
                locationId={item.locationId}
                isChangeable={canEditFields}
              >
                {item.name}
              </LocationText>
            </Col>
          </Row>
        ))}

        <Row>
          <Col span={24}>
            <fieldset className={style.fieldset}>
              <legend>Notification Settings</legend>
              <div className={style.notificationSettingContainer}>
                <div className={style.switchContainer}>
                  <Form.Item name="enableStartReminder" valuePropName="checked">
                    <Switch id="enableStartReminder" disabled={!canEditFields} />
                  </Form.Item>
                  <div>
                    <label htmlFor="enableStartReminder">Email reminder for candidates to start their assessment</label>
                    <Form.Item
                      noStyle={true}
                      shouldUpdate={(prev, curr) => prev.enableStartReminder !== curr.enableStartReminder}
                    >
                      {(props) => {
                        if (props.getFieldValue("enableStartReminder") !== true) return null;
                        return (
                          <div className={style.daysContainer}>
                            <p aria-hidden="true">Send reminder after</p>
                            <Form.Item
                              name="startReminderDays"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "number", min: 1 },
                                { pattern: /^\d+$/, message: "Whole numbers only" },
                              ]}
                            >
                              <InputNumber
                                min={1}
                                disabled={!canEditFields}
                                aria-label="Days to wait before sending start reminder"
                              />
                            </Form.Item>
                            <p aria-hidden="true">days</p>
                          </div>
                        );
                      }}
                    </Form.Item>
                  </div>
                </div>

                <div className={style.switchContainer}>
                  <Form.Item name="enableFinishReminder" valuePropName="checked">
                    <Switch id="enableFinishReminder" disabled={!canEditFields} />
                  </Form.Item>
                  <div>
                    <label htmlFor="enableFinishReminder">
                      Email reminder for candidates to finish their assessment
                    </label>
                    <Form.Item
                      noStyle={true}
                      shouldUpdate={(prev, curr) => prev.enableFinishReminder !== curr.enableFinishReminder}
                    >
                      {(props) => {
                        if (props.getFieldValue("enableFinishReminder") !== true) return null;
                        return (
                          <div className={style.daysContainer}>
                            <p aria-hidden="true">Send reminder after</p>
                            <Form.Item
                              name="finishReminderDays"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "number", min: 1 },
                                { pattern: /^\d+$/, message: "Whole numbers only" },
                              ]}
                            >
                              <InputNumber
                                min={1}
                                disabled={!canEditFields}
                                aria-label="Days to wait before sending finish reminder"
                              />
                            </Form.Item>
                            <p aria-hidden="true">days</p>
                          </div>
                        );
                      }}
                    </Form.Item>
                  </div>
                </div>
              </div>
            </fieldset>
          </Col>
        </Row>

        <Row>
          <Col xxl={18} xl={18} lg={24} md={24} sm={24} xs={24}>
            {type === AssessmentActions.NEW && (
              <div className={style.buttonWithAlertContainer}>
                <CustomButton arrowAfter title="Create" isLoading={isLoading} disabled={!canEditFields} />
                <div className={style.alertContainer}>{getAlert(alert)}</div>
              </div>
            )}
            {type === AssessmentActions.EDIT && (
              <div className={style.buttonWithAlertContainer}>
                <CustomButton title="Save" isLoading={isLoading} disabled={!canEditFields}>
                  <SaveSVG aria-hidden="true" />
                </CustomButton>
                <div className={style.alertContainer}>{getAlert(alert)}</div>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}

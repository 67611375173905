import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tabs } from "antd";

import { Loading } from "@/shared/components/Loading/Loading";
import { useGetTestHook } from "@/shared/hooks/tests/useGetTestHook";
import { capitalizeFirstLetter } from "@/shared/utils/common.utils";
import { getRoute } from "@/shared/utils/route/route.utils";

import TableQuestions from "@/modules/tables/TableQuestions";

import {
  ADMIN_ROUTE,
  COMPETENCIES_ROUTE,
  TEST_INSTRUCTIONS_ROUTE,
  TEST_NORMS_ROUTE,
  TESTS_ROUTE,
} from "@/routing/AppRouter/routes.constants";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { TestCompetencies } from "../TestManagement/TestCompetencies";
import { TestNorms } from "../TestManagement/TestNorms/TestNorms";
import TestInstructions from "../TestInstructions/TestInstructions";

import style from "./style.module.css";

const { TabPane } = Tabs;

export default function TestSettings() {
  const { pathname } = useLocation();
  const { test_id: testId } = useParams();

  const DEFAULT_TEST_SETTINGS_ROUTE = `/${ADMIN_ROUTE}/${TESTS_ROUTE}/${testId}`;

  const { test, isLoading } = useGetTestHook(Number(testId));

  if (isLoading) {
    return <Loading />;
  }

  const pathList = pathname.split("/");
  //TODO: use reactRouter params.
  const activeTab = pathList.at(-1);

  return (
    <div className="main-container">
      <SEO titleParams={[test?.name, activeTab ? capitalizeFirstLetter(activeTab) : "Elements"]} />
      <div className="inner-container">
        <Banner title={String(test?.name)} />
        <div className={style.container}>
          <Tabs defaultActiveKey={activeTab}>
            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_TEST_SETTINGS_ROUTE, "")} className={style.tabLink}>
                  Elements
                </Link>
              }
              key="elements"
            >
              {test && <TableQuestions test={test} />}
            </TabPane>

            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_TEST_SETTINGS_ROUTE, COMPETENCIES_ROUTE)} className={style.tabLink}>
                  Competencies
                </Link>
              }
              key={COMPETENCIES_ROUTE}
            >
              <TestCompetencies />
            </TabPane>

            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_TEST_SETTINGS_ROUTE, TEST_NORMS_ROUTE)} className={style.tabLink}>
                  Norms
                </Link>
              }
              key={TEST_NORMS_ROUTE}
            >
              <TestNorms />
            </TabPane>

            <TabPane
              tab={
                <Link to={getRoute(DEFAULT_TEST_SETTINGS_ROUTE, TEST_INSTRUCTIONS_ROUTE)} className={style.tabLink}>
                  Instructions
                </Link>
              }
              key={TEST_INSTRUCTIONS_ROUTE}
            >
              <TestInstructions />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

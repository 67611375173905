import { useContext } from "react";

import { IUserAnswer } from "@/shared/components/assessment/report/ReportComponent/ReportComponent";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { isValidNumericParam, minToSec, secondsToHms } from "@/shared/utils/common.utils";
import { maybe } from "@/shared/utils/formaters/dataFormater/commonFormatter";

export default function useGetAssessmentTestSectionLogsHook(answers: IUserAnswer[]) {
  const { assessment } = useContext(AssessmentContext);

  const testStartSectionElements = assessment?.assessmentTests
    ?.sort((a, b) => sortElements(a.rank, b.rank))
    .map((assessmentTest) =>
      assessmentTest.test.testElements
        .sort((a, b) => sortElements(a.rank, b.rank))
        .filter((testElement) => testElement.config.startsSection)
        .map((element) => {
          return { ...element, testName: assessmentTest.test.name };
        }),
    );
  const timeElements = testStartSectionElements!
    .flat()
    .map(({ testId, testName, rank, config: { key, startsSection } }) => {
      const timeInSec = maybe(startsSection?.timeLimit, minToSec, "No Limit");
      return {
        testElementRank: rank,
        testId,
        key,
        sectionName: `${testName} - ${startsSection?.name}`,
        sectionTime: isValidNumericParam(Number(timeInSec)) ? secondsToHms(Number(timeInSec)) : timeInSec,
      };
    });

  const answerTestTimes = answers[0].answers.map(({ testId, times }) => {
    return { testId, times };
  });

  return timeElements.map((t) => {
    const usedTime = answerTestTimes.find((answersTestTime) =>
      answersTestTime.times ? answersTestTime.times[t.key] : undefined,
    )?.times![t.key].time;
    return { ...t, usedTime: maybe(usedTime, secondsToHms) };
  });
}

import { ITestElement, LanguagesIsoEnum } from "@/modules/assessments/types/test.interface";

import { ALL_SCALES_OPTION } from "@/shared/components/assessment/report/TestAnalysisFiltersBlock/TestAnalysisFilterBlock";
import { FilterKeys, IFilter } from "@/shared/components/assessment/report/TestAnalysisBlock/TestAnalysisBlock";
import { ITestChoice, QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";

import { TTestAnalysisAnswers } from "@/shared/contexts/analysis.context";

import { isSubstring } from "@/shared/utils/comparison/comparison";

import { getAnswersNames } from "@/ui-components/testElementComponents/TrueFalseRadioGroup/TrueFalseRadioGroup";

export function getChoicesDifference(initial: ITestChoice[], modified: ITestChoice[]): ITestChoice[] {
  return initial
    .map((initialEl) => {
      return modified.filter((modEl) => {
        if (modEl.elementId === initialEl.elementId) {
          return modEl.choiceKey !== initialEl.choiceKey;
        }
      });
    })
    .flat();
}

export function isAnswersDifferent(
  initial: TTestAnalysisAnswers | undefined,
  updated: TTestAnalysisAnswers | undefined,
): boolean {
  if (initial && updated) {
    const differentAnswers: ITestChoice[] = getChoicesDifference(initial.answers, updated.answers);
    const hasDifferentNormId: boolean = initial.normId !== updated.normId;
    return hasDifferentNormId || differentAnswers.length > 0;
  } else return false;
}

export function filterElements(
  testElements: ITestElement[],
  filterOption: IFilter,
  initialChoices: ITestChoice[],
  currentChoices: ITestChoice[],
): ITestElement[] {
  const choicesDifference = getChoicesDifference(initialChoices, currentChoices);
  const filterKeys = Object.keys(filterOption);
  return testElements.filter((item) => {
    return filterKeys.every((key) => {
      if (!filterOption[key]) return true;
      switch (key) {
        case FilterKeys.text:
          return (
            item.config.type !== QuestionTypes.Text &&
            (isSubstring(item.config.payload.question[LanguagesIsoEnum.enUS].toString(), String(filterOption[key])) ||
              item.config.payload.choices.find(({ choice }) =>
                isSubstring(
                  getAnswerByTestElementType(item.config.type, choice),
                  String(filterOption[key]).toLowerCase(),
                ),
              ))
          );
        case FilterKeys.scale:
          return (
            String(filterOption[key]) === ALL_SCALES_OPTION ||
            (item.config.type !== QuestionTypes.Text &&
              item.config.payload.choices.find(({ scales }) =>
                scales.find((scale) => scale === String(filterOption[key])),
              ))
          );
        case FilterKeys.isChanged:
          return choicesDifference.length > 0
            ? choicesDifference.find(({ elementId }) => item.elementId === elementId)
            : false;
        case FilterKeys.isUnanswered:
          const choice =
            currentChoices.length > 0
              ? currentChoices.find(({ elementId }) => item.elementId === elementId)
              : undefined;
          return choice ? !choice.choiceKey : false;
      }
    });
  });
}

function getAnswerByTestElementType(type: QuestionTypes, answer: string | boolean): string {
  switch (type) {
    case QuestionTypes.TrueFalse:
    case QuestionTypes.FollowNotFollow:
    case QuestionTypes.CorrectIncorrect:
    case QuestionTypes.MadeNotMade:
    case QuestionTypes.StrongWeak:
      return Boolean(answer)
        ? getAnswersNames().true[type][LanguagesIsoEnum.enUS]
        : getAnswersNames().false[type][LanguagesIsoEnum.enUS];
    default:
      return String(answer);
  }
}

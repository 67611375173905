import React, { ReactElement } from "react";
import cn from "classnames";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { AlignLeftOutlined, ClockCircleOutlined, PieChartOutlined } from "@ant-design/icons";

import { CandidateAssessmentViewTypes } from "@/modules/candidate/assessment/CandidateAssessment/CandidateAssessment";

import MarkdownPreviewCustom from "@/ui-components/commonComponents/MarkdownPreviewCustom/MarkdownPreviewCustom";
import ParagraphWithIcon from "@/ui-components/commonComponents/ParagraphWithIcon/ParagraphWithIcon";

import style from "./style.module.css";

export interface IWelcomeElement {
  type: CandidateAssessmentViewTypes;
  title?: string;
  description: string | undefined;
  children?: ReactElement<any, any> | null;
  testsQuantity?: number;
  questionsQuantity?: number;
  leftTime?: string;
  timerDisabled?: boolean;
}

export default function WelcomeElement({
  type,
  title,
  description,
  children,
  testsQuantity,
  questionsQuantity,
  leftTime,
  timerDisabled = false,
}: IWelcomeElement) {
  const { xxl, xl, lg } = useBreakpoint();
  const isDesktopView = xxl || xl || lg;

  return (
    <div className={cn({ [style.desktopContainer]: isDesktopView })}>
      <div
        className={cn(style.componentWrapper, {
          [style.finishComponent]: type === CandidateAssessmentViewTypes.finish,
        })}
      >
        <div
          className={cn(style.title, {
            [style.desktopTitle]: isDesktopView,
          })}
        >
          {title ? title : "Thank You"}
        </div>

        {type === CandidateAssessmentViewTypes.start && (
          <div className={style.mobileStatisticsContainer}>
            <ParagraphWithIcon
              text={`${testsQuantity ? testsQuantity : 0} ${testsQuantity && testsQuantity > 1 ? "tests" : "test"}`}
            >
              <PieChartOutlined aria-hidden="true" />
            </ParagraphWithIcon>

            <ParagraphWithIcon
              text={`${questionsQuantity ? questionsQuantity : 0} ${
                questionsQuantity && questionsQuantity > 1 ? "questions" : "question"
              }`}
            >
              <AlignLeftOutlined aria-hidden="true" />
            </ParagraphWithIcon>

            <ParagraphWithIcon text={timerDisabled ? "Untimed" : `${leftTime ? leftTime : 0}`}>
              <ClockCircleOutlined aria-hidden="true" />
            </ParagraphWithIcon>
          </div>
        )}
        <div className={cn(style.introOutroTexts, { [style.outroText]: type === CandidateAssessmentViewTypes.finish })}>
          <MarkdownPreviewCustom source={description} style={{ fontWeight: 400 }} />
        </div>
      </div>
      {type === CandidateAssessmentViewTypes.start && children && (
        <div className={style.desktopButtonContainer}>{children}</div>
      )}
    </div>
  );
}

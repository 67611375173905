import { Loading } from "@/shared/components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "@/shared/contexts/user.context";
import { ADMIN_ROUTE, CANDIDATE_ROUTE, CLIENT_ROUTE, SERVER_FAIL_ROUTE } from "@/routing/AppRouter/routes.constants";

export function AdminInitLogin({
  clientID,
  isClient,
  isAdmin,
  isLoading,
  isErroredState,
}: {
  isLoading: boolean;
  isErroredState: boolean;
  isAdmin: boolean;
  isClient: boolean;
  clientID?: number;
}) {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (isErroredState) {
      return navigate(SERVER_FAIL_ROUTE);
    }

    if (isLoading) {
      return;
    }

    if (isAdmin) {
      return navigate(`/${ADMIN_ROUTE}`);
    }

    if (isClient) {
      const clientRoute = clientID ? `/${CLIENT_ROUTE}/${clientID}` : `/${CLIENT_ROUTE}`;

      return navigate(clientRoute);
    }

    if (user) {
      return navigate(`/${CANDIDATE_ROUTE}/invites`);
    }

    return;
  }, [clientID, isClient, isAdmin, user, isLoading, isErroredState, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <p>You'll be redirected in a moment</p>
    </div>
  );
}

import { httpClient } from "@/shared/services/http";
import { IAssessmentInviteLog } from "@/shared/hooks/use-get-invite-logs";

export class InviteHttpService {
  static async getCandidateAnswersByInviteId(inviteId: number) {
    const { data: answers } = await httpClient.get(`/api/invites/${inviteId}/answers`);
    return answers;
  }

  static async getCandidateTestAnswersByInviteId(inviteId: number, testId: number) {
    const { data } = await httpClient.get(`/api/invites/${inviteId}/tests/${testId}/answers`);
    return data;
  }

  static async getAssessmentInviteLogs(inviteId: number): Promise<IAssessmentInviteLog[]> {
    const { data } = await httpClient.get(`/api/assessments/invites/${inviteId}/logs`);

    return data || [];
  }

  static async rescindInvites(assessmentId: string | undefined, inviteIds: number[]) {
    const { data } = await httpClient.post(`/api/assessments/${assessmentId}/invites/rescind`, { inviteIds });

    return data;
  }

  static async disableInviteTimer(assessmentId: string | number, inviteIds: Array<number>) {
    return httpClient.patch(`/api/assessments/${assessmentId}/invites/disable-timer`, { inviteIds });
  }

  static async rescoreInvites({
    sourceAssessmentId,
    targetAssessmentId,
    inviteIds,
  }: {
    sourceAssessmentId: number;
    targetAssessmentId: number;
    inviteIds: Array<number>;
  }) {
    return httpClient.post(`/api/assessments/${targetAssessmentId}/invites/rescore`, { sourceAssessmentId, inviteIds });
  }
}

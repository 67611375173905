import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addClients, updateSelectedClient } from "@/shared/services/store/clientStore";

import { IClient } from "@/modules/client/clients.interface";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";

export const CLIENT_ID = "CLIENT_ID";

export function useGetClientsHook(isStaff: boolean, isLoadingFirebase: boolean) {
  const dispatch = useDispatch();
  const { reduxClients, selectedClient: reduxPickedClientId } = useReduxClientsHook();

  const [clients, setClients] = useState<IClient[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const asyncFunction = async () => {
      try {
        setIsLoading(true);

        const clients: IClient[] = await ClientHttpService.getAllClients();
        const updateClientsWithKey: IClient[] = clients.map((item: IClient) => ({ ...item, key: item.clientId }));
        setClients(updateClientsWithKey);
        dispatch(addClients(updateClientsWithKey));
      } catch (e: any) {
        console.error(`allClientsError, ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoadingFirebase) return;

    if (isStaff && reduxClients.length === 0) {
      asyncFunction();
    } else {
      setClients(reduxClients);
      setIsLoading(false);
    }
  }, [isLoadingFirebase, reduxClients]);

  useEffect(() => {
    const localStorageClient = localStorage.getItem(CLIENT_ID);
    if (!reduxPickedClientId) {
      if (clients) {
        if (localStorageClient) {
          dispatch(updateSelectedClient(Number(localStorageClient)));
        } else if (clients.length !== 0) {
          dispatch(updateSelectedClient(clients[0].clientId));
          localStorage.setItem(CLIENT_ID, String(clients[0].clientId));
        }
      }
    }
  }, [clients, reduxPickedClientId]);

  return {
    isLoading,
    clients,
    reduxClients,
    selectedClient: reduxPickedClientId,
  };
}

import { useEffect, useState } from "react";

import { isValidNumericParam } from "@/shared/utils/common.utils";

import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";

import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";

export function useGetClientAssessmentInvitesStatsHook(assessmentId: number) {
  const [clientAssessmentInvitesStats, setClientAssessmentInvitesStats] = useState<
    Record<AssessmentInviteStatus, number>
  >({ in_progress: 0, accepted: 0, done: 0, invited: 0, started: 0, with_an_error: 0, rescinded: 0 });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchClientAssessmentInvitesStats = async () => {
    try {
      setIsLoading(true);

      const clientInvitesStats = await InvitesHttpService.getAssessmentInvitesStats(assessmentId);
      const assessmentInvitesStats = clientInvitesStats[assessmentId];

      assessmentInvitesStats && setClientAssessmentInvitesStats(assessmentInvitesStats);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isValidNumericParam(assessmentId)) {
      fetchClientAssessmentInvitesStats();
    }
  }, [assessmentId]);

  return { clientAssessmentInvitesStats, isLoading };
}

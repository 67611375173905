import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IAssessment } from "@/modules/assessments/assessments.types";
import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";

import { CANDIDATE_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useClientAssessmentHook(code: string | undefined, assessmentId: number | undefined) {
  const navigate = useNavigate();

  const [assessment, setAssessment] = useState<IAssessment>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!code) {
      // TODO: Move side effects outside of the hook
      setIsLoading(false);
      return navigate(`/${CANDIDATE_ROUTE}/invites`);
    }

    const fetchAssessment = async () => {
      if (!assessmentId) {
        return;
      }

      try {
        setIsLoading(true);

        const inviteAssessment = await AssessmentHttpService.getAssessmentByID(assessmentId);

        if (inviteAssessment) {
          setAssessment(inviteAssessment);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssessment();
  }, [assessmentId]);

  return { assessment, isLoading };
}

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { CANDIDATE_ROUTE, LOGIN_ROUTE } from "@/routing/AppRouter/routes.constants";

export interface IPrivateCandidateRouterProps {
  isAuthenticated: boolean;
}

export const PrivateCandidateRoute = ({ isAuthenticated }: IPrivateCandidateRouterProps) => {
  if (!isAuthenticated) {
    return <Navigate to={`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`} />;
  }

  return <Outlet />;
};

import React, { ChangeEvent } from "react";
import { Input } from "antd";

import "./custom-search.css";

interface ICustomSearch {
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
}

export default function CustomSearch({ onSearchChange, placeholder = "Search...", value = "" }: ICustomSearch) {
  return <Input.Search placeholder={placeholder} onChange={onSearchChange} value={value} />;
}

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useGetQueryCode(code: string, returnAll?: false): string | null;
export function useGetQueryCode(code: string, returnAll: true): Array<string> | null;

// Implementation
export function useGetQueryCode(code: string, returnAll = false): string | Array<string> | null {
  const { search } = useLocation();
  const value = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    if (returnAll) {
      const values = searchParams.getAll(code);
      return values.length > 0 ? values : null;
    } else {
      return searchParams.get(code);
    }
  }, [search, code, returnAll])

  return value
}
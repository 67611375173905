import { useEffect, useState } from "react";

import { Loading } from "@/shared/components/Loading/Loading";
import { TestAnswerStatusEnum } from "@/shared/services/tests/tests.types";

import { ITest } from "@/modules/assessments/types/test.interface";
import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";
import TableTests, { ITestTable } from "@/modules/tables/TableTests";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { useGetAllTestsHook } from "../hooks/useGetAllTests.hook";
import { useGetTestsStatsHook } from "../hooks/useGetTestsStats.hook";

export default function Tests() {
  const { tests, isLoading } = useGetAllTestsHook();
  const { testsStats, isLoading: isLoadingStats } = useGetTestsStatsHook();

  const { reduxClients } = useReduxClientsHook();

  const [dataList, setDataList] = useState<ITestTable[]>([]);

  useEffect(() => {
    const dataList: ITestTable[] = tests.map((test: ITest) => {
      const clientObject = reduxClients.find((client) => client.clientId === test.clientId);

      if (!testsStats) {
        return {
          key: test.testId,
          id: test.testId,
          name: test.name,
          client: clientObject?.name,
          questionsCount: 0,
          joinedAssessments: 0,
          finishedTestQuantity: 0,
          countOfNorms: 0,
          suggestedTime: 0,
          startsSections: [],
        };
      }

      return {
        key: test.testId,
        id: test.testId,
        name: test.name,
        questionsCount: testsStats.testsElements[test.testId]
          ? testsStats.testsElements[test.testId].countOfElements
          : 0,
        client: clientObject?.name,
        joinedAssessments: testsStats.assessmentTests[test.testId]
          ? testsStats.assessmentTests[test.testId].countOfAssessments
          : 0,
        finishedTestQuantity:
          testsStats.testsAnswers[test.testId] && testsStats.testsAnswers[test.testId][TestAnswerStatusEnum.Done]
            ? testsStats.testsAnswers[test.testId][TestAnswerStatusEnum.Done].countOfAnswers
            : 0,
        countOfNorms: testsStats.testsNorms[test.testId] ? testsStats.testsNorms[test.testId].countOfNorms : 0,
        suggestedTime: testsStats.testsTime[test.testId] ? testsStats.testsTime[test.testId].suggestedTime : 0,
        startsSections: testsStats.testsSections[test.testId] ?? [],
      };
    });

    setDataList(dataList);
  }, [tests, testsStats]);

  if (isLoading || isLoadingStats) {
    return <Loading />;
  }

  return (
    <div className="main-container">
      <SEO titleParams={["Tests"]} />
      <div className="inner-container">
        <TableTests dataList={dataList} />
      </div>
    </div>
  );
}

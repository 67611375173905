import React, { ChangeEvent, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import debounce from "lodash.debounce";
import cn from "classnames";

import { ReactComponent as PlusSVG } from "@/assets/icons/feather-icons/plus-16-white.svg";

import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { ITest } from "@/modules/assessments/types/test.interface";

import style from "./style.module.css";
import "./custom-table.css";

export interface IAssessmentTable {
  assessmentId: number;
  key: number;
  name: string;
  invited: number;
  inProgress: number;
  finished: number;
  tests: number;
  testNames: string[];
  questions: number;
  timing: string;
  createdAt: string;
  archivedOn?: Date | null;
}

export default function TableAssessments({
  dataList,
  onCreateAssessment,
  pageSize,
  currentPage,
  totalCount,
  setCurrentPage,
  onSortChange,
  sortOptions,
  loading,
  onAssessmentSearch,
  setPageSize,
  setTestIdsFilter,
  tests,
  onAssessmentClone,
}: {
  dataList: IAssessmentTable[];
  onCreateAssessment: () => void;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  setCurrentPage: (page: number) => void;
  onSortChange: (options: any) => void;
  sortOptions: { column: string | undefined; direction: string | undefined };
  loading: boolean;
  onAssessmentSearch: (q: string) => void;
  setPageSize: (size: number) => void;
  setTestIdsFilter: (value: number[]) => void;
  tests: ITest[];
  onAssessmentClone: (assessment: IAssessmentTable) => void;
}) {
  const ability = useContext(ClientAbilityContext);
  const canCreateAssessment = ability.can(Action.Create, Subject.ASSESSMENT);

  const assessmentColumns: ColumnsType<IAssessmentTable> = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      className: style.tableColumnName,
      width: "25%",
      ellipsis: true,
      sorter: true,
      // TODO: Sort icons don't work for some reason without setting it manually here, need to fix
      sortOrder:
        sortOptions?.direction && sortOptions?.column === "name"
          ? sortOptions?.direction === "DESC"
            ? "descend"
            : "ascend"
          : null,
      render: (value, record) => (
        <Tooltip title={record.name} placement={"topLeft"} overlayStyle={{ maxWidth: "75%", minWidth: "50px" }}>
          <Link
            to={
              !record.invited && canCreateAssessment ? `${record.assessmentId}/tests` : `${record.assessmentId}/invites`
            }
            className={style.tableLink}
          >
            <span style={{ textDecoration: record.archivedOn ? "line-through" : "unset" }}>{value}</span>
          </Link>
        </Tooltip>
      ),
    },
    {
      key: "invited",
      title: "Invited",
      dataIndex: "invited",
      className: style.tableColumn,
      sorter: true,
      sortOrder:
        sortOptions?.direction && sortOptions?.column === "invited"
          ? sortOptions?.direction === "DESC"
            ? "descend"
            : "ascend"
          : null,
    },
    {
      key: "in_progress",
      title: "In Progress",
      dataIndex: "inProgress",
      className: style.tableColumn,
      sorter: true,
      sortOrder:
        sortOptions?.direction && sortOptions?.column === "in_progress"
          ? sortOptions?.direction === "DESC"
            ? "descend"
            : "ascend"
          : null,
    },
    {
      key: "done",
      title: "Finished",
      dataIndex: "finished",
      className: style.tableColumn,
      sorter: true,
      sortOrder:
        sortOptions?.direction && sortOptions?.column === "done"
          ? sortOptions?.direction === "DESC"
            ? "descend"
            : "ascend"
          : null,
    },
    {
      key: "tests",
      title: "Tests",
      dataIndex: "tests",
      width: "15%",
      render: (value, record) => {
        const shownValue = value ? `${value} (${record.testNames.join(", ")})` : value;
        return (
          <Tooltip overlay={shownValue}>
            <span>{shownValue}</span>
          </Tooltip>
        );
      },
      className: cn(style.tableColumn, style.ellipsisContent),
      filters: tests.map(({ testId, name }) => ({ text: name, value: testId })),
    },
    {
      key: "questions",
      title: "Questions",
      dataIndex: "questions",
      className: style.tableColumn,
    },
    {
      key: "timing",
      title: "Timing (min)",
      dataIndex: "timing",
      className: style.tableColumn,
    },
    {
      key: "createdAt",
      title: "Created On",
      dataIndex: "createdAt",
      className: style.tableColumn,
      sorter: true,
      sortOrder:
        sortOptions?.direction && sortOptions?.column === "createdAt"
          ? sortOptions?.direction === "DESC"
            ? "descend"
            : "ascend"
          : null,
      render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    },
  ];

  const actionsColumn = {
    key: "actions",
    title: "Actions",
    dataIndex: "",
    className: style.tableColumn,
    render: (_: any, record: IAssessmentTable) => (
      <div>
        <Button style={{ fontWeight: "bolder", textTransform: "uppercase" }} onClick={() => onAssessmentClone(record)}>
          Clone
        </Button>
      </div>
    ),
  };

  const handleSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onAssessmentSearch(target.value);
  };

  return (
    <div className={cn(style.tableBlock, "table-assessments")}>
      <TableHeader tableName="Assessments" handleSearchChange={debounce(handleSearchChange, 500)}>
        <>
          {canCreateAssessment && (
            <CustomButton title="New Assessment" onClick={onCreateAssessment} buttonSize={ButtonSize.small}>
              <PlusSVG aria-hidden="true" />
            </CustomButton>
          )}
        </>
      </TableHeader>
      <Table
        columns={canCreateAssessment ? [...assessmentColumns, actionsColumn] : assessmentColumns}
        loading={loading}
        dataSource={dataList}
        scroll={{ x: "1200px" }}
        style={{ width: "100%" }}
        onChange={(pagination, filters, sorter: any) => {
          const direction =
            typeof sorter.order === "undefined" ? sorter.order : sorter.order === "ascend" ? "ASC" : "DESC";

          onSortChange({ direction, column: sorter.columnKey });
          setTestIdsFilter(filters.tests as number[]);
        }}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          total: totalCount,
          showSizeChanger: true,
          current: currentPage,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
          },
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
}

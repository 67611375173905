import React, { useState } from "react";
import cn from "classnames";
import { Form, FormInstance } from "antd";
import MDEditor from "@uiw/react-md-editor";

import { CustomInput, InputType } from "@/ui-components/commonComponents/Input/Input";

import style from "@/shared/components/assessment/AboutAssessment/style.module.css";
import "@/shared/components/assessment/AboutAssessment/index.css";

const INPUT_MAX_LENGTH: number = 1000;

export default function LocationText({
  children,
  mode,
  location,
  locationId,
  isChangeable = true,
  form,
}: {
  children: string;
  mode: "view" | "edit";
  location: string;
  locationId: number;
  isChangeable?: boolean;
  form: FormInstance;
}) {
  const initialValue: string | null = form.getFieldValue(location);
  const value: string = initialValue === null ? "" : initialValue;

  const [inputLength, setInputLength] = useState<number>(value.length);
  const onInput = (value: string | undefined) => {
    form.setFieldsValue({ [location]: value });
    setInputLength(String(value).length);
  };

  return mode === "edit" ? (
    <Form.Item
      className={cn("inputTitle", style.mdEditor)}
      data-length={`${inputLength} / ${INPUT_MAX_LENGTH}`}
      key={locationId}
      name={location}
      label={location}
    >
      {!isChangeable ? (
        <CustomInput placeholder={"Type..."} inputType={InputType.textarea} disabled />
      ) : (
        <MDEditor
          highlightEnable={false}
          textareaProps={{ maxLength: INPUT_MAX_LENGTH }}
          placeholder="Type..."
          value={value}
          onChange={onInput}
        />
      )}
    </Form.Item>
  ) : (
    <p>{children}</p>
  );
}

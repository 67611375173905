import { Badge, Radio } from "antd";

import { IChoice } from "@/modules/assessments/types/test.interface";

import { TestElementMode } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { getScaleRepresentation, getUniqueKeysValues } from "@/shared/components/testManagement/TestElementInfoBlock";

import { findKeys } from "@/ui-components/testElementComponents/StatementsRatingGroup/StatementsRatingGroup";
import { badgeColor } from "@/ui-components/testElementComponents/TrueFalseRadioGroup/TrueFalseRadioGroup";

import "./LikelyTrueFalseRadioGroupe.css";

interface ILikelyTrueFalseRadioGroup {
  id: number;
  mode?: TestElementMode;
  currentAnswer: string | boolean | undefined;
  choices: IChoice[];
  onRadioChange: (e: any) => void;
}

enum LikelyTrueFalseChoices {
  C1 = "T",
  C2 = "LT",
  C3 = "LI",
  C4 = "LF",
  C5 = "F",
}

export function LikelyTrueFalseRadioGroup({
  id,
  mode = TestElementMode.Candidate,
  currentAnswer,
  choices,
  onRadioChange,
}: ILikelyTrueFalseRadioGroup) {
  const showBadge: boolean = mode === TestElementMode.Admin;

  return (
    <div className="testElementContainer" style={{ width: showBadge ? "60%" : "100%" }}>
      {showBadge && (
        <div className="badges">
          {choices.map(({ key, scales }, index) => (
            <Badge
              key={key}
              count={scales.length !== 0 ? `+${getScaleRepresentation(getUniqueKeysValues([scales]))}` : 0}
              offset={[20, -10]}
              color={badgeColor}
            >
              <p style={{ width: index === 0 || 4 ? 30 : index === 1 || 3 ? 24 : 18 }}></p>
            </Badge>
          ))}
        </div>
      )}

      <Radio.Group
        id={String(id)}
        name={String(id)}
        className="radioGroup"
        style={{ marginTop: showBadge ? 0 : "2em" }}
        onChange={onRadioChange}
        value={String(currentAnswer)}
      >
        <Radio.Button
          id={findKeys(choices, LikelyTrueFalseChoices.C1)}
          value={LikelyTrueFalseChoices.C1}
          className="large"
        />
        <Radio.Button
          id={findKeys(choices, LikelyTrueFalseChoices.C2)}
          value={LikelyTrueFalseChoices.C2}
          className="medium"
        />
        <Radio.Button
          id={findKeys(choices, LikelyTrueFalseChoices.C3)}
          value={LikelyTrueFalseChoices.C3}
          className="small"
        />
        <Radio.Button
          id={findKeys(choices, LikelyTrueFalseChoices.C4)}
          value={LikelyTrueFalseChoices.C4}
          className="medium"
        />

        <Radio.Button
          id={findKeys(choices, LikelyTrueFalseChoices.C5)}
          value={LikelyTrueFalseChoices.C5}
          className="large"
        />
      </Radio.Group>
      <div className="radioGroup textGroup" style={{ marginTop: "25px" }}>
        <p className="largeParagraph">{LikelyTrueFalseChoices.C1}</p>
        <p className="mediumParagraph">{LikelyTrueFalseChoices.C2}</p>
        <p className="smallParagraph">{LikelyTrueFalseChoices.C3}</p>
        <p className="mediumParagraph">{LikelyTrueFalseChoices.C4}</p>
        <p className="largeParagraph">{LikelyTrueFalseChoices.C5}</p>
      </div>
    </div>
  );
}

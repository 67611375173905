import React from "react";
import { Form } from "antd";
import cn from "classnames";

import { Banner } from "@/ui-components/commonComponents/Banner/Banner";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CustomInput, InputType, ISelectOption } from "@/ui-components/commonComponents/Input/Input";

import style from "./style.module.css";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export function AddNewCharacter({
  title,
  formName,
  isLoading = false,
  instructions,
  onSubmit,
  inputType = InputType.input,
  inputProps,
  disabled,
  buttonProps,
}: {
  title: string;
  formName: string;
  isLoading?: boolean;
  disabled?: boolean;
  instructions?: { description: string; items: string[] };
  onSubmit: (value: any) => void;
  inputType?: InputType.input | InputType.textarea;
  isEmail?: boolean;
  inputProps: {
    name: string;
    label: string;
    errorText: string;
    required: boolean;
    isEmail?: boolean;
    initialValue?: string | number;
    selectOptions?: ISelectOption[];
    inputType?: InputType.input | InputType.textarea | InputType.select;
  }[];
  buttonProps: {
    title: string;
  };
}) {
  const [form] = Form.useForm();

  return (
    <div className={style.background}>
      <div
        className={cn(style.mainContainer, {
          [style.candidatesContainer]: inputType === InputType.textarea,
        })}
      >
        <div className={style.innerContainer}>
          <Banner title={title} />

          {instructions && (
            <div className={style.instructions}>
              <p>{instructions.description}</p>
              <ul style={{ textAlign: "left" }}>
                {instructions.items.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          <Form form={form} name={formName} {...formItemLayout} onFinish={onSubmit} requiredMark={false}>
            {inputProps.map(({ initialValue, name, label, required, errorText, isEmail, selectOptions, ...input }) => (
              <Form.Item
                className={style.inputTitle}
                key={name}
                name={name}
                initialValue={initialValue}
                label={label}
                rules={[
                  {
                    type: isEmail ? "email" : "string",
                    required: required,
                    message: errorText,
                  },
                ]}
              >
                <CustomInput
                  verboseMode
                  disabled={disabled}
                  placeholder="Type..."
                  selectOptions={selectOptions}
                  inputType={input.inputType || inputType}
                />
              </Form.Item>
            ))}

            <div className={style.buttonContainer}>
              <CustomButton title={buttonProps.title} isLoading={isLoading} disabled={disabled} />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

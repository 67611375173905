import { useEffect, useState } from "react";

import { JobRolesHttpService } from "../services/JobRolesHttpService";

export interface IJobRole {
  roleid: number;
  name: string;
  value?: string;
}

export function useAllJobRolesHook() {
  const [jobRolesList, setJobRolesList] = useState<IJobRole[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const jobRoles = await JobRolesHttpService.getAllJobRoles();
        const upd: IJobRole[] = jobRoles.map((item: { roleId: number; name: string }) => ({
          value: item.name,
          name: item.name,
          roleid: item.roleId,
        }));
        setJobRolesList(upd);
        setIsLoading(false);
      } catch (e: any) {
        console.error(`all job roles error: ${e}`);
      }
    })();
  }, []);

  return { jobRolesList, isLoading };
}

import * as React from "react";
import { ReactElement } from "react";
import { Modal } from "antd";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import "./index.css";

export function BasicModal({
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  autoHeight,
  footer,
  isClosable = true,
}: {
  isModalOpen?: boolean;
  children?: ReactElement<any, any>;
  handleOk?: (() => void) | undefined;
  handleCancel?: (() => void) | undefined;
  autoHeight?: boolean;
  footer?: React.ReactNode;
  isClosable?: boolean;
}) {
  return (
    <Modal
      className={!autoHeight ? "modalContainer basicModalContainer" : "basicModalContainer"}
      closable={isClosable}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      footer={footer ?? [<CustomButton key="cancel" title="close" onClick={handleCancel} />]}
    >
      {children}
    </Modal>
  );
}

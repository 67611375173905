import React, { useContext } from "react";
import cn from "classnames";

import TestElement, {
  ITestChoice,
  QuestionFontSize,
  QuestionTypes,
  TestElementMode,
} from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { AnalysisContext, TTestAnalysisAnswers } from "@/shared/contexts/analysis.context";
import { isAnswersDifferent } from "@/shared/components/assessment/report/utils/analysisUtils";

import { IChoice, IQuestion } from "@/modules/assessments/types/test.interface";

import style from "../style.module.css";

export function TestElementAnalysisComponent({
  elementId,
  testId,
  disableChoices = false,
  type,
  choices,
  text,
  initialAnswer,
  testAnswer,
  required = false,
}: {
  elementId: number;
  testId: number;
  disableChoices?: boolean;
  type: QuestionTypes;
  choices: IChoice[];
  text: IQuestion;
  required?: boolean;
  initialAnswer?: TTestAnalysisAnswers;
  testAnswer?: TTestAnalysisAnswers;
}) {
  const { updatedTestsAnswers, setUpdatedTestsAnswers, setHasChangedElements } = useContext(AnalysisContext);

  const initialAnswerKey: string | undefined = initialAnswer?.answers.find(
    (answer) => answer.elementId === elementId,
  )?.choiceKey;
  const currentAnswerKey: string | undefined = testAnswer?.answers.find(
    (answer) => answer.elementId === elementId,
  )?.choiceKey;

  const handleChoiceSelect = async (value: ITestChoice) => {
    const answersWithoutModifiedAnswer = testAnswer?.answers.filter((answer) => answer.elementId !== value.elementId);
    if (answersWithoutModifiedAnswer) {
      const updatedAnswers = [...answersWithoutModifiedAnswer, value];
      const other = updatedTestsAnswers.filter((ans) => ans.testId !== testId);
      const current: TTestAnalysisAnswers = {
        testId,
        answers: updatedAnswers,
        times: testAnswer?.times || null,
        normId: Number(testAnswer?.normId),
      };
      if (setUpdatedTestsAnswers) {
        setUpdatedTestsAnswers([...other, current]);
      }
      if (setHasChangedElements) {
        setHasChangedElements(isAnswersDifferent(initialAnswer, current));
      }
    }
  };

  return (
    <div
      className={cn(style.analysisTestElement, {
        [style.changedTestElement]: initialAnswerKey !== currentAnswerKey,
        [style.requiredTestElement]: initialAnswerKey === currentAnswerKey && required,
      })}
    >
      <TestElement
        mode={TestElementMode.Admin}
        disableChoices={disableChoices}
        choices={choices}
        id={elementId}
        questionType={type}
        currentAnswer={currentAnswerKey}
        question={text}
        questionFontSize={QuestionFontSize.xsmall}
        onChoiceSelect={handleChoiceSelect}
      />
    </div>
  );
}

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "../style.module.css";

export function TimerErrorModalFooter({ handleOk }: { handleOk?: () => void }) {
  return (
    <div className={style.actionButtons}>
      <CustomButton key="ok" title="refresh page" onClick={handleOk} />
    </div>
  );
}

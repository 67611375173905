import { useEffect, useState } from "react";

import { ITest, ITestElement } from "@/modules/assessments/types/test.interface";

import { getIfPrevQuestionNewTest, isAllRequiredQuestionsAnswered } from "../utils/candidate-assessment-view.utils";
import { ITestChoice } from "@/shared/components/candidate/scenes/TestElement/TestElement";

export function useNavigationButtonsHook({
  currentPage,
  questionsList,
  currentAnswers,
  test,
}: {
  currentPage: number;
  currentAnswers: ITestChoice[];
  questionsList: ITestElement[][];
  test?: ITest;
}) {
  const [isAllowToGoNext, setIsAllowToGoNext] = useState<boolean>(false);
  const [isAllowToGoBack, setIsAllowToGoBack] = useState<boolean>(false);

  useEffect(() => {
    const requiredQuestionsAnswered = isAllRequiredQuestionsAnswered(currentAnswers, questionsList, currentPage);

    setIsAllowToGoNext(requiredQuestionsAnswered || false);
  }, [currentPage, currentAnswers]);

  useEffect(() => {
    const ifPrevQuestionNewTest = getIfPrevQuestionNewTest(questionsList, currentPage);

    setIsAllowToGoBack((!ifPrevQuestionNewTest && test?.config.backButton) || false);
  }, [currentPage, test]);

  return {
    isAllowToGoBack,
    isAllowToGoNext,
  };
}

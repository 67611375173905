import { combineReducers, configureStore } from "@reduxjs/toolkit";

import rolesReducer from "./rolesStore";
import clientsReducer from "./clientStore";
import competenciesReducer from "./competenciesStore";

const rootReducer = combineReducers({
  clients: clientsReducer,
  roles: rolesReducer,
  competencies: competenciesReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Type of the store itself
export type AppStore = typeof store;

import * as React from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";

import { Loading } from "@/shared/components/Loading/Loading";
import { useGetTestNormsHook } from "../../hooks/use-get-test-norms.hook";

import { NormScales } from "./NormScales";
import { normColumns } from "./norm-table-columns";

import style from "../../TestSettings/style.module.css";

export function TestNorms() {
  const { test_id: testId } = useParams();

  const { norms, isLoading } = useGetTestNormsHook(Number(testId));

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={style.innerTabsContainer}>
      <Table
        columns={normColumns}
        dataSource={norms}
        rowKey="normId"
        expandable={{
          expandedRowRender: (record) => <NormScales normId={record.normId} key={record.normId} />,
        }}
      />
    </div>
  );
}

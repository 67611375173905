import { FirebaseOptions } from "firebase/app";

import { INIT_LOGIN_ROUTE, CANDIDATE_ROUTE } from "@/routing/AppRouter/routes.constants";

export const SIGN_IN_SUCCESS_URL_ADMIN = `/${INIT_LOGIN_ROUTE}`;
export const SIGN_IN_SUCCESS_URL_CANDIDATE = `/${CANDIDATE_ROUTE}/${INIT_LOGIN_ROUTE}`;

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

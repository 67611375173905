import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import style from "./style.module.css";
import cn from "classnames";

export enum LoadingSize {
  fullPage = "fullPage",
  default = "default",
  small = "small",
}

const Loading = ({ size = LoadingSize.fullPage }: { size?: LoadingSize }) => {
  return (
    <div
      className={cn(style.loaderContainer, {
        [style.defaultContainer]: size === LoadingSize.default,
        [style.smallContainer]: size === LoadingSize.small,
      })}
    >
      <Spin
        indicator={
          <LoadingOutlined
            className={cn(style.loader, {
              [style.defaultLoader]: size === LoadingSize.default,
              [style.smallLoader]: size === LoadingSize.small,
            })}
            spin
          />
        }
      />
    </div>
  );
};

export { Loading };

import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { CANDIDATE_ROUTE, LOGIN_ROUTE } from "@/routing/AppRouter/routes.constants";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "./style.module.css";

export interface IMainPageProps {}

const MainPage: React.FunctionComponent<IMainPageProps> = (props) => {
  const navigate = useNavigate();

  if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test") {
    return <Navigate to={`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`} replace={true} />;
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1>THIS IS THE MAIN PAGE</h1>
        <div className={style.buttonGroup}>
          <div className={style.buttonContainer}>
            <CustomButton title={"Sign in as staff"} onClick={() => navigate(LOGIN_ROUTE)} />
          </div>
          <div className={style.buttonContainer}>
            <CustomButton
              title={"Sign in as candidate"}
              onClick={() => navigate(`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { MainPage };

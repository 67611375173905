import { createContext } from "react";

import { ITestElement } from "@/modules/assessments/types/test.interface";

export interface ICurrentPageContext {
  currentPage: number;
  questionsList: ITestElement[][];
}

export const CurrentPageContext = createContext<ICurrentPageContext>({
  currentPage: 0,
  questionsList: [],
});

import { ElementType } from "react";
import DOMPurify from "dompurify";

interface IRenderedHtml {
  children: string | Node;
  tag?: ElementType;
}

export default function RenderedHtml({ children, tag: Tag = "div", ...rest }: IRenderedHtml) {
  if (!children) return null;

  return (
    <Tag
      {...rest}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children),
      }}
    />
  );
}

import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

import { ASSESSMENT_EDIT_INVITES_ROUTE, ASSESSMENT_ROUTE, CLIENT_ROUTE } from "@/routing/AppRouter/routes.constants";

import { AssessmentHttpService } from "@/modules/assessments/services/assessment-http.service";
import { CandidateUtils } from "@/modules/assessments/scenes/AddCandidates/candidate.utils";

import { AddNewCharacter } from "@/shared/components/AddNew/AddNewCharacter";

import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import { useValidateClientAccessHook } from "@/shared/hooks/access/useValidateClientAccess.hook";

import { InputType } from "@/ui-components/commonComponents/Input/Input";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

const inviteInstructions = {
  description: "In the text field put one or more candidate's data in one of these formats:",
  items: ["Email;FullName", "Email"],
};

export default function AddCandidate() {
  const { client_id: clientId, assessment_id: assessmentId } = useParams<{
    client_id: string;
    assessment_id: string;
  }>();
  useValidateClientAccessHook(String(clientId));

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInviteCandidate = async (value: { candidatesData: string }) => {
    setIsLoading(true);

    try {
      const candidatesCsvFile = await CandidateUtils.fromTextToCsvCandidates(value.candidatesData);

      await AssessmentHttpService.inviteToAssessment(Number(assessmentId), candidatesCsvFile);
      notification.success({
        message: (
          <>
            <p>Invites sent. Invites might take a few seconds to process.</p>
          </>
        ),
      });

      setTimeout(() => {
        navigate(`/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${assessmentId}/${ASSESSMENT_EDIT_INVITES_ROUTE}`);
      }, 3000);
    } catch (e: any) {
      notification.error({ message: `You cannot invite a candidate to a non-existent assessment` });
    } finally {
      setIsLoading(false);
    }
  };
  const { assessment } = useContext(AssessmentContext);

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canCreateAssessmentInvite = assessmentAbility.can(Action.Create, Subject.ASSESSMENT_INVITE);

  return (
    <>
      <SEO titleParams={[assessment?.name, "InviteCandidates"]} />
      <AddNewCharacter
        isLoading={isLoading}
        disabled={!canCreateAssessmentInvite}
        title="Invite Candidates"
        formName="inviteCandidates"
        inputType={InputType.textarea}
        onSubmit={handleInviteCandidate}
        instructions={inviteInstructions}
        inputProps={[
          {
            name: "candidatesData",
            label: "Candidates data",
            errorText: "Input candidate's data in one or required formats",
            required: true,
          },
        ]}
        buttonProps={{
          title: "Send invite",
        }}
      />
    </>
  );
}

import React, { useState } from "react";
import logo from "@/assets/image/psp-logo.svg";
import style from "./style.module.css";
import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { notification } from "antd";
import { UserInviteConfirmationHttpService } from "./services/user-invite-confirmation.service";
import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";

export function AcceptInviteConfirmation() {
  const email = String(useGetQueryCode("email"));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (email: string) => {
    try {
      setIsSubmitting(true);
      await UserInviteConfirmationHttpService.acceptUserInvite(email);
      setIsSubmitting(false);
      notification.success({
        message:
          "Invite Accepted! If your account exists, you will receive an email with further instructions on setting up your account.",
      });
    } catch {
      setIsSubmitting(false);
      notification.error({
        message: "Oops, something went wrong. Please try again or contact support for help.",
      });
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.logo}>
        <img src={logo} className={style.logoImage} alt="PSP Metrics" />
      </div>
      <div className={style.container}>
        <h1 className={style.title}>Accept Your Invitation</h1>
        <p className={style.text}>
          If you have an account, you'll receive an email requesting you to create a new password for your account.
        </p>
        <p className={style.text}>
          Once requested, you'll have about an hour to complete the process before the link becomes invalid.
        </p>
        <div className={style.buttonWrapper}>
          <CustomButton title="Accept Invite" isLoading={isSubmitting} onClick={() => handleSubmit(email)} />
        </div>
      </div>
    </div>
  );
}

import { IClient } from "../clients.interface";

export class ReduxClientService {
  static updateClientService(reduxClients: IClient[], updatedClient: IClient) {
    return reduxClients.map((client) => {
      if (updatedClient.clientId === client.clientId) {
        return updatedClient;
      }

      return client;
    });
  }
}

import { Progress } from "antd";

import { useProgressHook } from "./useProgress.hook";

import styles from "./styles.module.css";

import "./antd.css";

export function AssessmentProgress() {
  const assessmentProgresses = useProgressHook();

  return (
    <div className={styles.progressWrapper}>
      {assessmentProgresses.map(({ testColor, percentage, testId }) => (
        <Progress
          key={testId}
          percent={percentage}
          strokeColor={testColor}
          trailColor={`${testColor}50`}
          showInfo={false}
          strokeWidth={6}
          strokeLinecap="square"
          className={styles.progressElement}
        />
      ))}
    </div>
  );
}

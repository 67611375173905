import * as React from "react";

import { Select } from "antd";
import Input, { InputProps as AntInputProps } from "antd/lib/input/index";

export enum InputType {
  "textarea",
  "password",
  "input",
  "select",
}

export type ISelectOption = { value: string; name: string; description?: string };

export type InputProps = AntInputProps & {
  inputType?: InputType;
  title?: string;
  placeholder?: string;
  selectOptions?: ISelectOption[];
  defaultValue?: string;
  textAreaMinHeight?: number;
  maxLength?: number;
  verboseMode?: boolean;
};

export const CustomInput: React.FunctionComponent<InputProps> = ({
  inputType,
  title,
  defaultValue,
  textAreaMinHeight = 60,
  maxLength,
  selectOptions,
  verboseMode,
  ...props
}: any) => {
  switch (inputType) {
    case InputType.textarea:
      return (
        <div>
          <Input.TextArea
            {...props}
            showCount={Boolean(maxLength)}
            maxLength={maxLength}
            style={{ minHeight: textAreaMinHeight }}
            className={String(props.className)}
            defaultValue={defaultValue}
          />
        </div>
      );
    case InputType.password:
      return (
        <div>
          <Input.Password
            {...props}
            className={String(props.className)}
            showCount={Boolean(maxLength)}
            maxLength={maxLength}
          />
        </div>
      );
    case InputType.select:
      return (
        <Select
          {...props}
          className={String(props.className)}
          defaultValue={selectOptions[0] ? selectOptions[0].value : ""}
          style={{ backgroundColor: "#fff" }}
        >
          {selectOptions.map(({ name, value, description }: ISelectOption) => (
            <Select.Option value={value} key={value} title={verboseMode ? undefined : description}>
              {name} {verboseMode && description && <span>- {description}</span>}
            </Select.Option>
          ))}
        </Select>
      );
    case InputType.input:
    default:
      return (
        <div>
          <Input
            {...props}
            style={
              maxLength
                ? {
                    fontSize: "14px",
                    border: "none",
                    padding: "0.86em 1.14em",
                    borderRadius: "0.25em",
                  }
                : null
            }
            className={String(props.className)}
            defaultValue={defaultValue}
            showCount={Boolean(maxLength)}
            maxLength={maxLength}
          />
        </div>
      );
  }
};

import { useEffect, useRef, useState } from "react";

export function useCacheResult<T>(
  cacheKey: string,
  fetchFunction: Function,
  functionParams: (T | undefined)[] = [],
  useEffectParams: (T | undefined)[] = [],
  canExecute = true,
) {
  const cache = useRef<{ [key: string | symbol]: any }>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!fetchFunction || !canExecute) {
      return;
    }

    if (
      functionParams &&
      functionParams.findIndex((param) => {
        if (param === undefined) {
          return true;
        }

        if (typeof param === "number") {
          return isNaN(param);
        }
      }) !== -1
    ) {
      return;
    }

    const fetchData = async () => {
      if (cache.current[cacheKey]) {
        return;
      }

      try {
        setIsLoading(true);
        const data = await fetchFunction(...(functionParams?.length ? functionParams : []));
        cache.current[cacheKey] = data;
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [...useEffectParams, canExecute]);

  return { isLoading, data: cache.current[cacheKey] };
}

import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useGetQueryCode } from "@/shared/hooks/use-get-query-code.hook";
import { UserContext } from "@/shared/contexts/user.context";
import { Loading } from "@/shared/components/Loading/Loading";
import {
  ASSESSMENT_CODE_SEARCH,
  ASSESSMENT_ID_SEARCH,
  ASSESSMENT_ROUTE,
  CANDIDATE_ROUTE,
  SELF_REGISTER_ROUTE,
  SERVER_FAIL_ROUTE,
} from "@/routing/AppRouter/routes.constants";

export function CandidateInitLogin({ isErroredState }: { isErroredState: boolean }) {
  const navigate = useNavigate();

  const { user, isLoading } = useContext(UserContext);
  const code = useGetQueryCode(ASSESSMENT_CODE_SEARCH);
  const assessmentId = useGetQueryCode(ASSESSMENT_ID_SEARCH);

  useEffect(() => {
    if (isErroredState) {
      return navigate(SERVER_FAIL_ROUTE);
    }

    if (isLoading) {
      return;
    }

    if (user) {
      const candidateUrl = code ? `/${CANDIDATE_ROUTE}/invites/${code}` : `/${CANDIDATE_ROUTE}/invites`;
      const selfRegisterAssessmentUrl = `/${ASSESSMENT_ROUTE}/${assessmentId}/${SELF_REGISTER_ROUTE}`;

      return navigate(assessmentId ? selfRegisterAssessmentUrl : candidateUrl);
    }

    return;
  }, [user, isLoading, isErroredState, navigate, code, assessmentId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <p>You'll be redirected in a moment</p>
    </div>
  );
}

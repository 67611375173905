import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { InviteHttpService } from "@/modules/assessments/services/invite-http.service";
import { ITestAnswer } from "@/shared/components/charts/types/chartTypes";
import { isValidNumericParam } from "@/shared/utils/common.utils";
import { NOT_FOUND_ROUTE } from "@/routing/AppRouter/routes.constants";

export function useGetAnswersByIdHook() {
  const [answers, setAnswers] = useState<ITestAnswer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAnswers = useCallback(async (inviteId: number): Promise<ITestAnswer[] | undefined> => {
    if (!isValidNumericParam(inviteId)) {
      return [];
    }
    try {
      setIsLoading(true);
      const inviteAnswers = await InviteHttpService.getCandidateAnswersByInviteId(inviteId);
      setAnswers(inviteAnswers);
      return inviteAnswers;
    } catch (e: any) {
      console.warn("Get answers error: ", e);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return {
    answers,
    fetchAnswers,
    isLoading,
  };
}

export function useGetCandidateAnswersHook(inviteId: number) {
  const navigate = useNavigate();

  const { fetchAnswers, answers, isLoading } = useGetAnswersByIdHook();

  useEffect(() => {
    if (isValidNumericParam(inviteId)) {
      fetchAnswers(inviteId);
    } else {
      navigate(`/${NOT_FOUND_ROUTE}`);
    }
  }, [inviteId, navigate, fetchAnswers]);

  return { answers, isLoading };
}

export function useGetAllCandidateAnswersHook(inviteId: number, testIds: number[] | undefined) {
  const [answers, setAnswers] = useState<{ testId: number; answer: ITestAnswer }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        setIsLoading(true);
        if (testIds) {
          const answersFromAPI: ({ testId: number; answer: ITestAnswer } | undefined)[] = await Promise.all(
            testIds.map(async (testId) => {
              const answer = await InviteHttpService.getCandidateTestAnswersByInviteId(inviteId, testId).catch(
                () => null,
              );
              if (!answer) return;
              return {
                testId: testId,
                answer,
              };
            }),
          );
          setAnswers(answersFromAPI.filter(Boolean) as { testId: number; answer: ITestAnswer }[]);
        }
      } catch (e: any) {
        console.error(`Fetching candidate answers error, ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (isValidNumericParam(inviteId)) {
      fetchAnswers();
    }
  }, [inviteId, testIds]);

  return { answers, isLoading };
}

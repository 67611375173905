import { useEffect, useState } from "react";

import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { IClient } from "@/modules/client/clients.interface";
import { useReduxClientsHook } from "@/modules/client/hooks/useReduxClientsHook";

import { isValidNumericParam } from "@/shared/utils/common.utils";

import { HttpStatus } from "@/routing/services/statusCodes";

export interface IError {
  hasError: boolean;
  errorCode?: number;
}

export function useClientByIdHook(clientId: number | undefined) {
  const { reduxClients } = useReduxClientsHook();
  const [client, setClient] = useState<IClient>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<IError>({ hasError: false });

  const fetchClient = async () => {
    try {
      setIsLoading(true);
      const clientFromAPI: IClient = await ClientHttpService.getClientById(Number(clientId));
      if (clientFromAPI) {
        setClient(clientFromAPI);
      }
    } catch (e: any) {
      console.error(`getting clientById error: ${e}`);
      setError({ hasError: true, errorCode: e.response.data.statusCode });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      const reduxClient = reduxClients.find((client) => client.clientId === clientId);
      if (!reduxClient && isValidNumericParam(clientId) && clientId !== 0) {
        fetchClient()
      } else if (reduxClient) {
        setClient(reduxClient)
        setIsLoading(false)
      } else {
        setError({ hasError: true, errorCode: HttpStatus.NOT_FOUND });
        setIsLoading(false);
      }
    }
  }, [clientId]);

  return { client, isLoading, error };
}

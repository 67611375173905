import React from "react";
import style from "./style.module.css";
import TestElement, {
  ITestChoice,
  QuestionFontSize,
  QuestionTypes,
} from "@/shared/components/candidate/scenes/TestElement/TestElement";
import { ITestElement } from "@/modules/assessments/types/test.interface";

export interface IGroupedTestElementsContainer {
  currentAnswers: ITestChoice[];
  isTimedOutSection: boolean;
  groupHeaderText?: string;
  segment: ITestElement[];
  handleChoiceSelect: (value: ITestChoice) => void;
}

export function GroupedTestElementsContainer({
  currentAnswers,
  isTimedOutSection,
  groupHeaderText,
  segment,
  handleChoiceSelect,
}: IGroupedTestElementsContainer) {
    return (
      <div className={style.groupedContainer}>
        {groupHeaderText && <p className={style.groupHeader}>{groupHeaderText}</p>}
        {segment.map((element) => (
          <div key={element.elementId} className={style.groupedTestItem}>
            <TestElement
              id={element.elementId}
              required={element.config.required}
              disableChoices={isTimedOutSection}
              choices={element.config.payload.choices}
              currentAnswer={currentAnswers.find((answer) => answer.elementId === element.elementId)?.choiceKey}
              questionType={element.config.type}
              question={
                element.config.type === QuestionTypes.Text
                  ? element.config.payload.body
                  : element.config.payload.question
              }
              questionNumber={element.questionNumber}
              questionFontSize={QuestionFontSize.large}
              onChoiceSelect={handleChoiceSelect}
            />
          </div>
        ))}
      </div>
    );
  }

import axios from "axios";
import { Firebase } from "../firebase";
import { FIREBASE_ACCESS_TOKEN } from "../../hooks/auth/useFirebaseAuth.hook";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use(async (config) => {
  const firebase = new Firebase();
  const isExpiredToken = await firebase.isExpiredToken();

  const token = (await firebase.getToken(isExpiredToken)) ?? localStorage.getItem(FIREBASE_ACCESS_TOKEN);

  if (token) {
    localStorage.setItem(FIREBASE_ACCESS_TOKEN, token);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

import { IComputeTestScorePayload } from "@/shared/components/assessment/service/computeTest.service";
import { IComputeAssessmentScorePayload } from "@/shared/components/assessment/service/computeAssessment.service";
import { httpClient } from "@/shared/services/http";

export class ComputeHttpService {
  static async computeTestScore(testId: number, payload: IComputeTestScorePayload) {
    const { data: score } = await httpClient.post(`api/tests/${testId}/answers`, payload);
    return score;
  }
  static async computeAssessmentScore(payload: IComputeAssessmentScorePayload) {
    const { data: score } = await httpClient.post(`api/assessments/scores`, payload);
    return score;
  }
}

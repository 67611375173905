import { CustomButton } from "@/ui-components/commonComponents/Button/Button";

import style from "../style.module.css";

export function TimeOutModalFooter({
  okText,
  cancelText,
  handleOk,
  handleCancel,
}: {
  okText?: string;
  cancelText?: string;
  handleOk?: () => void;
  handleCancel?: () => void;
}) {
  return (
    <div className={style.actionButtons}>
      <CustomButton key="ok" title={okText || "next"} onClick={handleOk} />
      <CustomButton key="cancel" title={cancelText || "close"} onClick={handleCancel} />
    </div>
  );
}

import { ICompetency } from "@/shared/components/competencies/types";
import { getUniqueElements } from "@/shared/components/charts/utils/transformDataToChartData";

import { sortElements } from "@/shared/utils/array/sort.utils";
import { isValidNumericParam } from "@/shared/utils/common.utils";

import { ChartCategories, IChart, IMultiChart } from "../types/chartTypes";

interface ICompetencyWithMultipleValues {
  competency: string;
  values: number[];
}

export enum ChartDataType {
  ptr5,
  ptr100,
}
export const chartMaxValues: { [key: number]: number } = {
  [ChartDataType.ptr5]: 5,
  [ChartDataType.ptr100]: 100,
};

const getValuesFullList = (allValuesOfSingleCompetencyList: ICompetencyWithMultipleValues[]): number[] => {
  return allValuesOfSingleCompetencyList.map((item) => item.values).flat();
};

export function getRadarTicksCount(
  competencyWithMultipleValuesList: ICompetencyWithMultipleValues[],
  chartType: ChartDataType,
): number {
  const values = getValuesFullList(competencyWithMultipleValuesList);
  const MAX_VALUE: number = chartMaxValues[chartType];
  const MIN_VALUE: number = Math.min(0, Math.min(...values) - 1);
  return MAX_VALUE - MIN_VALUE;
}

export function getBarTicksCount(
  competencyWithMultipleValuesList: ICompetencyWithMultipleValues[],
  chartType: ChartDataType,
): number {
  const values = getValuesFullList(competencyWithMultipleValuesList);
  let MIN_VALUE: number;
  const MAX_VALUE: number = chartMaxValues[chartType];
  const min: number = Math.min(0, ...values);

  if (min >= 0) {
    MIN_VALUE = 0;
  } else {
    MIN_VALUE = min;
  }
  return MAX_VALUE - MIN_VALUE;
}

export const getAllValuesOfSingleCompetency = (chartData: IChart[]): ICompetencyWithMultipleValues[] => {
  if (chartData.length !== 0) {
    const dataKeys: string[] = Object.keys(chartData[0]).filter((key) => isValidNumericParam(key));
    return chartData.map((data) => {
      return { competency: data.competency, values: dataKeys.map((key) => data[Number(key)]).flat() };
    });
  }
  return [{ competency: "", values: [] }];
};

export const getChartDataWithHiddenScores = (
  allValuesOfSingleCompetencyList: ICompetencyWithMultipleValues[],
  initialChartData: IChart[],
): IChart[] => {
  const leftCompetencies = allValuesOfSingleCompetencyList
    .filter((item) => getUniqueElements(item.values).length !== 1)
    .map((item) => {
      return item.competency;
    });
  return initialChartData.filter((x) => leftCompetencies.includes(x.competency));
};

export const sortDataByNameAndTestRank = (usersChartData: IMultiChart[]): IMultiChart[] => {
  return usersChartData.map((data) => {
    const sortedChartData = data.chartData.sort((a, b) => {
      if (a.testRank !== b.testRank) {
        return a.testRank - b.testRank;
      } else {
        return a.competencyName.toUpperCase().localeCompare(b.competencyName.toUpperCase());
      }
    });
    return {
      ...data,
      chartData: sortedChartData,
    };
  });
};

export const divideChartDataByCategories = (usersChartData: IMultiChart[]): Map<ChartCategories, IMultiChart[]> => {
  const categorizedChartData = new Map<ChartCategories, IMultiChart[]>();
  if (usersChartData.length) {
    const uniqueChartsCategories: ChartCategories[] = Array.from(
      new Set(usersChartData[0].chartData.map((item) => item.chartCategory)),
    );

    uniqueChartsCategories.forEach((category) => {
      const chartDataRelatedToUser = usersChartData.map((userData) => {
        const chartPointsData = userData.chartData.filter((item) => item.chartCategory === category);
        return { ...userData, chartData: chartPointsData };
      });
      categorizedChartData.set(category, chartDataRelatedToUser);
    });
  }
  return categorizedChartData;
};

export const sortByTestOrderColorAndName = (chartValues: IChart[]): IChart[] => {
  return chartValues.sort((a, b) => {
    if (a.testRank !== b.testRank) {
      return a.testRank - b.testRank;
    }

    const firstColor = a.reportCompetencyColor || a.testColor;
    const secondColor = b.reportCompetencyColor || b.testColor;

    if (firstColor !== secondColor) {
      return firstColor.localeCompare(secondColor);
    }

    return sortElements(a.competencyName, b.competencyName);
  });
};

export enum ReportProps {
  reportColor,
  explanation,
}

export const getCompetencyProperty = (
  competencies: ICompetency[],
  base64Competency: string,
  testId: number,
  property: ReportProps,
): string | undefined => {
  const testCompetencies: ICompetency[] = competencies
    .flat()
    .filter(({ testId: competencyTestId }) => testId === competencyTestId);
  const competency = testCompetencies.find(({ key }) => key === base64Competency);
  return competency
    ? property === ReportProps.explanation
      ? competency.explanation
      : property === ReportProps.reportColor
      ? competency.reportColor
      : undefined
    : undefined;
};
